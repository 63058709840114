const authActions = {
    CANDIDATE_SINUP:'CANDIDATE_SINUP',
    CANDIDATE_LOGIN:'CANDIDATE_LOGIN',
    CANDIDATE_LOGOUT:'CANDIDATE_LOGOUT',
    CANDIDATE_FORGETPASSWORD:'CANDIDATE_FORGETPASSWORD',
    CANDIDATE_TOKEN_VERIFY: 'CANDIDATE_TOKEN_VERIFY',
    SET_CANDIDATE_TOKEN_DETAILS: 'SET_CANDIDATE_TOKEN_DETAILS',
    CHECK_DUPLICATE_EMAIL_OR_MOBILENO: 'CHECK_DUPLICATE_EMAIL_OR_MOBILENO',

    VERIFY_EMAILID_FOR_SIGNUP: 'VERIFY_EMAILID_FOR_SIGNUP',
    VERIFY_MOBILENO_FOR_SIGNUP: 'VERIFY_MOBILENO_FOR_SIGNUP',
    SEND_OTP_FOR_MOBILE_VERIFICATION: 'SEND_OTP_FOR_MOBILE_VERIFICATION',
    CHECK_EMAIL_VERIFICAITON: 'CHECK_EMAIL_VERIFICAITON',
    RESEND_EMAIL_FOR_VERIFICATION: 'RESEND_EMAIL_FOR_VERIFICATION',
    CHECK_CANDIDATE_MOBILENO: 'CHECK_CANDIDATE_MOBILENO',
    FORGOTPASSWORD_MOBILEVERIFICATION: 'FORGOTPASSWORD_MOBILEVERIFICATION',
    FORGOTPASSWORD_MOBILEVERIFICATION_SENDMAILID: 'FORGOTPASSWORD_MOBILEVERIFICATION_SENDMAILID',
    CHANGE_PASSWORD:'CHANGE_PASSWORD',
    FORGOTMAIL_OTP_VERIFY:'FORGOTMAIL_OTP_VERIFY',
    
    GET_PROFILE_REGISTRATION_ACTIVE_TAB: 'GET_PROFILE_REGISTRATION_ACTIVE_TAB',
    SET_PROFILE_REGISTRATION_ACTIVE_TAB: 'SET_PROFILE_REGISTRATION_ACTIVE_TAB',
    CANDIDATE_RESET_PASSWORD: 'CANDIDATE_RESET_PASSWORD',
    CANDIDATE_FORGET_EMAILID: 'CANDIDATE_FORGET_EMAILID',

    CHECK_KEYCLOAK_SID : 'CHECK_KEYCLOAK_SID',
    GET_TEMP_CANDIDATE_DETAILS:'GET_TEMP_CANDIDATE_DETAILS',
    SET_TEMP_CANDIDATE_DETAILS:'SET_TEMP_CANDIDATE_DETAILS',
    


};

export default authActions;