import masterActions from "./action";

const initialState = {
  gender: [],
  mothertongue: [],
  religion: [],
  region: [],
  nationality: [],
  annualIncome: [],
  state: [],
  district: [],
  taluka: [],
  village: [],
  category: [],
  caste: [],
  pwdType: [],
  pwdSubType: [],
  board: [],
  language: [],
  allDistrict: [],
  grievance: [],
  minority: [],
  documentType: [],
  // stateForReg: {
  //   parmanent: [],
  //   corespondence: []
  // },
  districtForReg: {
    parmanent: [],
    corespondence: []
  },
  talukaForReg: {
    parmanent: [],
    corespondence: []
  },
  villageForReg: {
    parmanent: [],
    corespondence: []
  },
  districtForCor: {
    parmanent: [],
    corespondence: []
  },
  talukaForCor: {
    parmanent: [],
    corespondence: []
  },
  villageForCor: {
    parmanent: [],
    corespondence: []
  },
  sportParticipationLevel:[],
  sportRank:[],
  sportName:[],
  sportBody:[],
  MaritalStatus:[],
  orphanType:[],
  ewsDistrict:[],
  ewsTaluka:[],
  cvcDistrict:[],
  sscSchoolDistrict:[],
  sscSchoolTaluka:[],
  hscSchoolDistrict:[],
  hscSchoolTaluka:[],
  graduationBranchName:[],
  awardedClass:[],
  nclAuthority:[],
  educationClass:[],
  educationStream:[],
  issuingAuthority:[],
  specialisationSubject:[],
  specialisationMethod:[],
  cetExamName:[]
  }

const masterReducer = (state = initialState, action) => {
  switch (action.type) {
    case masterActions.SET_GENDER:
      return {
        ...state,
        gender: action.payload,
      };
    case masterActions.SET_MOTHERTONGUE:
      return {
        ...state,
        mothertongue: action.payload,
      };
    case masterActions.SET_RELIGION:
      return {
        ...state,
        religion: action.payload,
      };
    case masterActions.SET_REGION:
      return {
        ...state,
        region: action.payload,
      };
    case masterActions.SET_NATIONALITY:
      return {
        ...state,
        nationality: action.payload,
      };
    case masterActions.SET_ANNUALINCOME:
      return {
        ...state,
        annualIncome: action.payload,
      };
    case masterActions.SET_STATE:
      return {
        ...state,
        state: action.payload,
      };
    case masterActions.SET_DISTRICT:
      return {
        ...state,
        district: action.payload,
      };
    case masterActions.SET_TALUKA:
      return {
        ...state,
        taluka: action.payload,
      };
    case masterActions.SET_VILLAGE:
      return {
        ...state,
        village: action.payload,
      };
    case masterActions.SET_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case masterActions.SET_CASTE:
      return {
        ...state,
        caste: action.payload,
      };
    case masterActions.SET_PWD_TYPE:
      return {
        ...state,
        pwdType: action.payload,
      };
      case masterActions.SET_PWDSUB_TYPE:
        return {
          ...state,
          pwdSubType: action.payload,
        };
    case masterActions.SET_BOARD:
      return {
        ...state,
        board: action.payload,
      };
    case masterActions.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case masterActions.SET_ALL_DISTRICT:
      return {
        ...state,
        allDistrict: action.payload,
      };
    case masterActions.SET_GRIEVANCE:
      return {
        ...state,
        grievance: action.payload,
      };
    case masterActions.SET_MINORITY:
      return {
        ...state,
        minority: action.payload,
      };
    case masterActions.SET_DOCUMENT_TYPE:
      return {
        ...state,
        documentType: action.payload,
      };
    // case masterActions.SET_STATE_FOR_REGISTRATION:
    //   return {
    //     ...state,
    //     stateForReg: action.payload,
    //   };
    case masterActions.SET_DISTRICT_FOR_REGISTRATION:
      return {
        ...state,
        districtForReg: action.payload,
      };
    case masterActions.SET_TALUKA_FOR_REGISTRATION:
      return {
        ...state,
        talukaForReg: action.payload,
      };
    case masterActions.SET_VILLAGE_FOR_REGISTRATION:
      return {
        ...state,
        villageForReg: action.payload,
      };
    case masterActions.SET_DISTRICT_FOR_COURSE:
      return {
        ...state,
        districtForCor: action.payload,
      };
    case masterActions.SET_TALUKA_FOR_COURSE:
      return {
        ...state,
        talukaForCor: action.payload,
      };
    case masterActions.SET_VILLAGE_FOR_COURSE:
      return {
        ...state,
        villageForCor: action.payload,
      };
    case masterActions.SET_SPORT_PARTICIPATION_LEVEL:
      return {
        ...state,
        sportParticipationLevel: action.payload,
      };
    case masterActions.SET_SPORT_RANK:
      return {
        ...state,
        sportRank: action.payload,
      };
    case masterActions.SET_SPORT_NAME:
      return {
        ...state,
        sportName: action.payload,
      };
    case masterActions.SET_SPORT_BODY:
      return {
        ...state,
        sportBody: action.payload,
      };
    case masterActions.SET_MARITAL_STATUS:
      return {
        ...state,
        MaritalStatus: action.payload,
      };
    case masterActions.SET_ORPHAN_TYPE:
      return {
        ...state,
        orphanType: action.payload,
      };
    case masterActions.SET_EWS_DISTRICT:
      return {
        ...state,
        ewsDistrict: action.payload,
      };
    case masterActions.SET_EWS_TALUKA:
      return {
        ...state,
        ewsTaluka: action.payload,
      };
    case masterActions.SET_CVC_DISTRICT:
      return {
        ...state,
        cvcDistrict: action.payload,
      };
    case masterActions.SET_SSC_SCHOOL_DISTRICT:
      return {
        ...state,
        sscSchoolDistrict: action.payload,
      };
    case masterActions.SET_SSC_SCHOOL_TALUKA:
      return {
        ...state,
        sscSchoolTaluka: action.payload,
      };
    case masterActions.SET_HSC_SCHOOL_DISTRICT:
      return {
        ...state,
        hscSchoolDistrict: action.payload,
      };
    case masterActions.SET_HSC_SCHOOL_TALUKA:
      return {
        ...state,
        hscSchoolTaluka: action.payload,
      };
    case masterActions.SET_GRADUATION_BRANCH_NAME:
      return {
        ...state,
        graduationBranchName: action.payload,
      };
    case masterActions.SET_AWARDED_CLASS:
      return {
        ...state,
        awardedClass: action.payload,
      };
      case masterActions.SET_NCL_AUTHORITY:
      return {
        ...state,
        nclAuthority: action.payload,
      };
      case masterActions.SET_EDUCATION_CLASS:
      return {
        ...state,
        educationClass: action.payload,
      };
      case masterActions.SET_EDUCATION_STREAM:
      return {
        ...state,
        educationStream: action.payload,
      };
      case masterActions.SET_ISSUING_AUTHORITY:
      return {
        ...state,
        issuingAuthority: action.payload,
      };
      case masterActions.SET_G_SPECIALISATION_METHOD:
        return {
          ...state,
          specialisationMethod: action.payload
        }
      case masterActions.SET_G_SPECIALISATION_OR_SUBJECT:
        return {
          ...state,
          specialisationSubject: action.payload
        }  
        case masterActions.SET_CET_EXAM_NAME:
          return {
            ...state,
            cetExamName: action.payload
          }  
  

        
    default:
      return state;
  }
};

export default masterReducer;