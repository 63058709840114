import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Tooltip,
  Chip,
  Checkbox,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import masterActions from "../../redux/master/action";
import Cookies from "js-cookie";
import {
  AlphabetsValidation,
  cgpaValidation,
  percentageNumberValidation,
  percentageValidation,
  NumbersValidation,
  markValidation,
  outOfMarkValidation,
} from "../../utils/validations";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../common/backButton";
import SubmitButton from "../../common/submitButton";
import candidatesActions from "../../redux/candidates/actions";
import { masterFieldLabel, isFieldDisabled } from "../../utils/courseValues";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/de";
import { cssProperties } from "../../utils/commonCssProperties";

export default function AcademicDetailsForm({
  nextPageName,
  previousPageName,
}) {
  const [years, setYears] = useState([]);
  const [hscYears, setHscYears] = useState([]);
  const [graduationYears, setGraduationYears] = useState([]);
  const location = useLocation();
  const courseid = location.state?.courseid;
  const statusForRedirect = location.state?.status;
  const coursename = location.state?.coursename;
  const {
    state,
    board,
    sscSchoolTaluka,
    sscSchoolDistrict,
    hscSchoolDistrict,
    hscSchoolTaluka,
    graduationBranchName,
    awardedClass,
    educationClass,
    educationStream,
    specialisationMethod,
    specialisationSubject,
    cetExamName,
  } = useSelector((state) => state.masterReducer);
  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const { masterFields } = useSelector((state) => state.candidatesReducer);
  const { courseFullRegistrationDetails, otherDetails, courseFormDetails } =
    useSelector((state) => state.candidatesReducer);

  const PGPPreference = [
    { value: 1, label: "Engineering" },
    { value: 2, label: "Pharmacy" },
    { value: 3, label: "Agriculture" },
  ];

  const options = [
    { value: 1, label: "Option 1" },
    { value: 2, label: "Option 2" },
    { value: 3, label: "Option 3" },
    { value: 4, label: "Option 4" },
    { value: 5, label: "Option 5" },
  ];

  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    watch,
    getValues,
    control,
    setError,
    clearErrors,
    formState: { errors },
    setFocus,
  } = useForm({ mode: "onChange" });

  const onError = (errors) => {
    const fError = Object.keys(errors)[0];
    if (fError) {
      setFocus(fError);
      document.getElementsByName(fError)[0]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const date = new Date();
  const currentYear = date.getFullYear();
  const isPaid = courseFormDetails[0]?.ispaid;

  useEffect(() => {
    dispatch({ type: masterActions.GET_STATE });
    dispatch({ type: masterActions.GET_BOARD });
    dispatch({ type: masterActions.GET_CET_EXAM_NAME });

    dispatch({
      type: masterActions.GET_AWARDED_CLASS_BY_ID,
      payload: courseid,
    });
    dispatch({
      type: masterActions.GET_GRADUATION_BRANCH_NAME_BY_ID,
      payload: courseid,
    });
    dispatch({ type: masterActions.GET_G_SPECIALISATION_METHOD });
    dispatch({ type: masterActions.GET_EDUCATION_CLASS });
    dispatch({ type: masterActions.GET_EDUCATION_STREAM });

    const getYears = () => {
      const array = [];
      for (let i = 1945; i <= currentYear; i++) {
        array.push(i);
      }
      return array;
    };
    setYears(getYears());
  }, []);

  // useEffect(() => {
  //   const selectedHscYear = watch("hscpassingyear");
  //   if (selectedHscYear) {
  //     const graduationStartYear = parseInt(selectedHscYear);
  //     const availableGraduationYears = years.filter(
  //       (year) => year >= graduationStartYear
  //     );
  //     setGraduationYears(availableGraduationYears);
  //   }
  //   else {
  //     const graduationStartYear = parseInt(1960);
  //     const availableGraduationYears = years.filter(
  //       (year) => year >= graduationStartYear
  //     );
  //     setGraduationYears(availableGraduationYears);

  //   }
  //   console.log('hscpassingstatus-----', getValues('hscpassingstatus'));
  // }, [watch("hscpassingyear"), years]);

  const handleProcced = (data) => {
    const corcandidateacademicid =
      courseFullRegistrationDetails[0]?.corcandidateacademicid;
    dispatch({
      type: candidatesActions.COURSE_ACADEMIC_DETAILS_CREATION,
      payload: {
        data: {
          ...data,
          operation: corcandidateacademicid
            ? parseInt(corcandidateacademicid)
            : 0,
          candidateid: parseInt(candidateid),
          courseid: courseid,
          coursename: coursename,
          status: 1,
          createdby: parseInt(candidateid),
          updatedby: corcandidateacademicid ? parseInt(candidateid) : "",
        },
        navigate: navigate,
        nextPageName: nextPageName,
        statusForRedirect: statusForRedirect,
      },
    });
    dispatch({
      type: candidatesActions.GET_COURSE_FORM_DETAILS,
      payload: { courseid: courseid },
    });
    dispatch({
      type: candidatesActions.GET_COURSE_STATUS,
      payload: { candidateid, courseid },
    });
    dispatch({
      type: candidatesActions.GET_IS_PAID,
      payload: { candidateid, courseid },
    });
  };

  const handleBack = () => {
    navigate(
      `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=${previousPageName}`,
      { state: { courseid: courseid, coursename: coursename } }
    );
  };
  useEffect(() => {
    if (
      courseFullRegistrationDetails[0]?.corcandidateacademicid &&
      courseFullRegistrationDetails[0]?.corcandidateacademicid > 0
    ) {
      dispatch({
        type: masterActions.GET_SSC_SCHOOL_DISTRICT,
        payload: courseFullRegistrationDetails[0]?.sscschoolstateid,
      });
      dispatch({
        type: masterActions.GET_SSC_SCHOOL_TALUKA,
        payload: courseFullRegistrationDetails[0]?.sscschooldistrictid,
      });
      dispatch({
        type: masterActions.GET_HSC_SCHOOL_DISTRICT,
        payload: courseFullRegistrationDetails[0]?.hsccollegestateid,
      });
      dispatch({
        type: masterActions.GET_HSC_SCHOOL_TALUKA,
        payload: courseFullRegistrationDetails[0]?.hsccollegedistrictid,
      });
      reset({ ...courseFullRegistrationDetails[0] });

      setValue(
        "sscpassingyear",
        courseFullRegistrationDetails[0]?.sscpassingyear
      );
      setValue(
        "ssctotalpercentage",
        courseFullRegistrationDetails[0]?.ssctotalpercentage
      );
      setValue(
        "sscboardid",
        parseInt(courseFullRegistrationDetails[0]?.sscboardid)
      );
      setValue(
        "sscschoolstateid",
        courseFullRegistrationDetails[0]?.sscschoolstateid
      );
      dispatch({
        type: masterActions.GET_SSC_SCHOOL_DISTRICT,
        payload: getValues("sscschoolstateid"),
      });
      setValue("hscboardid", courseFullRegistrationDetails[0]?.hscboardid);
      setValue(
        "hscmarksobtained",
        courseFullRegistrationDetails[0]?.hscmarksobtained
      );
      setValue(
        "hscmarksoutof",
        courseFullRegistrationDetails[0]?.hscmarksoutof
      );
      setValue(
        "hscpassingyear",
        courseFullRegistrationDetails[0]?.hscpassingyear
      );
      setValue(
        "hscpercentage",
        courseFullRegistrationDetails[0]?.hscpercentage
      );
      setValue("hscmarktype", courseFullRegistrationDetails[0]?.marktype);
      setValue(
        "hscpassingstatus",
        courseFullRegistrationDetails[0]?.hscpassingstatus
      );
      trigger("hscpassingyear");
      trigger("graduationpassingyear");
    } else if (otherDetails?.length > 0) {
      reset({ ...otherDetails[0] });

      dispatch({
        type: masterActions.GET_HSC_SCHOOL_DISTRICT,
        payload: otherDetails[0]?.hsccollegestateid,
      });
      dispatch({
        type: masterActions.GET_HSC_SCHOOL_TALUKA,
        payload: otherDetails[0]?.hsccollegedistrictid,
      });
      dispatch({
        type: masterActions.GET_SSC_SCHOOL_DISTRICT,
        payload: courseFullRegistrationDetails[0]?.sscschoolstateid,
      });
      dispatch({
        type: masterActions.GET_SSC_SCHOOL_TALUKA,
        payload: courseFullRegistrationDetails[0]?.sscschooldistrictid,
      });
      setValue("sscpassingyear", otherDetails[0]?.sscpassingyear);
      setValue("ssctotalpercentage", otherDetails[0]?.ssctotalpercentage);
      setValue("sscboardid", otherDetails[0]?.sscboardid);
      setValue("sscschoolstateid", otherDetails[0]?.sscschoolstateid);
      dispatch({
        type: masterActions.GET_SSC_SCHOOL_DISTRICT,
        payload: getValues("sscschoolstateid"),
      });
      setValue("hscboardid", otherDetails[0]?.hscboardid);
      setValue("hscmarksobtained", otherDetails[0]?.hscmarksobtained);
      setValue("hscmarksoutof", otherDetails[0]?.hscmarksoutof);
      setValue("hscpassingyear", otherDetails[0]?.hscpassingyear);
      setValue("hscpercentage", otherDetails[0]?.hscpercentage);
      setValue("hscmarktype", otherDetails[0]?.marktype);
      setValue("hscpassingstatus", otherDetails[0]?.appearingfor);

      trigger("hscpassingyear");
      trigger("graduationpassingyear");
    }
  }, [courseFullRegistrationDetails, otherDetails]);

  useEffect(() => {
    if (candidateid) {
      dispatch({
        type: candidatesActions.GET_COURSE_REGISTRATION_DETAILS,
        payload: {
          courseid: courseid,
        },
      });
      dispatch({ type: candidatesActions.GET_OTHER_PERSONAL_DETAILS });
    }
  }, [candidateid]);

  useEffect(() => {
    if (courseid) {
      dispatch({
        type: candidatesActions.GET_COURSE_FORM_DETAILS,
        payload: { courseid: courseid },
      });
      dispatch({
        type: candidatesActions.GET_MASTER_FIELDS,
        payload: {
          data: { courseid: courseid },
        },
      });
    }
  }, [courseid]);

  // mark percentage calculation

  const months = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const [CheckboxValue, setCheckBoxValue] = useState(false);

  const handleCheckbox = (e) => {
    const checked = e.target.checked;
    setCheckBoxValue(checked);
  };

  // useEffect(() => {
  //   const marksObtained = parseInt(watch("fymarksobtained")) || 0;
  //   const marksOutOf = parseInt(watch("fymarksoutof")) || 0;
  //   if (marksOutOf > 0) {
  //     const calculatedPercentage = ((marksObtained / marksOutOf) * 100).toFixed(
  //       2
  //     );
  //     setValue("fypercentage", calculatedPercentage);
  //   } else {
  //     setValue("fypercentage", "");
  //   }
  // }, [watch("fymarksobtained"), watch("fymarksoutof")]);

  // const symarksobtained = watch('symarksobtained');
  // const symarksoutof = watch('symarksoutof');

  // useEffect(() => {
  //   if (symarksobtained && symarksoutof) {
  //     const obtained = parseFloat(symarksobtained);
  //     const outOf = parseFloat(symarksoutof);
  //     if (!isNaN(obtained) && !isNaN(outOf) && outOf !== 0) {
  //       const percentage = (obtained / outOf) * 100;
  //       setValue('sypercentage', percentage.toFixed(2));
  //     }
  //   }
  // }, [symarksobtained, symarksoutof, setValue]);

  return (
    <Grid
      p={3}
      style={{
        padding: "20px 25px 0px 25px",
        minHeight: "60vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
        border: "1px solid #0F4F96",
      }}
    >
      {masterFields?.find(
        (field) => field.masterfieldlabel === masterFieldLabel.SSC_PASSING_YEAR
      ) && (
        <Grid item xs={12} md={12}>
          <Typography
            pt={1}
            pb={1}
            sx={{
              fontSize: "24px",
              color: `${cssProperties?.color?.tertiary}`,
              fontWeight: 500,
              lineHeight: "30px",
            }}
          >
            {" "}
            SSC INFORMATION
          </Typography>
        </Grid>
      )}
      <Grid container spacing={2}>
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SSC_PASSING_YEAR
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.sscpassingyear}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SSC_PASSING_YEAR
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("sscpassingyear"),
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="sscpassingyear"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_PASSING_YEAR
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.SSC_PASSING_YEAR
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("sscpassingyear", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_PASSING_YEAR
                      )?.ismandatory === "1"
                        ? "SSC Passing Year is required"
                        : false,
                  })}
                  value={watch("sscpassingyear") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.SSC_PASSING_YEAR,
                    null,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("sscpassingyear", e.target.value);
                      trigger("sscpassingyear");
                    },
                  }}
                  error={!!errors.sscpassingyear}
                  helperText={
                    errors.sscpassingyear ? errors.sscpassingyear.message : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {/* {years?.map((year, index) => (
                    <MenuItem key={index} value={year}>
                      {year}
                    </MenuItem>
                  ))} */}
                  {Array.from(
                    { length: new Date().getFullYear() - 1945 },
                    (_, i) => 1945 + i
                  ).map((year, index) => (
                    <MenuItem key={index} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.SELECT_SSC_EQUIVALENT_PASSING_MONTH
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.sscpassingmonth}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SELECT_SSC_EQUIVALENT_PASSING_MONTH
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("sscpassingmonth"),
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="sscpassingmonth"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SELECT_SSC_EQUIVALENT_PASSING_MONTH
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.SELECT_SSC_EQUIVALENT_PASSING_MONTH
                        )?.fieldlabel
                      }
                    </span>
                  }
                  {...register("sscpassingmonth", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SELECT_SSC_EQUIVALENT_PASSING_MONTH
                      )?.ismandatory === "1"
                        ? "SSC/Equivalent Passing Month is required"
                        : false,
                  })}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("sscpassingmonth", e.target.value);
                      trigger("sscpassingmonth");
                    },
                  }}
                  error={!!errors.sscpassingmonth}
                  helperText={
                    errors.sscpassingmonth ? errors.sscpassingmonth.message : ""
                  }
                  value={watch("sscpassingmonth") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.SELECT_SSC_EQUIVALENT_PASSING_MONTH,
                    null,
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {months.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SSC_SEAT_NUMBER
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.SSC_SEAT_NUMBER
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SSC_SEAT_NUMBER
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_SEAT_NUMBER
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="Enter Caste Name"
                {...register("sscseatno", {
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SSC_SEAT_NUMBER
                    )?.ismandatory == "1"
                      ? "\u00A0 \u00A0 SSC seat number is required"
                      : false,
                })}
                // onInput={AlphabetsValidation}
                error={!!errors.sscseatno}
                helperText={errors.sscseatno?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("sscseatno"),
                }}
                disabled={isFieldDisabled(
                  masterFields,
                  masterFieldLabel.SSC_SEAT_NUMBER,
                  null,

                  isPaid
                )}
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SSC_CLASS
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.sscclass}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.SSC_CLASS
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("sscclass"),
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="sscclass"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel === masterFieldLabel.SSC_CLASS
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.SSC_CLASS
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("sscclass", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel === masterFieldLabel.SSC_CLASS
                      )?.ismandatory === "1"
                        ? "SSC Class Name is required"
                        : false,
                  })}
                  value={watch("sscclass") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.SSC_CLASS,
                    null,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("sscclass", e.target.value);
                      trigger("sscclass");
                    },
                  }}
                  error={!!errors.sscclass}
                  helperText={errors.sscclass ? errors.sscclass.message : ""}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {educationClass?.map((val, i) => (
                    <MenuItem key={i} value={val?.educationclassid}>
                      {val?.educationclass}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SSC_TOTAL_PERCENTAGE
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.SSC_TOTAL_PERCENTAGE
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SSC_TOTAL_PERCENTAGE
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_TOTAL_PERCENTAGE
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="SSC/Equivalent Total Percentage*"
                {...register("ssctotalpercentage", {
                  required:
                    // getValues("hscpassingstatus") != 1 ||
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SSC_TOTAL_PERCENTAGE
                    )?.ismandatory != "1"
                      ? false
                      : "SSC Total percentage is required",
                  // validate: percentageValidation,
                })}
                onInput={percentageNumberValidation}
                value={
                  parseFloat(getValues("ssctotalpercentage")) > 100
                    ? 100
                    : getValues("ssctotalpercentage")
                }
                error={!!errors.ssctotalpercentage}
                helperText={errors.ssctotalpercentage?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("ssctotalpercentage"),
                }}
                disabled={isFieldDisabled(
                  masterFields,
                  masterFieldLabel.SSC_TOTAL_PERCENTAGE,
                  null,
                  isPaid
                )}
              />
            </Tooltip>
            <Box>
              <small style={{ color: "#666666" }}>
                Up to 2 decimal places are allowed
              </small>
            </Box>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SSC_BOARD
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.sscboardid}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.SSC_BOARD
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("sscboardid") || watch("sscboardid") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="sscboardid"
                  label={
                    <span>
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.SSC_BOARD
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.SSC_BOARD
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("sscboardid", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel === masterFieldLabel.SSC_BOARD
                      )?.ismandatory === "1"
                        ? "SSC Board is required"
                        : false,
                  })}
                  value={watch("sscboardid") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.SSC_BOARD,
                    null,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      const id = e.target.value;

                      const filterId = board?.find(
                        (row) => row.boardid === parseInt(id)
                      );

                      const finalId = filterId?.boardstateid;

                      setValue(
                        "sscschoolstateid",
                        parseInt(finalId) == -2 ? null : parseInt(finalId)
                      );
                      dispatch({
                        type: masterActions.GET_SSC_SCHOOL_DISTRICT,
                        payload: parseInt(finalId),
                      });
                      setValue("sscboardid", id);
                      clearErrors("sscboardid");

                      // const id = e.target.value;
                      // setValue("sscboardid", id);
                      // setValue("sscschoolstateid", id);

                      // setValue("sscschoolstateid", id);
                      if (e != 58) {
                        clearErrors("sscotherboardname");
                      }
                      if (e == 58) {
                        setValue("sscschoolstateid", "");
                      }
                    },
                  }}
                  error={!!errors.sscboardid}
                  helperText={
                    errors.sscboardid ? errors.sscboardid.message : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {board?.map((val, i) => (
                    <MenuItem value={val?.boardid} key={i}>
                      {val?.board}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SSC_OTHER_BOARD_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.SSC_OTHER_BOARD_NAME
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {watch("sscboardid") == 58 && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_OTHER_BOARD_NAME
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="Enter SSC Other Board Name"
                {...register("sscotherboardname", {
                  required:
                    watch("sscboardid") == 58
                      ? "SSC Other Board is required"
                      : false,
                })}
                // onInput={AlphabetsValidation}
                error={!!errors.sscotherboardname}
                helperText={errors.sscotherboardname?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                value={
                  watch("sscboardid") == 58 ? watch("sscotherboardname") : ""
                }
                InputLabelProps={{
                  shrink: !!watch("sscotherboardname"),
                }}
                disabled={watch("sscboardid") != 58}
              />
            </Tooltip>
          </Grid>
        )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SSC_SCHOOL_DISTRICT
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.sscschooldistrictid}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SSC_SCHOOL_DISTRICT
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink:
                      !!watch("sscschooldistrictid") ||
                      watch("sscschooldistrictid") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="sscschooldistrictid"
                  label={
                    <span>
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.SSC_SCHOOL_DISTRICT
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.SSC_SCHOOL_DISTRICT
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("sscschooldistrictid", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_SCHOOL_DISTRICT
                      )?.ismandatory === "1"
                        ? "SSC School District is required"
                        : false,
                  })}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      const districtId = e.target.value;
                      setValue("sscschooldistrictid", e.target.value);
                      dispatch({
                        type: masterActions.GET_SSC_SCHOOL_TALUKA,
                        payload: districtId,
                      });

                      trigger("sscschooldistrictid");
                    },
                  }}
                  error={!!errors.sscschooldistrictid}
                  helperText={
                    errors.sscschooldistrictid
                      ? errors.sscschooldistrictid.message
                      : ""
                  }
                  value={watch("sscschooldistrictid") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.SSC_SCHOOL_DISTRICT,
                    null,
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={0}>Not Applicable</MenuItem>
                  {getValues("sscschoolstateid") !== 0 &&
                    getValues("sscschoolstateid") !== -1 &&
                    sscSchoolDistrict?.map((val, i) => (
                      <MenuItem key={i} value={val?.districtid}>
                        {val?.district}
                      </MenuItem>
                    ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SSC_SCHOOL_TALUKA
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.sscschooltalukaid}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SSC_SCHOOL_TALUKA
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink:
                      !!watch("sscschooltalukaid") ||
                      watch("sscschooltalukaid") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="sscschooltalukaid"
                  label={
                    <span>
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.SSC_SCHOOL_TALUKA
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.SSC_SCHOOL_TALUKA
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("sscschooltalukaid", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_SCHOOL_TALUKA
                      )?.ismandatory === "1"
                        ? "SSC School Taluka is required"
                        : false,
                  })}
                  onChange={(e) => {}}
                  value={watch("sscschooltalukaid") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.SSC_SCHOOL_TALUKA,
                    null,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("sscschooltalukaid", e.target.value);
                      trigger("sscschooltalukaid");
                    },
                  }}
                  error={!!errors.sscschooltalukaid}
                  helperText={
                    errors.sscschooltalukaid
                      ? errors.sscschooltalukaid.message
                      : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={0}>Not Applicable</MenuItem>
                  {sscSchoolTaluka?.map((val, i) => (
                    <MenuItem value={val?.talukaid} key={i}>
                      {val?.taluka}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SSC_PASSED_FROM_INDIA
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.sscpassedfromindia}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SSC_PASSED_FROM_INDIA
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink:
                      !!watch("sscpassedfromindia") ||
                      watch("sscpassedfromindia") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="sscpassedfromindia"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_PASSED_FROM_INDIA
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.SSC_PASSED_FROM_INDIA
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("sscpassedfromindia", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_PASSED_FROM_INDIA
                      )?.ismandatory === "1"
                        ? "SSC Passed From India is required"
                        : false,
                  })}
                  value={watch("sscpassedfromindia") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.SSC_PASSED_FROM_INDIA,
                    null,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("sscpassedfromindia", e.target.value);
                      trigger("sscpassedfromindia");
                    },
                  }}
                  error={!!errors.sscpassedfromindia}
                  helperText={
                    errors.sscpassedfromindia
                      ? errors.sscpassedfromindia.message
                      : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SSC_SCHOOL_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.SSC_SCHOOL_NAME
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SSC_SCHOOL_NAME
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_SCHOOL_NAME
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="SSC SCHOOL NAME*"
                {...register("sscschoolname", {
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SSC_SCHOOL_NAME
                    )?.ismandatory === "1"
                      ? "\u00A0 \u00A0 SSC School Name is required"
                      : false,
                })}
                // onInput={percentageNumberValidation}
                value={getValues("sscschoolname")}
                error={!!errors.sscschoolname}
                helperText={errors.sscschoolname?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("sscschoolname"),
                }}
                disabled={isFieldDisabled(
                  masterFields,
                  masterFieldLabel.SSC_SCHOOL_NAME,
                  null,
                  isPaid
                )}
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.APPEARING_FOR_HSC
        ) && (
          <Grid item xs={12} md={12}>
            <Typography
              pt={1}
              sx={{
                fontSize: "24px",
                color: `${cssProperties?.color?.tertiary}`,
                fontWeight: 500,
                lineHeight: "30px",
              }}
            >
              {" "}
              HSC INFORMATION{" "}
            </Typography>
          </Grid>
        )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.APPEARING_FOR_HSC
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.isappeared}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.APPEARING_FOR_HSC
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink:
                      !!watch("hscpassingstatus") ||
                      watch("hscpassingstatus") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="hscpassingstatus"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.APPEARING_FOR_HSC
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.APPEARING_FOR_HSC
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("hscpassingstatus", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.APPEARING_FOR_HSC
                      )?.ismandatory === "1"
                        ? "Select is required"
                        : false,
                  })}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("hscpassingstatus", e.target.value);
                      setValue("hscboardid", "");
                      setValue("hscmarksobtained", "");
                      setValue("hscmarksoutof", "");
                      setValue("hscpassingyear", "");
                      setValue("hscpassingmonth", "");
                      setValue("hscstream", "");
                      setValue("hscseatno", "");
                      setValue("hscclass", "");
                      setValue("hscpercentage", "");
                      setValue("hscmarktype", "");
                      setValue("hscenglishpercentage", "");
                      setValue("hscenglishmarksoutof", "");
                      setValue("hscenglishmarksobtained", "");
                      trigger("hscpassingstatus");
                      trigger("hsccollegename");
                      if (getValues("hscpassingstatus") === 1) {
                        clearErrors("hscpassingyear");
                        clearErrors("hscpassingmonth");
                        clearErrors("hscstream");
                        clearErrors("hscseatno");
                        clearErrors("hscclass");
                        clearErrors("hscmarktype");
                        clearErrors("hscmarksoutof");
                        clearErrors("hscenglishmarksobtained");
                        clearErrors("hscenglishmarksoutof");
                        clearErrors("hscenglishpercentage");
                        clearErrors("hscpercentage");
                        clearErrors("hscmarksobtained");
                      }
                    },
                  }}
                  error={!!errors.hscpassingstatus}
                  helperText={
                    errors.hscpassingstatus
                      ? errors.hscpassingstatus.message
                      : ""
                  }
                  value={watch("hscpassingstatus") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.APPEARING_FOR_HSC,
                    null,
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_PASSING_YEAR
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.hscpassingyear}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_PASSING_YEAR
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("hscpassingyear"),
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="hscpassingyear"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_PASSING_YEAR
                      )?.ismandatory === "1" &&
                        !(
                          getValues("hscpassingstatus") == 1 || isPaid === "1"
                        ) && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.HSC_PASSING_YEAR
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  // {...register("hscpassingyear", {
                  //   required:
                  //     getValues("hscpassingstatus") == 1 ||
                  //       masterFields?.find(
                  //         (field) =>
                  //           field?.masterfieldlabel ===
                  //           masterFieldLabel.HSC_PASSING_YEAR
                  //       )?.ismandatory !== "1"
                  //       ? false
                  //       : " HSC/Diploma Passing Year is required",
                  // })}
                  {...register("hscpassingyear", {
                    required:
                      getValues("hscpassingstatus") == 1 ||
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_PASSING_YEAR
                      )?.ismandatory !== "1"
                        ? false
                        : "HSC/Diploma Passing Year is required",
                    validate: (value) => {
                      const sscYear = getValues("sscpassingyear");
                      if (value && sscYear && value < sscYear) {
                        return "HSC Passing Year must not be less than SSC Passing Year";
                      }
                      return true;
                    },
                  })}
                  value={watch("hscpassingyear") ?? ""}
                  // disabled={isFieldDisabled(masterFields, masterFieldLabel.HSC_PASSING_YEAR)}
                  disabled={
                    getValues("hscpassingstatus") == 1
                      ? true
                      : false || isPaid === "1"
                  }
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("hscpassingyear", e.target.value);
                      trigger("hscpassingyear");
                      setValue("graduationpassingyear", "");
                    },
                  }}
                  error={!!errors.hscpassingyear}
                  helperText={
                    errors.hscpassingyear ? errors.hscpassingyear.message : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {/* {
                      // years
                      //   ?.filter((year) => year >= (watch("sscpassingyear") + 2))
                      watch("sscpassingyear") &&
                      Array.from(
                        { length: 2024 - parseInt(watch("sscpassingyear")) + 1 },
                        (v, i) => parseInt(watch("sscpassingyear")) + 2 + i
                      )?.map((year, index) => (
                        <MenuItem key={index} value={year}>
                          {year}
                        </MenuItem>
                      ))
                    } */}
                  {watch("sscpassingyear") &&
                    Array.from(
                      {
                        length:
                          new Date().getFullYear() -
                          (parseInt(watch("sscpassingyear")) + 1),
                      },
                      (_, i) => parseInt(watch("sscpassingyear")) + 2 + i
                    )?.map((year, index) => (
                      <MenuItem key={index} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_PASSING_MONTH
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.hscpassingmonth}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_PASSING_MONTH
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("hscpassingmonth"),
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="hscpassingmonth"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_PASSING_MONTH
                      )?.ismandatory === "1" &&
                        !(
                          getValues("hscpassingstatus") == 1 || isPaid === "1"
                        ) && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.HSC_PASSING_MONTH
                        )?.fieldlabel
                      }
                    </span>
                  }
                  {...register("hscpassingmonth", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_PASSING_MONTH
                      )?.ismandatory === "1" &&
                      !(getValues("hscpassingstatus") == 1 || isPaid === "1")
                        ? "HSC Passing Month is required"
                        : false,
                  })}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("hscpassingmonth", e.target.value);
                      trigger("hscpassingmonth");
                    },
                  }}
                  error={!!errors.hscpassingmonth}
                  helperText={
                    errors.hscpassingmonth ? errors.hscpassingmonth.message : ""
                  }
                  value={watch("hscpassingmonth") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.HSC_PASSING_MONTH,
                    getValues("hscpassingyear") ? 0 : 1,
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {months.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.HSC_STREAM
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.hscstream}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.HSC_STREAM
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("hscstream"),
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="hscstream"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_STREAM
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.HSC_STREAM
                        )?.fieldlabel
                      }
                    </span>
                  }
                  {...register("hscstream", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_STREAM
                      )?.ismandatory === "1"
                        ? "HSC stream is required"
                        : false,
                  })}
                  value={watch("hscstream") ?? ""}
                  disabled={
                    isFieldDisabled(
                      masterFields,
                      masterFieldLabel.HSC_STREAM,
                      null,
                      isPaid
                    ) || isPaid === "1"
                  }
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("hscstream", e.target.value);
                      trigger("hscstream");
                    },
                  }}
                  error={!!errors.hscstream}
                  helperText={errors.hscstream ? errors.hscstream.message : ""}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {educationStream.map((stream) => (
                    <MenuItem
                      key={stream.educationstreamid}
                      value={stream.educationstreamid}
                    >
                      {stream.educationstream}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.HSC_SEAT_NUMBER
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.HSC_SEAT_NUMBER
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_SEAT_NUMBER
                    )?.ismandatory === "1" &&
                      !(
                        getValues("hscpassingstatus") == 1 || isPaid === "1"
                      ) && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_SEAT_NUMBER
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="Enter Seat Number"
                {...register("hscseatno", {
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_SEAT_NUMBER
                    )?.ismandatory == "1" &&
                    !(getValues("hscpassingstatus") == 1 || isPaid === "1")
                      ? "HSC Seat number is required"
                      : false,
                })}
                // onInput={AlphabetsValidation}
                error={!!errors.hscseatno}
                helperText={errors.hscseatno?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("hscseatno"),
                }}
                disabled={
                  isFieldDisabled(
                    masterFields,
                    masterFieldLabel.HSC_SEAT_NUMBER,
                    null,

                    isPaid
                  ) ||
                  getValues("hscpassingstatus") == 1 ||
                  isPaid === "1"
                }
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.HSC_CLASS
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.hscclass}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.HSC_CLASS
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("hscclass"),
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="hscclass"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel === masterFieldLabel.HSC_CLASS
                      )?.ismandatory === "1" &&
                        !(
                          getValues("hscpassingstatus") == 1 || isPaid === "1"
                        ) && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.HSC_CLASS
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("hscclass", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel === masterFieldLabel.HSC_CLASS
                      )?.ismandatory === "1" &&
                      !(getValues("hscpassingstatus") == 1 || isPaid === "1")
                        ? "HSC Class Name is required"
                        : false,
                  })}
                  value={watch("hscclass") ?? ""}
                  disabled={
                    isFieldDisabled(
                      masterFields,
                      masterFieldLabel.HSC_CLASS,
                      null,
                      isPaid
                    ) ||
                    getValues("hscpassingstatus") == 1 ||
                    isPaid === "1"
                  }
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("hscclass", e.target.value);
                      trigger("hscclass");
                    },
                  }}
                  error={!!errors.hscclass}
                  helperText={errors.hscclass ? errors.hscclass.message : ""}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {educationClass?.map((val, i) => (
                    <MenuItem key={i} value={val?.educationclassid}>
                      {val?.educationclass}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.HSC_BOARD
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.hscboardid}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.HSC_BOARD
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("hscboardid") || watch("hscboardid") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="hscboardid"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel === masterFieldLabel.HSC_BOARD
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.HSC_BOARD
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("hscboardid", {
                    required:
                      // getValues("hscpassingstatus") != 1 ||
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel === masterFieldLabel.HSC_BOARD
                      )?.ismandatory != "1"
                        ? false
                        : "HSC/Diploma/Equivalent Board is required",
                  })}
                  value={watch("hscboardid") ?? ""}
                  disabled={isPaid === "1"}
                  error={!!errors?.hscboardid}
                  helperText={errors.hscboardid?.message}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("hscboardid", e.target.value);

                      trigger("hscboardid");
                      const selectedBoard = board.find(
                        (val) => val.boardid === e.target.value
                      )?.board;
                      setValue("hscboard", selectedBoard);
                      trigger("hscboardid");
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {board?.map((val, i) => (
                    <MenuItem value={val?.boardid} key={i}>
                      {val?.board}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_OTHER_BOARD_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.HSC_OTHER_BOARD_NAME
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {watch("hscboardid") == 58 && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_OTHER_BOARD_NAME
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="Enter HSC Other Board Name"
                {...register("hscotherboardname", {
                  required:
                    watch("hscboardid") == 58
                      ? "SSC Other Board is required"
                      : false,
                })}
                // onInput={AlphabetsValidation}
                error={!!errors.hscotherboardname}
                helperText={errors.hscotherboardname?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                value={
                  watch("hscboardid") == 58 ? watch("hscotherboardname") : ""
                }
                InputLabelProps={{
                  shrink: !!watch("hscotherboardname"),
                }}
                disabled={watch("hscboardid") != 58}
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.HSC_MARKS_TYPE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.hscmarktype}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_MARKS_TYPE
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink:
                      !!watch("hscmarktype") || watch("hscmarktype") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="hscmarktype"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_MARKS_TYPE
                      )?.ismandatory === "1" &&
                        !(
                          getValues("hscpassingstatus") == 1 || isPaid === "1"
                        ) && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.HSC_MARKS_TYPE
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("hscmarktype", {
                    required:
                      getValues("hscpassingstatus") == 1
                        ? false
                        : "Mark type is required",
                  })}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("hscmarktype", e.target.value);
                      trigger("hscmarktype");
                      setValue("hscpercentage", "");
                      setValue("hscmarksobtained", "");
                      setValue("hscmarksoutof", "");
                      clearErrors([
                        "hscmarksobtained",
                        "hscmarksoutof"
                      ])
                      const value = parseFloat(getValues("hscmarksobtained"));
                      const maxValue = parseFloat(getValues("hscmarksoutof"));
                      if (getValues("hscmarktype") != 1) {
                        clearErrors("hscpercentage");
                      }
                      let percentage = (value / maxValue) * 100;
                      parseInt(e.target.value) === 2 &&
                        setValue(
                          "hscpercentage",
                          !isNaN(percentage)
                            ? parseFloat(percentage).toFixed(2)
                            : ""
                        );
                    },
                  }}
                  error={!!errors.hscmarktype}
                  helperText={
                    errors.hscmarktype ? errors.hscmarktype.message : ""
                  }
                  value={watch("hscmarktype") ?? ""}
                  disabled={
                    getValues("hscpassingstatus") == 1 || isPaid === "1"
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={2}>Percentage</MenuItem>
                  <MenuItem value={1}>CGPA</MenuItem>
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_MARKS_OBTAINED
        ) && (
          // <Grid item xs={12} md={6}>
          //   <TextField
          //     fullWidth
          //     variant="outlined"
          //     type="text"
          //     sx={{
          //       "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
          //       {
          //         display: "none",
          //       },
          //       "& input[type=number]": {
          //         MozAppearance: "textfield",
          //       },
          //     }}
          //     label={
          //       <span>
          //         {masterFields?.find(
          //           (field) =>
          //             field?.masterfieldlabel ===
          //             masterFieldLabel.HSC_MARKS_OBTAINED
          //         )?.ismandatory === "1" &&
          //           !(
          //             watch("hscpassingstatus") == 1 ||
          //             isPaid === "1" ||
          //             watch("hscmarktype") === ""
          //           ) && (
          //             <span className="error" style={{ color: "red" }}>
          //               {" "}
          //               *
          //             </span>
          //           )}
          //         {
          //           masterFields?.find(
          //             (field) =>
          //               field?.masterfieldlabel ===
          //               masterFieldLabel.HSC_MARKS_OBTAINED
          //           )?.fieldlabel
          //         }

          //       </span>
          //     }
          //     placeholder="“HSC / Diploma Marks / CGPA Obtained"
          //     {...register("hscmarksobtained", {
          //       required:
          //         getValues("hscpassingstatus") == 1
          //           ? false
          //           : "“HSC / Diploma Marks / CGPA Obtained is required",
          //       validate: (value) => {
          //         return watch("hscmarktype") === 1
          //           ? cgpaValidation(value)
          //           : markValidation(value);
          //       },
          //     })}
          //     InputLabelProps={{
          //       shrink: !!watch("hscmarksobtained"),
          //     }}

          //     onInput={
          //       watch("marktype") === 1
          //         ? cgpaValidation
          //         : markValidation
          //     }
          //     onChange={(e) => {
          //       if (watch("hscmarktype") === 2) {
          //         percentageValidation();
          //       } else {
          //         cgpaValidation();
          //       }
          //       const value = parseInt(e.target.value);
          //       const maxValue = parseInt(getValues("hscmarksoutof"));
          //       const maxAllowed = maxValue <= 900 ? maxValue : 900;
          //       if (value > maxAllowed) {
          //         e.target.value = maxAllowed;
          //       }
          //       setValue("hscmarksobtained", e.target.value);
          //       if (watch("hscmarktype") === 2) {
          //         percentageValidation();
          //       } else {
          //         const cgpaError = cgpaValidation(value);
          //         if (cgpaError) {
          //           setError("hscmarksobtained", {
          //             type: "manual",
          //             message: cgpaError,
          //           });
          //         }
          //       }
          //       let percentage = (value / maxValue) * 100;
          //       watch("hscmarktype") == 2 &&
          //         setValue(
          //           "hscpercentage",
          //           !isNaN(percentage) ? parseFloat(percentage).toFixed(2) : ""
          //         );
          //       let cgpa = (value / maxValue) * 10
          //       watch("hscmarktype") == 1 &&
          //         setValue(
          //           "hscpercentage",
          //           !isNaN(cgpa) ? parseFloat(cgpa).toFixed(2) : "");
          //     }}
          //     InputProps={{ inputMode: "decimal" }}
          //     error={!!errors.hscmarksobtained}
          //     helperText={errors.hscmarksobtained?.message}
          //     FormHelperTextProps={{
          //       style: { margin: 0 },
          //     }}

          //     disabled={
          //       watch("hscpassingstatus") == 1 ||
          //       isPaid === "1" ||
          //       watch("hscmarktype") === ""
          //     }
          //   />
          // </Grid>
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.HSC_MARKS_OBTAINED
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                // sx={{
                //   "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                //     {
                //       display: "none",
                //     },
                //   "& input[type=number]": {
                //     MozAppearance: "textfield",
                //   },
                // }}
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_MARKS_OBTAINED
                    )?.ismandatory === "1" &&
                      !(
                        watch("hscpassingstatus") == 1 ||
                        isPaid === "1" ||
                        watch("hscmarktype") === ""
                      ) && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_MARKS_OBTAINED
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="HSC / Diploma Marks / CGPA Obtained"
                {...register("hscmarksobtained", {
                  required:
                    !(
                      watch("hscpassingstatus") == 1 ||
                      isPaid === "1" ||
                      watch("hscmarktype") === ""
                    ) && "HSC / Diploma Marks / CGPA Obtained is required",
                  validate: (value) =>
                    watch("hscmarktype") === 1
                      ? cgpaValidation(value)
                      : percentageNumberValidation(value),
                })}
                InputLabelProps={{
                  shrink: !!watch("hscmarksobtained"),
                }}
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  // const numericValue = NumbersValidation(e);
                  const maxValue = parseFloat(getValues("hscmarksoutof"));
                  setValue("hscmarksobtained", e.target.value);
                  trigger("hscmarksobtained");
                  trigger("hscmarksoutof");
                  clearErrors("hscmarksobtained");
                  if (value > maxValue) {
                    setError("hscmarksoutof", {
                      type: "manual",
                      message:
                        "Marks out of must be greater than or equal to marks obtained",
                    });
                  }
                  if (watch("hscmarktype") === 2) {
                    const percentage = (value / maxValue) * 100;
                    setValue(
                      "hscpercentage",
                      !isNaN(percentage) ? percentage.toFixed(2) : ""
                    );
                    if (!markValidation(value)) {
                      clearErrors("hscmarksobtained");
                    } else {
                      setError("hscmarksobtained", {
                        type: "manual",
                        message: "Invalid percentage",
                      });
                    }
                  } else if (watch("hscmarktype") === 1) {
                    const cgpa = (value / maxValue) * 100;
                    setValue(
                      "hscpercentage",
                      !isNaN(cgpa) ? cgpa.toFixed(2) : ""
                    );
                    const cgpaError = cgpaValidation(value);
                    if (!cgpaError) {
                      clearErrors("hscmarksobtained");
                    } else {
                      setError("hscmarksobtained", {
                        type: "manual",
                        message: cgpaError,
                      });
                    }
                  }
                }}
                InputProps={{ inputMode: "decimal" }}
                error={!!errors.hscmarksobtained}
                helperText={errors.hscmarksobtained?.message}
                FormHelperTextProps={{ style: { margin: 0 } }}
                disabled={
                  watch("hscpassingstatus") == 1 ||
                  isPaid === "1" ||
                  watch("hscmarktype") === ""
                }
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_MARKS_OUT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.HSC_MARKS_OUT_OF
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_MARKS_OUT_OF
                    )?.ismandatory === "1" &&
                      !(
                        watch("hscpassingstatus") == 1 ||
                        isPaid === "1" ||
                        watch("hscmarktype") === ""
                      ) && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_MARKS_OUT_OF
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="HSC / Diploma Marks / CGPA Out Of"
                {...register("hscmarksoutof", {
                  required:
                    !(
                      watch("hscpassingstatus") == 1 ||
                      isPaid === "1" ||
                      watch("hscmarktype") === ""
                    ) && "HSC / Diploma Marks / CGPA Out Of is required",

                  validate: (value) => {
                    const obtainedMarks = parseFloat(
                      getValues("hscmarksobtained")
                    );

                    if (obtainedMarks > parseFloat(value)) {
                      return "Marks out of must be greater than or equal to marks obtained";
                    }

                    return watch("hscmarktype") === 1
                      ? cgpaValidation(value)
                      : outOfMarkValidation(value, obtainedMarks);
                  },
                })}
                // onInput={trigger("hscmarksoutof")}
                onChange={(e) => {
                  const maxValue = parseFloat(e.target.value);
                  const obtainedMarks = parseFloat(
                    getValues("hscmarksobtained")
                  );

                  trigger("hscmarksobtained");
                  trigger("hscmarksoutof");
                  if (obtainedMarks > maxValue) {
                    setError("hscmarksoutof", {
                      type: "manual",
                      message:
                        "Marks out of must be greater than or equal to marks obtained",
                    });
                  } else {
                    clearErrors("hscmarksoutof");
                    clearErrors("hscpercentage", "");
                    const percentage = (obtainedMarks / maxValue) * 100;
                    if (watch("hscmarktype") === 2) {
                      setValue(
                        "hscpercentage",
                        !isNaN(percentage) ? percentage.toFixed(2) : ""
                      );
                      clearErrors("hscpercentage");
                    }
                    setValue("hscmarksoutof", e.target.value);
                    if (watch("hscmarktype") === 1) {
                      const cgpaError = cgpaValidation(maxValue);
                      if (cgpaError) {
                        setError("hscmarksoutof", {
                          type: "manual",
                          message: cgpaError,
                        });
                      }
                    } else if (watch("marktype") === 2) {
                      outOfMarkValidation(maxValue, obtainedMarks);
                    }
                  }

                  if (watch("hscmarktype") === 1) {
                    const cgpa = (obtainedMarks / maxValue) * 100;
                    setValue(
                      "hscpercentage",
                      !isNaN(cgpa) ? cgpa.toFixed(2) : ""
                    );
                  }

                  trigger("hscmarksobtained");
                  trigger("hscmarksoutof");
                }}
                error={!!errors?.hscmarksoutof}
                helperText={errors.hscmarksoutof?.message}
                FormHelperTextProps={{ style: { margin: 0 } }}
                InputLabelProps={{ shrink: !!watch("hscmarksoutof") }}
                // disabled={
                //   getValues("hscpassingstatus") === 1 ||
                //   isPaid === "1" ||
                //   watch("hscmarktype") === "" ||
                //   getValues("hscmarksobtained") === ""
                // }
                disabled={
                  watch("hscpassingstatus") == 1 ||
                  isPaid === "1" ||
                  watch("hscmarktype") === "" ||
                  getValues("hscmarksobtained") === ""
                }
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.HSC_CALCULATED_PERCENTAGE
        ) && (
          <Grid item xs={12} md={6}>
            {/* <TextField
              fullWidth
              variant="outlined"
              inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
              type="text"
              label={
                <span> {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_CALCULATED_PERCENTAGE
                  )?.fieldlabel
                }
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_CALCULATED_PERCENTAGE
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="HSC/Diploma Calculated/Equivalent Percentage*"
                {...register("hscpercentage", {
                  required:
                    getValues("hscpassingstatus") == 1
                      ? false
                      : "HSC/Diploma Calculated/Equivalent percentage is required",
                      validate: parseInt( watch("hscmarktype")) === 1 ? cgpaValidation : percentageValidation,
                })}
                error={!!errors?.hscpercentage}
                helperText={errors?.hscpercentage?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("hscpercentage"),
                }}
                disabled={(watch("hscmarktype") != 1 )}
              /> */}
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.HSC_CALCULATED_PERCENTAGE
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                  inputMode: "numeric",
                }}
                fullWidth
                disabled={
                  watch("hscmarktype") != 1 || watch("hscmarktype") != 2
                }
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                variant="outlined"
                type="number"
                min="0"
                label={
                  <span>
                    {!(
                      watch("hscpassingstatus") == 1 ||
                      isPaid === "1" ||
                      watch("hscmarktype") === ""
                    ) && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                    {"HSC / Diploma Equivalent Percentage"}{" "}
                  </span>
                }
                placeholder={"Enter the HSC / Diploma Equivalent Percentage"}
                {...register("hscpercentage", {
                  required:
                    !(
                      watch("hscpassingstatus") == 1 ||
                      isPaid === "1" ||
                      watch("hscmarktype") === ""
                    ) && "HSC / Diploma Total percentage % is required",

                  validate: percentageValidation,
                })}
                error={!!errors.hscpercentage}
                helperText={errors.hscpercentage?.message}
                FormHelperTextProps={{ style: { margin: 0 } }}
                InputLabelProps={{ shrink: !!watch("hscpercentage") }}
                InputProps={{ inputMode: "decimal" }}
              />
            </Tooltip>

            <Box>
              {/* <small style={{ color: "#666666" }}>
                Up to 2 decimal places are allowed
              </small> */}
            </Box>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_COLLEGE_STATE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.hsccollegestateid}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_COLLEGE_STATE
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("hsccollegestateid"),
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="hsccollegestateid"
                  label={
                    <span>
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.HSC_COLLEGE_STATE
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.HSC_COLLEGE_STATE
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("hsccollegestateid", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_COLLEGE_STATE
                      )?.ismandatory === "1"
                        ? "HSC College State is required"
                        : false,
                  })}
                  value={watch("hsccollegestateid") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.HSC_COLLEGE_STATE,
                    null,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      const stateId = e.target.value;
                      setValue("hsccollegestateid", e.target.value);
                      setValue("hsccollegedistrictid", "");
                      setValue("hsccollegetalukaid", "");

                      dispatch({
                        type: masterActions.GET_HSC_SCHOOL_DISTRICT,
                        payload: stateId,
                      });

                      trigger("hsccollegestateid");
                    },
                  }}
                  error={!!errors.hsccollegestateid}
                  helperText={
                    errors.hsccollegestateid
                      ? errors.hsccollegestateid.message
                      : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {state?.map((val, i) => (
                    <MenuItem value={val?.stateid} key={i}>
                      {val?.state}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_COLLEGE_DISTRICT
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.hsccollegedistrictid}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_COLLEGE_DISTRICT
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink:
                      !!watch("hsccollegedistrictid") ||
                      watch("hsccollegedistrictid") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="hsccollegedistrictid"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_COLLEGE_DISTRICT
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.HSC_COLLEGE_DISTRICT
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("hsccollegedistrictid", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_COLLEGE_DISTRICT
                      )?.ismandatory === "1"
                        ? "HSC College District is required"
                        : false,
                  })}
                  value={watch("hsccollegedistrictid") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.HSC_COLLEGE_DISTRICT,
                    null,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      const districtId = e?.target?.value;
                      setValue("hsccollegedistrictid", e.target.value);
                      setValue("hsccollegetalukaid", "");
                      dispatch({
                        type: masterActions.GET_HSC_SCHOOL_TALUKA,
                        payload: districtId,
                      });

                      trigger("hsccollegedistrictid");
                    },
                  }}
                  error={!!errors.hsccollegedistrictid}
                  helperText={
                    errors.hsccollegedistrictid
                      ? errors.hsccollegedistrictid.message
                      : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={0}>Not Applicable</MenuItem>
                  {getValues("hsccollegestateid") !== 0 &&
                    getValues("hsccollegestateid") !== -1 &&
                    hscSchoolDistrict?.map((val, i) => (
                      <MenuItem value={val?.districtid} key={i}>
                        {val?.district}
                      </MenuItem>
                    ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_COLLEGE_TALUKA
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.hsccollegetalukaid}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_COLLEGE_TALUKA
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink:
                      !!watch("hsccollegetalukaid") ||
                      watch("hsccollegetalukaid") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="hsccollegetalukaid"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_COLLEGE_TALUKA
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.HSC_COLLEGE_TALUKA
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("hsccollegetalukaid", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_COLLEGE_TALUKA
                      )?.ismandatory === "1"
                        ? "HSC College Taluka is required"
                        : false,
                  })}
                  value={watch("hsccollegetalukaid") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.HSC_COLLEGE_TALUKA,
                    null,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("hsccollegetalukaid", e.target.value);
                      trigger("hsccollegetalukaid");
                    },
                  }}
                  error={!!errors.hsccollegetalukaid}
                  helperText={
                    errors.hsccollegetalukaid
                      ? errors.hsccollegetalukaid.message
                      : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={0}>Not Applicable</MenuItem>
                  {hscSchoolTaluka?.map((val, i) => (
                    <MenuItem value={val?.talukaid} key={i}>
                      {val?.taluka}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_COLLEGE_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.HSC_COLLEGE_NAME
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_COLLEGE_NAME
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_COLLEGE_NAME
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="HSC COLLEGE NAME*"
                {...register("hsccollegename", {
                  required: "HSC College Name is required",
                })}
                onChange={(e) => {
                  trigger("hsccollegename");
                  setValue("hsccollegename", e.target.value);
                  clearErrors("hsccollegename");
                }}
                // onInput={percentageNumberValidation}
                value={getValues("hsccollegename")}
                error={!!errors.hsccollegename}
                helperText={errors.hsccollegename?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("hsccollegename"),
                }}
                disabled={isFieldDisabled(
                  masterFields,
                  masterFieldLabel.HSC_COLLEGE_NAME,
                  null,
                  isPaid
                )}
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_ENG_MARKS_OBTAINED
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.HSC_ENG_MARKS_OBTAINED
                )?.fieldlabel
              }
              arrow
            >
              {/* <TextField
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_ENG_MARKS_OBTAINED
                    )?.ismandatory === "1" &&
                      !(
                        getValues("hscpassingstatus") == 1 || isPaid === "1"
                      ) && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_ENG_MARKS_OBTAINED
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="HSC English Marks Obtained"
                {...register("hscenglishmarksobtained", {
                  required:
                    getValues("hscpassingstatus") == 1
                      ? false
                      : "HSC English Marks Obtained is required",

                  validate: (value) => {
                    const regex = /^\d{1,2}(\.\d{1,2})?$/; // Regex to allow numbers up to 99 and 1 or 2 decimal places
                    const numberValue = parseFloat(value);

                    if (!regex.test(value)) {
                      return "Please enter a valid number (up to two decimal places).";
                    }

                    if (numberValue >= 100) {
                      return "Value cannot be 100 or more.";
                    }

                    return true; // Pass validation
                  },
                })}
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  const maxValue = parseFloat(
                    getValues("hscenglishmarksoutof")
                  );

                  setValue("hscenglishmarksobtained", e.target.value);
                  if (maxValue < value) {
                    setError("hscenglishmarksobtained", {
                      type: "manual",
                      message:
                        "Marks out of must be greater than or equal to marks obtained",
                    });
                    let percentage = (value / maxValue) * 100;
                    setValue(
                      "hscenglishpercentage",
                      !isNaN(percentage)
                        ? parseFloat(percentage).toFixed(2)
                        : ""
                    );
                  } else {
                    clearErrors("hscenglishmarksobtained");
                  }
                }}
                inputProps={{
                  min: 0,
                  max:
                    parseInt(getValues("hscenglishmarksoutof")) <= 900
                      ? parseInt(getValues("hscenglishmarksoutof"))
                      : 900,
                  style: { height: 56, boxSizing: "border-box" },
                }}
                error={!!errors.hscenglishmarksobtained}
                helperText={errors.hscenglishmarksobtained?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("hscenglishmarksobtained"),
                }}
                disabled={getValues("hscpassingstatus") == 1 || isPaid === "1"}
              /> */}
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_ENG_MARKS_OBTAINED
                    )?.ismandatory === "1" &&
                      !(
                        getValues("hscpassingstatus") == 1 || isPaid === "1"
                      ) && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_ENG_MARKS_OBTAINED
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="HSC English Marks Obtained"
                {...register("hscenglishmarksobtained", {
                  required:
                    getValues("hscpassingstatus") == 1
                      ? false
                      : "HSC English Marks Obtained is required",
                  validate: (value) => {
                    if (getValues("hscpassingstatus") == 1 || isPaid === "1") {
                      return true; // Skip validation when the field is disabled
                    }

                    const regex = /^\d{1,2}(\.\d{1,2})?$/; // Regex for numbers up to 99 with 2 decimals
                    const numberValue = parseFloat(value);

                    // Check if the input contains any non-numeric characters (including alphabets)
                    if (/[^0-9.]/.test(value)) {
                      return "Alphabets are not allowed. Please enter a valid number.";
                    }

                    // Ensure the value is between 0 and 100, inclusive
                    if (numberValue < 0 || numberValue > 100) {
                      return "Value should be between 0 and 100.";
                    }

                    // if (!regex.test(value)) {
                    //   return "Please enter a valid number (up to two decimal places).";
                    // }

                    // Ensure that marks obtained cannot be greater than marks out of
                    const maxValue = parseFloat(
                      getValues("hscenglishmarksoutof")
                    );
                    if (maxValue < numberValue) {
                      return "Marks obtained cannot be greater than marks out of.";
                    }

                    return true;
                  },
                })}
                // onChange={(e) => {
                //   const value = parseFloat(e.target.value);
                //   const maxValue = parseFloat(
                //     getValues("hscenglishmarksoutof")
                //   );

                //   setValue("hscenglishmarksobtained", e.target.value);

                //   if (maxValue < value) {
                //     setError("hscenglishmarksobtained", {
                //       type: "manual",
                //       message:
                //         "Marks obtained cannot be greater than marks out of.",
                //     });
                //   } else {
                //     clearErrors("hscenglishmarksoutof");
                //     clearErrors("hscenglishmarksobtained");
                //   }

                //   if (maxValue > value) {
                //     let percentage = (value / maxValue) * 100;
                //     setValue(
                //       "hscenglishpercentage",
                //       !isNaN(percentage)
                //         ? parseFloat(percentage).toFixed(2)
                //         : ""
                //     );
                //   }
                //   trigger("hscenglishmarksobtained");
                // }}

                onChange={(e) => {
                  const obtainedValue = parseFloat(e.target.value);
                  const maxValue = parseFloat(
                    getValues("hscenglishmarksoutof")
                  );

                  if (isNaN(obtainedValue) || obtainedValue < 0) {
                    setError("hscenglishmarksobtained", {
                      type: "manual",
                      message: "Please enter a valid positive number.",
                    });
                    return;
                  }

                  if (obtainedValue > maxValue) {
                    setError("hscenglishmarksobtained", {
                      type: "manual",
                      message: "Marks obtained cannot exceed marks out of.",
                    });
                    return;
                  }

                  clearErrors("hscenglishmarksobtained");
                  clearErrors("hscenglishmarksoutof");

                  setValue("hscenglishmarksobtained", e.target.value);

                  if (maxValue > 0) {
                    const percentage = (
                      (obtainedValue / maxValue) *
                      100
                    ).toFixed(2);
                    setValue("hscenglishpercentage", percentage);
                  }
                  trigger("hscenglishmarksobtained");
                }}
                inputProps={{
                  min: 0,
                  max:
                    parseInt(getValues("hscenglishmarksoutof")) <= 900
                      ? parseInt(getValues("hscenglishmarksoutof"))
                      : 900,
                  style: { height: 56, boxSizing: "border-box" },
                }}
                error={!!errors.hscenglishmarksobtained}
                helperText={errors.hscenglishmarksobtained?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("hscenglishmarksobtained"),
                }}
                disabled={getValues("hscpassingstatus") == 1 || isPaid === "1"}
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_ENG_MARKS_OUT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.HSC_ENG_MARKS_OUT_OF
                )?.fieldlabel
              }
              arrow
            >
              {/* <TextField
                fullWidth
                variant="outlined"
                type="text"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_ENG_MARKS_OUT_OF
                    )?.ismandatory === "1" &&
                      !(getValues("hscpassingstatus") == 1
                        ? true
                        : false || isPaid === "1") && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_ENG_MARKS_OUT_OF
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="HSC English Marks Out Of*"
                {...register("hscenglishmarksoutof", {
                  required:
                    getValues("hscpassingstatus") == 1
                      ? false
                      : "HSC English Marks Out Of is required",
                  validate: (value) => {
                    const regex = /^\d{1,2}(\.\d{1,2})?$/; // Regex to allow numbers up to 99 and 1 or 2 decimal places
                    const numberValue = parseFloat(value);

                    if (!regex.test(value)) {
                      return "Please enter a valid number (up to two decimal places).";
                    }

                    if (numberValue >= 100) {
                      return "Value cannot be 100 or more.";
                    }

                    return true; // Pass validation
                  },
                })}
                onChange={(e) => {
                  const maxValue = parseFloat(e.target.value);
                  const value = parseFloat(
                    getValues("hscenglishmarksobtained")
                  );
                  trigger("hscenglishmarksoutof");
                  clearErrors("hscenglishmarksoutof");
                  clearErrors("hscenglishpercentage");
                  if (maxValue < value) {
                    setError("hscenglishmarksoutof", {
                      type: "manual",
                      message:
                        "Marks out of must be greater than or equal to marks obtained",
                    });
                    let percentage = (value / maxValue) * 100;
                    setValue(
                      "hscenglishpercentage",
                      !isNaN(percentage)
                        ? parseFloat(percentage).toFixed(2)
                        : ""
                    );
                  } else {
                    setValue(
                      "hscenglishmarksoutof",
                      parseFloat(e.target.value)
                    );
                    clearErrors("hscenglishmarksoutof");
                  }
                }}
                value={getValues("hscenglishmarksoutof") || ""}
                error={!!errors?.hscenglishmarksoutof}
                helperText={errors.hscenglishmarksoutof?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("hscenglishmarksoutof"),
                }}
                // disabled={isFieldDisabled(masterFields, masterFieldLabel.HSC_ENG_MARKS_OUT_OF)}
                disabled={
                  getValues("hscpassingstatus") == 1
                    ? true
                    : false || isPaid === "1"
                }
              /> */}
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_ENG_MARKS_OUT_OF
                    )?.ismandatory === "1" &&
                      !(
                        getValues("hscpassingstatus") == 1 || isPaid === "1"
                      ) && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_ENG_MARKS_OUT_OF
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="HSC English Marks Out Of*"
                {...register("hscenglishmarksoutof", {
                  required:
                    watch("hscpassingstatus") == 1
                      ? false
                      : "HSC English Marks Out Of is required",

                  validate: (value) => {
                    if (getValues("hscpassingstatus") == 1 || isPaid === "1") {
                      return true; // Skip validation when the field is disabled
                    }

                    const regex = /^\d{1,2}(\.\d{1,2})?$/; // Regex for numbers up to 99 with 2 decimals
                    const numberValue = parseFloat(value);

                    // Check if the value is not a number (i.e., contains alphabets)
                    if (isNaN(value)) {
                      return "Alphabets are not allowed. Please enter a valid number.";
                    }

                    // Ensure the value matches the regex for numbers up to two decimals

                    // Ensure the value is between 0 and 100, inclusive
                    if (numberValue < 0 || numberValue > 100) {
                      return "Value should be between 0 and 100.";
                    }

                    // if (!regex.test(value)) {
                    //   return "Please enter a valid number (up to two decimal places).";
                    // }
                    // Ensure that marks out of are greater than or equal to marks obtained
                    const obtainedValue = parseFloat(
                      getValues("hscenglishmarksobtained")
                    );
                    if (numberValue < obtainedValue) {
                      return "Marks out of must be greater than or equal to marks obtained.";
                    }

                    return true;
                  },
                })}
                // onChange={(e) => {
                //   const maxValue = parseFloat(e.target.value);
                //   const value = parseFloat(
                //     getValues("hscenglishmarksobtained")
                //   );

                //   setValue("hscenglishmarksoutof", e.target.value);

                //   if (maxValue < value) {
                //     setError("hscenglishmarksoutof", {
                //       type: "manual",
                //       message:
                //         "Marks out of must be greater than or equal to marks obtained.",
                //     });
                //     let percentage = (value / maxValue) * 100;
                //     setValue(
                //       "hscenglishpercentage",
                //       !isNaN(percentage)
                //         ? parseFloat(percentage).toFixed(2)
                //         : ""
                //     );
                //   } else {
                //     clearErrors("hscenglishmarksoutof");
                //     clearErrors("hscenglishmarksobtained");
                //   }

                //   if (value < maxValue) {
                //     let percentage = (value / maxValue) * 100;
                //     setValue(
                //       "hscenglishpercentage",
                //       !isNaN(percentage)
                //         ? parseFloat(percentage).toFixed(2)
                //         : ""
                //     );
                //   }
                //   trigger("hscenglishmarksoutof");
                //   trigger("hscenglishmarksobtained");
                // }}
                onChange={(e) => {
                  const maxValue =
                    e.target.value.trim() === ""
                      ? ""
                      : parseFloat(e.target.value);

                  if (maxValue === "") {
                    // Allow clearing the value
                    clearErrors("hscenglishmarksoutof");
                    setValue("hscenglishmarksoutof", "");
                  } else if (isNaN(maxValue)) {
                    setError("hscenglishmarksoutof", {
                      type: "manual",
                      message: "Marks out of must be a valid number.",
                    });
                  } else if (maxValue <= 0) {
                    setError("hscenglishmarksoutof", {
                      type: "manual",
                      message: "Marks out of must be a positive number.",
                    });
                  } else {
                    clearErrors("hscenglishmarksoutof");
                    setValue("hscenglishmarksoutof", e.target.value);
                  }

                  const obtainedValue = parseFloat(
                    getValues("hscenglishmarksobtained")
                  );

                  if (maxValue < obtainedValue) {
                    setError("hscenglishmarksoutof", {
                      type: "manual",
                      message:
                        "Marks out of must be greater than or equal to marks obtained.",
                    });
                  } else {
                    clearErrors("hscenglishmarksobtained");
                  }

                  if (obtainedValue <= maxValue) {
                    const percentage = (
                      (obtainedValue / maxValue) *
                      100
                    ).toFixed(2);
                    setValue("hscenglishpercentage", percentage);
                  }

                  trigger("hscenglishmarksoutof");
                  trigger("hscenglishmarksobtained");
                }}
                value={getValues("hscenglishmarksoutof") || ""}
                error={!!errors?.hscenglishmarksoutof}
                helperText={errors.hscenglishmarksoutof?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("hscenglishmarksoutof"),
                }}
                disabled={getValues("hscpassingstatus") == 1 || isPaid === "1"}
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.HSC_ENG_CALCULATED_PERCENTAGE
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.HSC_ENG_CALCULATED_PERCENTAGE
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                // type="number"
                {...register("hscenglishpercentage", {
                  required:
                    getValues("hscpassingstatus") == 1
                      ? false
                      : "HSC English Percentage is required",
                  validate: (value) => {
                    // if (value > 100) {
                    //   return "Value should be between 0 and 100.";
                    // }

                    return true;
                  },
                })}
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_ENG_CALCULATED_PERCENTAGE
                    )?.ismandatory === "1" &&
                      !(
                        getValues("hscpassingstatus") == 1 || isPaid === "1"
                      ) && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_ENG_CALCULATED_PERCENTAGE
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="HSC English Calculated/Equivalent Percentage*"
                onInput={percentageValidation}
                {...register("hscenglishpercentage", {
                  required:
                    getValues("hscpassingstatus") == 1
                      ? false
                      : "HSC English Calculated/Equivalent percentage is required",
                })}
                error={!!errors?.hscenglishpercentage}
                helperText={errors?.hscenglishpercentage?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("hscenglishpercentage"),
                }}
                disabled={
                  // getValues("hscpassingstatus") == 1 ||
                  // isPaid === "1" ||
                  // getValues("hscenglishmarksoutof") !== "" ||
                  // getValues("hscenglishmarksobtained") !== ""
                  true
                }
              />
            </Tooltip>
            <Box>
              {/* <small style={{ color: "#666666" }}>
                Up to 2 decimal places are allowed
              </small> */}
            </Box>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
              masterFieldLabel.ELIGIBLITY_QUALIFICATION ||
            field.masterfieldlabel === masterFieldLabel.GRADUATION_STATUS ||
            field.masterfieldlabel === masterFieldLabel.GRADUATION_PASSING_YEAR
        ) && (
          <Grid item xs={12} md={12}>
            <Typography
              pt={1}
              sx={{
                fontSize: "24px",
                color: `${cssProperties?.color?.tertiary}`,
                fontWeight: 500,
                lineHeight: "30px",
              }}
            >
              {" "}
              GRADUATION INFORMATION{" "}
            </Typography>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.ELIGIBLITY_QUALIFICATION
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
              error={!!errors.eligibilityqualification}
            >
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.ELIGIBLITY_QUALIFICATION
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink:
                      !!watch("eligibilityqualification") ||
                      watch("eligibilityqualification") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  id="eligibilityqualification"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.ELIGIBLITY_QUALIFICATION
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.ELIGIBLITY_QUALIFICATION
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("eligibilityqualification", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.ELIGIBLITY_QUALIFICATION
                      )?.ismandatory === "1"
                        ? "Eligibility Qualification is required"
                        : false,
                  })}
                  value={watch("eligibilityqualification") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.ELIGIBLITY_QUALIFICATION,
                    null,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("eligibilityqualification", e.target.value);
                      trigger("eligibilityqualification");
                    },
                  }}
                  error={!!errors.eligibilityqualification}
                  helperText={
                    errors.eligibilityqualification
                      ? errors.eligibilityqualification.message
                      : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {/* {state?.map((val, i) => ( */}
                  {/* <MenuItem value={0} key={0}>
                  Not Applicable
                </MenuItem> */}
                  <MenuItem value={1} key={1}>
                    Graduation
                  </MenuItem>
                  <MenuItem value={2} key={2}>
                    Post Graduation
                  </MenuItem>

                  {/* ))} */}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_STATUS
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
              error={!!errors.graduationstatus}
            >
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_STATUS
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink:
                      !!watch("graduationstatus") ||
                      watch("graduationstatus") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="graduationstatus"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.GRADUATION_STATUS
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.GRADUATION_STATUS
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("graduationstatus", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.GRADUATION_STATUS
                      )?.ismandatory === "1"
                        ? "Graduation Status is required"
                        : false,
                  })}
                  value={watch("graduationstatus") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.GRADUATION_STATUS,
                    null,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("graduationstatus", e.target.value);
                      trigger("graduationstatus");
                      setValue("graduationpassingyear", "");
                      setValue("graduationbranchid", "");
                      setValue("graduationmarktype", "");
                      setValue("graduationpercentage", "");
                      setValue("awardedclassid", "");
                      setValue("graduationmarksobtained", "");
                      setValue("graduationmarksoutof", "");
                      setValue("graduationduration", "");
                      setValue("guniversityname", "");
                      setValue("gpassingmonth", "");
                      setValue("gotheruniversitystate", "");
                      setValue("gotherdegreename", "");
                      setValue("gotheruniversityname", "");
                      setValue("tymarksobtained", "");
                      setValue("tymarksoutof", "");
                      setValue("typercentage", "");
                      setValue("tynoofattempts", "");
                      setValue("fourymarksobtained", "");
                      setValue("fourymarksoutof", "");
                      setValue("fourypercentage", "");
                      setValue("fourynoofattempts", "");
                      setValue("aggregatemarksobtained", "");
                      setValue("aggregatemarksoutof", "");
                      setValue("aggregatepercentage", "");

                      setValue("fymarksobtained", "");
                      setValue("fymarksoutof", "");
                      setValue("fypercentage", "");
                      setValue("fynoofattempts", "");
                      setValue("symarksobtained", "");
                      setValue("symarksoutof", "");
                      setValue("sypercentage", "");
                      setValue("synoofattempts", "");
                      if (getValues("graduationstatus") == 1) {
                        clearErrors("graduationpassingyear", "");
                        clearErrors("gpassingmonth", "");
                        clearErrors("graduationbranchid", "");
                        clearErrors("graduationmarktype", "");
                        clearErrors("graduationpercentage", "");
                        clearErrors("awardedclassid", "");
                        clearErrors("graduationmarksobtained", "");
                        clearErrors("graduationmarksoutof", "");
                        clearErrors("graduationduration", "");
                        clearErrors("documentfortypea");
                        clearErrors("documentof");
                        clearErrors("cetexamname");
                        clearErrors("passinstuteonmh");
                        clearErrors("gcollegename");
                        clearErrors("alreadyadmitted");
                        clearErrors("gotheruniversitystate");
                        clearErrors("gotherdegreename");
                        clearErrors("gotheruniversityname");
                        clearErrors("guniversityname");
                        clearErrors("gspecialsubject");
                        clearErrors("gengmediumcollege");
                        clearErrors("genglangcontenttest");
                        clearErrors("goptspecialedcollege");
                        clearErrors("tymarksobtained", "");
                        clearErrors("tymarksoutof", "");
                        clearErrors("typercentage", "");
                        clearErrors("tynoofattempts", "");
                        clearErrors("fourymarksobtained", "");
                        clearErrors("fourymarksoutof", "");
                        clearErrors("fourypercentage", "");
                        clearErrors("fourynoofattempts", "");
                        clearErrors("aggregatemarksobtained", "");
                        clearErrors("aggregatemarksoutof", "");
                        clearErrors("aggregatepercentage", "");
                        clearErrors("fymarksobtained", "");
                        clearErrors("fymarksoutof", "");
                        clearErrors("fypercentage", "");
                        clearErrors("fynoofattempts", "");
                        clearErrors("symarksobtained", "");
                        clearErrors("symarksoutof", "");
                        clearErrors("sypercentage", "");
                        clearErrors("synoofattempts", "");
                      }
                    },
                  }}
                  error={!!errors.graduationstatus}
                  helperText={
                    errors.graduationstatus
                      ? errors.graduationstatus.message
                      : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                    Appearing
                  </MenuItem>
                  <MenuItem value={2} key={2}>
                    Passed
                  </MenuItem>
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_PASSING_YEAR
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
              error={!!errors.graduationpassingyear}
            >
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_PASSING_YEAR
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("graduationpassingyear"),
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="graduationpassingyear"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.GRADUATION_PASSING_YEAR
                      )?.ismandatory === "1" &&
                        watch("graduationstatus") == 2 && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.GRADUATION_PASSING_YEAR
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("graduationpassingyear", {
                    required:
                      !isFieldDisabled(
                        masterFields,
                        masterFieldLabel.GRADUATION_PASSING_YEAR
                      ) &&
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.GRADUATION_PASSING_YEAR
                      )?.ismandatory === "1" &&
                      getValues("graduationstatus") == 2
                        ? "Graduation Passing Year is required"
                        : false,
                  })}
                  value={watch("graduationpassingyear") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.GRADUATION_PASSING_YEAR,
                    watch("graduationstatus") != 2 ? 1 : 0,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("graduationpassingyear", e.target.value);
                      trigger("graduationpassingyear");
                    },
                  }}
                  error={!!errors.graduationpassingyear}
                  helperText={
                    errors.graduationpassingyear
                      ? errors.graduationpassingyear.message
                      : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {/* {graduationYears?.map((year, index) => (
                    <MenuItem key={index} value={year}>
                      {year}
                    </MenuItem>
                  ))} */}
                  {/* {  console.log( watch("hscpassingyear"),'console.log( watch("hscpassingyear"))',Array.from(
                        {
                          length: 2024 - parseInt(watch("hscpassingyear")) + 1,
                        },
                        (v, i) => parseInt(watch("hscpassingyear")) + 2 + i
                      ))} */}
                  {
                    // years
                    // ?.filter((year) => year >= (watch("hscpassingyear") + 3))

                    watch("hscpassingyear")
                      ? Array.from(
                          {
                            length:
                              2024 - parseInt(watch("hscpassingyear")) + 1,
                          },
                          (v, i) => parseInt(watch("hscpassingyear")) + 2 + i
                        ).map((year, index) => (
                          <MenuItem key={index} value={year}>
                            {year}
                          </MenuItem>
                        ))
                      : Array.from(
                          {
                            length:
                              2024 - parseInt(watch("sscpassingyear")) + 1,
                          },
                          (v, i) => parseInt(watch("sscpassingyear")) + 4 + i
                        ).map((year, index) => (
                          <MenuItem key={index} value={year}>
                            {year}
                          </MenuItem>
                        ))
                  }
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_BRANCH_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.graduationbranchid}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_BRANCH_NAME
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("graduationbranchid"),
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="graduationbranchid"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.GRADUATION_BRANCH_NAME
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}

                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.GRADUATION_BRANCH_NAME
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("graduationbranchid", {
                    required: !isFieldDisabled(
                      masterFields,
                      masterFieldLabel.GRADUATION_BRANCH_NAME,
                      isPaid
                    )
                      ? "Graduation Branch Name is required"
                      : false,
                  })}
                  value={watch("graduationbranchid") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.GRADUATION_BRANCH_NAME,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("graduationbranchid", e.target.value);
                      trigger("graduationbranchid");
                      dispatch({
                        type: masterActions.GET_G_SPECIALISATION_OR_SUBJECT,
                        payload: { id: e.target.value },
                      });

                      if (e != 79) {
                        clearErrors("gotherdegreename");
                        clearErrors("gotheruniversityname");
                        setValue("gotherdegreename", "");
                        setValue("gotheruniversityname", "");
                        setValue("guniversityname", "");
                      }
                    },
                  }}
                  error={!!errors.graduationbranchid}
                  helperText={
                    errors.graduationbranchid
                      ? errors.graduationbranchid.message
                      : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {graduationBranchName?.map((val, i) => (
                    <MenuItem key={i} value={val?.graduationbranchid}>
                      {val?.graduationbranch}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_MARK_TYPE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
              }}
              error={!!errors.graduationmarktype}
            >
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_MARK_TYPE
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink:
                      !!watch("graduationmarktype") ||
                      watch("graduationmarktype") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  id="graduationmarktype"
                  label={
                    <span>
                      { !isFieldDisabled(
                      masterFields,
                      masterFieldLabel.GRADUATION_MARK_TYPE,
                      watch("graduationstatus") != 2 ? 1 : 0,
                      isPaid
                    ) && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.GRADUATION_MARK_TYPE
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  // {...register("graduationmarktype", {
                  //   required:
                  //     !isFieldDisabled(
                  //       masterFields,
                  //       masterFieldLabel.GRADUATION_MARK_TYPE,
                  //       watch("graduationstatus")
                  //     ) &&
                  //       masterFields?.find(
                  //         (field) =>
                  //           field?.masterfieldlabel ===
                  //           masterFieldLabel.GRADUATION_MARK_TYPE
                  //       )?.ismandatory === "1"
                  //       ? "Graduation Marks Type is required"
                  //       : false,
                  // })}
                  // {...register("graduationmarktype", {
                  //   required:
                  //     getValues("graduationstatus") == 1
                  //       ? false
                  //       : "Graduation Marks Type is required",
                  // })}

                  {...register("graduationmarktype", {
                    required: !isFieldDisabled(
                      masterFields,
                      masterFieldLabel.GRADUATION_MARK_TYPE,
                      watch("graduationstatus") != 2 ? 1 : 0,
                      isPaid
                    )
                      ? getValues("graduationstatus") == 1
                        ? false
                        : "Graduation Marks Type is required"
                      : false,
                  })}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("graduationpercentage", "");
                      setValue("graduationmarktype", e.target.value);
                      trigger("graduationmarktype");
                      const value = parseInt(
                        getValues("graduationmarksobtained")
                      );
                      const maxValue = parseInt(
                        getValues("graduationmarksoutof")
                      );
                      setValue("graduationpercentage", "");
                      setValue("graduationmarksoutof", "");
                      setValue("graduationmarksobtained", "");
                      setValue("tymarksobtained", "");
                      setValue("tymarksoutof", "");
                      setValue("typercentage", "");
                      setValue("tynoofattempts", "");
                      setValue("fourymarksobtained", "");
                      setValue("fourymarksoutof", "");
                      setValue("fourypercentage", "");
                      setValue("fourynoofattempts", "");
                      setValue("aggregatemarksobtained", "");
                      setValue("aggregatemarksoutof", "");
                      setValue("aggregatepercentage", "");
                      setValue("fymarksobtained", "");
                      setValue("fymarksoutof", "");
                      setValue("fypercentage", "");
                      setValue("fynoofattempts", "");
                      setValue("symarksobtained", "");
                      setValue("symarksoutof", "");
                      setValue("sypercentage", "");
                      setValue("synoofattempts", "");
                      setValue("graduationmarksobtained");
                      setValue("graduationmarksoutof");
                      clearErrors([
                        "graduationmarksobtained",
                        "graduationmarksoutof",
                      ]);
                      if (getValues("graduationmarktype") !== 2) {
                        clearErrors("graduationpercentage");
                      }
                    },
                  }}
                  error={!!errors.graduationmarktype}
                  helperText={
                    errors.graduationmarktype
                      ? errors.graduationmarktype.message
                      : ""
                  }
                  value={watch("graduationmarktype") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.GRADUATION_MARK_TYPE,
                    watch("graduationstatus") != 2 ? 1 : 0,

                    isPaid
                  )}
                >
                  {/* <MenuItem value="">Select One</MenuItem> */}
                  {/* {state?.map((val, i) => ( */}
                  {/* <MenuItem value={0} key={0}>
                  Not Applicable
                </MenuItem> */}
                  <MenuItem value={1} key={1}>
                    Percentage
                  </MenuItem>
                  <MenuItem value={2} key={2}>
                    CGPA
                  </MenuItem>

                  {/* ))} */}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_MARK_OBTAINED
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.GRADUATION_MARK_OBTAINED
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <Typography>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.GRADUATION_MARK_OBTAINED
                    )?.ismandatory === "1" &&
                      !(
                        watch("graduationstatus") != 2 ||
                        isPaid === "1" ||
                        watch("graduationmarktype") === ""
                      ) && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.GRADUATION_MARK_OBTAINED
                      )?.fieldlabel
                    }
                  </Typography>
                }
                placeholder="Graduation Marks Obtained"
                {...register("graduationmarksobtained", {
                  required: !(
                    watch("graduationstatus") != 2 ||
                    isPaid === "1" ||
                    watch("graduationmarktype") === ""
                  )
                    ? "Graduation Marks Obtained is required"
                    : false,
                  validate: (value) => {
                    return watch("graduationmarktype") === 2
                      ? cgpaValidation(value)
                      : percentageNumberValidation(value);
                  },
                })}
                // onChange={(e) => {
                //   const value = parseInt(e.target.value) || 0;
                //   // const maxMarks = Math.min(parseInt(getValues("graduationmarksoutof")) || 900, 900);
                //   const maxMarks = parseInt(getValues("graduationmarksoutof"));
                //   clearErrors("graduationmarksobtained");
                //   // e.target.value = value > maxMarks ? maxMarks : value;

                //   trigger("graduationmarksobtained");

                //   trigger("graduationmarksoutof");
                //   setValue("graduationmarksobtained", e.target.value);
                //   if (watch("graduationmarktype") === 2) {
                //     const cgpaError = cgpaValidation(maxMarks);
                //     if (cgpaError) {
                //       setError("graduationmarksoutof", {
                //         type: "manual",
                //         message: cgpaError,
                //       });
                //     }
                //   } else if (watch("graduationmarktype") === 1) {
                //     percentageValidation();
                //   }
                // }}
                onChange={(e) => {
                  const value = parseFloat(e.target.value) || 0;
                  const maxMarks = parseFloat(
                    getValues("graduationmarksoutof")
                  );
                  setValue("graduationmarksobtained", e.target.value);

                  // if (watch("graduationmarktype") === 2) {
                  //   const cgpaError = cgpaValidation(value);
                  //   if (cgpaError) {
                  //     setError("graduationmarksobtained", {
                  //       type: "manual",
                  //       message: cgpaError,
                  //     });
                  //   }
                  // } else if (watch("graduationmarktype") === 1) {
                  //   // percentageValidation();
                  //   console.log("percentage in--->",percentageNumberValidation(value),value)
                  //   let percentageerror = percentageNumberValidation(value)
                  //   if(percentageerror){
                  //     setError("graduationmarksobtained", {
                  //       type: "manual",
                  //       message: percentageerror,
                  //     });
                  //   }
                  // }
                  const maxValue = parseInt(getValues("graduationmarksoutof"));
                  const obtainedMarks = parseFloat(
                    getValues("graduationmarksobtained")
                  );
                  let percentage = (obtainedMarks / maxValue) * 100;
                  if (watch("graduationmarktype") === 1) {
                    setValue(
                      "graduationpercentage",
                      !isNaN(percentage) ? percentage.toFixed(2) : ""
                    );
                  }
                  trigger("graduationmarksoutof");
                  trigger("graduationmarksobtained");
                  let cgpa = (obtainedMarks / maxValue) * 100;
                  if (watch("graduationmarktype") === 2) {
                    setValue(
                      "graduationpercentage",
                      !isNaN(cgpa) ? cgpa.toFixed(2) : ""
                    );
                  }
                  setTimeout(() => {
                    trigger([
                      "graduationmarksobtained",
                      "graduationmarksoutof",
                    ]);
                  }, 50);
                }}
                value={watch("graduationmarksobtained") ?? ""}
                InputProps={{ inputMode: 'numeric' }}
                error={!!errors.graduationmarksobtained}
                helperText={errors.graduationmarksobtained?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("graduationmarksobtained"),
                }}
                disabled={
                  watch("graduationstatus") != 2 ||
                  isPaid === "1" ||
                  watch("graduationmarktype") === ""
                }
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_MARK_OUT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.GRADUATION_MARK_OUT_OF
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.GRADUATION_MARK_OUT_OF
                    )?.ismandatory === "1" &&
                      !(
                        watch("graduationstatus") != 2 ||
                        isPaid === "1" ||
                        watch("graduationmarktype") === ""
                      ) && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.GRADUATION_MARK_OUT_OF
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="Graduation Marks Out Of*"
                {...register("graduationmarksoutof", {
                  required:
                    !(
                      watch("graduationstatus") != 2 ||
                      isPaid === "1" ||
                      watch("graduationmarktype") === ""
                    ) && "Graduation Marks Out Of is required",
                  validate: (value) => {
                    const obtainedMarks = parseFloat(
                      getValues("graduationmarksobtained"),
                      10
                    );
                    if (value && obtainedMarks > parseFloat(value, 10)) {
                      return "Marks out of must be greater than or equal to marks obtained";
                    }
                    return watch("graduationmarktype") === 2
                      ? cgpaValidation(value)
                      : outOfMarkValidation(value);
                  },
                })}
                onChange={(e) => {
                  const maxValue = parseFloat(e.target.value);
                  const obtainedMarks = parseFloat(
                    getValues("graduationmarksobtained")
                  );
                  clearErrors("graduationpercentage");
                  setValue("graduationmarksoutof", e.target.value);

                  // Prevent invalid input (when maxValue is less than obtainedMarks)
                  if (maxValue < obtainedMarks) {
                    setError("graduationmarksoutof", {
                      type: "manual",
                      message:
                        "Marks out of must be greater than or equal to marks obtained",
                    });
                  } else {
                    clearErrors("graduationmarksoutof");

                    // Handle percentage or CGPA based on the mark type
                    if (watch("graduationmarktype") === 2) {
                      const cgpaError = cgpaValidation(maxValue);
                      if (cgpaError) {
                        setError("graduationmarksoutof", {
                          type: "manual",
                          message: cgpaError,
                        });
                      }
                    } else if (watch("graduationmarktype") === 1) {
                      percentageValidation();
                    }

                    // Calculate percentage or CGPA based on the entered values
                    let percentage = (obtainedMarks / maxValue) * 100;
                    if (watch("graduationmarktype") === 1) {
                      setValue(
                        "graduationpercentage",
                        !isNaN(percentage) ? percentage.toFixed(2) : ""
                      );
                    }
                    trigger("graduationmarksoutof");
                    trigger("graduationmarksobtained");
                    let cgpa = (obtainedMarks / maxValue) * 100;
                    if (watch("graduationmarktype") === 2) {
                      setValue(
                        "graduationpercentage",
                        !isNaN(cgpa) ? cgpa.toFixed(2) : ""
                      );
                    }
                  }

                  trigger("graduationmarksobtained");
                  trigger("graduationmarksoutof");
                  trigger("graduationpercentage");
                }}
                error={!!errors?.graduationmarksoutof}
                helperText={errors.graduationmarksoutof?.message}
                FormHelperTextProps={{ style: { margin: 0 } }}
                InputLabelProps={{ shrink: !!watch("graduationmarksoutof") }}
                disabled={
                  watch("graduationstatus") != 2 ||
                  isPaid === "1" ||
                  watch("graduationmarktype") === "" ||
                  watch("graduationmarksobtained") === ""
                }
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.GRADUATION_MARK_CALCULATED_PERCENTAGE
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.GRADUATION_MARK_CALCULATED_PERCENTAGE
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{
                  style: { height: 56, boxSizing: "border-box" },
                  inputMode: "numeric", // Helps mobile keyboards show numeric keypad
                }}
                type="text"
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.GRADUATION_MARK_CALCULATED_PERCENTAGE
                    )?.ismandatory === "1" &&
                      !(
                        watch("graduationstatus") != 2 ||
                        isPaid === "1" ||
                        watch("graduationmarktype") === ""
                      ) && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.GRADUATION_MARK_CALCULATED_PERCENTAGE
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="Graduation Marks Percentage"
                {...register("graduationpercentage", {
                  required:
                    !(
                      watch("graduationstatus") != 2 ||
                      isPaid === "1" ||
                      watch("graduationmarktype") === ""
                    ) && "Graduation percentage % is required",

                  validate: percentageValidation,
                })}
                onInput={percentageNumberValidation}
                // value={}
                error={!!errors?.graduationpercentage}
                helperText={errors.graduationpercentage?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("graduationpercentage"),
                }}
                // disabled={isFieldDisabled(masterFields, masterFieldLabel.GRADUATION_MARK_CALCULATED_PERCENTAGE)}
                // disabled={getValues("hscpassingstatus") == 1 ? true : false}
                disabled={
                  parseInt(watch("graduationmarktype")) === 2 ||
                  parseInt(watch("graduationmarktype")) === 1 ||
                  watch("graduationstatus") != 2 ||
                  isPaid === "1" ||
                  watch("graduationmarktype") === ""
                }
                // disabled={getValues("graduationstatus") == 1 || isPaid === "1"}
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.AWARDED_CLASS_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.awardedclassid}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.AWARDED_CLASS_NAME
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("awardedclassid"),
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="awardedclassid"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.AWARDED_CLASS_NAME
                      )?.ismandatory === "1" &&
                        getValues("graduationstatus") == 2 && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.AWARDED_CLASS_NAME
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("awardedclassid", {
                    required:
                      !isFieldDisabled(
                        masterFields,
                        masterFieldLabel.AWARDED_CLASS_NAME,
                        watch("graduationstatus") != 2 ? 1 : 0
                      ) &&
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.AWARDED_CLASS_NAME
                      )?.ismandatory === "1"
                        ? "Awarded Class Name is required"
                        : false,
                  })}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("awardedclassid", e.target.value);
                      trigger("awardedclassid");
                    },
                  }}
                  error={!!errors.awardedclassid}
                  helperText={
                    errors.awardedclassid ? errors.awardedclassid.message : ""
                  }
                  value={watch("awardedclassid") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.AWARDED_CLASS_NAME,
                    watch("graduationstatus") != 2 ? 1 : 0,
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {awardedClass?.map((val, i) => (
                    <MenuItem key={i} value={val?.awardedclassid}>
                      {val?.awardedclass}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_DURATION
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.awardedclassid}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_DURATION
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink:
                      !!watch("graduationduration") ||
                      watch("graduationduration") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="graduationduration"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.GRADUATION_DURATION
                      )?.ismandatory === "1" &&
                        getValues("graduationstatus") == 2 && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.GRADUATION_DURATION
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("graduationduration", {
                    required:
                      !isFieldDisabled(
                        masterFields,
                        masterFieldLabel.GRADUATION_DURATION,
                        watch("graduationstatus") != 2 ? 1 : 0
                      ) &&
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.GRADUATION_DURATION
                      )?.ismandatory === "1"
                        ? "Graduation Duration is required"
                        : false,
                  })}
                  value={watch("graduationduration") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.GRADUATION_DURATION,
                    watch("graduationstatus") != 2 ? 1 : 0,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("graduationduration", e.target.value);
                      trigger("graduationduration");
                    },
                  }}
                  error={!!errors.graduationduration}
                  helperText={
                    errors.graduationduration
                      ? errors.graduationduration.message
                      : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  {Array.from({ length: 8 }, (_, i) => i + 3).map((value) => (
                    <MenuItem value={value} key={value}>
                      {value}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={1} key={1}>
                  3
                </MenuItem>
                <MenuItem value={2} key={2}>
                  4
                </MenuItem>
                <MenuItem value={3} key={3}>
                  5
                </MenuItem>

                <MenuItem value={4} key={4}>
                  6
                </MenuItem> */}

                  {/* ))} */}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {/* <Grid item xs={12} md={12}>
          <Typography
            pt={1}
            sx={{
              fontSize: "24px",
              color: `${cssProperties?.color?.tertiary}`,
              fontWeight: 500,
              lineHeight: "30px",
            }}
          >
            {" "}
            POST GRADUATION INFORMATION
          </Typography>
        </Grid> */}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.DOCUMENT_FOR_TYPE_A
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.documentfortypea}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.DOCUMENT_FOR_TYPE_A
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("documentfortypea"),
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="documentfortypea"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.DOCUMENT_FOR_TYPE_A
                      )?.ismandatory === "1" &&
                        getValues("graduationstatus") == 2 && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.DOCUMENT_FOR_TYPE_A
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("documentfortypea", {
                    required: !isFieldDisabled(
                      masterFields,
                      masterFieldLabel.DOCUMENT_FOR_TYPE_A,
                      watch("graduationstatus")
                    )
                      ? masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.DOCUMENT_FOR_TYPE_A
                        )?.ismandatory === "1" &&
                        getValues("graduationstatus") == 2
                        ? "Document for Type A is required"
                        : false
                      : false,
                  })}
                  value={watch("documentfortypea") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.DOCUMENT_FOR_TYPE_A,
                    watch("graduationstatus") != 2 ? 1 : 0,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("documentfortypea", e.target.value);
                      trigger("documentfortypea");
                    },
                  }}
                  error={!!errors.documentfortypea}
                  helperText={
                    errors.documentfortypea
                      ? errors.documentfortypea.message
                      : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>

                  <MenuItem value={1} key={1}>
                    A
                  </MenuItem>
                  <MenuItem value={2} key={1}>
                    B
                  </MenuItem>

                  <MenuItem value={3} key={1}>
                    C
                  </MenuItem>
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.DOCUMENT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.documentof}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.DOCUMENT_OF
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("documentof"),
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="documentof"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.DOCUMENT_OF
                      )?.ismandatory === "1" &&
                        getValues("graduationstatus") == 2 &&
                        getValues("graduationstatus") !== 1 && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.DOCUMENT_OF
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("documentof", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.DOCUMENT_OF
                      )?.ismandatory === "1" &&
                      getValues("graduationstatus") == 2
                        ? "Document of is required"
                        : false,
                  })}
                  value={watch("documentof") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.DOCUMENT_OF,
                    watch("graduationstatus") != 2 ? 1 : 0,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("documentof", e.target.value);
                      trigger("documentof");
                    },
                  }}
                  error={!!errors.documentof}
                  helperText={
                    errors.documentof ? errors.documentof.message : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                    type 1
                  </MenuItem>

                  <MenuItem value={2} key={1}>
                    type 2
                  </MenuItem>
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}
        {/*     
{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.CASTE_NAME_FOR_OPEN
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              
              error={!!errors.castenameforopen}
            >
              <InputLabel
                shrink={!!watch("castenameforopen")}
                htmlFor="castenameforopen"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CASTE_NAME_FOR_OPEN
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.CASTE_NAME_FOR_OPEN
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                id="castenameforopen"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.CASTE_NAME_FOR_OPEN
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.CASTE_NAME_FOR_OPEN
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("castenameforopen", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CASTE_NAME_FOR_OPEN
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("castenameforopen", e.target.value);
                  trigger("castenameforopen");
                }}
                value={watch("castenameforopen") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.CASTE_NAME_FOR_OPEN)}
              >
                <MenuItem value="">Select One</MenuItem>
                
              </Select>
              {errors.castenameforopen && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.castenameforopen.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
     */}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.CET_EXAM_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.cetexamname}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.CET_EXAM_NAME
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink:
                      !!watch("cetexamname") || watch("cetexamname") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="cetexamname"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.CET_EXAM_NAME
                      )?.ismandatory === "1" &&
                        getValues("graduationstatus") == 2 &&
                        getValues("graduationstatus") !== 1 && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.CET_EXAM_NAME
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("cetexamname", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.CET_EXAM_NAME
                      )?.ismandatory === "1" &&
                      getValues("graduationstatus") == 2
                        ? "CET Exam Name is required"
                        : false,
                  })}
                  value={watch("cetexamname") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.CET_EXAM_NAME,
                    watch("graduationstatus") != 2 ? 1 : 0,

                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("cetexamname", e.target.value);
                      trigger("cetexamname");
                    },
                  }}
                  error={!!errors.cetexamname}
                  helperText={
                    errors.cetexamname ? errors.cetexamname.message : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                    type 1
                  </MenuItem>
                  <MenuItem value={2} key={1}>
                    type 2
                  </MenuItem>

                  {/* ))} */}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.WHETHER_PASS_INSTUTE_ON_MH
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
              error={!!errors.passinstuteonmh}
            >
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.WHETHER_PASS_INSTUTE_ON_MH
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink:
                      !!watch("passinstuteonmh") ||
                      watch("passinstuteonmh") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="passinstuteonmh"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.WHETHER_PASS_INSTUTE_ON_MH
                      )?.ismandatory === "1" &&
                        getValues("graduationstatus") == 2 && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.WHETHER_PASS_INSTUTE_ON_MH
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("passinstuteonmh", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.WHETHER_PASS_INSTUTE_ON_MH
                      )?.ismandatory === "1" &&
                      getValues("graduationstatus") == 2
                        ? "Whether Passed Institute on MH is required"
                        : false,
                  })}
                  value={watch("passinstuteonmh") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.WHETHER_PASS_INSTUTE_ON_MH,
                    watch("graduationstatus") != 2 ? 1 : 0,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("passinstuteonmh", e.target.value);
                      trigger("passinstuteonmh");
                    },
                  }}
                  error={!!errors.passinstuteonmh}
                  helperText={
                    errors.passinstuteonmh ? errors.passinstuteonmh.message : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                    Yes
                  </MenuItem>
                  <MenuItem value={0} key={1}>
                    No
                  </MenuItem>

                  {/* ))} */}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.PF_GRADUATION_DATE
        ) && (
          <Tooltip
            title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.PF_GRADUATION_DATE
              )?.fieldlabel
            }
            arrow
          >
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="pfgraduationdate"
                  control={control}
                  rules={{
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.PF_GRADUATION_DATE
                      )?.ismandatory == "1" &&
                      getValues("graduationstatus") == 2 &&
                      "PF graduation date is required",
                  }}
                  render={({ field }) => (
                    <DatePicker
                      views={["year", "month", "day"]}
                      maxDate={dayjs()}
                      minDate={dayjs("1900-01-01")}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        readOnly: true,
                      }}
                      disableFuture
                      format="DD/MM/YYYY"
                      {...field}
                      label={
                        <span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.PF_GRADUATION_DATE
                          )?.ismandatory === "1" &&
                            getValues("graduationstatus") == 2 && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.PF_GRADUATION_DATE
                            )?.fieldlabel
                          }
                        </span>
                      }
                      disabled={
                        getValues("pfgraduationdate") == 0 ||
                        watch("graduationstatus") == 1 ||
                        isPaid === "1" ||
                        getValues("graduationstatus") != 2
                      }
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(date) => {
                        if (date) {
                          const formattedDate = dayjs(date)
                            .hour(18)
                            .minute(30)
                            .second(0)
                            .millisecond(0)
                            .toISOString();
                          field.onChange(formattedDate);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      error={!!errors.pfgraduationdate}
                      helperText={
                        errors.pfgraduationdate
                          ? errors.pfgraduationdate.message
                          : ""
                      }
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.pfgraduationdate,
                          helperText: errors.pfgraduationdate?.message,
                          FormHelperTextProps: {
                            style: { margin: 0 },
                          },
                          style: {
                            width: "100%",
                            borderRadius: "8px",
                          },
                          inputProps: {
                            readOnly: true,
                          },
                        },
                        layout: {
                          sx: {
                            borderRadius: "58px",
                          },
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Tooltip>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.INTERN_TRAINING_DATE
        ) && (
          <Tooltip
            title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.INTERN_TRAINING_DATE
              )?.fieldlabel
            }
            arrow
          >
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="internshiptrainingdate"
                  control={control}
                  rules={{
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.INTERN_TRAINING_DATE
                      )?.ismandatory == "1" &&
                      getValues("graduationstatus") == 2 &&
                      "PF graduation date is required",
                  }}
                  render={({ field }) => (
                    <DatePicker
                      format="DD/MM/YYYY"
                      views={["year", "month", "day"]}
                      maxDate={dayjs()}
                      minDate={dayjs("1900-01-01")}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        readOnly: true,
                      }}
                      {...field}
                      label={
                        <span>
                          {(masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.INTERN_TRAINING_DATE
                          )?.ismandatory === "1" &&
                            !(
                              getValues("internshiptrainingdate") == 0 ||
                              watch("graduationstatus") == 1 ||
                              isPaid === "1"
                            )) ||
                            (getValues("graduationstatus") == 2 && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            ))}
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.INTERN_TRAINING_DATE
                            )?.fieldlabel
                          }
                        </span>
                      }
                      disabled={
                        getValues("internshiptrainingdate") == 0 ||
                        watch("graduationstatus") == 1 ||
                        isPaid === "1" ||
                        getValues("graduationstatus") != 2
                      }
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(date) => {
                        if (date) {
                          const formattedDate = dayjs(date)
                            .hour(18)
                            .minute(30)
                            .second(0)
                            .millisecond(0)
                            .toISOString();
                          field.onChange(formattedDate);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      disableFuture
                      error={!!errors.internshiptrainingdate}
                      helperText={
                        errors.internshiptrainingdate
                          ? errors.internshiptrainingdate.message
                          : ""
                      }
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.internshiptrainingdate,
                          helperText: errors.internshiptrainingdate?.message,
                          FormHelperTextProps: {
                            style: { margin: 0 },
                          },
                          style: {
                            width: "100%",
                            borderRadius: "8px",
                          },
                          inputProps: {
                            readOnly: true,
                          },
                        },
                        layout: {
                          sx: {
                            borderRadius: "58px",
                          },
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Tooltip>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.MH_COUNCIL_REGISTRATION
        ) && (
          <Tooltip
            title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.MH_COUNCIL_REGISTRATION
              )?.fieldlabel
            }
            arrow
          >
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="councilregdate"
                  control={control}
                  rules={{
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.MH_COUNCIL_REGISTRATION
                      )?.ismandatory == "1" &&
                      getValues("graduationstatus") == 2 &&
                      "MH council reg date is required",
                  }}
                  render={({ field }) => (
                    <DatePicker
                      views={["year", "month", "day"]}
                      maxDate={dayjs()}
                      minDate={dayjs("1900-01-01")}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        readOnly: true,
                      }}
                      {...field}
                      label={
                        <span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.MH_COUNCIL_REGISTRATION
                          )?.ismandatory === "1" &&
                            !(
                              getValues("councilregdate") == 0 ||
                              watch("graduationstatus") == 1 ||
                              (isPaid === "1" &&
                                getValues("graduationstatus") == 1)
                            ) &&
                            getValues("graduationstatus") == 2 && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.MH_COUNCIL_REGISTRATION
                            )?.fieldlabel
                          }
                        </span>
                      }
                      disabled={
                        getValues("councilregdate") == 0 ||
                        watch("graduationstatus") == 1 ||
                        isPaid === "1" ||
                        getValues("graduationstatus") != 2
                      }
                      disableFuture
                      format="DD/MM/YYYY"
                      value={field.value ? dayjs(field.value) : null}
                      error={!!errors.councilregdate}
                      helperText={
                        errors.councilregdate
                          ? errors.councilregdate.message
                          : ""
                      }
                      onChange={(date) => {
                        if (date) {
                          const formattedDate = dayjs(date)
                            .hour(18)
                            .minute(30)
                            .second(0)
                            .millisecond(0)
                            .toISOString();
                          field.onChange(formattedDate);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.councilregdate,
                          helperText: errors.councilregdate?.message,
                          FormHelperTextProps: {
                            style: { margin: 0 },
                          },
                          style: {
                            width: "100%",
                            borderRadius: "8px",
                          },
                          inputProps: {
                            readOnly: true,
                          },
                        },
                        layout: {
                          sx: {
                            borderRadius: "58px",
                          },
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Tooltip>
        )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.RE_COUNCIL_REGISTRATION
        ) && (
          <Tooltip
            title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.RE_COUNCIL_REGISTRATION
              )?.fieldlabel
            }
            arrow
          >
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="recouncilregdate"
                  control={control}
                  rules={{
                    required:
                      getValues("recouncilregdate") !== 1 ||
                      (masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.RE_COUNCIL_REGISTRATION
                      )?.ismandatory !== "1" &&
                        getValues("graduationstatus") != 2)
                        ? false
                        : "PF graduation date is required",
                  }}
                  render={({ field }) => (
                    <DatePicker
                      views={["year", "month", "day"]}
                      maxDate={dayjs()}
                      minDate={dayjs("1900-01-01")}
                      inputProps={{
                        style: { height: 56, boxSizing: "border-box" },
                        readOnly: true,
                      }}
                      {...field}
                      label={
                        <span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.RE_COUNCIL_REGISTRATION
                          )?.ismandatory === "1" &&
                            !(
                              getValues("recouncilregdate") == 0 ||
                              watch("graduationstatus") == 1 ||
                              isPaid === "1"
                            ) &&
                            getValues("graduationstatus") == 2 && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.RE_COUNCIL_REGISTRATION
                            )?.fieldlabel
                          }
                        </span>
                      }
                      error={!!errors.recouncilregdate}
                      helperText={
                        errors.recouncilregdate
                          ? errors.recouncilregdate.message
                          : ""
                      }
                      disabled={
                        getValues("recouncilregdate") == 0 ||
                        watch("graduationstatus") == 1 ||
                        isPaid === "1" ||
                        getValues("graduationstatus") != 2
                      }
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(date) => {
                        if (date) {
                          const formattedDate = dayjs(date)
                            .hour(18)
                            .minute(30)
                            .second(0)
                            .millisecond(0)
                            .toISOString();
                          field.onChange(formattedDate);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      disableFuture
                      format="DD/MM/YYYY"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.recouncilregdate,
                          helperText: errors.recouncilregdate?.message,
                          FormHelperTextProps: {
                            style: { margin: 0 },
                          },
                          style: {
                            width: "100%",
                            borderRadius: "8px",
                          },
                          inputProps: {
                            readOnly: true,
                          },
                        },
                        layout: {
                          sx: {
                            borderRadius: "58px",
                          },
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Tooltip>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.ALREADY_ADMITED
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
              error={!!errors.alreadyadmitted}
            >
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.ALREADY_ADMITED
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink:
                      !!watch("alreadyadmitted") ||
                      watch("alreadyadmitted") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="alreadyadmitted"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.ALREADY_ADMITED
                      )?.ismandatory === "1" &&
                        getValues("graduationstatus") == 2 && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.ALREADY_ADMITED
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("alreadyadmitted", {
                    required:
                      !isFieldDisabled(
                        masterFields,
                        masterFieldLabel.ALREADY_ADMITED,
                        watch("graduationstatus")
                      ) &&
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.ALREADY_ADMITED
                      )?.ismandatory === "1" &&
                      getValues("graduationstatus") == 2
                        ? "Already Admitted is required"
                        : false,
                  })}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("alreadyadmitted", e.target.value);
                      trigger("alreadyadmitted");
                    },
                  }}
                  error={!!errors.alreadyadmitted}
                  helperText={
                    errors.alreadyadmitted ? errors.alreadyadmitted.message : ""
                  }
                  value={watch("alreadyadmitted") ?? ""}
                  disabled={
                    isFieldDisabled(
                      masterFields,
                      masterFieldLabel.ALREADY_ADMITED,
                      isPaid
                    ) || getValues("graduationstatus") != 2
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                    Yes
                  </MenuItem>
                  <MenuItem value={0} key={0}>
                    No
                  </MenuItem>

                  {/* ))} */}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.G_UNIVERSITY_STATE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.gotheruniversitystate}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.G_UNIVERSITY_STATE
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("gotheruniversitystate"),
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="gotheruniversitystate"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.G_UNIVERSITY_STATE
                      )?.ismandatory === "1" &&
                        getValues("graduationstatus") == 2 && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.G_UNIVERSITY_STATE
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("gotheruniversitystate", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.G_UNIVERSITY_STATE
                      )?.ismandatory === "1" &&
                      getValues("graduationstatus") == 2
                        ? "Graduation State is required"
                        : false,
                  })}
                  value={watch("gotheruniversitystate") ?? ""}
                  disabled={
                    isFieldDisabled(
                      masterFields,
                      masterFieldLabel.G_UNIVERSITY_STATE,
                      watch("graduationstatus") != 2 ? 1 : 0,
                      isPaid
                    ) || !(getValues("graduationstatus") !== 1)
                  }
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      const stateId = e.target.value;
                      setValue("gotheruniversitystate", e.target.value);
                      trigger("gotheruniversitystate");
                    },
                  }}
                  error={!!errors.gotheruniversitystate}
                  helperText={
                    errors.gotheruniversitystate
                      ? errors.gotheruniversitystate.message
                      : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {state?.map((val, i) => (
                    <MenuItem value={val?.stateid} key={i}>
                      {val?.state}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.G_CollegeName
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.G_CollegeName
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_CollegeName
                    )?.ismandatory === "1" &&
                      getValues("graduationstatus") == 2 && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.G_CollegeName
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="Enter College Name"
                {...register("gcollegename", {
                  required:
                    !isFieldDisabled(
                      masterFields,
                      masterFieldLabel.G_CollegeName,
                      watch("graduationstatus") != 2 ? 1 : 0
                    ) &&
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_CollegeName
                    )?.ismandatory === "1"
                      ? "\u00A0 \u00A0 Graduation College Name name is required"
                      : false,
                })}
                // onInput={AlphabetsValidation}
                error={!!errors.gcollegename}
                helperText={errors.gcollegename?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("gcollegename"),
                }}
                disabled={isFieldDisabled(
                  masterFields,
                  masterFieldLabel.G_CollegeName,
                  watch("graduationstatus") != 2 ? 1 : 0,
                  isPaid
                )}
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.University_Name
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.University_Name
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.University_Name
                    )?.ismandatory === "1" &&
                      getValues("graduationbranchid") != 79 &&
                      getValues("graduationstatus") == 2 && (
                        // (
                        //   getValues("graduationstatus") !== 1) &&
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.University_Name
                      )?.fieldlabel
                    }
                  </span>
                }
                onInput={AlphabetsValidation}
                placeholder="Enter Caste Name"
                {...register("guniversityname", {
                  required:
                    !isFieldDisabled(
                      masterFields,
                      masterFieldLabel.University_Name
                      // watch("graduationstatus")
                    ) &&
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.University_Name
                    )?.ismandatory === "1" &&
                    getValues("graduationbranchid") != 79 &&
                    getValues("graduationstatus") == 2 &&
                    "\u00A0 \u00A0 Graduation University name is required",
                })}
                // onInput={AlphabetsValidation}
                error={!!errors.guniversityname}
                helperText={errors.guniversityname?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("guniversityname"),
                }}
                disabled={
                  isFieldDisabled(
                    masterFields,
                    masterFieldLabel.University_Name,
                    watch("graduationstatus") != 2 ? 1 : 0,

                    isPaid
                  ) || getValues("graduationbranchid") == 79
                }
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.G_OTHER_DEGREE_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.G_OTHER_DEGREE_NAME
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_OTHER_DEGREE_NAME
                    ) &&
                      watch("graduationbranchid") == 79 &&
                      getValues("graduationstatus") == 2 && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.G_OTHER_DEGREE_NAME
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="Enter Graduation Other Degree Name"
                {...register("gotherdegreename", {
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_OTHER_DEGREE_NAME
                    ) &&
                    getValues("graduationbranchid") == 79 &&
                    getValues("graduationstatus") == 2
                      ? "Graduation other degree name is required"
                      : false,
                })}
                onInput={AlphabetsValidation}
                error={!!errors.gotherdegreename}
                helperText={errors.gotherdegreename?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("gotherdegreename"),
                }}
                value={watch("gotherdegreename") ?? ""}
                disabled={
                  isFieldDisabled(
                    masterFields,
                    masterFieldLabel.G_OTHER_DEGREE_NAME,
                    watch("graduationstatus") != 2 ? 1 : 0,

                    isPaid
                  ) || !(getValues("graduationbranchid") == 79)
                }
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.G_OTHER_UNIVERSITY_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.G_OTHER_UNIVERSITY_NAME
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_OTHER_UNIVERSITY_NAME
                    ) &&
                      watch("graduationbranchid") == 79 &&
                      getValues("graduationstatus") == 2 && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.G_OTHER_UNIVERSITY_NAME
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="Enter Graduation Other university Name"
                {...register("gotheruniversityname", {
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_OTHER_UNIVERSITY_NAME
                    ) &&
                    watch("graduationbranchid") == 79 &&
                    getValues("graduationstatus") == 2
                      ? "Graduation other university name is required"
                      : false,
                })}
                onInput={AlphabetsValidation}
                value={watch("gotheruniversityname") ?? ""}
                error={!!errors.gotheruniversityname}
                helperText={errors.gotheruniversityname?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("gotheruniversityname"),
                }}
                disabled={
                  isFieldDisabled(
                    masterFields,
                    masterFieldLabel.G_OTHER_UNIVERSITY_NAME,
                    watch("graduationstatus") != 2 ? 1 : 0,
                    isPaid
                  ) || !(watch("graduationbranchid") == 79)
                }
              />
            </Tooltip>
          </Grid>
        )}
        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.G_PASSING_MONTH
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.gpassingmonth}>
              <InputLabel
                shrink={!!watch("gpassingmonth")}
                htmlFor="gpassingmonth"
                style={{
                  color:
                    watch("graduationstatus") !== 2
                      ? "rgba(0, 0, 0, 0.38)"
                      : "",
                }}
              >
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.G_PASSING_MONTH
                )?.ismandatory === "1" &&
                  getValues("graduationstatus") !== 1 && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.G_PASSING_MONTH
                  )?.fieldlabel
                }
              </InputLabel>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.G_PASSING_MONTH
                  )?.fieldlabel
                }
                arrow
              >
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="gpassingmonth"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.G_PASSING_MONTH
                      )?.ismandatory === "1" &&
                        getValues("graduationstatus") == 2 && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.G_PASSING_MONTH
                        )?.fieldlabel
                      }
                    </span>
                  }
                  {...register("gpassingmonth", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.G_PASSING_MONTH
                      )?.ismandatory === "1" && watch("graduationstatus") == 2
                        ? "Graduation Passing Month is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("gpassingmonth", e.target.value);
                    trigger("gpassingmonth");
                  }}
                  value={watch("gpassingmonth") ?? ""}
                  disabled={
                    isFieldDisabled(
                      masterFields,
                      masterFieldLabel.G_PASSING_MONTH,
                      watch("graduationstatus") != 2 ? 1 : 0,
                      isPaid
                    ) || watch("graduationstatus") != 2
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {months.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </Select>
              </Tooltip>
              {errors.gpassingmonth && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.gpassingmonth.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.G_SPECIALISATION_OR_SUBJECT
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.gspecialsubject}>
              <InputLabel
                htmlFor="gspecialsubject"
                style={{
                  color:
                    watch("graduationstatus") !== 2
                      ? "rgba(0, 0, 0, 0.38)"
                      : "",
                }}
              >
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.G_SPECIALISATION_OR_SUBJECT
                )?.ismandatory === "1" &&
                  watch("graduationstatus") == 2 && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.G_SPECIALISATION_OR_SUBJECT
                  )?.fieldlabel
                }
              </InputLabel>
              <Controller
                name="gspecialsubject"
                control={control}
                defaultValue={[]}
                rules={{
                  required:
                    watch("graduationstatus") === 2 &&
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_SPECIALISATION_OR_SUBJECT
                    )?.ismandatory === "1"
                      ? "Graduation Specialization is required"
                      : false,
                }}
        
                InputLabelProps={{
                  shrink: !!watch("gspecialsubject"),
                }}
                render={({ field }) => (
                  <Tooltip
                    title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.G_SPECIALISATION_OR_SUBJECT
                      )?.fieldlabel
                    }
                    arrow
                  >
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      {...field}
                      id="gspecialsubject"
                      multiple
                      label={
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.G_SPECIALISATION_OR_SUBJECT
                            )?.fieldlabel
                          }{" "}
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.G_SPECIALISATION_OR_SUBJECT
                          )?.ismandatory === "1" &&
                            watch("graduationstatus") == 2 && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      value={Array.isArray(field?.value) ? field?.value : []}
                      disabled={isFieldDisabled(
                        masterFields,
                        masterFieldLabel.G_SPECIALISATION_OR_SUBJECT,
                        watch("graduationstatus") != 2 ? 1 : 0,
                        isPaid
                      )}
                      onChange={(e) => {
                        const selectedValues = e.target.value;
                        const validValues = selectedValues.filter(
                          (val) => val !== null && val !== undefined
                        );
                        const uniqueValues = Array.from(
                          new Set(validValues.map((val) => val))
                        );

                        setValue("gspecialsubject", uniqueValues);
                        trigger("gspecialsubject");
                      }}
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => {
                            const selectedValue = specialisationSubject?.find(
                              (val) => val?.gspecializationid == value
                            );
                            return (
                              <Chip
                                key={value}
                                label={
                                  watch("gspecialsubject") == 0
                                    ? "Not Applicable"
                                    : selectedValue?.gspecializaiton
                                }
                              />
                            );
                          })}
                        </Box>
                      )}
                    >
                      <MenuItem value={0}>Not Applicable</MenuItem>
                      {specialisationSubject?.map((val, i) => (
                        <MenuItem key={i} value={val?.gspecializationid}>
                          {val?.gspecializaiton}
                        </MenuItem>
                      ))}
                    </Select>
                  </Tooltip>
                )}
              />
              {errors.gspecialsubject && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.gspecialsubject.message}
                </small>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.DATE_OF_PASSING_FINAL_GRADUATION
        ) && (
          <Tooltip
            title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.DATE_OF_PASSING_FINAL_GRADUATION
              )?.fieldlabel
            }
            arrow
          >
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="pgpassingdate"
                  control={control}
                  rules={{
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.DATE_OF_PASSING_FINAL_GRADUATION
                      )?.ismandatory == "1" &&
                      "Post graduation Passing date is required",
                  }}
                  render={({ field }) => (
                    <DatePicker
                      views={["year", "month", "day"]}
                      maxDate={dayjs()}
                      minDate={dayjs("1900-01-01")}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        readOnly: true,
                      }}
                      disableFuture
                      format="DD/MM/YYYY"
                      {...field}
                      label={
                        <span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.DATE_OF_PASSING_FINAL_GRADUATION
                          )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.DATE_OF_PASSING_FINAL_GRADUATION
                            )?.fieldlabel
                          }
                        </span>
                      }
                      disabled={isFieldDisabled(
                        masterFields,
                        masterFieldLabel.DATE_OF_PASSING_FINAL_GRADUATION,
                        // watch("pgstatus") != 2 ? 1 : 0,
                        isPaid
                      )}
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(date) => {
                        if (date) {
                          const formattedDate = dayjs(date)
                            .hour(18)
                            .minute(30)
                            .second(0)
                            .millisecond(0)
                            .toISOString();
                          field.onChange(formattedDate);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      error={!!errors.pgpassingdate}
                      helperText={
                        errors.pgpassingdate ? errors.pgpassingdate.message : ""
                      }
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.pgpassingdate,
                          helperText: errors.pgpassingdate?.message,
                          FormHelperTextProps: {
                            style: { margin: 0 },
                          },
                          style: {
                            width: "100%",
                            borderRadius: "8px",
                          },
                          inputProps: {
                            readOnly: true,
                          },
                        },
                        layout: {
                          sx: {
                            borderRadius: "58px",
                          },
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Tooltip>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.PASSED_GRADUATION_FROM_MH
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.pgpassedfromindia}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.PASSED_GRADUATION_FROM_MH
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink:
                      !!watch("pgpassedfromindia") ||
                      watch("pgpassedfromindia") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="pgpassedfromindia"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.PASSED_GRADUATION_FROM_MH
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.PASSED_GRADUATION_FROM_MH
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("pgpassedfromindia", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.PASSED_GRADUATION_FROM_MH
                      )?.ismandatory === "1"
                        ? "Post Graduation Passed From India is required"
                        : false,
                  })}
                  value={watch("pgpassedfromindia") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.PASSED_GRADUATION_FROM_MH,
                    // watch("graduationstatus") != 2 ? 1 : 0,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("pgpassedfromindia", e.target.value);
                      trigger("pgpassedfromindia");
                    },
                  }}
                  error={!!errors.pgpassedfromindia}
                  helperText={
                    errors.pgpassedfromindia
                      ? errors.pgpassedfromindia.message
                      : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.INTERNSHIP_COMPLETION_DATE
        ) && (
          <Tooltip
            title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.INTERNSHIP_COMPLETION_DATE
              )?.fieldlabel
            }
            arrow
          >
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="pgpinterncompleteddate"
                  control={control}
                  rules={{
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.INTERNSHIP_COMPLETION_DATE
                      )?.ismandatory == "1" &&
                      watch("pgstatus") !== 1 &&
                      "Post graduation Intenship Completion date is required",
                  }}
                  render={({ field }) => (
                    <DatePicker
                      views={["year", "month", "day"]}
                      maxDate={dayjs()}
                      minDate={dayjs("1900-01-01")}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        readOnly: true,
                      }}
                      disableFuture
                      format="DD/MM/YYYY"
                      {...field}
                      label={
                        <span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.INTERNSHIP_COMPLETION_DATE
                          )?.ismandatory === "1" &&
                            watch("pgstatus") !== 1 && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.INTERNSHIP_COMPLETION_DATE
                            )?.fieldlabel
                          }
                        </span>
                      }
                      disabled={isFieldDisabled(
                        masterFields,
                        masterFieldLabel.INTERNSHIP_COMPLETION_DATE,
                        watch("pgstatus") != 2 ? 1 : 0,
                        isPaid
                      )}
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(date) => {
                        if (date) {
                          const formattedDate = dayjs(date)
                            .hour(18)
                            .minute(30)
                            .second(0)
                            .millisecond(0)
                            .toISOString();
                          field.onChange(formattedDate);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      error={!!errors.pgpinterncompleteddate}
                      helperText={
                        errors.pgpinterncompleteddate
                          ? errors.pgpinterncompleteddate.message
                          : ""
                      }
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.pgpinterncompleteddate,
                          helperText: errors.pgpinterncompleteddate?.message,
                          FormHelperTextProps: {
                            style: { margin: 0 },
                          },
                          style: {
                            width: "100%",
                            borderRadius: "8px",
                          },
                          inputProps: {
                            readOnly: true,
                          },
                        },
                        layout: {
                          sx: {
                            borderRadius: "58px",
                          },
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Tooltip>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_COLLEGE
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.GRADUATION_COLLEGE
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.GRADUATION_COLLEGE
                    )?.ismandatory === "1" &&
                      watch("pgstatus") !== 1 && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.GRADUATION_COLLEGE
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="Enter Yes/No Is You Graduation From College"
                {...register("isgraduationfromclg", {
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.GRADUATION_COLLEGE
                    )?.ismandatory == "1" && watch("pgstatus") !== 1
                      ? "\u00A0 \u00A0 Graduation From College is required"
                      : false,
                })}
                error={!!errors.isgraduationfromclg}
                helperText={errors.isgraduationfromclg?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("isgraduationfromclg"),
                }}
                disabled={isFieldDisabled(
                  masterFields,
                  masterFieldLabel.GRADUATION_COLLEGE,
                  // watch("pgstatus") != 2 ? 1 : 0,
                  isPaid
                )}
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.MAHARASHTRA_OT_PT_REGISTRATION
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset" error={!!errors.pgotptreg}>
              <FormLabel component="legend">
                {
                  masterFields?.find(
                    (field) =>
                      field.masterfieldlabel ===
                      masterFieldLabel.MAHARASHTRA_OT_PT_REGISTRATION
                  )?.fieldlabel
                }
              </FormLabel>
              <Controller
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.MAHARASHTRA_OT_PT_REGISTRATION
                    )?.ismandatory === "1" &&
                    getValues("pggraduationstatus") == 2
                      ? "Select is required"
                      : false,
                }}
                control={control}
                name="pgotptreg"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    value={watch("pgotptreg") ?? ""}
                    disabled={getValues("graduationstatus") != 2}
                  >
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio
                          disabled={
                            watch("graduationstatus") === 2 ? false : true
                          }
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      control={
                        <Radio
                          disabled={
                            watch("graduationstatus") === 2 ? false : true
                          }
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              {errors.pgotptreg && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.pgotptreg.message}
                </small>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.RCI_REGISTRATION
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset" error={!!errors.pgrcireg}>
              <FormLabel component="legend">
                {
                  masterFields?.find(
                    (field) =>
                      field.masterfieldlabel ===
                      masterFieldLabel.RCI_REGISTRATION
                  )?.fieldlabel
                }
              </FormLabel>
              <Controller
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.RCI_REGISTRATION
                    )?.ismandatory === "1" &&
                    getValues("pggraduationstatus") == 2
                      ? "Select is required"
                      : false,
                }}
                control={control}
                name="pgrcireg"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    value={watch("pgrcireg") ?? ""}
                    disabled={getValues("graduationstatus") != 2}
                  >
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio
                          disabled={
                            watch("graduationstatus") === 2 ? false : true
                          }
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      control={
                        <Radio
                          disabled={
                            watch("graduationstatus") === 2 ? false : true
                          }
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              {errors.pgrcireg && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.pgrcireg.message}
                </small>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.PASSED_PG_GRADUATION_FROM_MH
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset" error={!!errors.ispgmh}>
              <FormLabel component="legend">
                {
                  masterFields?.find(
                    (field) =>
                      field.masterfieldlabel ===
                      masterFieldLabel.PASSED_PG_GRADUATION_FROM_MH
                  )?.fieldlabel
                }
              </FormLabel>
              <Controller
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.PASSED_PG_GRADUATION_FROM_MH
                    )?.ismandatory === "1" &&
                    getValues("pggraduationstatus") == 2
                      ? "Select is required"
                      : false,
                }}
                control={control}
                name="ispgmh"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    value={watch("ispgmh") ?? ""}
                    disabled={getValues("ispgmh") != 2}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              {errors.ispgmh && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.ispgmh.message}
                </small>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.PURSUING_PG_COURSE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset" error={!!errors.isadmittedpg}>
              <FormLabel component="legend">
                {
                  masterFields?.find(
                    (field) =>
                      field.masterfieldlabel ===
                      masterFieldLabel.PURSUING_PG_COURSE
                  )?.fieldlabel
                }
              </FormLabel>
              <Controller
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.PURSUING_PG_COURSE
                    )?.ismandatory === "1" &&
                    getValues("pggraduationstatus") == 2
                      ? "Select is required"
                      : false,
                }}
                control={control}
                name="isadmittedpg"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    value={watch("isadmittedpg") ?? ""}
                  >
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio
                          disabled={
                            watch("graduationstatus") === 2 ? false : true
                          }
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      control={
                        <Radio
                          disabled={
                            watch("graduationstatus") === 2 ? false : true
                          }
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              {errors.isadmittedpg && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.isadmittedpg.message}
                </small>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.G_MATHEMATICS_SUBJECT
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset" error={!!errors.gmathssubject}>
              <FormLabel component="legend">
                {
                  masterFields?.find(
                    (field) =>
                      field.masterfieldlabel ===
                      masterFieldLabel.G_MATHEMATICS_SUBJECT
                  )?.fieldlabel
                }
              </FormLabel>
              <Controller
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_MATHEMATICS_SUBJECT
                    )?.ismandatory === "1" && getValues("graduationstatus") == 2
                      ? "Select Mathematics is required"
                      : false,
                }}
                control={control}
                name="gmathssubject"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    value={watch("gmathssubject") ?? ""}
                    disabled={getValues("graduationstatus") != 2}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              {errors.gmathssubject && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.gmathssubject.message}
                </small>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.G_EXAM_PASS_BEFORE_1975
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              component="fieldset"
              error={!!errors.gexampassbefore1975}
            >
              <FormLabel component="legend">
                {
                  masterFields?.find(
                    (field) =>
                      field.masterfieldlabel ===
                      masterFieldLabel.G_EXAM_PASS_BEFORE_1975
                  )?.fieldlabel
                }
              </FormLabel>
              <Controller
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_EXAM_PASS_BEFORE_1975
                    )?.ismandatory === "1" && getValues("graduationstatus") == 2
                      ? "Select Exam passing before 1975 is required"
                      : false,
                }}
                control={control}
                name="gexampassbefore1975"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    value={watch("gexampassbefore1975") ?? ""}
                    disabled={getValues("graduationstatus") != 2}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              {errors.gexampassbefore1975 && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.gexampassbefore1975.message}{" "}
                </small>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.G_ENG_MEDIUM_COLLEGE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              component="fieldset"
              error={!!errors.gengmediumcollege}
            >
              <FormLabel component="legend">
                {
                  masterFields?.find(
                    (field) =>
                      field.masterfieldlabel ===
                      masterFieldLabel.G_ENG_MEDIUM_COLLEGE
                  )?.fieldlabel
                }
              </FormLabel>
              <Controller
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_ENG_MEDIUM_COLLEGE
                    )?.ismandatory === "1" && getValues("graduationstatus") == 2
                      ? "Select Graduation English Medium is required"
                      : false,
                }}
                control={control}
                name="gengmediumcollege"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    value={watch("gengmediumcollege") ?? ""}
                    disabled={getValues("graduationstatus") != 2}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              {errors.gengmediumcollege && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.gengmediumcollege.message}{" "}
                </small>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.G_ENG_LANG_CONTENT_TEST
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              component="fieldset"
              error={!!errors.genglangcontenttest}
            >
              <FormLabel component="legend">
                {
                  masterFields?.find(
                    (field) =>
                      field.masterfieldlabel ===
                      masterFieldLabel.G_ENG_LANG_CONTENT_TEST
                  )?.fieldlabel
                }
              </FormLabel>
              <Controller
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_ENG_LANG_CONTENT_TEST
                    )?.ismandatory === "1" && getValues("graduationstatus") == 2
                      ? "Select Graduation English Contest is required"
                      : false,
                }}
                control={control}
                name="genglangcontenttest"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    value={watch("genglangcontenttest") ?? ""}
                    disabled={getValues("graduationstatus") != 2}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              {errors.genglangcontenttest && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.genglangcontenttest.message}{" "}
                </small>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.G_OPT_SPECIAL_ED_COLLEGE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              component="fieldset"
              error={!!errors.goptspecialedcollege}
            >
              <FormLabel component="legend">
                {
                  masterFields?.find(
                    (field) =>
                      field.masterfieldlabel ===
                      masterFieldLabel.G_OPT_SPECIAL_ED_COLLEGE
                  )?.fieldlabel
                }
              </FormLabel>
              <Controller
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_OPT_SPECIAL_ED_COLLEGE
                    )?.ismandatory === "1" && getValues("graduationstatus") == 2
                      ? "Select Exam passing before 1975 is required"
                      : false,
                }}
                control={control}
                name="goptspecialedcollege"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    value={watch("goptspecialedcollege") ?? ""}
                    disabled={getValues("graduationstatus") != 2}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              {errors.goptspecialedcollege && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.goptspecialedcollege.message}{" "}
                </small>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.G_STUDIED_MATHEMATICS
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset" error={!!errors.gstudiedmath}>
              <FormLabel component="legend">
                {
                  masterFields?.find(
                    (field) =>
                      field.masterfieldlabel ===
                      masterFieldLabel.G_STUDIED_MATHEMATICS
                  )?.fieldlabel
                }
              </FormLabel>
              <Controller
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_STUDIED_MATHEMATICS
                    )?.ismandatory === "1" && getValues("graduationstatus") == 2
                      ? "Select is required"
                      : false,
                }}
                control={control}
                name="gstudiedmath"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    value={watch("gstudiedmath") ?? ""}
                    disabled={getValues("graduationstatus") != 2}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              {errors.gstudiedmath && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.gstudiedmath.message}{" "}
                </small>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.G_PASSED_BEFORE_1975
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              component="fieldset"
              error={!!errors.ispassedbefore1975}
            >
              <FormLabel component="legend">
                {
                  masterFields?.find(
                    (field) =>
                      field.masterfieldlabel ===
                      masterFieldLabel.G_PASSED_BEFORE_1975
                  )?.fieldlabel
                }
              </FormLabel>
              <Controller
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_PASSED_BEFORE_1975
                    )?.ismandatory === "1" && getValues("graduationstatus") == 2
                      ? "Select is required"
                      : false,
                }}
                control={control}
                name="ispassedbefore1975"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    value={watch("ispassedbefore1975") ?? ""}
                    disabled={getValues("graduationstatus") != 2}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              {errors.ispassedbefore1975 && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.ispassedbefore1975.message}{" "}
                </small>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.G_ENGLISH_LANGUAGE_TEST
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset" error={!!errors.genglishlangtest}>
              <FormLabel component="legend">
                {
                  masterFields?.find(
                    (field) =>
                      field.masterfieldlabel ===
                      masterFieldLabel.G_ENGLISH_LANGUAGE_TEST
                  )?.fieldlabel
                }
              </FormLabel>
              <Controller
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_ENGLISH_LANGUAGE_TEST
                    )?.ismandatory === "1" && getValues("graduationstatus") == 2
                      ? "Select is required"
                      : false,
                }}
                control={control}
                name="genglishlangtest"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    value={watch("genglishlangtest") ?? ""}
                    disabled={getValues("graduationstatus") != 2}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                        if (e.target.value !== "1") {
                        setValue("geltc", "");
                        setValue("gspecialeducationclg", "");
                      }
                    }}
  
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              {errors.genglishlangtest && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.genglishlangtest.message}{" "}
                </small>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.G_ELTC
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset" error={!!errors.geltc}>
            <FormLabel component="legend">
  {
    masterFields?.find(
      (field) => field.masterfieldlabel === masterFieldLabel.G_ELTC
    )?.fieldlabel
  }{" "}
  {watch("genglishlangtest") === "1" && (
    <span style={{ fontSize: "14px", color: "red" }}>
      (You will have to appear for English Language Content Test (ELCT) along with B.Ed. CET.)
    </span>
  )}
</FormLabel>
           <Controller
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.G_ELTC
                    )?.ismandatory === "1" &&  getValues("graduationstatus") == 2 &&
                    watch("genglishlangtest") === "1"
                      ? "Select is required"
                      : false,
                }}
                control={control}
                name="geltc"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    value={watch("geltc") ?? ""}
                    disabled={
                      getValues("graduationstatus") != 2 ||
                      watch("genglishlangtest") !== "1"
                    }
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Yes"

                      disabled={
                      
                        watch("genglishlangtest") !== "1"
                      }
                     
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="No"
                      disabled={
                       
                        watch("genglishlangtest") !== "1"
                      }
                    />
                  </RadioGroup>
                )}
              />
              {errors.geltc && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.geltc.message}{" "}
                </small>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.G_SPECIAL_EDUCATION_CLG
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              component="fieldset"
              error={!!errors.gspecialeducationclg}
            >
              <FormLabel component="legend">
                {
                  masterFields?.find(
                    (field) =>
                      field.masterfieldlabel ===
                      masterFieldLabel.G_SPECIAL_EDUCATION_CLG
                  )?.fieldlabel
                }
              </FormLabel>
              <Controller
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_SPECIAL_EDUCATION_CLG
                    )?.ismandatory === "1" &&  getValues("graduationstatus") == 2 &&
                    watch("genglishlangtest") === "1"
                      ? "Select is required"
                      : false,
                }}
                control={control}
                name="gspecialeducationclg"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    value={watch("gspecialeducationclg") ?? ""}
                    disabled={
                      getValues("graduationstatus") != 2 ||
                      watch("genglishlangtest") !== "1"
                    }
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Yes"
                      disabled={
                      
                        watch("genglishlangtest") !== "1"
                      }
                     
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="No"
                      disabled={
                      
                        watch("genglishlangtest") !== "1"
                      }
                     
                    />
                  </RadioGroup>
                )}
              />
              {errors.gspecialeducationclg && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.gspecialeducationclg.message}{" "}
                </small>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.G_SPECIAL_ED_METHOD_SUBJECTS
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.gspecialeducationmethod}>
              <InputLabel
                // shrink={!!watch("gspecialeducationmethod")}
                htmlFor="gspecialeducationmethod"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.G_SPECIAL_ED_METHOD_SUBJECTS
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.G_SPECIAL_ED_METHOD_SUBJECTS
                )?.ismandatory === "1" &&
                  watch("goptspecialedcollege") == 1 && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
              </InputLabel>
              <Controller
                name="gspecialeducationmethod"
                control={control}
                defaultValue={[]}
                rules={{ required: "Graduation Specialization is required" }}
                InputLabelProps={{
                  shrink: !!watch("gspecialeducationmethod"),
                }}
                render={({ field }) => (
                  <Tooltip
                    title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.G_SPECIAL_ED_METHOD_SUBJECTS
                      )?.fieldlabel
                    }
                    arrow
                  >
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      {...field}
                      id="gspecialeducationmethod"
                      multiple
                      label={
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.G_SPECIAL_ED_METHOD_SUBJECTS
                            )?.fieldlabel
                          }{" "}
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.G_SPECIAL_ED_METHOD_SUBJECTS
                          )?.ismandatory === "1" &&
                            watch("goptspecialedcollege") == 1 && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      // value={getValues("pgspecialeducationmethod")}
                      {...register("gspecialeducationmethod", {
                        required:
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.G_SPECIAL_ED_METHOD_SUBJECTS
                          )?.ismandatory === "1" &&
                          watch("goptspecialedcollege") == 1 &&
                          "Graduation Specialization is required",
                      })}
                      onChange={(e) => {
                        const selectedValues = e.target.value;
                        const validValues = selectedValues.filter(
                          (val) => val !== null && val !== undefined
                        );
                        const uniqueValues = Array.from(
                          new Set(validValues.map((val) => val))
                        );

                        // field.onChange(uniqueValues);
                        setValue("gspecialeducationmethod", uniqueValues);
                        trigger("gspecialeducationmethod");
                      }}
                      // value={
                      //   watch("goptspecialedcollege") != 1
                      //     ? []
                      //     : watch("gspecialeducationmethod") ?? []
                      // }
                      // value={field?.value}

                      value={Array.isArray(field?.value) ? field?.value : []}
                      disabled={
                        // watch("gspecialeducationclg") != "1" || 
                        // watch("goptspecialedcollege") !== 1 ||  
                        isFieldDisabled(
                          masterFields,
                          masterFieldLabel.G_SPECIAL_ED_METHOD_SUBJECTS,
                          // watch("graduationstatus") !== 2 ? 1 : 0,
                           watch("gspecialeducationclg") == "1" ? 0 : 1,
                          isPaid
                        )
                      }
                      inputProps={{
                        style: { height: 56, boxSizing: "border-box" },
                      }}
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => {
                            const selectedValue = specialisationMethod?.find(
                              (val) => val?.gspecialedmethodid == value
                            );
                            // console.log(selected,'selected')
                            // console.log(
                            //   selectedState?.state,
                            //   "languagename"
                            // );

                            return (
                              <Chip
                                key={value}
                                label={selectedValue?.gspecialedmethod}
                              />
                            );
                          })}
                        </Box>
                      )}
                    >
                      {specialisationMethod?.map((val, i) => (
                        <MenuItem key={i} value={val?.gspecialedmethodid}>
                          {val?.gspecialedmethod}
                        </MenuItem>
                      ))}
                    </Select>
                  </Tooltip>
                )}
              />
              {errors.gspecialeducationmethod && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.gspecialeducationmethod.message}
                </small>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.COUNCIL_REG_APP_NO ||
            field.masterfieldlabel ===
              masterFieldLabel.PGP_EDUCATION_PREFERENCE_1
        ) && (
          <Grid item xs={12} md={12}>
            {/* <Typography
              pt={1}
              sx={{
                fontSize: "24px",
                color: `${cssProperties?.color?.tertiary}`,
                fontWeight: 500,
                lineHeight: "30px",
              }}
            >
              {" "}
              PGP INFORMATION{" "}
            </Typography> */}
          </Grid>
        )}

        <Grid item xs={12} md={12}>
          {/* <Typography
            pt={1}
            sx={{
              fontSize: "24px",
              color: `${cssProperties?.color?.tertiary}`,
              fontWeight: 500,
              lineHeight: "30px",
            }}
          >
            {" "}
            FIRST YEAR MARKS{" "}
          </Typography> */}
        </Grid>

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.FY_MARKS_OBTAINED
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.FY_MARKS_OBTAINED
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.FY_MARKS_OBTAINED
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.FY_MARKS_OBTAINED
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                placeholder="Fy Marks Obtained"
                {...register("fymarksobtained", {
                  required:
                    watch("graduationstatus") === 1 ||
                    watch("graduationstatus") === 2
                      ? "FY  Marks Obtained is required"
                      : false,
                  validate: (value) => {
                    const maxMarks =
                      watch("graduationmarktype") === 2 ? 10.0 : 900;
                    const outOfValue = parseInt(watch("fymarksoutof"));

                    // Check if value is a decimal when graduationmarktype is "percentage"
                    if (watch("graduationmarktype") === 1 && value % 1 !== 0) {
                      return "Decimal values are not allowed for percentage.";
                    }

                    if (parseInt(value) > maxMarks) {
                      return `Marks obtained cannot be greater than ${maxMarks}`;
                    }

                    if (
                      watch("graduationmarktype") === 1 &&
                      value > outOfValue
                    ) {
                      return "Marks obtained cannot be greater than marks out of";
                    }
                    return true;
                  },
                })}
                onInput={(e) => {
                  const regex = /^[1-9][0-9]*\.?[0-9]*$/;
                  const value = e.target.value;

                  if (!regex.test(value)) {
                    e.target.value = value.slice(0, -1);
                  }
                }}
                onChange={(e) => {
                  const value = parseFloat(e.target.value) || 0;
                  const maxMarks = parseFloat(getValues("fymarksoutof"));
                  setValue("fymarksobtained", e.target.value);

                  if (watch("graduationmarktype") === 2) {
                    const cgpaError = cgpaValidation(value);
                    if (cgpaError) {
                      setError("fymarksobtained", {
                        type: "manual",
                        message: cgpaError,
                      });
                    }
                  } else if (watch("graduationmarktype") === 1) {
                    percentageValidation();
                  }
                  const maxValue = parseInt(getValues("fymarksoutof"));
                  const obtainedMarks = parseFloat(
                    getValues("fymarksobtained")
                  );
                  let percentage = (obtainedMarks / maxValue) * 100;
                  if (watch("graduationmarktype") === 1) {
                    setValue(
                      "fypercentage",
                      !isNaN(percentage) ? percentage.toFixed(2) : ""
                    );
                  }
                  trigger("fymarksoutof");
                  trigger("fymarksobtained");
                  let cgpa = (obtainedMarks / maxValue) * 100;
                  if (watch("graduationmarktype") === 2) {
                    setValue(
                      "fypercentage",
                      !isNaN(cgpa) ? cgpa.toFixed(2) : ""
                    );
                  }
                  setTimeout(() => {
                    trigger(["fymarksobtained", "fymarksoutof"]);
                  }, 50);
                }}
                inputProps={{
                  min: 0,
                  max: watch("graduationmarktype") === 2 ? 10.0 : 900,
                  style: { height: 56, boxSizing: "border-box" },
                  step: watch("graduationmarktype") === 1 ? 1 : "any",
                }}
                error={!!errors.fymarksobtained}
                helperText={errors.fymarksobtained?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("fymarksobtained"),
                }}
                // disabled={isFieldDisabled(
                //   masterFields,
                //   masterFieldLabel.FY_MARKS_OBTAINED,
                //   isPaid
                // )}
                disabled={![1, 2].includes(watch("graduationmarktype"))}
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.FY_MARKS_OUT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.FY_MARKS_OUT_OF
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.FY_MARKS_OUT_OF
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.FY_MARKS_OUT_OF
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                placeholder="FY Marks Out Of*"
                {...register("fymarksoutof", {
                  required:
                    watch("graduationstatus") === 1 ||
                    watch("graduationstatus") === 2
                      ? "FY Marks Out Of is required"
                      : false,
                  // validate: (value) => {

                  //   const maxMarks =
                  //     watch("graduationmarktype") === 2 ? 10.0 : 900;

                  //     if (watch("graduationmarktype") === 1 && value % 1 !== 0) {
                  //       return "Decimal values are not allowed for percentage.";
                  //     }

                  //   if (parseInt(value) > maxMarks) {
                  //     return `Marks Out Of cannot exceed ${maxMarks}`;
                  //   }

                  //   if (watch(value) > parseInt(watch("fymarksobtained"))) {
                  //     return "Marks Out Of cannot be less than Marks Obtained";
                  //   }
                  //   return true;
                  // },
                  // validate: (value) => {
                  //   // Only validate if required condition is met
                  //   if (
                  //     !(
                  //       watch("graduationmarktype") !== "" &&
                  //       getValues("graduationstatus") == 1
                  //     )
                  //   ) {
                  //     return true; // Skip validation if not required
                  //   }

                  //   const maxMarks =
                  //     watch("graduationmarktype") === 2 ? 10.0 : 900;

                  //   if (watch("graduationmarktype") === 1 && value % 1 !== 0) {
                  //     return "Decimal values are not allowed for percentage.";
                  //   }

                  //   if (parseInt(value) > maxMarks) {
                  //     return `Marks Out Of cannot exceed ${maxMarks}`;
                  //   }

                  //   if (parseInt(value) < parseInt(watch("fymarksobtained"))) {
                  //     return "Marks Out Of cannot be less than Marks Obtained";
                  //   }

                  //   return true;
                  // },
                  validate: (value) => {
                    // Parse the input value as an integer
                    const intValue = parseInt(value);

                    // Validation for CGPA
                    if (watch("graduationmarktype") === 2) {
                      if (intValue > 10) {
                        return "Marks Out Of cannot exceed 10";
                      }
                    }

                    // Validation for percentage type
                    if (watch("graduationmarktype") === 1 && value % 1 !== 0) {
                      return "Decimal values are not allowed for percentage.";
                    }

                    // Only validate if the required condition is met
                    if (
                      !(
                        watch("graduationmarktype") !== "" &&
                        getValues("graduationstatus") == 1
                      )
                    ) {
                      return true; // Skip validation if not required
                    }

                    const maxMarks =
                      watch("graduationmarktype") === 2 ? 10.0 : 900;

                    // General validations
                    if (intValue > maxMarks) {
                      return `Marks Out Of cannot exceed ${maxMarks}`;
                    }

                    if (intValue < parseInt(watch("fymarksobtained"))) {
                      return "Marks Out Of cannot be less than Marks Obtained";
                    }

                    return true;
                  },
                })}
                onInput={(e) => {
                  const regex = /^[1-9][0-9]*\.?[0-9]*$/;
                  const value = e.target.value;

                  if (!regex.test(value)) {
                    e.target.value = value.slice(0, -1);
                  }
                }}
                error={!!errors?.fymarksoutof}
                helperText={errors.fymarksoutof?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                inputProps={{
                  style: { height: 56, boxSizing: "border-box" },
                  step: watch("graduationmarktype") === 1 ? 1 : "any",
                }}
                InputLabelProps={{
                  shrink: !!watch("fymarksoutof"),
                }}
                // disabled={isFieldDisabled(
                //   masterFields,
                //   masterFieldLabel.FY_MARKS_OUT_OF,
                //   isPaid
                // )}

                disabled={![1, 2].includes(watch("graduationmarktype"))}
                onChange={(e) => {
                  const maxValue = parseFloat(e.target.value);
                  const obtainedMarks = parseFloat(
                    getValues("fymarksobtained")
                  );
                  clearErrors("fypercentage");
                  setValue("fymarksoutof", e.target.value);

                  if (maxValue < obtainedMarks) {
                    setError("fymarksoutof", {
                      type: "manual",
                      message:
                        "Marks out of must be greater than or equal to marks obtained",
                    });
                  } else {
                    clearErrors("fymarksoutof");

                    if (watch("graduationmarktype") === 2) {
                      const cgpaError = cgpaValidation(maxValue);
                      if (cgpaError) {
                        setError("fymarksoutof", {
                          type: "manual",
                          message: cgpaError,
                        });
                      }
                    } else if (watch("graduationmarktype") === 1) {
                      percentageValidation();
                    }

                    let percentage = (obtainedMarks / maxValue) * 100;
                    if (watch("graduationmarktype") === 1) {
                      setValue(
                        "fypercentage",
                        !isNaN(percentage) ? percentage.toFixed(2) : ""
                      );
                    }
                    trigger("fymarksoutof");
                    trigger("fymarksobtained");
                    let cgpa = (obtainedMarks / maxValue) * 100;
                    if (watch("graduationmarktype") === 2) {
                      setValue(
                        "fypercentage",
                        !isNaN(cgpa) ? cgpa.toFixed(2) : ""
                      );
                    }
                  }

                  trigger("fymarksobtained");
                  trigger("fymarksoutof");
                  trigger("fypercentage");
                }}
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.FY_CALCULATED_PERCENTAGE
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.FY_CALCULATED_PERCENTAGE
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.FY_CALCULATED_PERCENTAGE
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.FY_CALCULATED_PERCENTAGE
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                placeholder="Fy Calculated/Equivalent Percentage*"
                {...register("fypercentage", {
                  required: !(
                    watch("graduationmarktype") != "" &&
                    getValues("graduationstatus") == 1
                  )
                    ? "Fy Calculated/Equivalent percentage is required"
                    : false,
                })}
                onChange={(e) => {
                  const percentage = parseFloat(e.target.value);
                  if (
                    !isNaN(percentage) &&
                    percentage >= 0 &&
                    percentage <= 100
                  ) {
                    setValue("fypercentage", percentage.toFixed(2));
                  }
                }}
                error={!!errors?.fypercentage}
                helperText={errors?.fypercentage?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                
                inputProps={{
                  readOnly: true, 
                  style: {
                    height: 56,
                    boxSizing: "border-box",
                    cursor: "default", 
                  },
                }}
                InputProps={{
                  style: {
                    pointerEvents: "none", 
                  },
                }}
                InputLabelProps={{
                  shrink: !!watch("fypercentage"),
                }}
                // disabled={isFieldDisabled(
                //   masterFields,
                //   masterFieldLabel.FY_CALCULATED_PERCENTAGE,
                //   1,
                //   isPaid
                // )}
                disabled={![1, 2].includes(watch("graduationmarktype"))}
              />
              <Box>
                {/* <small style={{ color: "#666666" }}>
                  Up to 2 decimal places are allowed
                </small> */}
              </Box>
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.FY_NO_OF_ATTEMPTS
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.FY_NO_OF_ATTEMPTS
                )?.fieldlabel
              }
              arrow
            >
              <FormControl fullWidth error={!!errors.fynoofattempts}>
                <InputLabel
                  shrink={
                    !!watch("fynoofattempts") || watch("fynoofattempts") === 0
                  }
                  htmlFor="fynoofattempts"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.FY_NO_OF_ATTEMPTS
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.FY_NO_OF_ATTEMPTS
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </InputLabel>
                <Select
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  id="fynoofattempts"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.FY_NO_OF_ATTEMPTS
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.FY_NO_OF_ATTEMPTS
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    </span>
                  }
                  {...register("fynoofattempts", {
                    required:
                      [1, 2].includes(watch("graduationmarktype")) &&
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.FY_NO_OF_ATTEMPTS
                      )?.ismandatory === "1"
                        ? "Fy No Of Attempts is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("fynoofattempts", e.target.value);
                    trigger("fynoofattempts");
                  }}
                  value={watch("fynoofattempts") ?? ""}
                  // disabled={isFieldDisabled(
                  //   masterFields,
                  //   masterFieldLabel.FY_NO_OF_ATTEMPTS,
                  //   // watch("graduationstatus") != 2 ? 1 : 0,
                  //   isPaid
                  // )}
                  disabled={![1, 2].includes(watch("graduationmarktype"))}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>

                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                    1
                  </MenuItem>
                  <MenuItem value={2} key={2}>
                    2
                  </MenuItem>
                  <MenuItem value={3} key={3}>
                    3
                  </MenuItem>
                </Select>
                {errors.fynoofattempts && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.fynoofattempts.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Tooltip>
          </Grid>
        )}

        <Grid item xs={12} md={12}>
          {/* <Typography
            pt={1}
            sx={{
              fontSize: "24px",
              color: `${cssProperties?.color?.tertiary}`,
              fontWeight: 500,
              lineHeight: "30px",
            }}
          >
            {" "}
            SECOND YEAR MARKS
          </Typography> */}
        </Grid>

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SY_MARKS_OBTAINED
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.SY_MARKS_OBTAINED
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SY_MARKS_OBTAINED
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SY_MARKS_OBTAINED
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                placeholder="Sy Marks Obtained"
                {...register("symarksobtained", {
                  required:
                    ![1, 2].includes(watch("graduationmarktype")) ||
                    watch("graduationstatus") !== 2
                      ? false
                      : "SY Marks Obtained is required",
                  validate: (value) => {
                    if (![1, 2].includes(watch("graduationmarktype")))
                      return true;
                    const outOfValue = parseInt(getValues("symarksoutof"));

                    // Validation for CGPA
                    if (watch("graduationmarktype") === 2) {
                      if (value > 10) {
                        return "Marks obtained cannot be greater than 10";
                      }
                      return true;
                    }

                    // Validation for percentage
                    if (watch("graduationmarktype") === 1) {
                      if (value % 1 !== 0) {
                        return "Decimal values are not allowed for percentage.";
                      }
                      if (value > Math.min(outOfValue, 900)) {
                        return "Marks obtained cannot be greater than marks out of or 900.";
                      }
                    }

                    return true;
                  },
                })}
                onInput={(e) => {
                  const regex = /^[1-9][0-9]*\.?[0-9]*$/;
                  const value = e.target.value;

                  if (!regex.test(value)) {
                    e.target.value = value.slice(0, -1);
                  }
                }}
                onChange={(e) => {
                  const value = parseFloat(e.target.value) || 0;
                  setValue("symarksobtained", e.target.value);

                  if (watch("graduationmarktype") === 2) {
                    const cgpaError = cgpaValidation(value);
                    if (cgpaError) {
                      setError("symarksobtained", {
                        type: "manual",
                        message: cgpaError,
                      });
                    }
                  } else if (watch("graduationmarktype") === 1) {
                    percentageValidation();
                  }
                  const maxValue = parseInt(getValues("symarksoutof"));
                  const obtainedMarks = parseFloat(
                    getValues("symarksobtained")
                  );
                  let percentage = (obtainedMarks / maxValue) * 100;
                  if (watch("graduationmarktype") === 1) {
                    setValue(
                      "sypercentage",
                      !isNaN(percentage) ? percentage.toFixed(2) : ""
                    );
                  }
                  trigger("symarksoutof");
                  trigger("symarksobtained");
                  let cgpa = (obtainedMarks / maxValue) * 100;
                  if (watch("graduationmarktype") === 2) {
                    setValue(
                      "sypercentage",
                      !isNaN(cgpa) ? cgpa.toFixed(2) : ""
                    );
                  }
                  setTimeout(() => {
                    trigger(["symarksobtained", "symarksoutof"]);
                  }, 50);
                }}
                inputProps={{
                  min: 0,
                  max:
                    watch("graduationmarktype") === 2
                      ? 10
                      : parseInt(getValues("symarksoutof")) <= 900
                      ? parseInt(getValues("symarksoutof"))
                      : 900,

                  step: watch("graduationmarktype") === 1 ? 1 : "any",
                }}
                error={!!errors.symarksobtained}
                helperText={errors.symarksobtained?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("symarksobtained"),
                }}
                // disabled={isFieldDisabled(
                //   masterFields,
                //   masterFieldLabel.SY_MARKS_OBTAINED,
                //   // watch("graduationstatus") != 2 ? 1 : 0,
                //   isPaid
                // )}
                disabled={![1, 2].includes(watch("graduationmarktype"))}
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SY_MARKS_OUT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.SY_MARKS_OUT_OF
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SY_MARKS_OUT_OF
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SY_MARKS_OUT_OF
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                placeholder="SY Marks Out Of*"
                {...register("symarksoutof", {
                  required:
                    watch("graduationstatus") == 2
                      ? "SY Marks Out of is required"
                      : false,
                  validate: (value) => {
                    const intValue = parseInt(value);

                    // Validation for CGPA
                    if (watch("graduationmarktype") === 2) {
                      if (intValue > 10) return "Marks Out Of cannot exceed 10";
                    }

                    if (watch("graduationmarktype") === 1 && value % 1 !== 0) {
                      return "Decimal values are not allowed for percentage.";
                    }

                    if (intValue > 900) return "Marks Out Of cannot exceed 900";
                    return true;
                  },
                })}
                onInput={(e) => {
                  const regex = /^[1-9][0-9]*\.?[0-9]*$/;
                  const value = e.target.value;

                  if (!regex.test(value)) {
                    e.target.value = value.slice(0, -1);
                  }
                }}
                // value={}
                error={!!errors?.symarksoutof}
                helperText={errors.symarksoutof?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                inputProps={{
                  step: watch("graduationmarktype") === 1 ? 1 : "any",
                }}
                InputLabelProps={{
                  shrink: !!watch("symarksoutof"),
                }}
                // disabled={isFieldDisabled(masterFields, masterFieldLabel.SY_MARKS_OUT_OF)}
                // disabled={isFieldDisabled(
                //   masterFields,
                //   masterFieldLabel.SY_MARKS_OUT_OF,
                //   // watch("graduationstatus") != 2 ? 1 : 0,
                //   isPaid
                // )}

                disabled={![1, 2].includes(watch("graduationmarktype"))}
                onChange={(e) => {
                  const maxValue = parseFloat(e.target.value);
                  const obtainedMarks = parseFloat(
                    getValues("symarksobtained")
                  );
                  clearErrors("sypercentage");
                  setValue("symarksoutof", e.target.value);

                  if (maxValue < obtainedMarks) {
                    setError("symarksoutof", {
                      type: "manual",
                      message:
                        "Marks out of must be greater than or equal to marks obtained",
                    });
                  } else {
                    clearErrors("symarksoutof");

                    if (watch("graduationmarktype") === 2) {
                      const cgpaError = cgpaValidation(maxValue);
                      if (cgpaError) {
                        setError("symarksoutof", {
                          type: "manual",
                          message: cgpaError,
                        });
                      }
                    } else if (watch("graduationmarktype") === 1) {
                      percentageValidation();
                    }

                    let percentage = (obtainedMarks / maxValue) * 100;
                    if (watch("graduationmarktype") === 1) {
                      setValue(
                        "sypercentage",
                        !isNaN(percentage) ? percentage.toFixed(2) : ""
                      );
                    }
                    trigger("symarksoutof");
                    trigger("symarksobtained");
                    let cgpa = (obtainedMarks / maxValue) * 100;
                    if (watch("graduationmarktype") === 2) {
                      setValue(
                        "sypercentage",
                        !isNaN(cgpa) ? cgpa.toFixed(2) : ""
                      );
                    }
                  }

                  trigger("symarksobtained");
                  trigger("symarksoutof");
                  trigger("sypercentage");
                }}
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SY_CALCULATED_PERCENTAGE
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.SY_CALCULATED_PERCENTAGE
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SY_CALCULATED_PERCENTAGE
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SY_CALCULATED_PERCENTAGE
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                placeholder="Sy Calculated/Equivalent Percentage*"
                {...register("sypercentage", {
                  required:
                    watch("graduationstatus") == 2
                      ? "Sy Calculated/Equivalent percentage is required"
                      : false,
                  // getValues("graduationstatus") != 1 ||
                  // masterFields?.find(
                  //   (field) =>
                  //     field?.masterfieldlabel ===
                  //     masterFieldLabel.SY_CALCULATED_PERCENTAGE
                  // )?.ismandatory != "1"
                  //   ? false
                  //   : "Fy Calculated/Equivalent percentage is required",
                })}
                error={!!errors?.sypercentage}
                helperText={errors?.sypercentage?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                
                inputProps={{
                  readOnly: true, 
                  style: {
                    height: 56,
                    boxSizing: "border-box",
                    cursor: "default", 
                  },
                }}
                InputProps={{
                  style: {
                    pointerEvents: "none", 
                  },
                }}
                InputLabelProps={{
                  shrink: !!watch("sypercentage"),
                }}
                // disabled={isFieldDisabled(
                //   masterFields,
                //   masterFieldLabel.SY_CALCULATED_PERCENTAGE,
                //   1,
                //   isPaid
                // )}
                disabled={![1, 2].includes(watch("graduationmarktype"))}
              />
              <Box>
                {/* <small style={{ color: "#666666" }}>
                  Up to 2 decimal places are allowed
                </small> */}
              </Box>
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SY_NO_OF_ATTEMPTS
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.SY_NO_OF_ATTEMPTS
                )?.fieldlabel
              }
              arrow
            >
              <FormControl fullWidth error={!!errors.synoofattempts}>
                <InputLabel
                  shrink={
                    !!watch("synoofattempts") || watch("synoofattempts") === 0
                  }
                  htmlFor="synoofattempts"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SY_NO_OF_ATTEMPTS
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SY_NO_OF_ATTEMPTS
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  id="synoofattempts"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.SY_NO_OF_ATTEMPTS
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SY_NO_OF_ATTEMPTS
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    </span>
                  }
                  {...register("synoofattempts", {
                    required:
                      [1, 2].includes(watch("graduationmarktype")) &&
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SY_NO_OF_ATTEMPTS
                      )?.ismandatory === "1"
                        ? "Sy No Of Attempts is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("synoofattempts", e.target.value);
                    trigger("synoofattempts");
                  }}
                  value={watch("synoofattempts") ?? ""}
                  // disabled={isFieldDisabled(
                  //   masterFields,
                  //   masterFieldLabel.SY_NO_OF_ATTEMPTS,
                  //   // watch("graduationstatus") != 2 ? 1 : 0,
                  //   isPaid
                  // )}

                  disabled={![1, 2].includes(watch("graduationmarktype"))}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>

                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                    1
                  </MenuItem>
                  <MenuItem value={2} key={2}>
                    2
                  </MenuItem>
                  <MenuItem value={3} key={3}>
                    3
                  </MenuItem>
                </Select>
                {errors.synoofattempts && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.synoofattempts.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Tooltip>
          </Grid>
        )}

        <Grid item xs={12} md={12}>
          {/* sss */}
        </Grid>

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.TY_MARKS_OBTAINED
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.TY_MARKS_OBTAINED
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.TY_MARKS_OBTAINED
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.TY_MARKS_OBTAINED
                    )?.ismandatory === "1" &&
                      !(
                        watch("graduationmarktype") != "" &&
                        getValues("graduationstatus") == 1
                      ) && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="Ty Marks Obtained"
                {...register("tymarksobtained", {
                  //       required:
                  // [1, 2].includes(watch("graduationmarktype")) &&
                  // getValues("graduationstatus") === 1 &&
                  //         getValues("graduationstatus") !== 1 ||
                  //         masterFields?.find(
                  //           (field) =>
                  //             field?.masterfieldlabel ===
                  //             masterFieldLabel.TY_MARKS_OBTAINED
                  //         )?.ismandatory !== "1"
                  //           ? false
                  //           : "TY  Marks is required",

                  required:
                    !(
                      watch("graduationmarktype") != "" &&
                      getValues("graduationstatus") == 1
                    ) && "TY Marks is required",
                  // validate: (value) => {
                  //   // Skip validation if the field is disabled
                  //   if (watch("graduationmarktype") !="" && getValues("graduationstatus") ==1) return true;

                  //   if ( !(                  watch("graduationmarktype") !="" && getValues("graduationstatus") ==1
                  // )) {
                  //     if (value > 10) {
                  //       return "Marks obtained cannot be greater than 10";
                  //     }
                  //     return true;
                  //   }

                  //   if ( !(                  watch("graduationmarktype") !="" && getValues("graduationstatus") ==1
                  // )) {
                  //     const outOfValue = parseInt(getValues("tymarksoutof"));

                  //     // Check if decimal values are entered for percentage
                  //     if (watch("graduationmarktype") === 1 && value % 1 !== 0) {
                  //       return "Decimal values are not allowed for percentage.";
                  //     }

                  //     return (
                  //       parseInt(value) <=
                  //         (outOfValue <= 900 ? outOfValue : 900) ||
                  //       "Marks obtained cannot be greater than marks out of or 900"
                  //     );
                  //   }
                  // },
                  validate: (value) => {
                    // Skip validation if the field is disabled
                    if (
                      watch("graduationmarktype") !== "" &&
                      getValues("graduationstatus") == 1
                    )
                      return true;

                    const isCGPA = watch("graduationmarktype") === 2;
                    const isPercentage = watch("graduationmarktype") === 1;
                    const outOfValue = parseInt(getValues("tymarksoutof"));

                    if (isCGPA) {
                      if (value > 10) {
                        return "Marks obtained cannot be greater than 10";
                      }
                    }

                    if (isPercentage) {
                      if (value % 1 !== 0) {
                        return "Decimal values are not allowed for percentage.";
                      }
                      if (value > outOfValue || value > 900) {
                        return "Marks obtained cannot be greater than marks out of or 900.";
                      }
                    }

                    return true;
                  },
                })}
                onInput={(e) => {
                  const regex = /^[1-9][0-9]*\.?[0-9]*$/;
                  const value = e.target.value;

                  if (!regex.test(value)) {
                    e.target.value = value.slice(0, -1);
                  }
                }}
                onChange={(e) => {
                  const value = parseFloat(e.target.value) || 0;
                  setValue("tymarksobtained", e.target.value);

                  if (watch("graduationmarktype") === 2) {
                    const cgpaError = cgpaValidation(value);
                    if (cgpaError) {
                      setError("tymarksobtained", {
                        type: "manual",
                        message: cgpaError,
                      });
                    }
                  } else if (watch("graduationmarktype") === 1) {
                    percentageValidation();
                  }
                  const maxValue = parseInt(getValues("tymarksoutof"));
                  const obtainedMarks = parseFloat(
                    getValues("tymarksobtained")
                  );
                  let percentage = (obtainedMarks / maxValue) * 100;
                  if (watch("graduationmarktype") === 1) {
                    setValue(
                      "typercentage",
                      !isNaN(percentage) ? percentage.toFixed(2) : ""
                    );
                  }
                  trigger("tymarksoutof");
                  trigger("tymarksobtained");
                  let cgpa = (obtainedMarks / maxValue) * 100;
                  if (watch("graduationmarktype") === 2) {
                    setValue(
                      "typercentage",
                      !isNaN(cgpa) ? cgpa.toFixed(2) : ""
                    );
                  }
                  setTimeout(() => {
                    trigger(["tymarksobtained", "tymarksoutof"]);
                  }, 50);
                }}
                inputProps={{
                  min: 0,
                  max:
                    watch("graduationmarktype") === 2
                      ? 10
                      : parseInt(getValues("tymarksoutof")) <= 900
                      ? parseInt(getValues("tymarksoutof"))
                      : 900,
                  step: watch("graduationmarktype") === 1 ? 1 : "any",
                }}
                error={!!errors.tymarksobtained}
                helperText={errors.tymarksobtained?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("tymarksobtained"),
                }}
                // disabled={isFieldDisabled(
                //   masterFields,
                //   masterFieldLabel.TY_MARKS_OBTAINED,
                //   watch("graduationstatus") != 2 ? 1 : 0,
                //   isPaid
                // )}
                //                 disabled={
                //   ![1, 2].includes(watch("graduationmarktype")) ||
                //   watch("graduationstatus") === 2
                // }

                // disabled={
                //   !(
                //     watch("graduationstatus") !== 2 ||
                //     ([1, 2].includes(watch("graduationmarktype")) &&
                //       watch("graduationstatus") === 2)
                //   )
                // }
                disabled={
                  watch("graduationmarktype") != "" &&
                  getValues("graduationstatus") == 1
                }
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.TY_MARKS_OUT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.TY_MARKS_OUT_OF
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.TY_MARKS_OUT_OF
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.TY_MARKS_OUT_OF
                    )?.ismandatory === "1" &&
                      !(
                        watch("graduationmarktype") != "" &&
                        getValues("graduationstatus") == 1
                      ) && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="TY Marks Out Of*"
                {...register("tymarksoutof", {
                  //       required:
                  // [1, 2].includes(watch("graduationmarktype")) &&
                  // getValues("graduationstatus") === 1 &&
                  //         masterFields?.find(
                  //           (field) =>
                  //             field?.masterfieldlabel ===
                  //             masterFieldLabel.TY_MARKS_OUT_OF
                  //         )?.ismandatory != "1"
                  //           ? false
                  //           : "TY Marks Out Of is required",
                  required: !(
                    watch("graduationmarktype") != "" &&
                    getValues("graduationstatus") == 1
                  )
                    ? "Ty Marks Out Of is required"
                    : false,
                  validate: (value) => {
                    const intValue = parseInt(value);

                    // Validation for CGPA
                    if (watch("graduationmarktype") === 2) {
                      if (intValue > 10)
                        return "Marks Out Of cannot exceed 10 for CGPA.";
                    }

                    // Check if value is a decimal when graduationmarktype is "percentage"
                    if (watch("graduationmarktype") === 1 && value % 1 !== 0) {
                      return "Decimal values are not allowed for percentage.";
                    }

                    if (intValue > 900) return "Marks Out Of cannot exceed 900";
                    return true;
                  },
                })}
                onInput={(e) => {
                  const regex = /^[1-9][0-9]*\.?[0-9]*$/;
                  const value = e.target.value;

                  if (!regex.test(value)) {
                    e.target.value = value.slice(0, -1);
                  }
                }}
                // value={}
                error={!!errors?.tymarksoutof}
                helperText={errors.tymarksoutof?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                inputProps={{
                  step: watch("graduationmarktype") === 1 ? 1 : "any",
                }}
                InputLabelProps={{
                  shrink: !!watch("tymarksoutof"),
                }}
                // disabled={isFieldDisabled(
                //   masterFields,
                //   masterFieldLabel.TY_MARKS_OUT_OF,
                //   watch("graduationstatus") != 2 ? 1 : 0,
                //   isPaid
                // )}

                disabled={
                  watch("graduationmarktype") != "" &&
                  getValues("graduationstatus") == 1
                }
                onChange={(e) => {
                  const maxValue = parseFloat(e.target.value);
                  const obtainedMarks = parseFloat(
                    getValues("tymarksobtained")
                  );
                  clearErrors("typercentage");
                  setValue("tymarksoutof", e.target.value);

                  if (maxValue < obtainedMarks) {
                    setError("tymarksoutof", {
                      type: "manual",
                      message:
                        "Marks out of must be greater than or equal to marks obtained",
                    });
                  } else {
                    clearErrors("tymarksoutof");

                    if (watch("graduationmarktype") === 2) {
                      const cgpaError = cgpaValidation(maxValue);
                      if (cgpaError) {
                        setError("symarksoutof", {
                          type: "manual",
                          message: cgpaError,
                        });
                      }
                    } else if (watch("graduationmarktype") === 1) {
                      percentageValidation();
                    }

                    let percentage = (obtainedMarks / maxValue) * 100;
                    if (watch("graduationmarktype") === 1) {
                      setValue(
                        "typercentage",
                        !isNaN(percentage) ? percentage.toFixed(2) : ""
                      );
                    }
                    trigger("tymarksoutof");
                    trigger("tymarksobtained");
                    let cgpa = (obtainedMarks / maxValue) * 100;
                    if (watch("graduationmarktype") === 2) {
                      setValue(
                        "typercentage",
                        !isNaN(cgpa) ? cgpa.toFixed(2) : ""
                      );
                    }
                  }

                  trigger("tymarksobtained");
                  trigger("tymarksoutof");
                  trigger("typercentage");
                }}
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.TY_CALCULATED_PERCENTAGE
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.TY_CALCULATED_PERCENTAGE
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.TY_CALCULATED_PERCENTAGE
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.TY_CALCULATED_PERCENTAGE
                    )?.ismandatory === "1" &&
                      !(
                        watch("graduationmarktype") != "" &&
                        getValues("graduationstatus") == 1
                      ) && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="Ty Calculated/Equivalent Percentage*"
                {...register("typercentage", {
                  required:
                    !(
                      watch("graduationmarktype") != "" &&
                      getValues("graduationstatus") == 1
                    ) && "Ty Calculated/Equivalent percentage is required",
                  // getValues("graduationstatus") != 1 ||
                  // masterFields?.find(
                  //   (field) =>
                  //     field?.masterfieldlabel ===
                  //     masterFieldLabel.TY_CALCULATED_PERCENTAGE
                  // )?.ismandatory != "1"
                  //   ? false
                  //   : "Fy Calculated/Equivalent percentage is required",
                })}
                error={!!errors?.typercentage}
                helperText={errors?.typercentage?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                
                inputProps={{
                  readOnly: true, 
                  style: {
                    height: 56,
                    boxSizing: "border-box",
                    cursor: "default", 
                  },
                }}
                InputProps={{
                  style: {
                    pointerEvents: "none", 
                  },
                }}
                InputLabelProps={{
                  shrink: !!watch("typercentage"),
                }}
                // disabled={isFieldDisabled(
                //   masterFields,
                //   masterFieldLabel.TY_CALCULATED_PERCENTAGE,
                //   1,
                //   isPaid
                // )}
                disabled={
                  watch("graduationmarktype") != "" &&
                  getValues("graduationstatus") == 1
                }
              />
              <Box>
                {/* <small style={{ color: "#666666" }}>
                  Up to 2 decimal places are allowed
                </small> */}
              </Box>
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.TY_NO_OF_ATTEMPTS
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.TY_NO_OF_ATTEMPTS
                )?.fieldlabel
              }
              arrow
            >
              <FormControl fullWidth error={!!errors.tynoofattempts}>
                <InputLabel
                  shrink={
                    !!watch("tynoofattempts") || watch("tynoofattempts") === 0
                  }
                  htmlFor="tynoofattempts"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.TY_NO_OF_ATTEMPTS
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.TY_NO_OF_ATTEMPTS
                  )?.ismandatory === "1" &&
                    !(
                      watch("graduationmarktype") != "" &&
                      getValues("graduationstatus") == 1
                    ) && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  id="tynoofattempts"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.TY_NO_OF_ATTEMPTS
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.TY_NO_OF_ATTEMPTS
                      )?.ismandatory === "1" &&
                        !(
                          watch("graduationmarktype") != "" &&
                          getValues("graduationstatus") == 1
                        ) && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("tynoofattempts", {
                    required:
                      !(
                        watch("graduationmarktype") != "" &&
                        getValues("graduationstatus") == 1
                      ) &&
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.TY_NO_OF_ATTEMPTS
                      )?.ismandatory === "1"
                        ? "Ty No Of Attempts is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("tynoofattempts", e.target.value);
                    trigger("tynoofattempts");
                  }}
                  value={watch("tynoofattempts") ?? ""}
                  // disabled={isFieldDisabled(
                  //   masterFields,
                  //   masterFieldLabel.TY_NO_OF_ATTEMPTS,
                  //   watch("graduationstatus") != 2 ? 1 : 0,
                  //   isPaid
                  // )}
                  disabled={
                    watch("graduationmarktype") != "" &&
                    getValues("graduationstatus") == 1
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>

                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                    1
                  </MenuItem>
                  <MenuItem value={2} key={2}>
                    2
                  </MenuItem>
                  <MenuItem value={3} key={3}>
                    3
                  </MenuItem>
                </Select>
                {errors.tynoofattempts && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.tynoofattempts.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Tooltip>
          </Grid>
        )}

        <Grid item xs={12} md={12}>
          {/* <Typography
            pt={1}
            sx={{
              fontSize: "24px",
              color: `${cssProperties?.color?.tertiary}`,
              fontWeight: 500,
              lineHeight: "30px",
            }}
          >
            {" "}
            FOURTH YEAR MARKS
          </Typography> */}
        </Grid>

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.FOUR_Y_MARKS_OBTAINED
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.FOUR_Y_MARKS_OBTAINED
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.FOUR_Y_MARKS_OBTAINED
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.FOUR_Y_MARKS_OBTAINED
                    )?.ismandatory === "1" &&
                      !(
                        watch("graduationmarktype") != "" &&
                        getValues("graduationstatus") == 1
                      ) && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="Four Y Marks Obtained"
                {...register("fourymarksobtained", {
                  //       required:
                  // [1, 2].includes(watch("graduationmarktype")) &&
                  // getValues("graduationstatus") === 1 &&
                  //         masterFields?.find(
                  //           (field) =>
                  //             field?.masterfieldlabel ===
                  //             masterFieldLabel.FOUR_Y_MARKS_OBTAINED
                  //         )?.ismandatory !== "1"
                  //           ? false
                  //           : "Four Y  Marks is required",
                  required:
                    !(
                      watch("graduationmarktype") != "" &&
                      getValues("graduationstatus") == 1
                    ) && "Four Y Marks is required",
                  validate: (value) => {
                    const isCGPA = watch("graduationmarktype") === 2;
                    const isPercentage = watch("graduationmarktype") === 1;
                    const outOfValue = parseInt(getValues("fourymarksoutof"));

                    if (isCGPA) {
                      if (value > 10) {
                        return "Marks obtained cannot be greater than 10";
                      }
                    }

                    if (isPercentage) {
                      if (value % 1 !== 0) {
                        return "Decimal values are not allowed for percentage.";
                      }
                      if (value > outOfValue || value > 900) {
                        return "Marks obtained cannot be greater than marks out of or 900.";
                      }
                    }

                    return true;
                  },
                })}
                onInput={(e) => {
                  const regex = /^[1-9][0-9]*\.?[0-9]*$/;
                  const value = e.target.value;

                  if (!regex.test(value)) {
                    e.target.value = value.slice(0, -1);
                  }
                }}
                onChange={(e) => {
                  const value = parseFloat(e.target.value) || 0;
                  setValue("fourymarksobtained", e.target.value);

                  if (watch("graduationmarktype") == 2) {
                    const cgpaError = cgpaValidation(value);
                    if (cgpaError) {
                      setError("fourymarksobtained", {
                        type: "manual",
                        message: cgpaError,
                      });
                    }
                  } else if (watch("graduationmarktype") == 1) {
                    percentageValidation();
                  }
                  const maxValue = parseInt(getValues("fourymarksoutof"));
                  const obtainedMarks = parseFloat(
                    getValues("fourymarksobtained")
                  );
                  let percentage = (obtainedMarks / maxValue) * 100;
                  if (watch("graduationmarktype") == 1) {
                    setValue(
                      "fourypercentage",
                      !isNaN(percentage) ? percentage.toFixed(2) : ""
                    );
                  }
                  trigger("fourymarksoutof");
                  trigger("fourymarksobtained");
                  let cgpa = (obtainedMarks / maxValue) * 100;
                  if (watch("graduationmarktype") == 2) {
                    setValue(
                      "fourypercentage",
                      !isNaN(cgpa) ? cgpa.toFixed(2) : ""
                    );
                  }
                  setTimeout(() => {
                    trigger(["fourymarksobtained", "fourymarksoutof"]);
                  }, 50);
                }}
                inputProps={{
                  min: 0,
                  max:
                    watch("graduationmarktype") == 2
                      ? 10
                      : parseInt(getValues("fourymarksoutof")) <= 900
                      ? parseInt(getValues("fourymarksoutof"))
                      : 900,

                  step: watch("graduationmarktype") == 1 ? 1 : "any",
                }}
                error={!!errors.fourymarksobtained}
                helperText={errors.fourymarksobtained?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("fourymarksobtained"),
                }}
                // disabled={isFieldDisabled(
                //   masterFields,
                //   masterFieldLabel.FOUR_Y_MARKS_OBTAINED,
                //   watch("graduationstatus") != 2 ? 1 : 0,
                //   isPaid
                // )}
                disabled={
                  watch("graduationmarktype") != "" &&
                  getValues("graduationstatus") == 1
                }
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.FOUR_Y_MARKS_OUT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.FOUR_Y_MARKS_OUT_OF
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.FOUR_Y_MARKS_OUT_OF
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.FOUR_Y_MARKS_OUT_OF
                    )?.ismandatory === "1" &&
                      !(
                        watch("graduationmarktype") != "" &&
                        getValues("graduationstatus") == 1
                      ) && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="Candidate's Mother Name as on HSC Marksheet*"
                {...register("fourymarksoutof", {
                  //       required:
                  // [1, 2].includes(watch("graduationmarktype")) &&
                  // getValues("graduationstatus") === 1 &&
                  //         masterFields?.find(
                  //           (field) =>
                  //             field?.masterfieldlabel ===
                  //             masterFieldLabel.CANDIDATE_MOTHER_NAME_ON_HSC
                  //         )?.ismandatory != "1"
                  //           ? false
                  //           : "FourY  Marks Outof is required",
                  //   required:
                  //   !( watch("graduationmarktype") !="" && getValues("graduationstatus") ==1) && "FourY Marks is required",
                  // validate: (value) => {
                  //   // Skip validation if the field is disabled
                  //   if (!(watch("graduationmarktype") !== "" && getValues("graduationstatus") === 1)) {
                  //     return true;
                  //   }

                  // },
                  required:
                    !(
                      watch("graduationmarktype") != "" &&
                      getValues("graduationstatus") == 1
                    ) && "FourY Marks is required",

                  validate: (value) => {
                    if (watch("graduationmarktype") === 2) {
                      if (value > 10) {
                        return "Marks Out Of cannot exceed 10";
                      }
                    }

                    // Check if decimal values are entered for percentage
                    if (watch("graduationmarktype") == 1 && value % 1 != 0) {
                      return "Decimal values are not allowed for percentage.";
                    }

                    // Skip validation if the field is disabled or not required
                    if (
                      !(
                        watch("graduationmarktype") != "" &&
                        getValues("graduationstatus") == 1
                      )
                    ) {
                      return true;
                    }

                    if (getValues("graduationstatus") != 0) {
                      const outOfValue = parseInt(getValues("fourymarksoutof"));

                      // Validation for CGPA
                      if (watch("graduationmarktype") == 2) {
                        if (outOfValue > 10)
                          return "Marks Out Of cannot exceed 10";
                      }

                      if (getValues("graduationstatus") == 2) {
                        return (
                          parseInt(value) <=
                            (outOfValue <= 900 ? outOfValue : 900) ||
                          "Marks obtained cannot be greater than marks out of or 900"
                        );
                      }
                    }
                  },
                })}
                error={!!errors.fourymarksoutof}
                helperText={errors.fourymarksoutof?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                inputProps={{
                  step: watch("graduationmarktype") == 1 ? 1 : "any",
                }}
                InputLabelProps={{
                  shrink: !!watch("fourymarksoutof"),
                }}
                // disabled={isFieldDisabled(
                //   masterFields,
                //   masterFieldLabel.FOUR_Y_MARKS_OUT_OF,
                //   watch("graduationstatus") != 2 ? 1 : 0,
                //   isPaid
                // )}

                disabled={
                  watch("graduationmarktype") != "" &&
                  getValues("graduationstatus") == 1
                }
                onInput={(e) => {
                  const regex = /^[1-9][0-9]*\.?[0-9]*$/;
                  const value = e.target.value;

                  if (!regex.test(value)) {
                    e.target.value = value.slice(0, -1);
                  }
                }}
                onChange={(e) => {
                  const maxValue = parseFloat(e.target.value);
                  const obtainedMarks = parseFloat(
                    getValues("fourymarksobtained")
                  );
                  clearErrors("fourypercentage");
                  setValue("fourymarksoutof", e.target.value);

                  if (maxValue < obtainedMarks) {
                    setError("fourymarksoutof", {
                      type: "manual",
                      message:
                        "Marks out of must be greater than or equal to marks obtained",
                    });
                  } else {
                    clearErrors("fourymarksoutof");

                    if (watch("graduationmarktype") == 2) {
                      const cgpaError = cgpaValidation(maxValue);
                      if (cgpaError) {
                        setError("symarksoutof", {
                          type: "manual",
                          message: cgpaError,
                        });
                      }
                    } else if (watch("graduationmarktype") == 1) {
                      percentageValidation();
                    }

                    let percentage = (obtainedMarks / maxValue) * 100;
                    if (watch("graduationmarktype") == 1) {
                      setValue(
                        "fourypercentage",
                        !isNaN(percentage) ? percentage.toFixed(2) : ""
                      );
                    }
                    trigger("fourymarksoutof");
                    trigger("fourymarksobtained");
                    let cgpa = (obtainedMarks / maxValue) * 100;
                    if (watch("graduationmarktype") == 2) {
                      setValue(
                        "fourypercentage",
                        !isNaN(cgpa) ? cgpa.toFixed(2) : ""
                      );
                    }
                  }

                  trigger("fourymarksobtained");
                  trigger("fourymarksoutof");
                  trigger("fourypercentage");
                }}
              />
            </Tooltip>
          </Grid>
        )}

        {/* {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.G_OTHER_DEGREE_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_OTHER_DEGREE_NAME
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.G_OTHER_DEGREE_NAME
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Enter Graduation Other Degree Name"
              {...register("gotherdegreename", {
                required:
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.G_OTHER_DEGREE_NAME
                  )?.ismandatory === "1"
                    ? "Graduation other degree name is required"
                    : false,
              })}
              // onInput={AlphabetsValidation}
              error={!!errors.gotherdegreename}
              helperText={errors.gotherdegreename?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("gotherdegreename"),
              }}
              disabled={isFieldDisabled(
                masterFields,
                masterFieldLabel.G_OTHER_DEGREE_NAME,
                null,
                isPaid
              )}
            />
          </Grid>
        )} */}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.FOUR_Y_CALCULATED_PERCENTAGE
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.FOUR_Y_CALCULATED_PERCENTAGE
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.FOUR_Y_CALCULATED_PERCENTAGE
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.FOUR_Y_CALCULATED_PERCENTAGE
                    )?.ismandatory === "1" &&
                      !(
                        watch("graduationmarktype") != "" &&
                        getValues("graduationstatus") == 1
                      ) && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="Enter Graduation Other Degree Name"
                {...register("fourypercentage", {
                  required: !(
                    watch("graduationmarktype") != "" &&
                    getValues("graduationstatus") == 1
                  )
                    ? "FourY Calculated/Equivalent percentage is required"
                    : false,

                  // masterFields?.find(
                  //   (field) =>
                  //     field?.masterfieldlabel ===
                  //     masterFieldLabel.FOUR_Y_CALCULATED_PERCENTAGE
                  // )?.ismandatory === "1"
                  //   ? "Graduation other degree name is required"
                  //   : false,
                })}
                error={!!errors.fourypercentage}
                helperText={errors.fourypercentage?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}

                inputProps={{
                  readOnly: true, 
                  style: {
                    height: 56,
                    boxSizing: "border-box",
                    cursor: "default", 
                  },
                }}
                InputProps={{
                  style: {
                    pointerEvents: "none", 
                  },
                }}
                InputLabelProps={{
                  shrink: !!watch("fourypercentage"),
                }}
                // disabled={isFieldDisabled(
                //   masterFields,
                //   masterFieldLabel.FOUR_Y_CALCULATED_PERCENTAGE,
                //   1,
                //   isPaid
                // )}
                disabled={
                  watch("graduationmarktype") != "" &&
                  getValues("graduationstatus") == 1
                }
              />
            </Tooltip>
          </Grid>
        )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
                )?.fieldlabel
              }
              arrow
            >
              {/* <FormControl fullWidth error={!!errors.gpassingmonth}>
                <InputLabel
                  shrink={
                    !!watch("fourynoofattempts") ||
                    watch("fourynoofattempts") === 0
                  }
                  htmlFor="fourynoofattempts"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="fourynoofattempts"
                  label={
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
                        )?.fieldlabel
                      }
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    </span>
                  }
                  {...register("fourynoofattempts", {
                    required:
                    [1, 2].includes(watch("graduationmarktype")) && 
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
                      )?.ismandatory === "1"
                        ? "FourY No Of Attempts is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("fourynoofattempts", e.target.value);
                    trigger("fourynoofattempts");
                  }}
                  value={watch("fourynoofattempts") ?? ""}
                  // disabled={isFieldDisabled(
                  //   masterFields,
                  //   masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS,
                  //   watch("graduationstatus") != 2 ? 1 : 0,
                  //   isPaid
                  // )}
                  disabled={
                    ![1, 2].includes(watch("graduationmarktype"))
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>

                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                    1
                  </MenuItem>
                  <MenuItem value={2} key={2}>
                    2
                  </MenuItem>
                  <MenuItem value={3} key={3}>
                    3
                  </MenuItem>
                </Select>
                {errors.fourynoofattempts && (
                  <FormHelperText style={{ margin: 0,color:"#d32f2f" }} className="error">
                    {errors.fourynoofattempts.message}
                  </FormHelperText>
                )}
              </FormControl> */}
              <FormControl fullWidth error={!!errors.fourynoofattempts}>
                <InputLabel
                  shrink={
                    !!watch("fourynoofattempts") ||
                    watch("fourynoofattempts") === 0
                  }
                  htmlFor="fourynoofattempts"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </InputLabel>
                <TextField
                  select
                  fullWidth
                  id="fourynoofattempts"
                  label={
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
                        )?.fieldlabel
                      }
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    </span>
                  }
                  value={watch("fourynoofattempts") ?? ""}
                  onChange={(e) => {
                    clearErrors("fourynoofattempts");
                    setValue("fourynoofattempts", e.target.value);
                    trigger("fourynoofattempts");
                  }}
                  {...register("fourynoofattempts", {
                    required:
                      [1, 2].includes(watch("graduationmarktype")) &&
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
                      )?.ismandatory === "1"
                        ? "FourY No Of Attempts is required"
                        : false,
                  })}
                  disabled={![1, 2].includes(watch("graduationmarktype"))}
                  SelectProps={{
                    MenuProps: {
                      disableScrollLock: true,
                    },
                    onChange: (e) => {
                      clearErrors("fourynoofattempts");
                      setValue("fourynoofattempts", e.target.value);
                      trigger("fourynoofattempts");
                    },
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  variant="outlined"
                  error={!!errors.fourynoofattempts}
                  helperText={errors.fourynoofattempts?.message}
                  FormHelperTextProps={{
                    style: { margin: 0 },
                  }}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={0}>Not Applicable</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                </TextField>
                {/* {errors.fourynoofattempts && (
    <FormHelperText style={{ margin: 0, color: "#d32f2f" }} className="error">
      {errors.fourynoofattempts.message}
    </FormHelperText>
  )} */}
              </FormControl>
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.AGG_MARKS_OBTAINED
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.AGG_MARKS_OBTAINED
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.AGG_MARKS_OBTAINED
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.AGG_MARKS_OBTAINED
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                placeholder="Ty Marks Obtained"
                {...register("aggregatemarksobtained", {
                  // required:
                  // [1, 2].includes(watch("graduationmarktype")) &&
                  // getValues("graduationstatus") === 1 &&
                  //   masterFields?.find(
                  //     (field) =>
                  //       field?.masterfieldlabel ===
                  //       masterFieldLabel.AGG_MARKS_OBTAINED
                  //   )?.ismandatory !== "1"
                  //     ? false
                  //     : "Aggregate  Marks is required",
                  required:
                    ![1, 2].includes(watch("graduationmarktype")) ||
                    watch("graduationstatus") !== 2
                      ? false
                      : "Aggregate Mark is required",
                  validate: (value) => {
                    if (![1, 2].includes(watch("graduationmarktype")))
                      return true;

                    if (getValues("graduationstatus") !== 0) {
                      const outOfValue =
                        parseInt(getValues("aggregatemarksoutof")) || 900;

                      if (watch("graduationmarktype") === 2) {
                        if (value > 10) {
                          return "Marks obtained cannot be greater than 10";
                        }
                        return true;
                      }

                      // Check if decimal values are entered for percentage
                      if (
                        watch("graduationmarktype") === 1 &&
                        value % 1 !== 0
                      ) {
                        return "Decimal values are not allowed for percentage.";
                      }

                      // Check if the entered marks are valid against max marks
                      if (
                        parseInt(value) > (outOfValue <= 900 ? outOfValue : 900)
                      ) {
                        return "Marks obtained cannot be greater than marks out of or 900";
                      }
                    }
                  },
                })}
                onInput={(e) => {
                  const regex = /^[1-9][0-9]*\.?[0-9]*$/;
                  const value = e.target.value;

                  if (!regex.test(value)) {
                    e.target.value = value.slice(0, -1);
                  }
                }}
                onChange={(e) => {
                  const value = parseFloat(e.target.value) || 0;
                  setValue("aggregatemarksobtained", e.target.value);

                  if (watch("graduationmarktype") === 2) {
                    const cgpaError = cgpaValidation(value);
                    if (cgpaError) {
                      setError("aggregatemarksobtained", {
                        type: "manual",
                        message: cgpaError,
                      });
                    }
                  } else if (watch("graduationmarktype") === 1) {
                    percentageValidation();
                  }
                  const maxValue = parseInt(getValues("aggregatemarksoutof"));
                  const obtainedMarks = parseFloat(
                    getValues("aggregatemarksobtained")
                  );
                  let percentage = (obtainedMarks / maxValue) * 100;
                  if (watch("graduationmarktype") === 1) {
                    setValue(
                      "aggregatepercentage",
                      !isNaN(percentage) ? percentage.toFixed(2) : ""
                    );
                  }
                  trigger("aggregatemarksoutof");
                  trigger("aggregatemarksobtained");
                  let cgpa = (obtainedMarks / maxValue) * 100;
                  if (watch("graduationmarktype") === 2) {
                    setValue(
                      "aggregatepercentage",
                      !isNaN(cgpa) ? cgpa.toFixed(2) : ""
                    );
                  }
                  setTimeout(() => {
                    trigger(["aggregatemarksobtained", "aggregatemarksoutof"]);
                  }, 50);
                }}
                inputProps={{
                  min: 0,
                  max:
                    watch("graduationmarktype") === 2
                      ? 10
                      : parseInt(getValues("aggregatemarksoutof")) <= 900
                      ? parseInt(getValues("aggregatemarksoutof"))
                      : 900,

                  step: watch("graduationmarktype") === 1 ? 1 : "any",
                }}
                error={!!errors.aggregatemarksobtained}
                helperText={errors.aggregatemarksobtained?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("aggregatemarksobtained"),
                }}
                // disabled={isFieldDisabled(
                //   masterFields,
                //   masterFieldLabel.AGG_MARKS_OBTAINED,
                //   watch("graduationstatus") != 2 ? 1 : 0,
                //   isPaid
                // )}
                disabled={
                  watch("graduationmarktype") != "" &&
                  getValues("graduationstatus") == 1
                }
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.AGG_MARKS_OUT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.AGG_MARKS_OUT_OF
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.AGG_MARKS_OUT_OF
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.AGG_MARKS_OUT_OF
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                placeholder="TY Marks Out Of*"
                {...register("aggregatemarksoutof", {
                  //       required:
                  // [1, 2].includes(watch("graduationmarktype")) &&
                  // getValues("graduationstatus") === 1 &&
                  //         masterFields?.find(
                  //           (field) =>
                  //             field?.masterfieldlabel ===
                  //             masterFieldLabel.AGG_MARKS_OUT_OF
                  //         )?.ismandatory != "1"
                  //           ? false
                  //           : "SY Marks Out Of is required",

                  required:
                    watch("graduationstatus") == 2
                      ? "Aggregate Marks out of is required"
                      : false,
                  validate: (value) => {
                    const intValue = parseInt(value);

                    // Validation for CGPA
                    if (watch("graduationmarktype") === 2) {
                      if (intValue > 10) return "Marks Out Of cannot exceed 10";
                    }

                    // Check if value is a decimal when graduationmarktype is "percentage"
                    if (watch("graduationmarktype") === 1 && value % 1 !== 0) {
                      return "Decimal values are not allowed for percentage.";
                    }

                    if (intValue > 900) return "Marks Out Of cannot exceed 900";
                    return true;
                  },
                })}
                onInput={(e) => {
                  const regex = /^[1-9][0-9]*\.?[0-9]*$/;
                  const value = e.target.value;

                  if (!regex.test(value)) {
                    e.target.value = value.slice(0, -1);
                  }
                }}
                error={!!errors?.aggregatemarksoutof}
                helperText={errors.aggregatemarksoutof?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                inputProps={{
                  step: watch("graduationmarktype") === 1 ? 1 : "any",
                }}
                InputLabelProps={{
                  shrink: !!watch("aggregatemarksoutof"),
                }}
                // disabled={isFieldDisabled(
                //   masterFields,
                //   masterFieldLabel.AGG_MARKS_OUT_OF,
                //   watch("graduationstatus") != 2 ? 1 : 0,
                //   isPaid
                // )}
                disabled={
                  watch("graduationmarktype") != "" &&
                  getValues("graduationstatus") == 1
                }
                onChange={(e) => {
                  const maxValue = parseFloat(e.target.value);
                  const obtainedMarks = parseFloat(
                    getValues("aggregatemarksobtained")
                  );
                  clearErrors("fourypercentage");
                  setValue("aggregatemarksoutof", e.target.value);

                  if (maxValue < obtainedMarks) {
                    setError("aggregatemarksoutof", {
                      type: "manual",
                      message:
                        "Marks out of must be greater than or equal to marks obtained",
                    });
                  } else {
                    clearErrors("aggregatemarksoutof");

                    if (watch("graduationmarktype") === 2) {
                      const cgpaError = cgpaValidation(maxValue);
                      if (cgpaError) {
                        setError("symarksoutof", {
                          type: "manual",
                          message: cgpaError,
                        });
                      }
                    } else if (watch("graduationmarktype") === 1) {
                      percentageValidation();
                    }

                    let percentage = (obtainedMarks / maxValue) * 100;
                    if (watch("graduationmarktype") === 1) {
                      setValue(
                        "aggregatepercentage",
                        !isNaN(percentage) ? percentage.toFixed(2) : ""
                      );
                    }
                    trigger("aggregatemarksoutof");
                    trigger("aggregatemarksobtained");
                    let cgpa = (obtainedMarks / maxValue) * 100;
                    if (watch("graduationmarktype") === 2) {
                      setValue(
                        "aggregatepercentage",
                        !isNaN(cgpa) ? cgpa.toFixed(2) : ""
                      );
                    }
                  }

                  trigger("aggregatemarksobtained");
                  trigger("aggregatemarksoutof");
                  trigger("aggregatepercentage");
                }}
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.AGG_CALCULATED_PERCENTAGE
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.AGG_CALCULATED_PERCENTAGE
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.AGG_CALCULATED_PERCENTAGE
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.AGG_CALCULATED_PERCENTAGE
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                placeholder="Ty Calculated/Equivalent Percentage*"
                {...register("aggregatepercentage", {
                  required:
                    watch("graduationstatus") == 2
                      ? "Aggregate Calculated/Equivalent percentage is required"
                      : false,
                  // getValues("graduationstatus") != 1 ||
                  // masterFields?.find(
                  //   (field) =>
                  //     field?.masterfieldlabel ===
                  //     masterFieldLabel.AGG_CALCULATED_PERCENTAGE
                  // )?.ismandatory != "1"
                  //   ? false
                  //   : "Fy Calculated/Equivalent percentage is required",
                })}
                error={!!errors?.aggregatepercentage}
                helperText={errors?.aggregatepercentage?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                
                inputProps={{
                  readOnly: true, 
                  style: {
                    height: 56,
                    boxSizing: "border-box",
                    cursor: "default", 
                  },
                }}
                InputProps={{
                  style: {
                    pointerEvents: "none", 
                  },
                }}
                InputLabelProps={{
                  shrink: !!watch("aggregatepercentage"),
                }}
                // disabled={isFieldDisabled(
                //   masterFields,
                //   masterFieldLabel.AGG_CALCULATED_PERCENTAGE,
                //   1,
                //   isPaid
                // )}
                disabled={
                  watch("graduationmarktype") != "" &&
                  getValues("graduationstatus") == 1
                }
              />
              <Box>
                {/* <small style={{ color: "#666666" }}>
                  Up to 2 decimal places are allowed
                </small> */}
              </Box>
            </Tooltip>
          </Grid>
        )}

        {/* {masterFields?.find(
           (field) =>
            field.masterfieldlabel === masterFieldLabel.AGG_MARKS_OBTAINED
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.gspecialsubject}>
              <InputLabel
                // shrink={!!watch("gspecialsubject")}
                htmlFor="gspecialsubject"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.G_SPECIALISATION_OR_SUBJECT
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.G_SPECIALISATION_OR_SUBJECT
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="gspecialsubject"
                control={control}
                defaultValue={[]}
                rules={{ required: "Graduation Specialization is required" }}
                InputLabelProps={{
                  shrink: !!watch("gspecialsubject"),
                }}
                render={({ field }) => (
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    {...field}
                    id="gspecialsubject"
                    multiple
                    label={
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.G_SPECIALISATION_OR_SUBJECT
                          )?.fieldlabel
                        }{" "}
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.G_SPECIALISATION_OR_SUBJECT
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    // value={getValues("pgspecialsubject")}
                    {...register("gspecialsubject", {
                      required:
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.G_SPECIALISATION_OR_SUBJECT
                        )?.ismandatory === "1"
                          ? "Graduation Specialization is required"
                          : false,
                    })}
                    onChange={(e) => {
                      const selectedValues = e.target.value;
                      console.log(selectedValues, "selval");
                      const validValues = selectedValues.filter(
                        (val) => val !== null && val !== undefined
                      );
                      const uniqueValues = Array.from(
                        new Set(validValues.map((val) => val?.toString()))
                      );

                      // field.onChange(uniqueValues);
                      setValue("gspecialsubject", uniqueValues);
                      trigger("gspecialsubject");
                    }}
                    value={watch("gspecialsubject") ?? []}
                    disabled={isFieldDisabled(
                      masterFields,
                      masterFieldLabel.G_SPECIALISATION_OR_SUBJECT,
                      null,
                      isPaid
                    )}
                    inputProps={{
                      style: { height: 56, boxSizing: "border-box" },
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => {
                          const selectedState = months?.find(
                            (state) => state?.value == value
                          );
                          // console.log(selected,'selected')
                          // console.log(
                          //   selectedState?.state,
                          //   "languagename"
                          // );

                          return (
                            <Chip key={value} label={selectedState?.label} />
                          );
                        })}
                      </Box>
                    )}
                  >
                    {months.map((month) => (
                      <MenuItem key={month.value} value={month.value}>
                        {month.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.gspecialsubject && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.gspecialsubject.message}
                </small>
              )}
            </FormControl>
          </Grid>
        )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.G_MATHEMATICS_SUBJECT
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset" error={!!errors.gmathssubject}>
              <FormLabel component="legend">
                {
                  masterFields?.find(
                    (field) =>
                      field.masterfieldlabel ===
                      masterFieldLabel.G_MATHEMATICS_SUBJECT
                  )?.fieldlabel
                }
              </FormLabel>
              <Controller
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_MATHEMATICS_SUBJECT
                    )?.ismandatory === "1"
                      ? "Select Mathematics is required"
                      : false,
                }}
                control={control}
                name="gmathssubject"
                render={({ field }) => (
                  <RadioGroup {...field} row value={watch("gmathssubject") ?? ""}>
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              {errors.gmathssubject && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.gmathssubject.message}
                </small>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.AGG_MARKS_OUT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset" error={!!errors.gexampassbefore1975}>
              <FormLabel component="legend">
                {
                  masterFields?.find(
                    (field) =>
                      field.masterfieldlabel ===
                      masterFieldLabel.G_EXAM_PASS_BEFORE_1975
                  )?.fieldlabel
                }
              </FormLabel>
              <Controller
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_EXAM_PASS_BEFORE_1975
                    )?.ismandatory === "1"
                      ? "Select Exam passing before 1975 is required"
                      : false,
                }}
                control={control}
                name="gexampassbefore1975"
                render={({ field }) => (
                  <RadioGroup {...field} row value={watch("gexampassbefore1975") ?? ""}>
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              {errors.gexampassbefore1975 && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.gexampassbefore1975.message}{" "}
                </small>
              )}
            </FormControl>
          </Grid>
        )}



{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.G_ENG_MEDIUM_COLLEGE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset" error={!!errors.gengmediumcollege}>
              <FormLabel component="legend">
                {
                  masterFields?.find(
                    (field) =>
                      field.masterfieldlabel ===
                      masterFieldLabel.G_ENG_MEDIUM_COLLEGE
                  )?.fieldlabel
                }
              </FormLabel>
              <Controller
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_ENG_MEDIUM_COLLEGE
                    )?.ismandatory === "1"
                      ? "Select Graduation English Medium is required"
                      : false,
                }}
                control={control}
                name="gengmediumcollege"
                
                render={({ field }) => (
                  <RadioGroup {...field} row value={watch("gengmediumcollege") ?? ""}>
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              {errors.gengmediumcollege && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.gengmediumcollege.message}{" "}
                </small>
              )}
            </FormControl>
          </Grid>
        )}



        
{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.G_ENG_LANG_CONTENT_TEST
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset" error={!!errors.genglangcontenttest}>
              <FormLabel component="legend">
                {
                  masterFields?.find(
                    (field) =>
                      field.masterfieldlabel ===
                      masterFieldLabel.G_ENG_LANG_CONTENT_TEST
                  )?.fieldlabel
                }
              </FormLabel>
              <Controller
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_ENG_LANG_CONTENT_TEST
                    )?.ismandatory === "1"
                      ? "Select Graduation English Contest is required"
                      : false,
                }}
                control={control}
                name="genglangcontenttest"
                render={({ field }) => (
                  <RadioGroup {...field} row value={watch("genglangcontenttest") ?? ""}>
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              {errors.genglangcontenttest && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.genglangcontenttest.message}{" "}
                </small>
              )}
            </FormControl>
          </Grid>
        )}



        
{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.G_OPT_SPECIAL_ED_COLLEGE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset" error={!!errors.goptspecialedcollege}>
              <FormLabel component="legend">
                {
                  masterFields?.find(
                    (field) =>
                      field.masterfieldlabel ===
                      masterFieldLabel.G_OPT_SPECIAL_ED_COLLEGE
                  )?.fieldlabel
                }
              </FormLabel>
              <Controller
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_OPT_SPECIAL_ED_COLLEGE
                    )?.ismandatory === "1"
                      ? "Select Exam passing before 1975 is required"
                      : false,
                }}
                control={control}
                name="goptspecialedcollege"
                render={({ field }) => (
                  <RadioGroup {...field} row value={watch("goptspecialedcollege") ?? ""}>
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
              {errors.goptspecialedcollege && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.goptspecialedcollege.message}{" "}
                </small>
              )}
            </FormControl>
          </Grid>
        )}

        


        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.CANDIDATE_NAME_ON_HSC
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.gspecialeducationmethod}>
              <InputLabel
                // shrink={!!watch("gspecialeducationmethod")}
                htmlFor="gspecialeducationmethod"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.G_SPECIAL_ED_METHOD_SUBJECTS
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.G_SPECIAL_ED_METHOD_SUBJECTS
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="gspecialeducationmethod"
                control={control}
                defaultValue={[]}
                rules={{ required: "Graduation Specialization is required" }}
                InputLabelProps={{
                  shrink: !!watch("gspecialeducationmethod"),
                }}
                render={({ field }) => (
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    {...field}
                    id="gspecialeducationmethod"
                    multiple
                    label={
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.G_SPECIAL_ED_METHOD_SUBJECTS
                          )?.fieldlabel
                        }{" "}
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.G_SPECIAL_ED_METHOD_SUBJECTS
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    // value={getValues("pgspecialeducationmethod")}
                    {...register("gspecialeducationmethod", {
                      required:
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.G_SPECIAL_ED_METHOD_SUBJECTS
                        )?.ismandatory === "1"
                          ? "Graduation Specialization is required"
                          : false,
                    })}
                    onChange={(e) => {
                      const selectedValues = e.target.value;
                      console.log(selectedValues, "selval");
                      const validValues = selectedValues.filter(
                        (val) => val !== null && val !== undefined
                      );
                      const uniqueValues = Array.from(
                        new Set(validValues.map((val) => val?.toString()))
                      );

                      // field.onChange(uniqueValues);
                      setValue("gspecialeducationmethod", uniqueValues);
                      trigger("gspecialeducationmethod");
                    }}
                    value={watch("gspecialeducationmethod") ?? []}
                    disabled={isFieldDisabled(
                      masterFields,
                      masterFieldLabel.G_SPECIAL_ED_METHOD_SUBJECTS,
                      null,
                      isPaid
                    )}
                    inputProps={{
                      style: { height: 56, boxSizing: "border-box" },
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => {
                          const selectedState = months?.find(
                            (state) => state?.value == value
                          );
                          // console.log(selected,'selected')
                          // console.log(
                          //   selectedState?.state,
                          //   "languagename"
                          // );

                          return (
                            <Chip key={value} label={selectedState?.label} />
                          );
                        })}
                      </Box>
                    )}
                  >
                    {months.map((month) => (
                      <MenuItem key={month.value} value={month.value}>
                        {month.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.gspecialeducationmethod && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.gspecialeducationmethod.message}
                </small>
              )}
            </FormControl>
          </Grid>
        )}
 */}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.POST_GRADUATION_STATUS
        ) && (
          <Grid item xs={12} md={12}>
            <Typography
              pt={1}
              sx={{
                fontSize: "24px",
                color: `${cssProperties?.color?.tertiary}`,
                fontWeight: 500,
                lineHeight: "30px",
              }}
            >
              {" "}
              POST GRADUATION INFORMATION
            </Typography>
          </Grid>
        )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.POST_GRADUATION_STATUS
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
              error={!!errors.pgstatus}
            >
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.POST_GRADUATION_STATUS
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("pgstatus") || watch("pgstatus") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="pgstatus"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.POST_GRADUATION_STATUS
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.POST_GRADUATION_STATUS
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("pgstatus", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.POST_GRADUATION_STATUS
                      )?.ismandatory === "1"
                        ? "Post Graduation Status is required"
                        : false,
                  })}
                  value={watch("pgstatus") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.POST_GRADUATION_STATUS,
                    null,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("pgstatus", e.target.value);
                      trigger("pgstatus");
                      setValue("pgpassingyear", "");
                      setValue("pgbranchid", "");
                      setValue("pgotherdegreename", "");
                      setValue("pgmarktype", "");
                      setValue("pgmarksobtained", "");
                      setValue("pgmarksoutof", "");
                      setValue("pgpercentage", "");
                      setValue("pgawardedclassid", "");
                      setValue("pgduration", "");
                      setValue("pgcollegestateid", "");
                      setValue("pguniversityname", "");
                      setValue("pgotheruniversityname", "");
                      setValue("pgpassingdate", "");
                      setValue("pgpassedfromindia", "");
                      setValue("pgpinterncompleteddate", "");
                      setValue("isgraduationfromclg", "");
                      if (getValues("pgstatus") == 1) {
                        clearErrors("pgstatus");
                        clearErrors("pgpassingyear");
                        clearErrors("pgbranchid");
                        clearErrors("pgotherdegreename");
                        clearErrors("pgmarktype");
                        clearErrors("pgmarksobtained");
                        clearErrors("pgmarksoutof");
                        clearErrors("pgpercentage");
                        clearErrors("pgawardedclassid");
                        clearErrors("pgduration");
                        clearErrors("pgcollegestateid");
                        clearErrors("pguniversityname");
                        clearErrors("pgotheruniversityname");
                        clearErrors("pgpassingdate");
                        clearErrors("pgpassedfromindia");
                        clearErrors("pgpinterncompleteddate");
                        clearErrors("isgraduationfromclg");
                      }
                    },
                  }}
                  error={!!errors.pgstatus}
                  helperText={errors.pgstatus ? errors.pgstatus.message : ""}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                    Appearing
                  </MenuItem>
                  <MenuItem value={2} key={2}>
                    Passed
                  </MenuItem>
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.POST_GRADUATION_PASSING_YEAR
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.pgpassingyear}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.POST_GRADUATION_PASSING_YEAR
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("pgpassingyear"),
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="pgpassingyear"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.POST_GRADUATION_PASSING_YEAR
                      )?.ismandatory === "1" &&
                        watch("pgstatus") !== 1 && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.POST_GRADUATION_PASSING_YEAR
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("pgpassingyear", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.POST_GRADUATION_PASSING_YEAR
                      )?.ismandatory === "1" && watch("pgstatus") !== 1
                        ? "Post Graduation Passing Year is required"
                        : false,
                  })}
                  value={watch("pgpassingyear") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.POST_GRADUATION_PASSING_YEAR,
                    watch("pgstatus") != 2 ? 1 : 0,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("pgpassingyear", e.target.value);
                      trigger("pgpassingyear");
                    },
                  }}
                  error={!!errors.pgpassingyear}
                  helperText={
                    errors.pgpassingyear ? errors.pgpassingyear.message : ""
                  }
                  FormHelperTextProps={{
                    style: { margin: 0 },
                  }}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {watch("graduationpassingyear") &&
                    Array.from(
                      {
                        length:
                          new Date().getFullYear() -
                          (parseInt(watch("graduationpassingyear")) + 1),
                      },
                      (_, i) => parseInt(watch("graduationpassingyear")) + 1 + i
                    )?.map((year, index) => (
                      <MenuItem key={index} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.POST_GRADUATION_BRANCH_DEGREE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.pgbranchid}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.POST_GRADUATION_BRANCH_DEGREE
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("pgbranchid"),
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="pgbranchid"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.POST_GRADUATION_BRANCH_DEGREE
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.POST_GRADUATION_BRANCH_DEGREE
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("pgbranchid", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.POST_GRADUATION_BRANCH_DEGREE
                      )?.ismandatory === "1"
                        ? "Post Graduation Branch Name/Degree is required"
                        : false,
                  })}
                  value={watch("pgbranchid") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.POST_GRADUATION_BRANCH_DEGREE,
                    watch("pgstatus") != 2 ? 0 : 0,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("pgbranchid", e.target.value);
                      trigger("pgbranchid");
                    },
                  }}
                  error={!!errors.pgbranchid}
                  helperText={
                    errors.pgbranchid ? errors.pgbranchid.message : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {graduationBranchName?.map((val, i) => (
                    <MenuItem key={i} value={val?.graduationbranchid}>
                      {val?.graduationbranch}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.OTHER_DEGREE_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.OTHER_DEGREE_NAME
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.OTHER_DEGREE_NAME
                    )?.ismandatory === "1" &&
                      watch("pgstatus") !== 1 && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.OTHER_DEGREE_NAME
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="Enter Post Graduation Other Degree Name"
                {...register("pgotherdegreename", {
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.OTHER_DEGREE_NAME
                    )?.ismandatory == "1" && watch("pgstatus") !== 1
                      ? "\u00A0 \u00A0  Post Graduation Other Degree Name is required"
                      : false,
                })}
                error={!!errors.pgotherdegreename}
                helperText={errors.pgotherdegreename?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("pgotherdegreename"),
                }}
                disabled={isFieldDisabled(
                  masterFields,
                  masterFieldLabel.OTHER_DEGREE_NAME,
                  watch("pgstatus") != 2 ? 1 : 0,
                  isPaid
                )}
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.POST_GRADUATION_SCORE_TYPE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
              }}
              error={!!errors.pgmarktype}
            >
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.POST_GRADUATION_SCORE_TYPE
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("pgmarktype") || watch("pgmarktype") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  id="pgmarktype"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.POST_GRADUATION_SCORE_TYPE
                      )?.ismandatory === "1" &&
                        watch("pgstatus") !== 1 && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.POST_GRADUATION_SCORE_TYPE
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("pgmarktype", {
                    required: !isFieldDisabled(
                      masterFields,
                      masterFieldLabel.POST_GRADUATION_SCORE_TYPE,
                      watch("pgstatus") != 2 ? 1 : 0,
                      isPaid
                    )
                      ? getValues("pgstatus") == 1
                        ? false
                        : "Post Graduation Marks Type is required"
                      : false,
                  })}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("pgpercentage", "");
                      setValue("pgmarktype", e.target.value);
                      trigger("pgmarktype");
                      const value = parseInt(getValues("pgmarksobtained"));
                      const maxValue = parseInt(getValues("pgmarksoutof"));
                      setValue("pgpercentage", "");
                      setValue("pgmarksoutof", "");
                      setValue("pgmarksobtained", "");
                      clearErrors("pgmarksobtained");
                      clearErrors("pgmarksoutof");
                      if (getValues("pgmarktype") !== 2) {
                        clearErrors("pgpercentage");
                      }
                    },
                  }}
                  error={!!errors.pgmarktype}
                  helperText={
                    errors.pgmarktype ? errors.pgmarktype.message : ""
                  }
                  value={watch("pgmarktype") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.POST_GRADUATION_SCORE_TYPE,
                    watch("pgstatus") != 2 ? 1 : 0,
                    isPaid
                  )}
                >
                  <MenuItem value={1} key={1}>
                    Percentage
                  </MenuItem>
                  <MenuItem value={2} key={2}>
                    CGPA
                  </MenuItem>
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.POST_GRADUATION_MARKS_OBTAINED
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.POST_GRADUATION_MARKS_OBTAINED
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <Typography>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.POST_GRADUATION_MARKS_OBTAINED
                    )?.ismandatory === "1" &&
                      watch("pgstatus") !== 1 && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.POST_GRADUATION_MARKS_OBTAINED
                      )?.fieldlabel
                    }
                  </Typography>
                }
                placeholder="Post Graduation Marks/CGPA Obtained"
                {...register("pgmarksobtained", {
                  required:
                    watch("pgstatus") !== 1
                      ? "Post Graduation Marks Obtained is required"
                      : false,
                  validate: (value) => {
                    return watch("pgmarktype") === 2
                      ? cgpaValidation(value)
                      : markValidation(value);
                  },
                })}
                onChange={(e) => {
                  const value = parseFloat(e.target.value) || 0;
                  setValue("pgmarksobtained", e.target.value);

                  if (watch("pgmarktype") === 2) {
                    const cgpaError = cgpaValidation(value);
                    if (cgpaError) {
                      setError("pgmarksobtained", {
                        type: "manual",
                        message: cgpaError,
                      });
                    }
                  } else if (watch("pgmarktype") === 1) {
                    percentageValidation();
                  }
                  const maxValue = parseInt(getValues("pgmarksoutof"));
                  const obtainedMarks = parseFloat(
                    getValues("pgmarksobtained")
                  );
                  let percentage = (obtainedMarks / maxValue) * 100;
                  if (watch("pgmarktype") === 1) {
                    setValue(
                      "pgpercentage",
                      !isNaN(percentage) ? percentage.toFixed(2) : ""
                    );
                  }
                  trigger("pgmarksoutof");
                  trigger("pgmarksobtained");
                  let cgpa = (obtainedMarks / maxValue) * 100;
                  if (watch("pgmarktype") === 2) {
                    setValue(
                      "pgpercentage",
                      !isNaN(cgpa) ? cgpa.toFixed(2) : ""
                    );
                  }
                  setTimeout(() => {
                    trigger(["pgmarksobtained", "pgmarksoutof"]);
                  }, 50);
                }}
                value={watch("pgmarksobtained") ?? ""}
                InputProps={{ inputMode: "decimal" }}
                error={!!errors.pgmarksobtained}
                helperText={errors.pgmarksobtained?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("pgmarksobtained"),
                }}
                disabled={isFieldDisabled(
                  masterFields,
                  masterFieldLabel.POST_GRADUATION_MARKS_OBTAINED,
                  watch("pgstatus") != 2 ? 1 : 0,
                  isPaid
                )}
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.POST_GRADUATION_MAX_MARKS
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.POST_GRADUATION_MAX_MARKS
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.POST_GRADUATION_MAX_MARKS
                    )?.ismandatory === "1" &&
                      watch("pgstatus") !== 1 && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.POST_GRADUATION_MAX_MARKS
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="Graduation Marks Out Of*"
                {...register("pgmarksoutof", {
                  required:
                    watch("pgstatus") !== 1 &&
                    "Post Graduation Marks Out Of is required",
                  validate: (value) => {
                    const obtainedMarks = parseFloat(
                      getValues("pgmarksobtained"),
                      10
                    );
                    if (value && obtainedMarks > parseFloat(value, 10)) {
                      return "Marks out of must be greater than or equal to marks obtained";
                    }
                    return watch("pgmarktype") === 2
                      ? cgpaValidation(value)
                      : outOfMarkValidation(value);
                  },
                })}
                onChange={(e) => {
                  const maxValue = parseFloat(e.target.value);
                  const obtainedMarks = parseFloat(
                    getValues("pgmarksobtained")
                  );
                  clearErrors("pgpercentage");
                  setValue("pgmarksoutof", e.target.value);

                  // Prevent invalid input (when maxValue is less than obtainedMarks)
                  if (maxValue < obtainedMarks) {
                    setError("pgmarksoutof", {
                      type: "manual",
                      message:
                        "Marks out of must be greater than or equal to marks obtained",
                    });
                  } else {
                    clearErrors("pgmarksoutof");

                    // Handle percentage or CGPA based on the mark type
                    if (watch("pgmarktype") === 2) {
                      const cgpaError = cgpaValidation(maxValue);
                      if (cgpaError) {
                        setError("pgmarksoutof", {
                          type: "manual",
                          message: cgpaError,
                        });
                      }
                    } else if (watch("pgmarktype") === 1) {
                      percentageValidation();
                    }

                    // Calculate percentage or CGPA based on the entered values
                    let percentage = (obtainedMarks / maxValue) * 100;
                    if (watch("pgmarktype") === 1) {
                      setValue(
                        "pgpercentage",
                        !isNaN(percentage) ? percentage.toFixed(2) : ""
                      );
                    }
                    trigger("pgmarksoutof");
                    trigger("pgmarksobtained");
                    let cgpa = (obtainedMarks / maxValue) * 100;
                    if (watch("pgmarktype") === 2) {
                      setValue(
                        "pgpercentage",
                        !isNaN(cgpa) ? cgpa.toFixed(2) : ""
                      );
                    }
                  }

                  trigger("pgmarksobtained");
                  trigger("pgmarksoutof");
                  trigger("pgpercentage");
                }}
                error={!!errors?.pgmarksoutof}
                helperText={errors.pgmarksoutof?.message}
                FormHelperTextProps={{ style: { margin: 0 } }}
                InputLabelProps={{ shrink: !!watch("pgmarksoutof") }}
                disabled={isFieldDisabled(
                  masterFields,
                  masterFieldLabel.POST_GRADUATION_MAX_MARKS,
                  watch("pgstatus") != 2 ? 1 : 0,
                  isPaid
                )}
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.POST_GRADUATION_PERCENTAGE
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.POST_GRADUATION_PERCENTAGE
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{
                  style: { height: 56, boxSizing: "border-box" },
                  inputMode: "numeric", // Helps mobile keyboards show numeric keypad
                }}
                type="text"
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.POST_GRADUATION_PERCENTAGE
                    )?.ismandatory === "1" &&
                      watch("pgstatus") !== 1 && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.POST_GRADUATION_PERCENTAGE
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="Graduation Marks Percentage"
                {...register("pgpercentage", {
                  required:
                    watch("pgstatus") !== 1 &&
                    "Post Graduation percentage % is required",
                  validate: percentageValidation,
                })}
                onInput={percentageNumberValidation}
                // value={}
                error={!!errors?.pgpercentage}
                helperText={errors.pgpercentage?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("pgpercentage"),
                }}
                disabled={
                //   isFieldDisabled(
                //   masterFields,
                //   masterFieldLabel.POST_GRADUATION_PERCENTAGE,
                //   watch("pgstatus") != 2 ? 1 : 0,
                //   isPaid
                // )
                true
              }
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.POST_GRADUATION_AWARDED_CLASS_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.pgawardedclassid}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.AWARDED_CLASS_NAME
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("pgawardedclassid"),
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="pgawardedclassid"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.POST_GRADUATION_AWARDED_CLASS_NAME
                      )?.ismandatory === "1" &&
                        watch("pgstatus") !== 1 && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.POST_GRADUATION_AWARDED_CLASS_NAME
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("pgawardedclassid", {
                    required:
                      watch("pgstatus") !== 1 &&
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.POST_GRADUATION_AWARDED_CLASS_NAME
                      )?.ismandatory === "1"
                        ? "Post Graduation Awarded Class Name is required"
                        : false,
                  })}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("pgawardedclassid", e.target.value);
                      trigger("pgawardedclassid");
                    },
                  }}
                  error={!!errors.pgawardedclassid}
                  helperText={
                    errors.pgawardedclassid
                      ? errors.pgawardedclassid.message
                      : ""
                  }
                  value={watch("pgawardedclassid") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.AWARDED_CLASS_NAME,
                    watch("pgstatus") != 2 ? 1 : 0,
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {awardedClass?.map((val, i) => (
                    <MenuItem key={i} value={val?.awardedclassid}>
                      {val?.awardedclass}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.POST_GRADUATION_DURATION
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.pgduration}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.POST_GRADUATION_DURATION
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("pgduration") || watch("pgduration") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="pgduration"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.POST_GRADUATION_DURATION
                      )?.ismandatory === "1" &&
                        getValues("pggraduationstatus") == 2 && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.POST_GRADUATION_DURATION
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("pgduration", {
                    required:
                      !isFieldDisabled(
                        masterFields,
                        masterFieldLabel.POST_GRADUATION_DURATION,
                        watch("pgstatus") != 2 ? 1 : 0
                      ) &&
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.POST_GRADUATION_DURATION
                      )?.ismandatory === "1"
                        ? "Post Graduation Duration is required"
                        : false,
                  })}
                  value={watch("pgduration") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.POST_GRADUATION_DURATION,
                    watch("pgstatus") != 2 ? 1 : 0,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("pgduration", e.target.value);
                      trigger("pgduration");
                    },
                  }}
                  error={!!errors.pgduration}
                  helperText={
                    errors.pgduration ? errors.pgduration.message : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  {Array.from({ length: 8 }, (_, i) => i + 3).map((value) => (
                    <MenuItem value={value} key={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.UNIVERSITY_STATE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.pgcollegestateid}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.UNIVERSITY_STATE
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("pgcollegestateid"),
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="pgcollegestateid"
                  label={
                    <span>
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.UNIVERSITY_STATE
                        )?.ismandatory === "1" &&
                          watch("pgstatus") !== 1 && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.UNIVERSITY_STATE
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("pgcollegestateid", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.UNIVERSITY_STATE
                      )?.ismandatory === "1" && watch("pgstatus") !== 1
                        ? "Post Graduation College State is required"
                        : false,
                  })}
                  value={watch("pgcollegestateid") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.UNIVERSITY_STATE,
                    watch("pgstatus") != 2 ? 1 : 0,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      const stateId = e.target.value;
                      setValue("pgcollegestateid", e.target.value);
                      dispatch({
                        type: masterActions.GET_HSC_SCHOOL_DISTRICT,
                        payload: stateId,
                      });

                      trigger("pgcollegestateid");
                    },
                  }}
                  error={!!errors.pgcollegestateid}
                  helperText={
                    errors.pgcollegestateid
                      ? errors.pgcollegestateid.message
                      : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {state?.map((val, i) => (
                    <MenuItem value={val?.stateid} key={i}>
                      {val?.state}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.UNIVERSITY_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.UNIVERSITY_NAME
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.UNIVERSITY_NAME
                    )?.ismandatory === "1" &&
                      watch("pgstatus") !== 1 && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.UNIVERSITY_NAME
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="Enter post graduation university name"
                {...register("pguniversityname", {
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.UNIVERSITY_NAME
                    )?.ismandatory == "1" && watch("pgstatus") !== 1
                      ? "\u00A0 \u00A0 Post Graduation University Name is required"
                      : false,
                })}
                error={!!errors.pguniversityname}
                helperText={errors.pguniversityname?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("pguniversityname"),
                }}
                disabled={isFieldDisabled(
                  masterFields,
                  masterFieldLabel.UNIVERSITY_NAME,
                  watch("pgstatus") != 2 ? 1 : 0,
                  isPaid
                )}
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.OTHER_UNIVERSITY_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.OTHER_UNIVERSITY_NAME
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.OTHER_UNIVERSITY_NAME
                    )?.ismandatory === "1" &&
                      watch("pgstatus") !== 1 && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.OTHER_UNIVERSITY_NAME
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="Enter Post Graduation University Name"
                {...register("pgotheruniversityname", {
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.OTHER_UNIVERSITY_NAME
                    )?.ismandatory == "1" && watch("pgstatus") !== 1
                      ? "\u00A0 \u00A0 Post Graduation University Name is required"
                      : false,
                })}
                error={!!errors.pgotheruniversityname}
                helperText={errors.pgotheruniversityname?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("pgotheruniversityname"),
                }}
                disabled={isFieldDisabled(
                  masterFields,
                  masterFieldLabel.OTHER_UNIVERSITY_NAME,
                  watch("pgstatus") != 2 ? 1 : 0,
                  isPaid
                )}
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.COUNCIL_REG_APP_NO ||
            field.masterfieldlabel ===
              masterFieldLabel.PGP_EDUCATION_PREFERENCE_1
        ) && (
          <Grid item xs={12} md={12}>
            <Typography
              pt={1}
              sx={{
                fontSize: "24px",
                color: `${cssProperties?.color?.tertiary}`,
                fontWeight: 500,
                lineHeight: "30px",
              }}
            >
              {" "}
              PGP INFORMATION{" "}
            </Typography>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.COUNCIL_REG_APP_NO
        ) && (
          <Grid item xs={12} md={6}>
            <Tooltip
              title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.COUNCIL_REG_APP_NO
                )?.fieldlabel
              }
              arrow
            >
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                  maxLength: 50,
                }}
                label={
                  <span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.COUNCIL_REG_APP_NO
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.COUNCIL_REG_APP_NO
                      )?.fieldlabel
                    }
                  </span>
                }
                placeholder="Enter the Council Reg App No"
                {...register("councilregappno", {
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.COUNCIL_REG_APP_NO
                    )?.ismandatory === "1"
                      ? "Council Reg App No is required"
                      : false,
                  // validate: NumbersValidation,
                })}
                onChange={(e) => {
                  setValue("councilregappno", e.target.value);
                  trigger("councilregappno");
                }}
                onInput={NumbersValidation}
                error={!!errors.councilregappno}
                helperText={errors.councilregappno?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("councilregappno")?.trim(),
                }}
                disabled={isFieldDisabled(
                  masterFields,
                  masterFieldLabel.COUNCIL_REG_APP_NO,
                  null,
                  isPaid
                )}
                // disabled={isFieldDisabled(masterFields, masterFieldLabel.COMMUNICATION_PINCODE) || disabled}
              />
            </Tooltip>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.APPLICATION_DATE
        ) && (
          <Tooltip
            title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel === masterFieldLabel.APPLICATION_DATE
              )?.fieldlabel
            }
            arrow
          >
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="applicationdate"
                  control={control}
                  rules={{
                    required:
                      getValues("applicationdate") !== 1 ||
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.APPLICATION_DATE
                      )?.ismandatory !== "1"
                        ? false
                        : "PF graduation date is required",
                  }}
                  render={({ field }) => (
                    <DatePicker
                      disableFuture
                      format="DD/MM/YYYY"
                      views={["year", "month", "day"]}
                     
                      inputProps={{
                        style: { height: 56, boxSizing: "border-box" },
                        readOnly: true,
                      }}
                      {...field}
                      label={
                        <span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.APPLICATION_DATE
                          )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.APPLICATION_DATE
                            )?.fieldlabel
                          }
                        </span>
                      }
                      disabled={
                        isFieldDisabled(
                          masterFields,
                          masterFieldLabel.APPLICATION_DATE,
                          null,
                          isPaid
                        ) || getValues("graduationstatus") != 2
                      }
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(date) => {
                        if (date) {
                          const formattedDate = dayjs(date)
                            .hour(18)
                            .minute(30)
                            .second(0)
                            .millisecond(0)
                            .toISOString();
                          field.onChange(formattedDate);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.applicationdate,
                          helperText: errors.applicationdate?.message,
                          FormHelperTextProps: {
                            style: { margin: 0 },
                          },
                          style: {
                            width: "100%",
                            borderRadius: "8px",
                          },
                          inputProps: {
                            readOnly: true,
                          },
                        },
                        layout: {
                          sx: {
                            borderRadius: "58px",
                          },
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Tooltip>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.PGP_EDUCATION_PREFERENCE_1
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.pgpedupreference1}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.PGP_EDUCATION_PREFERENCE_1
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink:
                      !!watch("pgpedupreference1") ||
                      watch("pgpedupreference1") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                    maxLength: 50,
                  }}
                  id="pgpedupreference1"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.PGP_EDUCATION_PREFERENCE_1
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.PGP_EDUCATION_PREFERENCE_1
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  // value={getValues("pgpedupreference1")}
                  {...register("pgpedupreference1", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.PGP_EDUCATION_PREFERENCE_1
                      )?.ismandatory === "1"
                        ? "PGP Education Preference 1 is required"
                        : false,
                  })}
                  value={watch("pgpedupreference1") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.PGP_EDUCATION_PREFERENCE_1,
                    null,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      const pgppreferenceid = e.target.value;
                      setValue("pgpedupreference1", pgppreferenceid);
                      const preferences = [
                        "pgpedupreference2",
                        "pgpedupreference3",
                      ];
                      preferences.forEach((pref) => {
                        if (
                          parseInt(e.target.value) === parseInt(watch(pref))
                        ) {
                          setValue(pref, "");
                        }
                      });
                      trigger("pgpedupreference1");
                    },
                  }}
                  error={!!errors.pgpedupreference1}
                  helperText={
                    errors.pgpedupreference1
                      ? errors.pgpedupreference1.message
                      : ""
                  }
                >
                  {/* <MenuItem value={0}>Not Applicable</MenuItem> */}
                  {PGPPreference?.map(({ value, label }, i) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={1}>Engineering</MenuItem>
                    <MenuItem value={2}>Pharmacy</MenuItem>
                    <MenuItem value={3}>Agriculture</MenuItem>
                     */}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.PGP_EDUCATION_PREFERENCE_2
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.pgpedupreference2}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.PGP_EDUCATION_PREFERENCE_2
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink:
                      !!watch("pgpedupreference2") ||
                      watch("pgpedupreference2") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                    maxLength: 50,
                  }}
                  id="pgpedupreference2"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.PGP_EDUCATION_PREFERENCE_2
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.PGP_EDUCATION_PREFERENCE_2
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  // value={getValues("pgpedupreference2")}
                  {...register("pgpedupreference2", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.PGP_EDUCATION_PREFERENCE_2
                      )?.ismandatory === "1"
                        ? "PGP Education Preference 1 is required"
                        : false,
                  })}
                  value={watch("pgpedupreference2") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.PGP_EDUCATION_PREFERENCE_2,
                    null,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      const pgppreferenceid = e.target.value;
                      setValue("pgpedupreference2", pgppreferenceid);
                      const preferences = [
                        "pgpedupreference1",
                        "pgpedupreference3",
                      ];
                      preferences.forEach((pref) => {
                        if (
                          parseInt(e.target.value) === parseInt(watch(pref))
                        ) {
                          setValue(pref, "");
                        }
                      });
                      trigger("pgpedupreference2");
                    },
                  }}
                  error={!!errors.pgpedupreference2}
                  helperText={
                    errors.pgpedupreference2
                      ? errors.pgpedupreference2.message
                      : ""
                  }
                >
                  {/* <MenuItem value={0}>Not Applicable</MenuItem> */}
                  {PGPPreference?.filter(
                    ({ value, label }) =>
                      parseInt(value) !== parseInt(watch("pgpedupreference1"))
                  ).map(({ value, label }, i) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={1}>Engineering</MenuItem>
                    <MenuItem value={2}>Pharmacy</MenuItem>
                    <MenuItem value={3}>Agriculture</MenuItem> */}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.PGP_EDUCATION_PREFERENCE_3
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.pgpedupreference3}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.PGP_EDUCATION_PREFERENCE_3
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink:
                      !!watch("pgpedupreference3") ||
                      watch("pgpedupreference3") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                    maxLength: 50,
                  }}
                  id="pgpedupreference3"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.PGP_EDUCATION_PREFERENCE_3
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.PGP_EDUCATION_PREFERENCE_3
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  // value={getValues("pgpedupreference3")}
                  {...register("pgpedupreference3", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.PGP_EDUCATION_PREFERENCE_3
                      )?.ismandatory === "1"
                        ? "PGP Education Preference 1 is required"
                        : false,
                  })}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      const pgppreferenceid = e.target.value;
                      setValue("pgpedupreference3", pgppreferenceid);
                      const preferences = [
                        "pgpedupreference1",
                        "pgpedupreference2",
                      ];
                      preferences.forEach((pref) => {
                        if (
                          parseInt(e.target.value) === parseInt(watch(pref))
                        ) {
                          setValue(pref, "");
                        }
                      });
                      trigger("pgpedupreference3");
                    },
                  }}
                  error={!!errors.pgpedupreference3}
                  helperText={
                    errors.pgpedupreference3
                      ? errors.pgpedupreference3.message
                      : ""
                  }
                  value={watch("pgpedupreference3") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.PGP_EDUCATION_PREFERENCE_3,
                    null,
                    isPaid
                  )}
                >
                  {/* <MenuItem value={0}>Not Applicable</MenuItem> */}

                  {PGPPreference?.filter(
                    ({ value, label }) =>
                      parseInt(value) !==
                        parseInt(watch("pgpedupreference1")) &&
                      parseInt(value) !== parseInt(watch("pgpedupreference2"))
                  ).map(({ value, label }, i) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={1}>Engineering</MenuItem>
                    <MenuItem value={2}>Pharmacy</MenuItem>
                    <MenuItem value={3}>Agriculture</MenuItem> */}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.EXAMINATION
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.pgpexaminationid}>
              <Tooltip
                title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.EXAMINATION
                  )?.fieldlabel
                }
                arrow
              >
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("pgpexaminationid"),
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: 56, boxSizing: "border-box" },
                  }}
                  id="pgpexaminationid"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.EXAMINATION
                      )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.EXAMINATION
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("pgpexaminationid", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_SCHOOL_STATE
                      )?.ismandatory === "1"
                        ? "SSC School State is required"
                        : false,
                  })}
                  value={watch("pgpexaminationid") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.SSC_SCHOOL_STATE,
                    null,
                    isPaid
                  )}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      const stateId = e.target.value;
                      setValue("pgpexaminationid", e.target.value);
                      trigger("pgpexaminationid");
                    },
                  }}
                  error={!!errors.pgpexaminationid}
                  helperText={
                    errors.pgpexaminationid
                      ? errors.pgpexaminationid.message
                      : ""
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {cetExamName?.map((val, i) => (
                    <MenuItem value={val?.cetexamid} key={i}>
                      {val?.cetexamname}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </FormControl>
          </Grid>
        )}

        {courseFormDetails[0]?.qualificationnotes &&
          courseFormDetails[0]?.qualificationnotes !== "null" && (
            <>
              <Grid item md={12}>
                <Grid
                  sx={{
                    backgroundColor: "#fff",
                    border: "1px solid #000",
                    fontSize: "16px",
                    color: "#000",
                    lineHeight: "24px",
                    borderRadius: "5px",
                    whiteSpace: "pre-line",
                  }}
                  component="p"
                  p={1}
                >
                  {courseFormDetails[0]?.qualificationnotes}
                </Grid>
                <Typography
                  sx={{
                    color: "#000",
                    fontSize: "18px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    onChange={handleCheckbox}
                    checked={CheckboxValue}
                    sx={{ margin: 0, padding: 0 }}
                  />
                  I have read and understood the Eligibility Criterion{" "}
                </Typography>
              </Grid>
            </>
          )}
        <Grid
          item
          md={12}
          pt={2}
          pb={2}
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : null,
            marginBottom: 1,
          }}
        >
          <BackButton handleBack={handleBack} />
          {/* <EditButton /> */}
          <SubmitButton
            disabled={
              isPaid === "1" ||
              (courseFormDetails[0]?.qualificationnotes &&
                courseFormDetails[0]?.qualificationnotes !== "null")
                ? !CheckboxValue
                : false
            }
            name={buttonLoader ? "loader" : "Save and Proceed"}
            handleSubmit={handleSubmit(handleProcced, onError)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
