import candidatesActions from "./actions";

const initialState = {
  showImage: null,
  showCompressedImage: null,
  showSignature: null,
  showCompressedSignature: null,
  personalDetails: [],
  addressDetails: [],
  categoryDetails: [],
  educationDetails: [],
  examDetails: [],
  certificateDetails: [],
  queryByCanidate: [],
  otherDetails: [],
  uploadDetails: [],
  courseUploadDetails: [],
  pincode: [],
  pincodeCorrespondence: [],
  courseActiveTab: [],
  FullCandidateDetails: [],
  courseFullRegistrationDetails: [],
  courseAddressDetails: [],
  courseList: {
    all : [],
    filtered:[]
  },
  courseTabs: [],
  masterFields: [],
  applicantid: {},
  courseStatus:[],
  ispaid:[],
  paymentReceiptList:[],
  examState:[],
  examDistrict:{
    all:[],
    filtered:[],
  },
  examLanguage:[],
  courseFormDetails:[],
  currentCourseDetails:[],
  ipAddress:[],
  otbsVenueDetails:{
    States:[],
    Districts:[],
    Venues:[]
  },
  currentOtbsVenue:[],
};

const candidatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case candidatesActions.SHOW_IMAGE:
      return {
        ...state,
        showImage: action.payload,
      };
    case candidatesActions.SHOW_COMPRESSED_IMAGE:
      return {
        ...state,
        showCompressedImage: action.payload,
      };
    case candidatesActions.SHOW_SIGNATURE:
      return {
        ...state,
        showSignature: action.payload,
      };
    case candidatesActions.SHOW_COMPRESSED_SIGNATURE:
      return {
        ...state,
        showCompressedSignature: action.payload,
      };

    case candidatesActions.SET_PROFILE_PERSONAL_DETAILS:
      return {
        ...state,
        personalDetails: action.payload,
      };
    case candidatesActions.SET_ADDRESS_PERSONAL_DETAILS:
      return {
        ...state,
        addressDetails: action.payload,
      };
    case candidatesActions.SET_CATEGORY_PERSONAL_DETAILS:
      return {
        ...state,
        categoryDetails: action.payload,
      };
    case candidatesActions.SET_EDUCATION_PERSONAL_DETAILS:
      return {
        ...state,
        educationDetails: action.payload,
      };
    case candidatesActions.SET_EXAM_PERSONAL_DETAILS:
      return {
        ...state,
        examDetails: action.payload,
      };
    case candidatesActions.SET_CERTIFICATE_PERSONAL_DETAILS:
      return {
        ...state,
        certificateDetails: action.payload,
      };
    case candidatesActions.SET_QUERY_STATUS_BY_CANDIDATEID:
      return {
        ...state,
        queryByCanidate: action.payload,
      };
    case candidatesActions.SET_OTHER_PERSONAL_DETAILS:
      return {
        ...state,
        otherDetails: action.payload,
      };
    case candidatesActions.SET_UPLOAD_PERSONAL_DETAILS:
      return {
        ...state,
        uploadDetails: action.payload,
      };
    case candidatesActions.SET_COURSE_UPLOAD_PERSONAL_DETAILS:
      return {
        ...state,
        courseUploadDetails: action.payload,
      };
    case candidatesActions.SET_PINCODE:
      return {
        ...state,
        pincode: action.payload,
      };
    case candidatesActions.SET_PINCODE_CORRESPONDENCE:
      return {
        ...state,
        pincodeCorrespondence: action.payload,
      };
    case candidatesActions.SET_FULL_CANDIDATE_DETAILS:
      return {
        ...state,
        FullCandidateDetails: action.payload,
      };
    case candidatesActions.SET_COURSE_REGISTRATION_DETAILS:
      return {
        ...state,
        courseFullRegistrationDetails: action.payload,
      };
      case candidatesActions.SET_COURSE_ADDRESS_DETAILS:
        return {
          ...state,
          courseAddressDetails: action.payload,
        };
      
    case candidatesActions.SET_ALL_COURSE_LIST:
      return {
        ...state,
        courseList: action.payload,
      };
    case candidatesActions.SET_COURSE_TAB:
      return {
        ...state,
        courseTabs: action.payload,
      };
    case candidatesActions.SET_MASTER_FIELDS:
      return {
        ...state,
        masterFields: action.payload,
      };
    case candidatesActions.SET_COURSE_ACTIVE_TAB:
      return {
        ...state,
        courseActiveTab: action.payload,
      };
    case candidatesActions.SET_COURSE_APPLICANTID:
      return {
        ...state,
        applicantid: action.payload,
      };
    case candidatesActions.SET_COURSE_STATUS:
      return {
        ...state,
        courseStatus: action.payload,
      };
    case candidatesActions.SET_COURSE_STATUS:
      return {
        ...state,
        courseStatus: action.payload,
      };
    case candidatesActions.SET_IS_PAID:
      return {
        ...state,
        ispaid: action.payload,
      };
      case candidatesActions.SET_PAYMENT_RECEIPT_LIST:
        return {
          ...state,
          paymentReceiptList: action.payload,
        };
      case candidatesActions.SET_EXAM_STATE:
        return {
          ...state,
          examState: action.payload,
        }
      case candidatesActions.SET_EXAM_DISTRICT:
          return {
            ...state,
            examDistrict: action.payload,
        }
        case candidatesActions.SET_EXAM_LANGUAGE:
          return {
            ...state,
            examLanguage: action.payload,
          }
          case candidatesActions.SET_COURSE_FORM_DETAILS:
          return {
            ...state,
            courseFormDetails:action.payload,
          } 
          case candidatesActions.SET_CURRENT_COURSE:
          return {
            ...state,
            currentCourseDetails:action.payload,
          }
          case candidatesActions.SET_IP_ADDRESS:
            return {
              ...state,
              ipAddress:action.payload
            }
            case candidatesActions.SET_OTBS_VENUE_DETAILS:
              return {
                ...state,
                otbsVenueDetails:action.payload
              }
              case candidatesActions.SET_CURRENT_VENUE_DETAILS:
              return {
                ...state,
                currentOtbsVenue:action.payload
              }
              
            
    default:
      return state;
  }
};

export default candidatesReducer;
