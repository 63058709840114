import React from "react";
import { Button } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";
import ButtonLoader from "../buttonLoader";

export default function MauvelousPrimaryButton({ title, handleClick,icon,endIcon,disabled }) {

    return (
        <Button
        disabled={disabled}
            variant="contained"
            sx={{
                borderRadius: '40px',
                padding: '10px 24px',
                backgroundColor: `${cssProperties?.buttonbgcolor?.primary}`,
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '15.4px',
                color: `${cssProperties?.buttonfontcolor?.black}`,
                border: '1px solid transparent',
                letterSpacing: '0.32px',
                boxShadow: 'none',
                whiteSpace: 'nowrap',
                '&:hover': {
                    backgroundColor: `${cssProperties?.buttonbgcolor?.primary}`,
                    color: `${cssProperties?.buttonfontcolor?.black}`,
                    boxShadow: 'none'
                },
                '@media (max-width: 530px)': {
                    fontSize: 'x-small', 
                    padding: '8px 16px', 
                    whiteSpace: 'normal', 
                    lineHeight: 'normal', 
                },
                '@media (max-width: 480px)': {
                    fontSize: 'x-small', 
                    padding: '8px 16px', 
                    whiteSpace: 'normal', 
                    lineHeight: 'normal', 
                },
                
                '@media (max-width: 380px)': {
                    fontSize: 'xx-small', 
                    padding: '6px 12px', 
                    whiteSpace: 'normal', 
                    textAlign: 'center', 
                },
            }}
            onClick={title !== 'loader' && handleClick}
            startIcon={icon}
            endIcon={endIcon}
        >
            {title === 'loader' ?
                <ButtonLoader color={cssProperties?.surface?.primary} /> :
                title
            }
        </Button>
    )
}