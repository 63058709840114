import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import BackButton from "../../common/backButton";
import SubmitButton from "../../common/submitButton";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import masterActions from "../../redux/master/action";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import candidatesActions from "../../redux/candidates/actions";
import { masterFieldLabel, isFieldDisabled } from "../../utils/courseValues";
import { cssProperties } from "../../utils/commonCssProperties";
import { AlphabetsValidationUppercase } from "../../utils/validations";

export default function ReservationDetailsForm({
  nextPageName,
  previousPageName,
}) {
  const [disability, setDisability] = useState("");
  // const [years, setYears] = useState([]);
  const location = useLocation();
  const courseid = location.state?.courseid;
  const coursename = location.state?.coursename;
  const statusForRedirect = location.state?.status;
  const { masterFields, courseFormDetails } = useSelector(
    (state) => state.candidatesReducer
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const date = new Date();
  const currentYear = date.getFullYear();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    register,
    handleSubmit,
    trigger,
    watch,
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
    formState: { errors },
    setFocus
  } = useForm({
    mode: "onChange",
  });
  // console.log(parseInt(watch("castecertificatestatus")),'certsta')
  // console.log(
  //   "dis id watch-----",
  //   parseInt(watch("disabilitytypeid")),
  //   watch("isdisability")
  // );

  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const {
    pwdType,
    pwdSubType,
    category,
    caste,
    mothertongue,
    religion,
    minority,
    sportParticipationLevel,
    sportRank,
    sportName,
    sportBody,
    MaritalStatus,
    orphanType,
    ewsDistrict,
    ewsTaluka,
    cvcDistrict,
    nclAuthority,
    issuingAuthority
  } = useSelector((state) => state.masterReducer);
  console.log(issuingAuthority, "issuingAuthority");


  const { courseFullRegistrationDetails, otherDetails } = useSelector(
    (state) => state.candidatesReducer
  );

  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );
  const isPaid = courseFormDetails[0]?.ispaid;
  // useEffect(() => {
  //   if (watch("isdisability") === 1) {
  //     trigger("disabilitytypeid");
  //   }
  // }, [watch("isdisability")]);

  // useEffect(() => {
  //       const generateYears = () => {
  //     const currentYear = new Date().getFullYear();
  //     const yearsArray = [];
  //     for (let i = 1994; i <= currentYear; i++) {
  //       yearsArray.push(i);
  //     }
  //     return yearsArray;
  //   };
  //   setYears(generateYears());
  // }, []);
  const years = Array.from({ length: 2024 - 1950 + 1 }, (_, i) => 2024 - i);

  useEffect(() => {
    dispatch({ type: masterActions.GET_BOARD });
    dispatch({ type: masterActions.GET_PWD_TYPE });
    dispatch({ type: masterActions.GET_CATEGORY });
    dispatch({ type: masterActions.GET_CASTE });
    dispatch({ type: masterActions.GET_RELIGION });
    dispatch({ type: masterActions.GET_MOTHERTONGUE });
    dispatch({ type: masterActions.GET_MINORITY });
    dispatch({ type: masterActions.GET_SPORT_PARTICIPATION_LEVEL });
    dispatch({ type: masterActions.GET_SPORT_RANK });
    dispatch({ type: masterActions.GET_SPORT_NAME });
    dispatch({ type: masterActions.GET_SPORT_BODY });
    // dispatch({ type: masterActions.GET_ORPHAN_TYPE });
    dispatch({ type: masterActions.GET_EWS_DISTRICT });

    dispatch({ type: masterActions.GET_CVC_DISTRICT });
    dispatch({ type: masterActions.GET_NCL_AUTHORITY });
    dispatch({ type: masterActions.GET_ISSUING_AUTHORITY });
  }, []);

  useEffect(() => {

    if (courseFullRegistrationDetails[0]?.corcandidatereservationid) {
      // console.log("insie courseFullRegistrationDetails");
      reset({ ...courseFullRegistrationDetails[0] });
      dispatch({
        type: masterActions.GET_EWS_TALUKA,
        payload: courseFullRegistrationDetails[0]?.ewsdistrictid,
      });

      let disabilitytypeid = courseFullRegistrationDetails[0]?.disabilitytypeid;
      !isNaN(parseInt(disabilitytypeid)) &&
        dispatch({
          type: masterActions.GET_PWDSUB_TYPE,
          payload: { pwdTypeID: disabilitytypeid },
        });
      if (disabilitytypeid) reset({ ...courseFullRegistrationDetails[0] });
      setValue(
        "isextratime",
        parseInt(courseFullRegistrationDetails[0]?.isextratime)
      );
      setValue(
        "isdisability",
        parseInt(courseFullRegistrationDetails[0]?.isdisability)
      );
      setValue(
        "isscribe",
        parseInt(courseFullRegistrationDetails[0]?.isscribe)
      );
      setValue(
        "pwdsubtypeid",
        parseInt(courseFullRegistrationDetails[0]?.pwdsubtypeid)
      );
      setValue(
        "disabilitytypeid",
        isNaN(parseInt(courseFullRegistrationDetails[0]?.disabilitytypeid)) ? "" :
          parseInt(courseFullRegistrationDetails[0]?.disabilitytypeid)
      );
      !isNaN(parseInt(getValues("disabilitytypeid"))) &&
        dispatch({
          type: masterActions.GET_PWDSUB_TYPE,
          payload: { pwdTypeID: parseInt(getValues("disabilitytypeid")) },
        });
      setValue(
        "religiousminorityid",
        isNaN(parseInt(courseFullRegistrationDetails[0]?.religiousminorityid)) ? "" :
          parseInt(courseFullRegistrationDetails[0]?.religiousminorityid)

      );
      setValue(
        "linguisticminorityid",
        isNaN(parseInt(courseFullRegistrationDetails[0]?.linguisticminorityid)) ? "" :
          parseInt(courseFullRegistrationDetails[0]?.linguisticminorityid)

      );
      setValue(
        "ismhcandidate",
        parseInt(courseFullRegistrationDetails[0]?.ismhcandidate)
      );
      setValue(
        "isminority",
        parseInt(courseFullRegistrationDetails[0]?.isminority)
      );
    } else if (courseFullRegistrationDetails.length > 0) {
      reset({ ...otherDetails[0] });
      setValue("isextratime", parseInt(otherDetails[0]?.isextratimerequired));
      setValue("isdisability", parseInt(otherDetails[0]?.isdisability));
      setValue("isscribe", parseInt(otherDetails[0]?.isscriberequired));
      setValue("pwdsubtypeid", parseInt(otherDetails[0]?.subdisabilitytypeid));
      setValue("disabilitytypeid", isNaN(parseInt(otherDetails[0]?.disabilitytypeid)) ? "" : parseInt(otherDetails[0]?.disabilitytypeid));
      !isNaN(parseInt(getValues("disabilitytypeid"))) &&
        dispatch({
          type: masterActions.GET_PWDSUB_TYPE,
          payload: { pwdTypeID: parseInt(getValues("disabilitytypeid")) },
        });
      setValue(
        "religiousminorityid",
        parseInt(otherDetails[0]?.religiousminorityid)
      );
      setValue(
        "linguisticminorityid",
        parseInt(otherDetails[0]?.linguisticminorityid)
      );
      setValue("ismhcandidate", parseInt(otherDetails[0]?.ismhcandidate));
      setValue("isminority", parseInt(otherDetails[0]?.isminority));
    }
  }, [courseFullRegistrationDetails, otherDetails]);

  useEffect(() => {
    if (candidateid) {
      dispatch({
        type: candidatesActions.GET_COURSE_REGISTRATION_DETAILS,
        payload: {
          courseid: courseid,
        },
      });
      dispatch({ type: candidatesActions.GET_OTHER_PERSONAL_DETAILS });
    }
  }, [candidateid]);

  const handleDisability = (e) => {
    if (parseInt(e.target.value) !== 1) {
      setValue("disabilitytypeid", "");
      setValue("pwdsubtypeid", "");
      setValue("isscribe", "");
      setValue("isextratime", "");
      clearErrors("disabilitytypeid")
      clearErrors("isscribe")
      clearErrors("pwdsubtypeid")
      clearErrors("isextratime")
    }

    setDisability(e.target.value);

  };

  const handleBack = () => {
    navigate(
      `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=${previousPageName}`,
      { state: { courseid: courseid, coursename: coursename } }
    );
  };

  // const onError = (errors) => {
  //   const fError = Object.keys(errors)[0];
  //   if (fError) {
  //     setFocus(fError);
  //     document.getElementsByName(fError)[0]?.scrollIntoView({
  //       behavior: 'smooth', 
  //       block: 'start',
  //     });
  //   }
  // };
  // const onError = (errors) => {
  //   const fieldOrder = [
  //     'issports', 'sportsparticipationlevelid', 'sportsparticipationyear',
  //     'sportsrankid', 'sportsnameid', 'sportsbodyid', `isphysicaleducation`,
  //     'isteachingexperience', 'isappliedforelct', 'isappliedforksu',
  //     'isappliedforspecialeducation', 'specialeducationmethods',
  //     'isdisability', 'disabilitytypeid', 'pwdsubtypeid',
  //     'isscribe', 'isextratime', 'isorphan', 'orphantype',
  //     'ismhcandidate', 'categoryid',
  //     'castename', 'casteid', 'appliedforews', 'ewscertificatestatus',
  //     'ewsapplicationno', 'ewsapplicationdate', 'ewsdistrictid',
  //     'ewstalukaid', 'castecertificatestatus', 'castecertificateno',
  //     'castecertificatereceiptno', 'castevaliditystatus',
  //     'castevaliditycertificateno', 'cvcapplicationno',
  //     'cvcapplicationdate', 'cvcauthority', 'cvcdistrictid',
  //     'cvcname', 'nclstatus', 'nclapplicationno', 'nclauthorityid',
  //     'isminority', 'linguisticminorityid', 'religiousminorityid'
  //   ];

  //   const fError = fieldOrder.find((field) => errors[field]);

  //   if (fError) {
  //     setFocus(fError);
  //     document.getElementsByName(fError)[0]?.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'start',
  //       inline: 'start',
  //     });
  //   }
  // };
  // console.log(watch("disabilitytypeid"), '(watch("disabilitytypeid")')
  // useEffect(() => {
  //   if ((getValues("isdisability") == 1) || (watch("isdisability") == 1)) {
  //     trigger("pwdsubtypeid")
  //     trigger("disabilitytypeid")
  //     trigger("isextratime")
  //   }
  // }, [watch("isdisability")])
  const issports = useRef();
  const sportsparticipationlevelid = useRef();
  const sportsparticipationyear = useRef();
  const sportsrankid = useRef();
  const sportsnameid = useRef();
  const sportsbodyid = useRef();
  const isphysicaleducation = useRef();
  const isteachingexperience = useRef();
  const isappliedforelct = useRef();
  const isappliedforksu = useRef();
  const isappliedforspecialeducation = useRef();
  const specialeducationmethods = useRef();
  const isdisability = useRef();
  const pwdsubtypeid = useRef();
  const disabilitytypeid = useRef();
  const isscribe = useRef();
  const isextratime = useRef();
  const isorphan = useRef();
  const orphantype = useRef();
  const ismhcandidate = useRef();
  const categoryid = useRef();
  const castename = useRef();
  const casteid = useRef();
  const appliedforews = useRef();
  const ewscertificatestatus = useRef();
  const ewsapplicationno = useRef();
  const ewsapplicationdate = useRef();
  const ewsdistrictid = useRef();
  const ewstalukaid = useRef();
  const castecertificatestatus = useRef();
  const castecertificateno = useRef();
  const castecertificatereceiptno = useRef();
  const castevaliditystatus = useRef();
  const castevaliditycertificateno = useRef();
  const castecertificateappdate = useRef();
  const cvcapplicationno = useRef();
  const cvcapplicationdate = useRef();
  const cvcauthority = useRef();
  const cvcdistrictid = useRef();
  const cvcname = useRef();
  const nclstatus = useRef();
  const nclapplicationno = useRef();
  const nclauthorityid = useRef();
  const isminority = useRef();
  const linguisticminorityid = useRef();
  const religiousminorityid = useRef();
  const nclapplicationdate = useRef();

  const onError = (errors) => {
    if (errors?.issports) {
      issports?.current?.focus();
    } else if (errors?.sportsparticipationlevelid) {
      sportsparticipationlevelid?.current?.focus();
    } else if (errors?.sportsparticipationyear) {
      sportsparticipationyear?.current?.focus();
    } else if (errors?.sportsrankid) {
      sportsrankid?.current?.focus();
    } else if (errors?.sportsnameid) {
      sportsnameid?.current?.focus();
    } else if (errors?.sportsbodyid) {
      sportsbodyid?.current?.focus();
    } else if (errors?.isphysicaleducation) {
      isphysicaleducation?.current?.focus();
    } else if (errors?.isteachingexperience) {
      isteachingexperience?.current?.focus();
    } else if (errors?.isappliedforelct) {
      isappliedforelct?.current?.focus();
    } else if (errors?.isappliedforksu) {
      isappliedforksu?.current?.focus();
    } else if (errors?.isappliedforspecialeducation) {
      isappliedforspecialeducation?.current?.focus();
    } else if (errors?.specialeducationmethods) {
      specialeducationmethods?.current?.focus();
    } else if (errors?.isdisability) {
      isdisability?.current?.focus();
    } else if (errors?.disabilitytypeid) {
      disabilitytypeid?.current?.focus();
    } else if (errors?.pwdsubtypeid) {
      pwdsubtypeid?.current?.focus();
    } else if (errors?.isextratime) {
      isextratime?.current?.focus();
    } else if (errors?.isscribe) {
      isscribe?.current?.focus();
    } else if (errors?.isorphan) {
      isorphan?.current?.focus();
    } else if (errors?.orphantype) {
      orphantype?.current?.focus();
    } else if (errors?.ismhcandidate) {
      ismhcandidate?.current?.focus();
    } else if (errors?.categoryid) {
      categoryid?.current?.focus();
    } else if (errors?.castename) {
      castename?.current?.focus();
    } else if (errors?.casteid) {
      casteid?.current?.focus();
    } else if (errors?.appliedforews) {
      appliedforews?.current?.focus();
    } else if (errors?.ewscertificatestatus) {
      ewscertificatestatus?.current?.focus();
    } else if (errors?.ewsapplicationno) {
      ewsapplicationno?.current?.focus();
    }

    else if (errors?.ewsapplicationdate) {
      document
        .querySelector("[name='ewsapplicationdate']")
        ?.focus();
    }


    else if (errors?.ewsdistrictid) {
      ewsdistrictid?.current?.focus();
    } else if (errors?.ewstalukaid) {
      ewstalukaid?.current?.focus();
    } else if (errors?.castecertificatestatus) {
      castecertificatestatus?.current?.focus();
    } else if (errors?.castecertificateno) {
      castecertificateno?.current?.focus();
    } else if (errors?.castecertificatereceiptno) {
      castecertificatereceiptno?.current?.focus();
    } else if (errors?.castecertificateappdate) {
      document
        .querySelector("[name='castecertificateappdate']")
        ?.focus();
    }
    else if (errors?.castevaliditystatus) {
      castevaliditystatus?.current?.focus();
    } else if (errors?.castevaliditycertificateno) {
      castevaliditycertificateno?.current?.focus();
    } else if (errors?.cvcapplicationno) {
      cvcapplicationno?.current?.focus();
    } else if (errors?.cvcapplicationdate) {
      document
        .querySelector("[name='cvcapplicationdate']")
        ?.focus();
    }
    else if (errors?.cvcauthority) {
      cvcauthority?.current?.focus();
    } else if (errors?.cvcdistrictid) {
      cvcdistrictid?.current?.focus();
    } else if (errors?.cvcname) {
      cvcname?.current?.focus();
    } else if (errors?.nclstatus) {
      nclstatus?.current?.focus();
    } else if (errors?.nclapplicationno) {
      nclapplicationno?.current?.focus();
    } else if (errors?.nclauthorityid) {
      nclauthorityid?.current?.focus();
    } else if (errors?.isminority) {
      isminority?.current?.focus();
    } else if (errors?.linguisticminorityid) {
      linguisticminorityid?.current?.focus();
    } else if (errors?.religiousminorityid) {
      religiousminorityid?.current?.focus();
    } else if (errors?.nclapplicationdate) {
      document
        .querySelector("[name='nclapplicationdate']")
        ?.focus();
    }
  };



  useEffect(() => {
    if (courseid) {
      dispatch({
        type: candidatesActions.GET_COURSE_FORM_DETAILS,
        payload: { courseid: courseid },
      });
      dispatch({
        type: candidatesActions.GET_MASTER_FIELDS,
        payload: {
          data: { courseid: courseid },
        },
      });
    }
  }, [courseid]);

  const handleProcced = (data) => {
    trigger('pwdsubtypeid')
    const corcandidatereservationid =
      courseFullRegistrationDetails[0]?.corcandidatereservationid;
    dispatch({
      type: candidatesActions.COURSE_RESERVATION_DETAILS_CREATION,
      payload: {
        data: {
          ...data,
          operation: corcandidatereservationid
            ? parseInt(corcandidatereservationid)
            : 0,
          candidateid: candidateid,
          status: 1,
          createdby: candidateid,
          updatedby: corcandidatereservationid ? parseInt(candidateid) : "",
          courseid: courseid,
          coursename: coursename,
        },
        navigate: navigate,
        nextPageName: nextPageName,
        statusForRedirect: statusForRedirect,
      },
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch({
      type: candidatesActions.GET_COURSE_FORM_DETAILS,
      payload: { courseid: courseid },
    });
    dispatch({ type: candidatesActions.GET_COURSE_STATUS, payload: { candidateid, courseid } })
    dispatch({ type: candidatesActions.GET_IS_PAID, payload: { candidateid, courseid } })
  };

  const handleCasteCertificateChange = (e) => {
    const { value } = e.target
    setValue("castecertificatereceiptno", value);
    trigger("castecertificatereceiptno")
  };
  useEffect(() => {
    trigger("issports")
  }, [])
  return (
    <Grid
      p={3}
      style={{
        padding: "20px 25px 0px 25px",
        minHeight: "60vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
        border: "2px solid #0F4F96",
      }}
    >
      <Grid container spacing={2}>
        {/* <Grid item xs={12} md={6}>
          <FormControl fullWidth size="small" error={!!errors.domiicilecertificatestatus}>
            <InputLabel
              shrink={!!watch("domiicilecertificatestatus") || watch("domiicilecertificatestatus") === 0}
              htmlFor="domiicilecertificatestatus"
            >
              Are you Domiciled in the state of Maharashtra?
              <span className="error">*</span>
            </InputLabel>
            <Select  MenuProps={{
                  disableScrollLock: true,
              }}
              id="domiicilecertificatestatus"
              label="Are you Domiciled in the state of Maharashtra?"
              {...register("domiicilecertificatestatus", {
                required: "Is MH Candidate is required",
              })}
              onChange={(e) => {
                setValue("domiicilecertificatestatus", e.target.value);
                trigger("domiicilecertificatestatus");
              }}
              value={watch("domiicilecertificatestatus") ?? ""}
            >
              <MenuItem value="">Select One</MenuItem>
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
            {errors.domiicilecertificatestatus && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.domiicilecertificatestatus.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid> */}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.IS_SPORT
        ) && (
            <Grid item xs={12} md={12}>
              <Typography
                pt={1}
                sx={{
                  fontSize: "24px",
                  color: `${cssProperties?.color?.tertiary}`,
                  fontWeight: 500,
                  lineHeight: "30px",
                }}
              >
                {" "}
                SPORTS INFORMATION{" "}
              </Typography>
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.IS_SPORT
        ) && (
            <Grid item xs={12} md={6} ref={issports}>
              <FormControl fullWidth error={!!errors.issports}>
                <Tooltip title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.IS_SPORT
                  )?.fieldlabel
                } arrow    PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [10, 0], 
                      },
                    },
                  ],
                }}
                  placement="right" 
                disableInteractive  
                enterDelay={500} 
                leaveDelay={200}>
                  <TextField
                    inputRef={issports}
                    select
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: !!watch("issports") || watch("issports") === 0,
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    id="issports"
                    name="issports"
                    label={
                      <span>
                        {masterFields?.find(
                          (field) => field?.masterfieldlabel === masterFieldLabel.IS_SPORT
                        )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}> *</span>
                          )}
                        <span>
                          {masterFields?.find(
                            (field) => field?.masterfieldlabel === masterFieldLabel.IS_SPORT
                          )?.fieldlabel}
                        </span>
                      </span>
                    }
                    {...register("issports", {
                      required: masterFields?.find(
                        (field) => field?.masterfieldlabel === masterFieldLabel.IS_SPORT
                      )?.ismandatory === "1" ? "Sports is required" : false,
                    })}
                    SelectProps={{
                      MenuProps: { disableScrollLock: true },
                      style: { height: "56px", boxSizing: "border-box" },
                      onChange: (e) => {
                        const selectedValue = e.target.value;
                        setValue("issports", selectedValue);
                        setValue("sportsparticipationlevelid", "");
                        setValue("sportsparticipationyear", "");
                        setValue("sportsrankid", "");
                        setValue("sportsnameid", "");
                        setValue("sportsbodyid", "");
                        clearErrors("issports")
                        if (selectedValue === 0) {
                          clearErrors("sportsparticipationlevelid")
                          clearErrors("sportsparticipationyear")
                          clearErrors("sportsrankid")
                          clearErrors("sportsnameid")
                          clearErrors("sportsbodyid")
                        }
                      },
                    }}
                    value={watch("issports") ?? ""}
                    disabled={isFieldDisabled(
                      masterFields,
                      masterFieldLabel.IS_SPORT,
                      null,
                      isPaid
                    )}
                    error={!!errors.issports}
                    helperText={errors.issports ? errors.issports.message : ""}
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </TextField>
                </Tooltip>
              </FormControl>

            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.SPORT_PARTICIPATION_LEVEL
        ) && (
          <Tooltip title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SPORT_PARTICIPATION_LEVEL
                      )?.fieldlabel
                    } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200} >
            <Grid item xs={12} md={6} ref={sportsparticipationlevelid}>
              <FormControl fullWidth error={!!errors.sportsparticipationlevelid}>
                <TextField
                  inputRef={sportsparticipationlevelid}
                  name="sportsparticipationlevelid"
                  select
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },

                  }}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("sportsparticipationlevelid") || watch("sportsparticipationlevelid") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                    maxLength: 50,
                  }}
                  id="sportsparticipationlevelid"
                  label={
                    <span>
                      {parseInt(watch("issports")) === 1 && (
                        <span className="error" style={{ color: "red" }}> *</span>
                      )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel === masterFieldLabel.SPORT_PARTICIPATION_LEVEL
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("sportsparticipationlevelid", {
                    required: masterFields?.find(
                      (field) => field?.masterfieldlabel === masterFieldLabel.SPORT_PARTICIPATION_LEVEL
                    )?.ismandatory === "1" && getValues("issports") === 1 ? "Sports Participation Level is required" : false,
                  })}
                  value={watch("sportsparticipationlevelid") ?? ""}
                  onChange={(e) => {
                    setValue("sportsparticipationlevelid", e.target.value)
                    clearErrors("sportsparticipationlevelid")
                    // trigger("sportsparticipationyear")
                    // trigger("sportsrankid")
                    // trigger("sportsnameid")
                    // trigger("sportsbodyid")
                  }
                  } // Assuming you have setValue function to manage form state
                  disabled={parseInt(watch("issports")) !== 1 || isPaid === "1"}
                  error={!!errors.sportsparticipationlevelid}
                  helperText={errors.sportsparticipationlevelid ? errors.sportsparticipationlevelid.message : ""}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {sportParticipationLevel?.map((val, i) => (
                    <MenuItem key={i} value={val?.sportsparticipationid}>
                      {val?.sportsparticipation}
                    </MenuItem>
                  ))}
                </TextField>

              </FormControl>
            </Grid>
            </Tooltip>

          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SPORT_PARTICIPATION_YEAR
        ) && (
            // <Grid item xs={12} md={6} ref={sportsparticipationyear}>
            //   <FormControl fullWidth error={!!errors.sportsparticipationyear}>
            //     <Controller
            //       name="sportsparticipationyear"
            //       control={control}
            //       rules={{
            //         required: parseInt(watch("issports")) === 1
            //           ? "Sports participation year is required"
            //           : false,
            //       }}
            //       render={({ field }) => (
            //         <Tooltip title={
            //           masterFields?.find(
            //             (field) =>
            //               field?.masterfieldlabel ===
            //               masterFieldLabel.SPORT_PARTICIPATION_YEAR
            //           )?.fieldlabel
            //         } arrow   PopperProps={{

            //           <TextField
            //             {...field}
            //             inputRef={sportsparticipationyear}
            //             name="sportsparticipationyear"
            //             select
            //             variant="outlined"
            //             fullWidth
            //             InputLabelProps={{
            //               shrink: !!watch("sportsparticipationyear") || watch("sportsparticipationyear") === 0,
            //             }}
            //             MenuProps={{
            //               disableScrollLock: true,
            //             }}
            //             inputProps={{
            //               style: { height: "56px", boxSizing: "border-box" },
            //               maxLength: 50,
            //             }}
            //             id="sportsparticipationyear"
            //             label={
            //               <span>
            //                 {parseInt(watch("issports")) === 1 && (
            //                   <span className="error" style={{ color: "red" }}> *</span>
            //                 )}
            //                 <span>
            //                   {
            //                     masterFields?.find(
            //                       (field) =>
            //                         field?.masterfieldlabel === masterFieldLabel.SPORT_PARTICIPATION_YEAR
            //                     )?.fieldlabel
            //                   }
            //                 </span>
            //               </span>
            //             }

            //             value={field.value ?? ""}
            //             disabled={parseInt(watch("issports")) !== 1 || isPaid === "1"}
            //             error={!!errors.sportsparticipationyear}
            //             helperText={errors.sportsparticipationyear ? errors.sportsparticipationyear.message : ""}
            //           >
            //             <MenuItem value="" disabled>
            //               Select One
            //             </MenuItem>
            //             {years.map((year, index) => (
            //               <MenuItem key={index} value={year}>
            //                 {year}
            //               </MenuItem>
            //             ))}
            //           </TextField>
            //         </Tooltip>
            //       )}
            //     />
            //   </FormControl>
            // </Grid>
            <Tooltip title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.SPORT_PARTICIPATION_YEAR
              )?.fieldlabel
            } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
            <Grid item xs={12} md={6} ref={sportsparticipationyear}>
              <FormControl fullWidth error={!!errors.sportsparticipationyear}>
                <TextField
                  inputRef={sportsparticipationyear}
                  name="sportsparticipationyear"
                  select
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },

                  }}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("sportsparticipationyear") || watch("sportsparticipationyear") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                    maxLength: 50,
                  }}
                  id="sportsparticipationyear"
                  label={
                    <span>
                      {parseInt(watch("issports")) === 1 && (
                        <span className="error" style={{ color: "red" }}> *</span>
                      )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel === masterFieldLabel.SPORT_PARTICIPATION_YEAR
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("sportsparticipationyear", {
                    required: parseInt(watch("issports")) === 1
                      ? "Sports participation year is required"
                      : false,
                  })}
                  value={watch("sportsparticipationyear") ?? ""}
                  onChange={(e) => {
                    setValue("sportsparticipationyear", e.target.value);
                    clearErrors("sportsparticipationyear");
                  }}
                  disabled={parseInt(watch("issports")) !== 1 || isPaid === "1"}
                  error={!!errors.sportsparticipationyear}
                  helperText={errors.sportsparticipationyear ? errors.sportsparticipationyear.message : ""}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {years.map((year, index) => (
                    <MenuItem key={index} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            </Tooltip>

          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SPORT_RANK
        ) && (
            // <Grid item xs={12} md={6} ref={sportsrankid}>
            //   <FormControl fullWidth error={!!errors.sportsrankid}>
            //     <Controller
            //       name="sportsrankid"
            //       control={control}
            //       rules={{
            //         required: parseInt(watch("issports")) === 1 ? "Sports rank is required" : false,
            //       }}
            //       render={({ field }) => (
            //         <Tooltip title={
            //           masterFields?.find(
            //             (field) =>
            //               field?.masterfieldlabel ===
            //               masterFieldLabel.SPORT_RANK
            //           )?.fieldlabel
            //         } arrow   PopperProps={{

            //           <TextField
            //             {...field} // Bind the field value to the controller
            //             inputRef={sportsrankid}
            //             name="sportsrankid"
            //             select
            //             variant="outlined"
            //             fullWidth
            //             InputLabelProps={{
            //               shrink: !!watch("sportsrankid") || watch("sportsrankid") === 0,
            //             }}
            //             MenuProps={{
            //               disableScrollLock: true,
            //             }}
            //             inputProps={{
            //               style: { height: "56px", boxSizing: "border-box" },
            //               maxLength: 50,
            //             }}
            //             id="sportsrankid"
            //             label={
            //               <span>
            //                 {parseInt(watch("issports")) === 1 && (
            //                   <span className="error" style={{ color: "red" }}> *</span>
            //                 )}
            //                 <span>
            //                   {
            //                     masterFields?.find(
            //                       (field) =>
            //                         field?.masterfieldlabel === masterFieldLabel.SPORT_RANK
            //                     )?.fieldlabel
            //                   }
            //                 </span>
            //               </span>
            //             }

            //             value={field.value ?? ""}
            //             disabled={parseInt(watch("issports")) !== 1 || isPaid === "1"}
            //             error={!!errors.sportsrankid}
            //             helperText={errors.sportsrankid ? errors.sportsrankid.message : ""}
            //           >
            //             <MenuItem value="" disabled>
            //               Select One
            //             </MenuItem>
            //             {sportRank.map((val, i) => (
            //               <MenuItem key={i} value={val?.sportsrankid}>
            //                 {val?.sportsrank}
            //               </MenuItem>
            //             ))}
            //           </TextField>
            //         </Tooltip>
            //       )}
            //     />
            //   </FormControl>
            // </Grid>
            <Tooltip title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.SPORT_RANK
              )?.fieldlabel
            } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
            <Grid item xs={12} md={6} ref={sportsrankid}>
              <FormControl fullWidth error={!!errors.sportsrankid}>
                <TextField
                  inputRef={sportsrankid}
                  name="sportsrankid"
                  select
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },

                  }}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("sportsrankid") || watch("sportsrankid") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                    maxLength: 50,
                  }}
                  id="sportsrankid"
                  label={
                    <span>
                      {parseInt(watch("issports")) === 1 && (
                        <span className="error" style={{ color: "red" }}> *</span>
                      )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel === masterFieldLabel.SPORT_RANK
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("sportsrankid", {
                    required: parseInt(watch("issports")) === 1 ? "Sports rank is required" : false,
                  })}
                  value={watch("sportsrankid") ?? ""}
                  onChange={(e) => {
                    setValue("sportsrankid", e.target.value);
                    clearErrors("sportsrankid");
                  }}
                  disabled={parseInt(watch("issports")) !== 1 || isPaid === "1"}
                  error={!!errors.sportsrankid}
                  helperText={errors.sportsrankid ? errors.sportsrankid.message : ""}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {sportRank.map((val, i) => (
                    <MenuItem key={i} value={val?.sportsrankid}>
                      {val?.sportsrank}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            </Tooltip>

          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SPORT_NAME
        ) && (
            // <Grid item xs={12} md={6} ref={sportsnameid}>
            //   <FormControl fullWidth error={!!errors.sportsnameid}>
            //     <Controller
            //       name="sportsnameid"
            //       control={control}
            //       rules={{
            //         required: parseInt(watch("issports")) === 1 ? "Sports name is required" : false,
            //       }}
            //       render={({ field }) => (
            //         <Tooltip title={
            //           masterFields?.find(
            //             (field) =>
            //               field?.masterfieldlabel ===
            //               masterFieldLabel.SPORT_NAME
            //           )?.fieldlabel
            //         } arrow   PopperProps={{

            //           <TextField
            //             {...field} // Bind the field value to the controller
            //             inputRef={sportsnameid}

            //             name="sportsnameid"
            //             select
            //             variant="outlined"
            //             fullWidth
            //             InputLabelProps={{
            //               shrink: !!watch("sportsnameid") || watch("sportsnameid") === 0,
            //             }}
            //             MenuProps={{
            //               disableScrollLock: true,
            //             }}
            //             inputProps={{
            //               style: { height: "56px", boxSizing: "border-box" },
            //               maxLength: 50,
            //             }}
            //             id="sportsnameid"
            //             label={
            //               <span>
            //                 {parseInt(watch("issports")) === 1 && (
            //                   <span className="error" style={{ color: "red" }}> *</span>
            //                 )}
            //                 <span>
            //                   {
            //                     masterFields?.find(
            //                       (field) =>
            //                         field?.masterfieldlabel === masterFieldLabel.SPORT_NAME
            //                     )?.fieldlabel
            //                   }
            //                 </span>
            //               </span>
            //             }

            //             value={field.value ?? ""}
            //             disabled={parseInt(watch("issports")) !== 1 || isPaid === "1"}
            //             error={!!errors.sportsnameid}
            //             helperText={errors.sportsnameid ? errors.sportsnameid.message : ""}
            //           >
            //             <MenuItem value="" disabled>
            //               Select One
            //             </MenuItem>
            //             {sportName?.map((val, i) => (
            //               <MenuItem key={i} value={val?.sportsid}>
            //                 {val?.sportsname}
            //               </MenuItem>
            //             ))}
            //           </TextField>
            //         </Tooltip>
            //       )}
            //     />
            //   </FormControl>
            // </Grid>
            <Tooltip title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.SPORT_NAME
              )?.fieldlabel
            } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
            <Grid item xs={12} md={6} ref={sportsnameid}>
              <FormControl fullWidth error={!!errors.sportsnameid}>
                <TextField
                  inputRef={sportsnameid}
                  name="sportsnameid"
                  select
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },

                  }}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("sportsnameid") || watch("sportsnameid") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                    maxLength: 50,
                  }}
                  id="sportsnameid"
                  label={
                    <span>
                      {parseInt(watch("issports")) === 1 && (
                        <span className="error" style={{ color: "red" }}> *</span>
                      )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel === masterFieldLabel.SPORT_NAME
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("sportsnameid", {
                    required: parseInt(watch("issports")) === 1 ? "Sports name is required" : false,
                  })}
                  value={watch("sportsnameid") ?? ""}
                  onChange={(e) => {
                    setValue("sportsnameid", e.target.value);
                    clearErrors("sportsnameid");
                  }}
                  disabled={parseInt(watch("issports")) !== 1 || isPaid === "1"}
                  error={!!errors.sportsnameid}
                  helperText={errors.sportsnameid ? errors.sportsnameid.message : ""}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {sportName?.map((val, i) => (
                    <MenuItem key={i} value={val?.sportsid}>
                      {val?.sportsname}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            </Tooltip>

          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SPORT_BODY
        ) && (
            // <Grid item xs={12} md={6} ref={sportsbodyid}>
            //   <FormControl fullWidth error={!!errors.sportsbodyid}>
            //     <Controller
            //       name="sportsbodyid"
            //       control={control}
            //       rules={{
            //         required: parseInt(watch("issports")) === 1 ? "Sports body is required" : false,
            //       }}
            //       render={({ field }) => (
            //         <Tooltip title={
            //           masterFields?.find(
            //             (field) =>
            //               field?.masterfieldlabel ===
            //               masterFieldLabel.SPORT_BODY
            //           )?.fieldlabel
            //         } arrow   PopperProps={{

            //           <TextField
            //             {...field} // Bind the field value to the controller
            //             inputRef={sportsbodyid}
            //             name="sportsbodyid"
            //             select
            //             variant="outlined"
            //             fullWidth
            //             InputLabelProps={{
            //               shrink: !!watch("sportsbodyid") || watch("sportsbodyid") === 0,
            //             }}
            //             MenuProps={{
            //               disableScrollLock: true,
            //             }}
            //             inputProps={{
            //               style: { height: "56px", boxSizing: "border-box" },
            //               maxLength: 50,
            //             }}
            //             id="sportsbodyid"
            //             label={
            //               <span>
            //                 {parseInt(watch("issports")) === 1 && (
            //                   <span className="error" style={{ color: "red" }}> *</span>
            //                 )}
            //                 <span>
            //                   {
            //                     masterFields?.find(
            //                       (field) =>
            //                         field?.masterfieldlabel === masterFieldLabel.SPORT_BODY
            //                     )?.fieldlabel
            //                   }
            //                 </span>
            //               </span>
            //             }

            //             value={field.value ?? ""}
            //             disabled={parseInt(watch("issports")) !== 1 || isPaid === "1"}
            //             error={!!errors.sportsbodyid}
            //             helperText={errors.sportsbodyid ? errors.sportsbodyid.message : ""}
            //           >
            //             <MenuItem value="" disabled>
            //               Select One
            //             </MenuItem>
            //             {sportBody?.map((val, i) => (
            //               <MenuItem key={i} value={val?.sportsbodyid}>
            //                 {val?.sportsbody}
            //               </MenuItem>
            //             ))}
            //           </TextField>
            //         </Tooltip>
            //       )}
            //     />
            //   </FormControl>
            // </Grid>
            <Tooltip title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.SPORT_BODY
              )?.fieldlabel
            } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
            <Grid item xs={12} md={6} ref={sportsbodyid}>
              <FormControl fullWidth error={!!errors.sportsbodyid}>
                <TextField
                  inputRef={sportsbodyid}
                  name="sportsbodyid"
                  select
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },

                  }}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("sportsbodyid") || watch("sportsbodyid") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                    maxLength: 50,
                  }}
                  id="sportsbodyid"
                  label={
                    <span>
                      {parseInt(watch("issports")) === 1 && (
                        <span className="error" style={{ color: "red" }}> *</span>
                      )}
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel === masterFieldLabel.SPORT_BODY
                          )?.fieldlabel
                        }
                      </span>
                    </span>
                  }
                  {...register("sportsbodyid", {
                    required: parseInt(watch("issports")) === 1 ? "Sports body is required" : false,
                  })}
                  value={watch("sportsbodyid") ?? ""}
                  onChange={(e) => {
                    setValue("sportsbodyid", e.target.value);
                    clearErrors("sportsbodyid");
                  }}
                  disabled={parseInt(watch("issports")) !== 1 || isPaid === "1"}
                  error={!!errors.sportsbodyid}
                  helperText={errors.sportsbodyid ? errors.sportsbodyid.message : ""}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {sportBody?.map((val, i) => (
                    <MenuItem key={i} value={val?.sportsbodyid}>
                      {val?.sportsbody}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            </Tooltip>

          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.IS_STUDIED_PHY_EDU
        ) && (
            // <Grid item xs={12} md={6} ref={isphysicaleducation}>
            //   <FormControl fullWidth error={!!errors.isphysicaleducation}>
            //     <Controller
            //       name="isphysicaleducation"
            //       control={control}
            //       rules={{
            //         required:
            //           masterFields?.find(
            //             (field) =>
            //               field?.masterfieldlabel === masterFieldLabel.IS_STUDIED_PHY_EDU
            //           )?.ismandatory === "1"
            //             ? "Physical Education is required"
            //             : false,
            //       }}
            //       render={({ field }) => (
            //         <Tooltip title={
            //           masterFields?.find(
            //             (field) =>
            //               field?.masterfieldlabel ===
            //               masterFieldLabel.IS_STUDIED_PHY_EDU
            //           )?.fieldlabel
            //         } arrow   PopperProps={{

            //           <TextField
            //             {...field} // Pass field value and onChange directly from Controller
            //             inputRef={isphysicaleducation}
            //             name="isphysicaleducation"
            //             select
            //             variant="outlined"
            //             fullWidth
            //             InputLabelProps={{
            //               shrink: !!watch("isphysicaleducation") || watch("isphysicaleducation") === 0,
            //             }}
            //             MenuProps={{
            //               disableScrollLock: true,
            //             }}
            //             inputProps={{
            //               style: { height: "56px", boxSizing: "border-box" },
            //               maxLength: 50,
            //             }}
            //             id="isphysicaleducation"
            //             label={
            //               <span>
            //                 {masterFields?.find(
            //                   (field) =>
            //                     field?.masterfieldlabel === masterFieldLabel.IS_STUDIED_PHY_EDU
            //                 )?.ismandatory === "1" && (
            //                     <span className="error" style={{ color: "red" }}>
            //                       {" "}*
            //                     </span>
            //                   )}
            //                 {masterFields?.find(
            //                   (field) =>
            //                     field?.masterfieldlabel === masterFieldLabel.IS_STUDIED_PHY_EDU
            //                 )?.fieldlabel}

            //               </span>
            //             }

            //             value={field.value ?? ""} // Ensure the correct value is set here
            //             disabled={isFieldDisabled(
            //               masterFields,
            //               masterFieldLabel.IS_STUDIED_PHY_EDU,
            //               null,
            //               isPaid
            //             )}
            //             error={!!errors.isphysicaleducation}
            //             helperText={errors.isphysicaleducation ? errors.isphysicaleducation.message : ""}
            //           >
            //             <MenuItem value="" disabled>
            //               Select One
            //             </MenuItem>
            //             <MenuItem value={1}>Yes</MenuItem>
            //             <MenuItem value={0}>No</MenuItem>
            //           </TextField>
            //         </Tooltip>
            //       )}
            //     />
            //   </FormControl>
            // </Grid>
            <Tooltip title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.IS_STUDIED_PHY_EDU
              )?.fieldlabel
            } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
            <Grid item xs={12} md={6} ref={isphysicaleducation}>
              <FormControl fullWidth error={!!errors.isphysicaleducation}>
                <TextField
                  inputRef={isphysicaleducation}
                  name="isphysicaleducation"
                  select
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },

                  }}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("isphysicaleducation") || watch("isphysicaleducation") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                    maxLength: 50,
                  }}
                  id="isphysicaleducation"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel === masterFieldLabel.IS_STUDIED_PHY_EDU
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}> *</span>
                        )}
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel === masterFieldLabel.IS_STUDIED_PHY_EDU
                      )?.fieldlabel}
                    </span>
                  }
                  {...register("isphysicaleducation", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel === masterFieldLabel.IS_STUDIED_PHY_EDU
                      )?.ismandatory === "1"
                        ? "Physical Education is required"
                        : false,
                  })}
                  value={watch("isphysicaleducation") ?? ""}
                  onChange={(e) => {
                    setValue("isphysicaleducation", e.target.value);
                    clearErrors("isphysicaleducation");
                  }}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.IS_STUDIED_PHY_EDU,
                    null,
                    isPaid
                  )}
                  error={!!errors.isphysicaleducation}
                  helperText={errors.isphysicaleducation ? errors.isphysicaleducation.message : ""}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            </Tooltip>

          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.IS_TEACHING_EXP
        ) && (
            // <Grid item xs={12} md={6} ref={isteachingexperience}>
            //   <FormControl fullWidth error={!!errors.isteachingexperience}>
            //     <Controller
            //       name="isteachingexperience"
            //       control={control}
            //       rules={{
            //         required:
            //           masterFields?.find(
            //             (field) => field?.masterfieldlabel === masterFieldLabel.IS_TEACHING_EXP
            //           )?.ismandatory === "1"
            //             ? "Teaching Experience is required"
            //             : false,
            //       }}
            //       render={({ field }) => (
            //         <Tooltip title={
            //           masterFields?.find(
            //             (field) =>
            //               field?.masterfieldlabel ===
            //               masterFieldLabel.IS_TEACHING_EXP
            //           )?.fieldlabel
            //         } arrow   PopperProps={{

            //           <TextField
            //             {...field} // Binding value to the field using Controller
            //             inputRef={isteachingexperience}
            //             name="isteachingexperience"
            //             select
            //             variant="outlined"
            //             fullWidth
            //             InputLabelProps={{
            //               shrink: !!watch("isteachingexperience") || watch("isteachingexperience") === 0,
            //             }}
            //             MenuProps={{
            //               disableScrollLock: true,
            //             }}
            //             inputProps={{
            //               style: { height: "56px", boxSizing: "border-box" },
            //               maxLength: 50,
            //             }}
            //             id="isteachingexperience"
            //             label={
            //               <span>
            //                 {masterFields?.find(
            //                   (field) => field?.masterfieldlabel === masterFieldLabel.IS_TEACHING_EXP
            //                 )?.ismandatory === "1" && (
            //                     <span className="error" style={{ color: "red" }}>
            //                       {" "}*
            //                     </span>
            //                   )}
            //                 {
            //                   masterFields?.find(
            //                     (field) => field?.masterfieldlabel === masterFieldLabel.IS_TEACHING_EXP
            //                   )?.fieldlabel
            //                 }

            //               </span>
            //             }

            //             value={field.value ?? ""}
            //             disabled={isFieldDisabled(
            //               masterFields,
            //               masterFieldLabel.IS_TEACHING_EXP,
            //               null,
            //               isPaid
            //             )}
            //             error={!!errors.isteachingexperience}
            //             helperText={errors.isteachingexperience ? errors.isteachingexperience.message : ""}
            //           >
            //             <MenuItem value="" disabled>
            //               Select One
            //             </MenuItem>
            //             <MenuItem value={1}>Yes</MenuItem>
            //             <MenuItem value={0}>No</MenuItem>
            //           </TextField>
            //         </Tooltip>
            //       )}
            //     />
            //   </FormControl>
            // </Grid>
            <Tooltip title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.IS_TEACHING_EXP
              )?.fieldlabel
            } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
            <Grid item xs={12} md={6} ref={isteachingexperience}>
              <FormControl fullWidth error={!!errors.isteachingexperience}>
                <TextField
                  inputRef={isteachingexperience}
                  name="isteachingexperience"
                  select
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },

                  }}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("isteachingexperience") || watch("isteachingexperience") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                    maxLength: 50,
                  }}
                  id="isteachingexperience"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) => field?.masterfieldlabel === masterFieldLabel.IS_TEACHING_EXP
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}> *</span>
                        )}
                      {
                        masterFields?.find(
                          (field) => field?.masterfieldlabel === masterFieldLabel.IS_TEACHING_EXP
                        )?.fieldlabel
                      }
                    </span>
                  }
                  {...register("isteachingexperience", {
                    required:
                      masterFields?.find(
                        (field) => field?.masterfieldlabel === masterFieldLabel.IS_TEACHING_EXP
                      )?.ismandatory === "1"
                        ? "Teaching Experience is required"
                        : false,
                  })}
                  value={watch("isteachingexperience") ?? ""}
                  onChange={(e) => {
                    setValue("isteachingexperience", e.target.value);
                    clearErrors("isteachingexperience");
                  }}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.IS_TEACHING_EXP,
                    null,
                    isPaid
                  )}
                  error={!!errors.isteachingexperience}
                  helperText={errors.isteachingexperience ? errors.isteachingexperience.message : ""}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
</Tooltip>

          )}
        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.IS_APPLIED_ELCT
        ) && (
            // <Grid item xs={12} md={6} ref={isappliedforelct}>
            //   <FormControl fullWidth error={!!errors.isappliedforelct}>
            //     <Controller
            //       name="isappliedforelct"
            //       control={control}
            //       rules={{
            //         required:
            //           masterFields?.find(
            //             (field) =>
            //               field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_ELCT
            //           )?.ismandatory === "1"
            //             ? "Applied for ELCT is required"
            //             : false,
            //       }}
            //       render={({ field }) => (
            //         <Tooltip title={
            //           masterFields?.find(
            //             (field) =>
            //               field?.masterfieldlabel ===
            //               masterFieldLabel.IS_APPLIED_ELCT
            //           )?.fieldlabel
            //         } arrow   PopperProps={{

            //           <TextField
            //             {...field} // Use the controller's field object
            //             inputRef={isappliedforelct}
            //             name="isappliedforelct"
            //             select
            //             variant="outlined"
            //             fullWidth
            //             InputLabelProps={{
            //               shrink: !!watch("isappliedforelct") || watch("isappliedforelct") === 0,
            //             }}
            //             MenuProps={{
            //               disableScrollLock: true,
            //             }}
            //             inputProps={{
            //               style: { height: "56px", boxSizing: "border-box" },
            //               maxLength: 50,
            //             }}
            //             id="isappliedforelct"
            //             label={
            //               <span>
            //                 {masterFields?.find(
            //                   (field) =>
            //                     field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_ELCT
            //                 )?.ismandatory === "1" && (
            //                     <span className="error" style={{ color: "red" }}>
            //                       {" "}*
            //                     </span>
            //                   )}
            //                 {
            //                   masterFields?.find(
            //                     (field) =>
            //                       field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_ELCT
            //                   )?.fieldlabel
            //                 }

            //               </span>
            //             }

            //             value={field.value ?? ""} // Ensure the correct value is set here
            //             disabled={isFieldDisabled(
            //               masterFields,
            //               masterFieldLabel.IS_APPLIED_ELCT,
            //               null,
            //               isPaid
            //             )}
            //             error={!!errors.isappliedforelct}
            //             helperText={errors.isappliedforelct ? errors.isappliedforelct.message : ""}
            //           >
            //             <MenuItem value="" disabled>
            //               Select One
            //             </MenuItem>
            //             <MenuItem value={1}>Yes</MenuItem>
            //             <MenuItem value={0}>No</MenuItem>
            //           </TextField>
            //         </Tooltip>
            //       )}
            //     />
            //   </FormControl>
            // </Grid>
            <Tooltip title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.IS_APPLIED_ELCT
              )?.fieldlabel
            } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
            <Grid item xs={12} md={6} ref={isappliedforelct}>
              <FormControl fullWidth error={!!errors.isappliedforelct}>
                <TextField
                  inputRef={isappliedforelct}
                  name="isappliedforelct"
                  select
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },

                  }}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("isappliedforelct") || watch("isappliedforelct") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                    maxLength: 50,
                  }}
                  id="isappliedforelct"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_ELCT
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}> *</span>
                        )}
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_ELCT
                        )?.fieldlabel
                      }
                    </span>
                  }
                  {...register("isappliedforelct", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_ELCT
                      )?.ismandatory === "1"
                        ? "Applied for ELCT is required"
                        : false,
                  })}
                  value={watch("isappliedforelct") ?? ""}
                  onChange={(e) => {
                    setValue("isappliedforelct", e.target.value);
                    clearErrors("isappliedforelct");
                  }}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.IS_APPLIED_ELCT,
                    null,
                    isPaid
                  )}
                  error={!!errors.isappliedforelct}
                  helperText={errors.isappliedforelct ? errors.isappliedforelct.message : ""}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            </Tooltip>

          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.IS_APPLIED_KSU
        ) && (
            // <Grid item xs={12} md={6} ref={isappliedforksu}>
            //   <FormControl fullWidth error={!!errors.isappliedforksu}>
            //     <Controller
            //       name="isappliedforksu"
            //       control={control}
            //       defaultValue={watch('isappliedforksu') ?? ''} // Make sure default value is taken from watch
            //       rules={{
            //         required:
            //           masterFields?.find(
            //             (field) =>
            //               field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_KSU
            //           )?.ismandatory === "1"
            //             ? "Applied for KSU is required"
            //             : false,
            //       }}
            //       render={({ field }) => (
            //         <Tooltip title={
            //           masterFields?.find(
            //             (field) =>
            //               field?.masterfieldlabel ===
            //               masterFieldLabel.IS_APPLIED_KSU
            //           )?.fieldlabel

            //           <TextField
            //             {...field} // Bind the controller's field (value, onChange, etc.)
            //             inputRef={isappliedforksu}
            //             name="isappliedforksu"
            //             select
            //             variant="outlined"
            //             fullWidth
            //             InputLabelProps={{
            //               shrink: !!field.value || field.value === 0, // Shrink label when value is set or 0
            //             }}
            //             MenuProps={{
            //               disableScrollLock: true,
            //             }}
            //             inputProps={{
            //               style: { height: "56px", boxSizing: "border-box" },
            //               maxLength: 50,
            //             }}
            //             id="isappliedforksu"
            //             label={
            //               <span>
            //                 {masterFields?.find(
            //                   (field) =>
            //                     field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_KSU
            //                 )?.ismandatory === "1" && (
            //                     <span className="error" style={{ color: "red" }}>
            //                       {" "}*
            //                     </span>
            //                   )}
            //                 {
            //                   masterFields?.find(
            //                     (field) =>
            //                       field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_KSU
            //                   )?.fieldlabel
            //                 }

            //               </span>
            //             }

            //             disabled={isFieldDisabled(
            //               masterFields,
            //               masterFieldLabel.IS_APPLIED_KSU,
            //               null,
            //               isPaid
            //             )}
            //             SelectProps={{
            //               MenuProps: { disableScrollLock: true },
            //               style: { height: "56px", boxSizing: "border-box" },
            //               onChange: (e) => {
            //                 field.onChange(e); // Handle field change
            //                 clearErrors("isappliedforksu"); // Clear errors on change
            //               },
            //             }}
            //             error={!!errors.isappliedforksu}
            //             helperText={errors.isappliedforksu ? errors.isappliedforksu.message : ""}
            //           >
            //             <MenuItem value="" disabled>
            //               Select One
            //             </MenuItem>
            //             <MenuItem value={1}>Yes</MenuItem>
            //             <MenuItem value={0}>No</MenuItem>
            //           </TextField></Tooltip>
            //       )}
            //     />
            //   </FormControl>
            // </Grid>
            <Tooltip title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.IS_APPLIED_KSU
              )?.fieldlabel
            } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
            <Grid item xs={12} md={6} ref={isappliedforksu}>
              <FormControl fullWidth error={!!errors.isappliedforksu}>
                
                  <TextField
                    inputRef={isappliedforksu}
                    name="isappliedforksu"
                    SelectProps={{
                      MenuProps: { disableScrollLock: true },
                      style: { height: "56px", boxSizing: "border-box" },

                    }}
                    select
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: !!watch("isappliedforksu") || watch("isappliedforksu") === 0,
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    id="isappliedforksu"
                    label={
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_KSU
                        )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_KSU
                          )?.fieldlabel
                        }
                      </span>
                    }
                    {...register("isappliedforksu", {
                      required:
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_KSU
                        )?.ismandatory === "1"
                          ? "Applied for KSU is required"
                          : false,
                    })}
                    value={watch("isappliedforksu") ?? ""}
                    onChange={(e) => {
                      setValue("isappliedforksu", e.target.value);
                      clearErrors("isappliedforksu");
                    }}
                    disabled={isFieldDisabled(
                      masterFields,
                      masterFieldLabel.IS_APPLIED_KSU,
                      null,
                      isPaid
                    )}
                    error={!!errors.isappliedforksu}
                    helperText={errors.isappliedforksu ? errors.isappliedforksu.message : ""}
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </TextField>
               
              </FormControl>
            </Grid>
            </Tooltip>

          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.IS_APPLIED_SPECIAL_EDU
        ) && (
            // <Grid item xs={12} md={6} ref={isappliedforspecialeducation}>
            //   <FormControl fullWidth error={!!errors.isappliedforspecialeducation}>
            //     <Controller
            //       name="isappliedforspecialeducation"
            //       control={control}
            //       defaultValue={watch('isappliedforspecialeducation') ?? ''} // Ensure the default value is from watch
            //       rules={{
            //         required:
            //           masterFields?.find(
            //             (field) =>
            //               field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_SPECIAL_EDU
            //           )?.ismandatory === "1"
            //             ? "Special Education is required"
            //             : false,
            //       }}
            //       render={({ field }) => (
            //         <Tooltip title={
            //           masterFields?.find(
            //             (field) =>
            //               field?.masterfieldlabel ===
            //               masterFieldLabel.IS_APPLIED_SPECIAL_EDU
            //           )?.fieldlabel
            //         } arrow   PopperProps={{

            //           <TextField
            //             {...field} // Bind the field's value, onChange, and other properties
            //             inputRef={isappliedforspecialeducation}
            //             name="isappliedforspecialeducation"
            //             select
            //             variant="outlined"
            //             fullWidth
            //             InputLabelProps={{
            //               shrink: !!field.value || field.value === 0, // Shrink when value is set or 0
            //             }}
            //             MenuProps={{
            //               disableScrollLock: true,
            //             }}
            //             inputProps={{
            //               style: { height: "56px", boxSizing: "border-box" },
            //               maxLength: 50,
            //             }}
            //             id="isappliedforspecialeducation"
            //             label={
            //               <span>
            //                 {masterFields?.find(
            //                   (field) =>
            //                     field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_SPECIAL_EDU
            //                 )?.ismandatory === "1" && (
            //                     <span className="error" style={{ color: "red" }}>
            //                       {" "}*
            //                     </span>
            //                   )}
            //                 {
            //                   masterFields?.find(
            //                     (field) =>
            //                       field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_SPECIAL_EDU
            //                   )?.fieldlabel
            //                 }

            //               </span>
            //             }

            //             disabled={isFieldDisabled(
            //               masterFields,
            //               masterFieldLabel.IS_APPLIED_SPECIAL_EDU,
            //               null,
            //               isPaid
            //             )}
            //             SelectProps={{
            //               MenuProps: { disableScrollLock: true },
            //               style: { height: "56px", boxSizing: "border-box" },
            //               onChange: (e) => {
            //                 setValue("specialeducationmethods", "")
            //                 field.onChange(e); // Handle field change
            //                 clearErrors("isappliedforspecialeducation"); // Clear errors when changing value
            //                 if (getValues("isappliedforspecialeducation") === 0) {
            //                   clearErrors("specialeducationmethods");

            //                 }
            //               },
            //             }}
            //             error={!!errors.isappliedforspecialeducation}
            //             helperText={errors.isappliedforspecialeducation ? errors.isappliedforspecialeducation.message : ""}
            //           >
            //             <MenuItem value="" disabled>
            //               Select One
            //             </MenuItem>
            //             <MenuItem value={1}>Yes</MenuItem>
            //             <MenuItem value={0}>No</MenuItem>
            //           </TextField>
            //         </Tooltip>
            //       )}
            //     />
            //   </FormControl>
            // </Grid>
            <Tooltip title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.IS_APPLIED_SPECIAL_EDU
              )?.fieldlabel
            } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
            <Grid item xs={12} md={6} ref={isappliedforspecialeducation}>
              <FormControl fullWidth error={!!errors.isappliedforspecialeducation}>
              
                  <TextField
                    inputRef={isappliedforspecialeducation}
                    name="isappliedforspecialeducation"
                    select
                    SelectProps={{
                      MenuProps: { disableScrollLock: true },
                      style: { height: "56px", boxSizing: "border-box" },

                    }}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: !!watch("isappliedforspecialeducation") || watch("isappliedforspecialeducation") === 0,
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    id="isappliedforspecialeducation"
                    label={
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_SPECIAL_EDU
                        )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_SPECIAL_EDU
                          )?.fieldlabel
                        }
                      </span>
                    }
                    {...register("isappliedforspecialeducation", {
                      required:
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_SPECIAL_EDU
                        )?.ismandatory === "1"
                          ? "Special Education is required"
                          : false,
                    })}
                    value={watch("isappliedforspecialeducation") ?? ""}
                    onChange={(e) => {
                      setValue("isappliedforspecialeducation", e.target.value);
                      clearErrors("isappliedforspecialeducation"); // Clear errors when changing value
                      if (e.target.value === "0") {
                        clearErrors("specialeducationmethods");
                      }
                      setValue("specialeducationmethods", ""); // Reset special education methods
                    }}
                    disabled={isFieldDisabled(
                      masterFields,
                      masterFieldLabel.IS_APPLIED_SPECIAL_EDU,
                      null,
                      isPaid
                    )}
                    error={!!errors.isappliedforspecialeducation}
                    helperText={errors.isappliedforspecialeducation ? errors.isappliedforspecialeducation.message : ""}
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </TextField>
               
              </FormControl>
            </Grid>
            </Tooltip>

          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SPECIAL_EDU_METHODS
        ) && (
            // <Grid item xs={12} md={6} ref={specialeducationmethods}>
            //   <FormControl fullWidth error={!!errors.specialeducationmethods}>
            //     <Controller
            //       name="specialeducationmethods"
            //       control={control}
            //       defaultValue={watch('specialeducationmethods') ?? ''} // Default value from watch
            //       rules={{
            //         required:
            //           parseInt(watch("isappliedforspecialeducation")) == 1
            //           || parseInt(getValues("isappliedforspecialeducation")) == 1

            //           && "Special education method is required"

            //       }}
            //       render={({ field }) => (
            //         <Tooltip title={
            //           masterFields?.find(
            //             (field) =>
            //               field?.masterfieldlabel ===
            //               masterFieldLabel.SPECIAL_EDU_METHODS
            //           )?.fieldlabel
            //         } arrow   PopperProps={{

            //           <TextField
            //             {...field} // Bind the field's value, onChange, and other props
            //             inputRef={specialeducationmethods}
            //             name="specialeducationmethods"
            //             select
            //             variant="outlined"
            //             fullWidth
            //             InputLabelProps={{
            //               shrink: !!field.value || field.value === 0, // Shrink when value is set or 0
            //             }}
            //             MenuProps={{
            //               disableScrollLock: true,
            //             }}
            //             inputProps={{
            //               style: { height: "56px", boxSizing: "border-box" },
            //               maxLength: 50,
            //             }}
            //             id="specialeducationmethods"
            //             label={
            //               <span>
            //                 {masterFields?.find(
            //                   (field) =>
            //                     field?.masterfieldlabel === masterFieldLabel.SPECIAL_EDU_METHODS
            //                 )?.ismandatory === "1" && (getValues("isappliedforspecialeducation") == 1)

            //                   && (
            //                     <span className="error" style={{ color: "red" }}>
            //                       {" "}*
            //                     </span>
            //                   )}
            //                 {
            //                   masterFields?.find(
            //                     (field) =>
            //                       field?.masterfieldlabel === masterFieldLabel.SPECIAL_EDU_METHODS
            //                   )?.fieldlabel
            //                 }

            //               </span>
            //             }

            //             disabled={watch("isappliedforspecialeducation") != 1 || isPaid === "1"
            //               || parseInt(getValues("isappliedforspecialeducation")) !== 1
            //             } // Disable based on the condition
            //             SelectProps={{
            //               MenuProps: { disableScrollLock: true },
            //               style: { height: "56px", boxSizing: "border-box" },
            //               onChange: (e) => {
            //                 field.onChange(e); // Handle field change
            //                 clearErrors("specialeducationmethods"); // Clear errors on change
            //               },
            //             }}
            //             error={!!errors.specialeducationmethods}
            //             helperText={errors.specialeducationmethods ? errors.specialeducationmethods.message : ""}
            //           >
            //             <MenuItem value="" disabled>
            //               Select One
            //             </MenuItem>
            //             <MenuItem value={1}>Yes</MenuItem>
            //             <MenuItem value={0}>No</MenuItem>
            //           </TextField>
            //         </Tooltip>
            //       )}
            //     />
            //   </FormControl>
            // </Grid>
            <Tooltip title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.SPECIAL_EDU_METHODS
              )?.fieldlabel
            } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
            <Grid item xs={12} md={6} ref={specialeducationmethods}>
              <FormControl fullWidth error={!!errors.specialeducationmethods}>
               
                  <TextField
                    inputRef={specialeducationmethods}
                    name="specialeducationmethods"
                    select
                    SelectProps={{
                      MenuProps: { disableScrollLock: true },
                      style: { height: "56px", boxSizing: "border-box" },

                    }}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: !!watch("specialeducationmethods") || watch("specialeducationmethods") === 0,
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    id="specialeducationmethods"
                    label={
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.SPECIAL_EDU_METHODS
                        )?.ismandatory === "1" && watch("isappliedforspecialeducation") == 1 && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel === masterFieldLabel.SPECIAL_EDU_METHODS
                          )?.fieldlabel
                        }
                      </span>
                    }
                    {...register("specialeducationmethods", {
                      required:
                        (parseInt(watch("isappliedforspecialeducation")) === 1 || parseInt(getValues("isappliedforspecialeducation")) === 1)
                          ? "Special education method is required"
                          : false,
                    })}
                    value={watch("specialeducationmethods") ?? ""}
                    onChange={(e) => {
                      setValue("specialeducationmethods", e.target.value);
                      clearErrors("specialeducationmethods"); // Clear errors on change
                    }}
                    disabled={
                      watch("isappliedforspecialeducation") != 1 || isPaid === "1" ||
                      (getValues("isappliedforspecialeducation")) != 1
                    }
                    error={!!errors.specialeducationmethods}
                    helperText={errors.specialeducationmethods ? errors.specialeducationmethods.message : ""}
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </TextField>
          
              </FormControl>
            </Grid>
            </Tooltip>

          )}

{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.PERSON_WITH_DISABILITY
        ) &&<Grid item xs={12} md={12} >
          <Typography
            pt={1}
            sx={{
              fontSize: "24px",
              color: `${cssProperties?.color?.tertiary}`,
              fontWeight: 500,
              lineHeight: "30px",
            }}
          >
            DISABILITY INFORMATION
          </Typography>
        </Grid>
}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.PERSON_WITH_DISABILITY
        ) && (
            <Grid item xs={12} md={6} ref={isdisability}>
              <FormControl fullWidth error={!!errors.isdisability}>
                <Tooltip title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.PERSON_WITH_DISABILITY
                  )?.fieldlabel
                } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                  <TextField
                    inputRef={isdisability}
                    select
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: !!watch("isdisability") || watch("isdisability") === 0,
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    // sx={{
                    //   "& .MuiOutlinedInput-notchedOutline": {
                    //     border: isFieldDisabled(
                    //       masterFields,
                    //       masterFieldLabel.PERSON_WITH_DISABILITY
                    //     )
                    //       ? "2px solid grey"
                    //       : "2px solid #3F41D1",
                    //   },
                    //   "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    //     border: isFieldDisabled(
                    //       masterFields,
                    //       masterFieldLabel.PERSON_WITH_DISABILITY
                    //     )
                    //       ? "2px solid grey"
                    //       : "2px solid #3F41D1",
                    //   },
                    // }}
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    id="isdisability"
                    label={
                      <span> {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.PERSON_WITH_DISABILITY
                      )?.ismandatory === "1" || parseInt(watch("issports")) === 1 && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.PERSON_WITH_DISABILITY
                            )?.fieldlabel
                          }
                        </span>

                      </span>
                    }
                    {...register("isdisability", {
                      required:
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.PERSON_WITH_DISABILITY
                        )?.ismandatory === "1"
                          ? "Disability status is required"
                          : false,
                    })}
                    SelectProps={{
                      MenuProps: { disableScrollLock: true },
                      style: { height: "56px", boxSizing: "border-box" },
                      onChange: (e) => {
                        handleDisability(e)
                        setValue("isdisability", parseInt(e.target.value));
                        clearErrors("isdisability")
                        clearErrors("disabilitytypeid")
                        clearErrors("pwdsubtypeid", "");
                        clearErrors("isscribe", "");
                        clearErrors("isextratime", "");
                        trigger("disabilitytypeid")
                        setValue("isscribe", "");
                        setValue("isextratime", "");
                      }
                    }}
                    value={watch("isdisability") ?? ""}
                    disabled={isFieldDisabled(
                      masterFields,
                      masterFieldLabel.PERSON_WITH_DISABILITY,
                      null,
                      isPaid
                    )}
                    error={!!errors.isdisability}
                    helperText={errors.isdisability ? errors.isdisability.message : ""}
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </TextField>
                </Tooltip>
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.TYPE_OF_DISABILITY
        ) && (
            <Tooltip
              title={
                masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.TYPE_OF_DISABILITY
                )?.fieldlabel || ""
              }
              arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
            >
              {/* <Grid item xs={12} md={6} ref={disabilitytypeid}>
                <FormControl fullWidth error={!!errors.disabilitytypeid}>

                  <Controller
                    name="disabilitytypeid"
                    control={control}
                    rules={{
                      required: parseInt(watch("isdisability")) == 1 || getValues("isdisability") == 1
                        && "Type of disability is required"

                    }}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        inputRef={disabilitytypeid}
                        select
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: !!value,
                        }}
                        inputProps={{
                          style: { height: "56px", boxSizing: "border-box" },
                          maxLength: 50,
                        }}
                        id="disabilitytypeid"
                        label={
                          <span>
                            {masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.TYPE_OF_DISABILITY
                            )?.ismandatory === "1" &&
                              watch("isdisability") == 1 && (
                                <span className="error" style={{ color: "red" }}>
                                  {" "}
                                  *
                                </span>
                              )}
                            <span>
                              {
                                masterFields?.find(
                                  (field) =>
                                    field?.masterfieldlabel ===
                                    masterFieldLabel.TYPE_OF_DISABILITY
                                )?.fieldlabel
                              }
                            </span>
                          </span>
                        }
                        value={value ?? ""}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          onChange(newValue);
                          setValue("disabilitytypeid", newValue);
                          trigger("disabilitytypeid");
                          setValue("pwdsubtypeid", "");
                          setValue("isscribe", "");
                          setValue("isextratime", "");
                          clearErrors("disabilitytypeid");

                          if (newValue && !isNaN(parseInt(newValue))) {
                            dispatch({
                              type: masterActions.GET_PWDSUB_TYPE,
                              payload: { pwdTypeID: newValue },
                            });
                          }
                        }}
                        onBlur={onBlur}
                        disabled={
                          parseInt(watch("isdisability")) !== 1 || isPaid === "1"
                        }
                        SelectProps={{
                          MenuProps: { disableScrollLock: true },
                          style: { height: "56px", boxSizing: "border-box" },
                        }}
                        error={!!error}
                        helperText={error?.message}
                      >
                        <MenuItem value="" disabled>
                          Select One
                        </MenuItem>
                        {pwdType?.map((val, i) => (
                          <MenuItem value={val?.pwdtypeid} key={i}>
                            {val?.pwdtype}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </FormControl>
              </Grid> */}
              <Grid item xs={12} md={6} ref={disabilitytypeid}>
                <FormControl fullWidth error={!!errors.disabilitytypeid}>
                  <TextField
                    inputRef={disabilitytypeid}
                    name="disabilitytypeid"
                    select
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: !!watch("disabilitytypeid"),
                    }}
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    id="disabilitytypeid"
                    label={
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.TYPE_OF_DISABILITY
                        )?.ismandatory === "1" &&
                          watch("isdisability") == 1 && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.TYPE_OF_DISABILITY
                            )?.fieldlabel
                          }
                        </span>
                      </span>
                    }
                    {...register("disabilitytypeid", {
                      validate: (value) => {
                        if (parseInt(watch("isdisability")) === 1 && !value) {
                          return "Select Type of Disability";
                        }
                        return true;
                      },
                    })}
                    value={isNaN(watch("disabilitytypeid")) ? "" : watch("disabilitytypeid") ?? ""}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setValue("disabilitytypeid", newValue);
                      trigger("disabilitytypeid");
                      setValue("pwdsubtypeid", "");
                      setValue("isscribe", "");
                      setValue("isextratime", "");
                      clearErrors("disabilitytypeid");

                      if (newValue && !isNaN(parseInt(newValue))) {
                        dispatch({
                          type: masterActions.GET_PWDSUB_TYPE,
                          payload: { pwdTypeID: newValue },
                        });
                      }
                    }}
                    disabled={
                      parseInt(watch("isdisability")) !== 1 || isPaid === "1"
                    }
                    SelectProps={{
                      MenuProps: { disableScrollLock: true },
                      style: { height: "56px", boxSizing: "border-box" },
                    }}
                    error={!!errors.disabilitytypeid}
                    helperText={errors.disabilitytypeid ? errors.disabilitytypeid.message : ""}
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    {pwdType?.map((val, i) => (
                      <MenuItem value={val?.pwdtypeid} key={i}>
                        {val?.pwdtype}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>

            </Tooltip>

          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.PWD_SUB_TYPE
        ) && (
            <Grid item xs={12} md={6} ref={pwdsubtypeid}>
              <FormControl fullWidth error={!!errors.pwdsubtypeid}>
                <Tooltip title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.PWD_SUB_TYPE
                  )?.fieldlabel
                } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                  <TextField
                    inputRef={pwdsubtypeid}
                    select
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: !!watch("pwdsubtypeid"),
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    // sx={{
                    //   "& .MuiOutlinedInput-notchedOutline": {
                    //     border: isFieldDisabled(
                    //       masterFields,
                    //       masterFieldLabel.PWD_SUB_TYPE
                    //     )
                    //       ? "2px solid grey"
                    //       : "2px solid #3F41D1",
                    //   },
                    //   "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    //     border: isFieldDisabled(
                    //       masterFields,
                    //       masterFieldLabel.PWD_SUB_TYPE
                    //     )
                    //       ? "2px solid grey"
                    //       : "2px solid #3F41D1",
                    //   },
                    // }}
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    id="pwdsubtypeid"
                    label={
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.PWD_SUB_TYPE
                        )?.ismandatory === "1" && (watch("isdisability") === 1) &&
                          !((getValues("disabilitytypeid") == "")) && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.PWD_SUB_TYPE
                            )?.fieldlabel
                          }
                        </span>

                      </span>
                    }
                    {...register("pwdsubtypeid", {
                      required:
                        parseInt(watch("isdisability")) === 1 ?
                          "Select PWD Subtype" : false,
                    })}
                    disabled={
                      parseInt(watch("isdisability")) !== 1 || isPaid === "1" || ((getValues("disabilitytypeid") == ""))
                    }
                    SelectProps={{
                      MenuProps: { disableScrollLock: true },
                      style: { height: "56px", boxSizing: "border-box" },
                      onChange: (e) => {
                        setValue("pwdsubtypeid", e.target.value);
                        trigger("pwdsubtypeid");
                        clearErrors("pwdsubtypeid")
                        let extratimerequiredvalue = pwdSubType?.find(
                          (obj) =>
                            parseInt(obj.pwdsubtypeid) === parseInt(e.target.value)
                        )?.extratimerequired;
                        setValue("isextratime", extratimerequiredvalue == 1 ? 1 : extratimerequiredvalue == 0 ? 0 : null);

                        let isscriberequiredvalue = pwdSubType?.find(
                          (obj) =>
                            parseInt(obj.pwdsubtypeid) === parseInt(e.target.value)
                        )?.scriberequired;
                        setValue("isscribe", isscriberequiredvalue == 1 ? 1 : isscriberequiredvalue == 0 ? 0 : null);
                        clearErrors("isscribe")
                        clearErrors("isextratime")
                      }
                    }}
                    error={!!errors.pwdsubtypeid}
                    helperText={errors.pwdsubtypeid ? errors.pwdsubtypeid.message : ""}
                    value={watch("pwdsubtypeid") ?? ""}
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    {pwdSubType?.map((val, i) => (
                      <MenuItem value={val?.pwdsubtypeid} key={i}>
                        {val?.pwdsubtype}
                      </MenuItem>
                    ))}
                  </TextField>
                </Tooltip>
              </FormControl>
            </Grid>
          )}

        {/* {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.REQUIRE_EXTRA_TIME
        ) && (
            <Grid item xs={12} md={6} ref={isextratime}>
              <FormControl fullWidth error={!!errors.isextratime}>

                <Controller
                  name="isextratime"
                  control={control}
                  rules={{

                    required:
                      parseInt(watch("isdisability")) === 1 ?
                        "Extra Time  is required" : false,
                  }}
                  render={({ field }) => (
                    <Tooltip title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.REQUIRE_EXTRA_TIME
                      )?.fieldlabel
                    } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                      <TextField
                        {...register("isextratime", {
                          required: masterFields?.find(
                            (field) => field?.masterfieldlabel === masterFieldLabel.IS_SPORT
                          )?.ismandatory === "1" || parseInt(watch("isdisability")) === 1 ? "Extra Time  is required" : false,
                        })}
                        inputRef={isextratime}
                        name="isextratime"
                        select
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: !!watch("isextratime") || watch("isextratime") === 0,
                        }}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        // sx={{
                        //   "& .MuiOutlinedInput-notchedOutline": {
                        //     border: isFieldDisabled(
                        //       masterFields,
                        //       masterFieldLabel.REQUIRE_EXTRA_TIME
                        //     )
                        //       ? "2px solid grey"
                        //       : "2px solid #3F41D1",
                        //   },
                        //   "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        //     border: isFieldDisabled(
                        //       masterFields,
                        //       masterFieldLabel.REQUIRE_EXTRA_TIME
                        //     )
                        //       ? "2px solid grey"
                        //       : "2px solid #3F41D1",
                        //   },
                        // }}
                        inputProps={{
                          style: { height: "56px", boxSizing: "border-box" },
                          maxLength: 50,
                        }}
                        {...field}
                        id="isextratime"
                        label={
                          <span>  {(watch("isdisability") === 1) && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                            <span>

                              {
                                masterFields?.find(
                                  (field) =>
                                    field?.masterfieldlabel ===
                                    masterFieldLabel.REQUIRE_EXTRA_TIME
                                )?.fieldlabel
                              }
                            </span>

                          </span>
                        }
                        {...field}
                        value={field.value ?? ""}
                        error={!!errors.isextratime}
                        helperText={errors.isextratime ? errors.isextratime.message : ""}
                        disabled={
                          parseInt(watch("isdisability")) !== 1 ||
                          (watch("pwdsubtypeid") &&
                            pwdSubType.find(
                              (obj) =>
                                parseInt(obj.pwdsubtypeid) ===
                                parseInt(watch("pwdsubtypeid"))
                            )?.extratimerequired !== 2) ||
                          isPaid === "1" || !(parseInt(watch("isdisability")) !== 1)
                        }

                      >
                        <MenuItem value="" disabled>
                          Select One
                        </MenuItem>
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                      </TextField>
                    </Tooltip>
                  )}
                />

              </FormControl>
            </Grid>
          )} */}
        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.REQUIRE_EXTRA_TIME
        ) && (
            <Grid item xs={12} md={6} ref={isextratime}>
              <FormControl fullWidth error={!!errors.isextratime}>
                <Tooltip
                  title={
                    masterFields?.find(
                      (field) =>
                        field.masterfieldlabel === masterFieldLabel.REQUIRE_EXTRA_TIME
                    )?.fieldlabel || ""
                  }
                  arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                >
                  <TextField
                   SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },

                  }}
                    {...register("isextratime", {
                      required:
                        parseInt(watch("isdisability")) === 1 &&
                          masterFields?.find(
                            (field) =>
                              field.masterfieldlabel === masterFieldLabel.REQUIRE_EXTRA_TIME
                          )?.ismandatory === "1" &&
                          !(getValues("pwdsubtypeid") === "")
                          ? "Select Require Extra time"
                          : false,
                    })}
                    id="isextratime"
                    name="isextratime"
                    onChange={(e) => {
                      clearErrors("isextratime"); // Clear errors on change
                      setValue("isextratime", e.target.value); // Set the value directly
                    }}
                    select
                    variant="outlined"
                    fullWidth
                    inputRef={isextratime}
                    InputLabelProps={{
                      shrink: !!watch("isextratime") || watch("isextratime") === 0,
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    value={getValues("isextratime") ?? ""}
                    error={!!errors.isextratime}
                    helperText={errors.isextratime ? errors.isextratime.message : ""}
                    disabled={
                      parseInt(watch("isdisability")) !== 1 ||
                      (watch("pwdsubtypeid") ?
                        pwdSubType.find(
                          (obj) =>
                            parseInt(obj.pwdsubtypeid) ===
                            parseInt(watch("pwdsubtypeid"))
                        )?.extratimerequired !== 2 :true)
                    }
                  
                    label={
                      <span>
                        {parseInt(watch("isdisability")) === 1 &&
                          masterFields?.find(
                            (field) =>
                              field.masterfieldlabel === masterFieldLabel.REQUIRE_EXTRA_TIME
                          )?.ismandatory === "1" &&
                          !(getValues("pwdsubtypeid") === "") && (
                            <span
                              className="error"
                              style={{ color: "red", marginRight: "4px" }}
                            >
                              *
                            </span>
                          )}
                        <span>
                          {masterFields?.find(
                            (field) =>
                              field.masterfieldlabel === masterFieldLabel.REQUIRE_EXTRA_TIME
                          )?.fieldlabel || "Extra Time"}
                        </span>
                      </span>
                    }
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </TextField>
                </Tooltip>
              </FormControl>
            </Grid>

          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.REQUIRE_SCRIBE
        ) && (
            // <Grid item xs={12} md={6} ref={isscribe}>
            //   <FormControl fullWidth error={!!errors.isscribe}>
            //     <Controller
            //       name="isscribe"
            //       control={control}
            //       rules={{
            //         required:
            //           parseInt(watch("isdisability")) === 1 &&
            //             masterFields?.find(
            //               (field) => field.masterfieldlabel === masterFieldLabel.REQUIRE_SCRIBE
            //             )?.ismandatory === "1" || !(getValues("pwdsubtypeid") == "")
            //             ? "Type of scribe is required"
            //             : false,
            //       }}
            //       render={({ field }) => (
            //         <Tooltip
            //           title={
            //             masterFields?.find(
            //               (field) => field.masterfieldlabel === masterFieldLabel.REQUIRE_SCRIBE
            //             )?.fieldlabel || ""
            //           }
            //           arrow   PopperProps={{

            //           <TextField
            //             {...field}
            //             {...register("isscribe", {
            //               required:
            //                 parseInt(watch("isdisability")) === 1 &&
            //                   masterFields?.find(
            //                     (field) =>
            //                       field.masterfieldlabel === masterFieldLabel.REQUIRE_SCRIBE
            //                   )?.ismandatory === "1" || !(getValues("pwdsubtypeid") == "")
            //                   ? "Type of scribe is required"
            //                   : false,
            //             })}
            //             onChange={(e) => {
            //               field.onChange(e)
            //               clearErrors("isscribe")
            //             }}
            //             id="isscribe"
            //             name="isscribe"
            //             select
            //             variant="outlined"
            //             fullWidth
            //             inputRef={isscribe}
            //             InputLabelProps={{
            //               shrink: !!watch("isscribe") || watch("isscribe") === 0,
            //             }}
            //             MenuProps={{
            //               disableScrollLock: true,
            //             }}
            //             inputProps={{
            //               style: { height: "56px", boxSizing: "border-box" },
            //               maxLength: 50,
            //             }}
            //             value={field.value ?? ""}
            //             error={!!errors.isscribe}
            //             helperText={
            //               errors.isscribe ? errors.isscribe.message : ""
            //             }
            //             disabled={
            //               parseInt(watch("isdisability")) !== 1 ||
            //               (watch("pwdsubtypeid") &&
            //                 pwdSubType.find(
            //                   (obj) =>
            //                     parseInt(obj.pwdsubtypeid) ===
            //                     parseInt(watch("pwdsubtypeid"))
            //                 )?.scriberequired !== 2) ||
            //               isPaid === "1"
            //               || (getValues("pwdsubtypeid") == "")
            //             }
            //             label={
            //               <span>
            //                 {parseInt(watch("isdisability")) === 1 &&
            //                   masterFields?.find(
            //                     (field) =>
            //                       field.masterfieldlabel === masterFieldLabel.REQUIRE_SCRIBE
            //                   )?.ismandatory === "1" && !((getValues("pwdsubtypeid") == "")) && (
            //                     <span
            //                       className="error"
            //                       style={{ color: "red", marginRight: "4px" }}
            //                     >
            //                       *
            //                     </span>
            //                   )}
            //                 <span>
            //                   {masterFields?.find(
            //                     (field) =>
            //                       field.masterfieldlabel === masterFieldLabel.REQUIRE_SCRIBE
            //                   )?.fieldlabel || "Type of Scribe"}
            //                 </span>
            //               </span>
            //             }
            //           >
            //             <MenuItem value="" disabled>
            //               Select One
            //             </MenuItem>
            //             <MenuItem value={1}>Yes</MenuItem>
            //             <MenuItem value={0}>No</MenuItem>
            //           </TextField>
            //         </Tooltip>
            //       )}
            //     />
            //   </FormControl>
            // </Grid>
            <Grid item xs={12} md={6} ref={isscribe}>
              <FormControl fullWidth error={!!errors.isscribe}>
                <Tooltip
                  title={
                    masterFields?.find(
                      (field) => field.masterfieldlabel === masterFieldLabel.REQUIRE_SCRIBE
                    )?.fieldlabel || ""
                  }
                  arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                >
                  <TextField
                   SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },

                  }}
                    {...register("isscribe", {
                      required:
                        parseInt(watch("isdisability")) === 1 &&
                          masterFields?.find(
                            (field) => field.masterfieldlabel === masterFieldLabel.REQUIRE_SCRIBE
                          )?.ismandatory === "1" || !(getValues("pwdsubtypeid") === "")
                          ? "Select Require scribe"
                          : false,
                    })}
                    onChange={(e) => {
                      clearErrors("isscribe"); // Clear errors on change
                      setValue("isscribe", e.target.value); // Set the value directly
                    }}
                    id="isscribe"
                    name="isscribe"
                    select
                    variant="outlined"
                    fullWidth
                    inputRef={isscribe}
                    InputLabelProps={{
                      shrink: !!watch("isscribe") || watch("isscribe") === 0,
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    value={getValues("isscribe") ?? ""}
                    error={!!errors.isscribe}
                    helperText={errors.isscribe ? errors.isscribe.message : ""}
                    disabled={
                      parseInt(watch("isdisability")) !== 1 ||
                      (watch("pwdsubtypeid") ?
                        pwdSubType.find(
                          (obj) =>
                            parseInt(obj.pwdsubtypeid) ===
                            parseInt(watch("pwdsubtypeid"))
                        )?.scriberequired !== 2: true)
                    }
                
                    label={
                      <span>
                        {parseInt(watch("isdisability")) === 1 &&
                          masterFields?.find(
                            (field) =>
                              field.masterfieldlabel === masterFieldLabel.REQUIRE_SCRIBE
                          )?.ismandatory === "1" &&
                          !(getValues("pwdsubtypeid") === "") && (
                            <span
                              className="error"
                              style={{ color: "red", marginRight: "4px" }}
                            >
                              *
                            </span>
                          )}
                        <span>
                          {masterFields?.find(
                            (field) =>
                              field.masterfieldlabel === masterFieldLabel.REQUIRE_SCRIBE
                          )?.fieldlabel || "Type of Scribe"}
                        </span>
                      </span>
                    }
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </TextField>
                </Tooltip>
              </FormControl>
            </Grid>

          )}

        <Grid item xs={12} md={12}>
          <Typography
            pt={1}
            sx={{
              fontSize: "24px",
              color: `${cssProperties?.color?.tertiary}`,
              fontWeight: 500,
              lineHeight: "30px",
            }}
          >
            ORPHAN INFORMATION
          </Typography>
        </Grid>

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.ORPHAN
        ) && (
            // <Grid item xs={12} md={6} ref={isorphan}>
            //   <FormControl fullWidth error={!!errors.isorphan}>
            //     <Controller
            //       name="isorphan"
            //       control={control}
            //       rules={{
            //         required:
            //           masterFields?.find(
            //             (field) =>
            //               field?.masterfieldlabel === masterFieldLabel.ORPHAN
            //           )?.ismandatory === "1"
            //             ? "Orphan is required"
            //             : false,
            //       }}
            //       render={({ field }) => (
            //         <Tooltip title={
            //           masterFields?.find(
            //             (field) =>
            //               field?.masterfieldlabel ===
            //               masterFieldLabel.ORPHAN
            //           )?.fieldlabel
            //         } arrow   PopperProps={{

            //           <TextField
            //             inputRef={isorphan}
            //             name="isorphan"
            //             select
            //             variant="outlined"
            //             fullWidth
            //             InputLabelProps={{
            //               shrink: !!watch("isorphan") || watch("isorphan") === 0,
            //             }}
            //             MenuProps={{
            //               disableScrollLock: true,
            //             }}
            //             sx={{
            //               "& .MuiOutlinedInput-notchedOutline": {
            //                 border: isFieldDisabled(
            //                   masterFields,
            //                   masterFieldLabel.ORPHAN
            //                 )
            //                   ? "2px solid grey"
            //                   : "2px solid #3F41D1",
            //               },
            //               "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            //                 border: isFieldDisabled(
            //                   masterFields,
            //                   masterFieldLabel.ORPHAN
            //                 )
            //                   ? "2px solid grey"
            //                   : "2px solid #3F41D1",
            //               },
            //             }}
            //             inputProps={{
            //               style: { height: "56px", boxSizing: "border-box" },
            //               maxLength: 50,
            //             }}
            //             {...field}
            //             id="isorphan"
            //             label={
            //               <span>
            //                 {masterFields?.find(
            //                   (field) =>
            //                     field?.masterfieldlabel === masterFieldLabel.ORPHAN
            //                 )?.ismandatory === "1" && (
            //                     <span className="error" style={{ color: "red" }}>
            //                       {" "}
            //                       *
            //                     </span>
            //                   )}
            //                 <span>
            //                   {
            //                     masterFields?.find(
            //                       (field) =>
            //                         field?.masterfieldlabel ===
            //                         masterFieldLabel.ORPHAN
            //                     )?.fieldlabel
            //                   }
            //                 </span>

            //               </span>
            //             }
            //             SelectProps={{
            //               MenuProps: { disableScrollLock: true },
            //               style: { height: "56px", boxSizing: "border-box" },
            //               onChange: (e) => {
            //                 setValue("isorphan", e.target.value);
            //                 setValue("orphantype", "");
            //                 clearErrors("isorphan")
            //                 if (getValues("isorphan") === 0) {
            //                   clearErrors("orphantype")
            //                 }
            //               },
            //             }}

            //             value={watch("isorphan") ?? ""}
            //             disabled={isFieldDisabled(
            //               masterFields,
            //               masterFieldLabel.ORPHAN,
            //               null,
            //               isPaid
            //             )}
            //             error={!!errors.isorphan}
            //             helperText={errors.isorphan ? errors.isorphan.message : ""}
            //           >
            //             <MenuItem value="" disabled>
            //               Select One
            //             </MenuItem>
            //             <MenuItem value={1}>Yes</MenuItem>
            //             <MenuItem value={0}>No</MenuItem>
            //           </TextField></Tooltip>
            //       )}
            //     />

            //   </FormControl>
            // </Grid>
            <Grid item xs={12} md={6} ref={isorphan}>
              <FormControl fullWidth error={!!errors.isorphan}>
                <Tooltip
                  title={
                    masterFields?.find(
                      (field) => field?.masterfieldlabel === masterFieldLabel.ORPHAN
                    )?.fieldlabel
                  }
                  arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                >
                  <TextField
                    {...register("isorphan", {
                      required:

                        "Orphan is required"

                    })}
                    inputRef={isorphan}
                    name="isorphan"
                    select
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: !!watch("isorphan") || watch("isorphan") === 0,
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: isFieldDisabled(
                          masterFields,
                          masterFieldLabel.ORPHAN
                        )
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: isFieldDisabled(
                          masterFields,
                          masterFieldLabel.ORPHAN
                        )
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                      },
                    }}
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    id="isorphan"
                    label={
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.ORPHAN
                        )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel === masterFieldLabel.ORPHAN
                            )?.fieldlabel
                          }
                        </span>
                      </span>
                    }
                    SelectProps={{
                      MenuProps: { disableScrollLock: true },
                      style: { height: "56px", boxSizing: "border-box" },
                      onChange: (e) => {
                        const value = e.target.value;
                        setValue("isorphan", value);
                        setValue("orphantype", "");
                        clearErrors("isorphan");
                        if (value === 0) {
                          clearErrors("orphantype");
                        }
                      },
                    }}
                    value={watch("isorphan") ?? ""}
                    disabled={isFieldDisabled(
                      masterFields,
                      masterFieldLabel.ORPHAN,
                      null,
                      isPaid
                    )}
                    error={!!errors.isorphan}
                    helperText={errors.isorphan ? errors.isorphan.message : ""}
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </TextField>
                </Tooltip>
              </FormControl>
            </Grid>

          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.ORPHAN_TYPE
        ) && (
            // <Grid item xs={12} md={6} ref={orphantype}>
            //   <FormControl fullWidth error={!!errors.orphantype}>

            //     <Controller
            //       name="orphantype"
            //       control={control}
            //       rules={{
            //         required:
            //           watch("isorphan") === 1 ? "Orphan type is required" : false,
            //       }}
            //       render={({ field }) => (
            //         <Tooltip title={
            //           masterFields?.find(
            //             (field) =>
            //               field?.masterfieldlabel ===
            //               masterFieldLabel.ORPHAN_TYPE
            //           )?.fieldlabel
            //         } arrow   PopperProps={{

            //           <TextField
            //             inputRef={orphantype}
            //             name="orphantype"
            //             select
            //             variant="outlined"
            //             fullWidth
            //             InputLabelProps={{
            //               shrink: !!watch("orphantype") || watch("orphantype") === 0, // Shrink label when value is set or 0
            //             }}
            //             MenuProps={{
            //               disableScrollLock: true,
            //             }}
            //             sx={{
            //               "& .MuiOutlinedInput-notchedOutline": {
            //                 border: isFieldDisabled(
            //                   masterFields,
            //                   masterFieldLabel.ORPHAN_TYPE
            //                 )
            //                   ? "2px solid grey"
            //                   : "2px solid #3F41D1",
            //               },
            //               "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            //                 border: isFieldDisabled(
            //                   masterFields,
            //                   masterFieldLabel.ORPHAN_TYPE
            //                 )
            //                   ? "2px solid grey"
            //                   : "2px solid #3F41D1",
            //               },
            //             }}
            //             inputProps={{
            //               style: { height: "56px", boxSizing: "border-box" },
            //               maxLength: 50,
            //             }}
            //             {...field}
            //             id="orphantype"
            //             label={
            //               <span>
            //                 <span>
            //                   {masterFields?.find(
            //                     (field) =>
            //                       field?.masterfieldlabel ===
            //                       masterFieldLabel.ORPHAN_TYPE
            //                   )?.ismandatory === "1" && (getValues("isorphan") === 1) && (
            //                       <span className="error" style={{ color: "red" }}>
            //                         {" "}
            //                         *
            //                       </span>
            //                     )}
            //                   {
            //                     masterFields?.find(
            //                       (field) =>
            //                         field?.masterfieldlabel ===
            //                         masterFieldLabel.ORPHAN_TYPE
            //                     )?.fieldlabel
            //                   }
            //                 </span>

            //               </span>
            //             }
            //             value={field.value ?? ""}
            //             error={!!errors.orphantype}
            //             helperText={errors.orphantype ? errors.orphantype.message : ""}
            //             disabled={watch("isorphan") !== 1 || isPaid === "1"}
            //           >
            //             <MenuItem value="" disabled>
            //               Select One
            //             </MenuItem>
            //             <MenuItem value={1}>A</MenuItem>
            //             <MenuItem value={2}>B</MenuItem>
            //             <MenuItem value={3}>C</MenuItem>
            //           </TextField></Tooltip>
            //       )}
            //     />

            //   </FormControl>
            // </Grid>
            <Grid item xs={12} md={6} ref={orphantype}>
              <FormControl fullWidth error={!!errors.orphantype}>
                <Tooltip
                  title={
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.ORPHAN_TYPE
                    )?.fieldlabel
                  }
                  arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                >
                  <TextField
                    {...register("orphantype", {
                      required:
                        watch("isorphan") === 1 ? "Orphan type is required" : false,

                    })}
                    inputRef={orphantype}
                    name="orphantype"
                    select
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: !!watch("orphantype") || watch("orphantype") === 0, // Shrink label when value is set or 0
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: isFieldDisabled(
                          masterFields,
                          masterFieldLabel.ORPHAN_TYPE
                        )
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: isFieldDisabled(
                          masterFields,
                          masterFieldLabel.ORPHAN_TYPE
                        )
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                      },
                    }}
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    id="orphantype"
                    SelectProps={{
                      MenuProps: { disableScrollLock: true },
                      style: { height: "56px", boxSizing: "border-box" },

                    }}
                    label={
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.ORPHAN_TYPE
                        )?.ismandatory === "1" && watch("isorphan") === 1 && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.ORPHAN_TYPE
                          )?.fieldlabel
                        }
                      </span>
                    }
                    value={watch("orphantype") ?? ""}
                    error={!!errors.orphantype}
                    helperText={errors.orphantype ? errors.orphantype.message : ""}
                    disabled={watch("isorphan") !== 1 || isPaid === "1"}
                    onChange={(e) => {
                      const value = e.target.value;
                      setValue("orphantype", value);
                      // Clear errors if the value is valid
                      if (watch("isorphan") === 1) {
                        clearErrors("orphantype");
                      }
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    <MenuItem value={1}>A</MenuItem>
                    <MenuItem value={2}>B</MenuItem>
                    <MenuItem value={3}>C</MenuItem>
                  </TextField>
                </Tooltip>
              </FormControl>
            </Grid>

          )}

        <Grid item xs={12} md={12}>
          <Typography
            pt={1}
            sx={{
              fontSize: "24px",
              color: `${cssProperties?.color?.tertiary}`,
              fontWeight: 500,
              lineHeight: "30px",
            }}
          >
            CATEGORY INFORMATION
          </Typography>
        </Grid>

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.IS_MH_CANDIDATE
        ) && (
            <Grid item xs={12} md={6} ref={ismhcandidate}>
              <FormControl fullWidth error={!!errors.ismhcandidate}>
                <Tooltip title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.IS_MH_CANDIDATE
                  )?.fieldlabel
                } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                  <TextField
                    inputRef={ismhcandidate}
                    select
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: !!watch("ismhcandidate") || watch("ismhcandidate") === 0, // Shrink label when value is set or 0
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: isFieldDisabled(
                          masterFields,
                          masterFieldLabel.IS_MH_CANDIDATE
                        )
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: isFieldDisabled(
                          masterFields,
                          masterFieldLabel.IS_MH_CANDIDATE
                        )
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                      },
                    }}
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                    }}
                    disabled={isPaid === "1"}
                    id="ismhcandidate"
                    label={
                      <span>

                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>

                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel?.IS_MH_CANDIDATE
                        )?.fieldlabel}
                      </span>
                    }
                    {...register("ismhcandidate", {
                      required:
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel?.IS_MH_CANDIDATE
                        )?.ismandatory === "1"
                          ? "MH Candidate is required"
                          : false,
                    })}
                    SelectProps={{
                      MenuProps: { disableScrollLock: true },
                      style: { height: "56px", boxSizing: "border-box" },
                      onChange: (e) => {
                        setValue("ismhcandidate", e.target.value);
                        trigger("ismhcandidate");
                        trigger("ismhcandidate");
                        setValue("categoryid", "");
                        setValue("ewsdistrictid", "");
                        setValue("linguisticminorityid", "");
                        setValue("religiousminorityid", "");
                        setValue("ewstalukaid", "");
                        setValue("castecertificatestatus", "");
                        setValue("cvcdistrictidismhcandidate", "");
                        setValue("nclauthorityid", "");
                        setValue("castename", "");
                        setValue("casteid", "");
                        setValue("appliedforews", "");
                        setValue("ewscertificatestatus", "");
                        setValue("ewsapplicationdate", "");
                        setValue("ewsapplicationno", "");
                        setValue("castevaliditystatus", "");
                        setValue("cvcapplicationno", "");
                        setValue("cvcapplicationdate", "");
                        setValue("cvcauthority", "");
                        setValue("castecertificateno", "");
                        setValue("castevaliditycertificateno", "");
                        setValue("cvcname", "");
                        setValue("castecertificateappdate", "");
                        setValue("nclstatus", "");
                        setValue("nclapplicationdate", "");
                        setValue("nclauthorityid", "");
                        setValue("castecertificatereceiptno", "");
                        if (getValues("ismhcandidate") !== 1) {
                          clearErrors("casteid")
                          clearErrors("castename")
                          clearErrors("linguisticminorityid")
                          clearErrors("religiousminorityid")
                          clearErrors("categoryid")
                          setValue("isminority", 0)
                          clearErrors("appliedforews")
                          clearErrors("ewscertificatestatus")
                          clearErrors("ewsapplicationno")
                          clearErrors("ewsapplicationdate")
                          clearErrors("ewsdistrictid")
                          clearErrors("ewstalukaid")
                          clearErrors("castecertificatestatus")
                          clearErrors("castecertificateno")
                          clearErrors("castecertificatereceiptno")
                          clearErrors("castecertificateappdate")
                          clearErrors("castevaliditystatus")
                          clearErrors("castevaliditycertificateno")
                          clearErrors("cvcapplicationno")
                          clearErrors("cvcapplicationdate")
                          clearErrors("cvcauthority")
                          clearErrors("cvcdistrictid")
                          clearErrors("cvcname")
                          clearErrors("nclstatus")
                          clearErrors("nclapplicationno")
                          clearErrors("nclapplicationdate")
                          clearErrors("nclauthorityid")

                        }
                      },
                    }}
                    error={!!errors.ismhcandidate}
                    helperText={errors.ismhcandidate ? errors.ismhcandidate.message : ""}
                    value={watch("ismhcandidate") ?? ""}
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </TextField>
                </Tooltip>
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.CATEGORY
        ) && (
            <Grid item xs={12} md={6} ref={categoryid}>
              <FormControl fullWidth error={!!errors.categoryid}>
                <Tooltip title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CATEGORY
                  )?.fieldlabel
                } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                  <TextField
                    inputRef={categoryid}
                    select
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: !!watch("categoryid") || watch("categoryid") === 0,
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border:
                          parseInt(watch("ismhcandidate")) !== 1
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border:
                          parseInt(watch("ismhcandidate")) !== 1
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                      },
                    }}
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                    }}
                    id="categoryid"
                    label={
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.CATEGORY
                        )?.ismandatory === "1" && !(parseInt(watch("ismhcandidate")) !== 1) && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel?.CATEGORY
                        )?.fieldlabel}
                      </span>


                    }
                    {...register("categoryid", {
                      required:
                        parseInt(watch("ismhcandidate")) === 1 &&
                        "Category is required",
                    })}
                    disabled={
                      parseInt(watch("ismhcandidate")) !== 1 || isPaid === "1"
                    }
                    onChange={(e) => {

                    }}
                    value={watch("categoryid") ?? ""}
                    SelectProps={{
                      MenuProps: { disableScrollLock: true },
                      style: { height: "56px", boxSizing: "border-box" },
                      onChange: (e) => {
                        dispatch({ type: masterActions.GET_CASTE_BY_ID, payload: e.target.value })
                        setValue("categoryid", e.target.value);
                        setValue("ewsdistrictid", "");
                        setValue("ewstalukaid", "");
                        setValue("castecertificatestatus", "");
                        setValue("cvcdistrictid", "");
                        setValue("nclauthorityid", "");
                        setValue("castename", "");
                        setValue("casteid", "");
                        setValue("appliedforews", "");
                        setValue("ewscertificatestatus", "");
                        setValue("ewsapplicationdate", "");
                        setValue("ewsapplicationno", "");
                        setValue("castevaliditystatus", "");
                        setValue("cvcapplicationno", "");
                        setValue("cvcapplicationdate", "");
                        setValue("cvcauthority", "");
                        setValue("castecertificateno", "");
                        setValue("castevaliditycertificateno", "");
                        setValue("cvcname", "");
                        setValue("castecertificateappdate", "");
                        setValue("nclstatus", "");
                        setValue("nclapplicationdate", "");
                        setValue("nclapplicationno", "");
                        setValue("nclauthorityid", "");
                        setValue("castecertificateno", "")
                        setValue("castecertificatereceiptno", "")
                        clearErrors("castecertificateno")
                        clearErrors("castecertificatereceiptno")
                        trigger("categoryid");
                        if (getValues("categoryid") === 1) {
                          clearErrors("castename")
                          clearErrors("castecertificateno")
                          clearErrors("castecertificatereceiptno")
                          clearErrors("castecertificatestatus")
                          clearErrors("castecertificateappdate")
                          clearErrors("castevaliditycertificateno")
                        }
                        if (getValues("categoryid") === 0) {
                          clearErrors("ewstalukaid")

                        }
                        if (getValues("categoryid") <= 1) {
                          clearErrors("casteid")
                        }
                        if (getValues("categoryid") !== 1) {
                          clearErrors("appliedforews")
                          clearErrors("ewsapplicationno")
                          clearErrors("ewscertificatestatus")
                          clearErrors("ewsapplicationdate")
                          clearErrors("ewsdistrictid")
                          clearErrors("ewstalukaid")

                        }
                        if (getValues("categoryid") === 1) {
                          clearErrors("castevaliditystatus")
                          clearErrors("cvcapplicationno")
                          clearErrors("cvcapplicationdate")
                          clearErrors("cvcauthority")
                          clearErrors("cvcdistrictid")
                          clearErrors("cvcname")
                          clearErrors("nclstatus")
                          clearErrors("nclapplicationno")
                          clearErrors("nclapplicationdate")
                          clearErrors("nclauthorityid")

                        }
                        if (getValues("categoryid") === 2) {
                          clearErrors("nclstatus")
                          clearErrors("nclapplicationno")
                          clearErrors("nclapplicationdate")
                          clearErrors("nclauthorityid")

                        }
                        if (getValues("categoryid") === 3) {
                          clearErrors("nclstatus")
                          clearErrors("nclapplicationno")
                          clearErrors("nclapplicationdate")
                          clearErrors("nclauthorityid")

                        }

                      },
                    }}
                    error={!!errors.categoryid}
                    helperText={errors.categoryid ? errors.categoryid.message : ""}
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>

                    {category?.map((val) => (
                      <MenuItem key={val?.categoryid} value={val?.categoryid}>
                        {val?.category}
                      </MenuItem>
                    ))}
                  </TextField>
                </Tooltip>
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.ENTER_CASTE_NAME
        ) && (
            <Grid item xs={12} md={6} ref={castename}>
              <Tooltip title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.ENTER_CASTE_NAME
                )?.fieldlabel
              } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                <TextField
                  inputRef={castename}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) !== 1
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) !== 1
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                  }}
                  fullWidth
                  variant="outlined"
                  type="text"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.ENTER_CASTE_NAME
                      )?.ismandatory === "1" && !(parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) !== 1 ||
                        isPaid === "1") && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.ENTER_CASTE_NAME
                        )?.fieldlabel
                      }

                    </span>
                  }
                  placeholder="Enter Caste Name"
                  {...register("castename", {
                    required:
                      parseInt(watch("categoryid")) === 1
                        ? "\u00A0 \u00A0 Caste name is required"
                        : false,
                  })}
                  // onInput={AlphabetsValidation}
                  error={!!errors.castename}
                  helperText={errors.castename?.message}
                  FormHelperTextProps={{
                    style: { margin: 0 },
                  }}
                  InputLabelProps={{
                    shrink: !!watch("castename"),
                  }}
                  disabled={
                    parseInt(watch("ismhcandidate")) !== 1 ||
                    parseInt(watch("categoryid")) !== 1 ||
                    isPaid === "1"
                  }
                />
              </Tooltip>
            </Grid>
          )}
        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SELECT_CASTE
        ) && (
            // <Grid item xs={12} md={6} ref={casteid}>
            //   <FormControl fullWidth error={!!errors.casteid}>

            //     <Controller
            //       name="casteid"
            //       control={control}
            //       rules={{
            //         required:
            //           parseInt(watch("categoryid")) > 1
            //             ? "Caste is required"
            //             : false,
            //       }}
            //       render={({ field }) => (
            //         <Tooltip title={
            //           masterFields?.find(
            //             (field) =>
            //               field?.masterfieldlabel ===
            //               masterFieldLabel.SELECT_CASTE
            //           )?.fieldlabel
            //         } arrow   PopperProps={{

            //           <TextField
            //             inputRef={casteid}
            //             select
            //             variant="outlined"
            //             fullWidth
            //             InputLabelProps={{
            //               shrink: !!watch("casteid") || watch("casteid") === 0,
            //             }}
            //             MenuProps={{
            //               disableScrollLock: true,
            //             }}
            //             sx={{
            //               "& .MuiOutlinedInput-notchedOutline": {
            //                 border:
            //                   parseInt(watch("ismhcandidate")) !== 1 ||
            //                     parseInt(watch("categoryid")) <= 1
            //                     ? "2px solid grey"
            //                     : "2px solid #3F41D1",
            //               },
            //               "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            //                 border:
            //                   parseInt(watch("ismhcandidate")) !== 1 ||
            //                     parseInt(watch("categoryid")) <= 1
            //                     ? "2px solid grey"
            //                     : "2px solid #3F41D1",
            //               },
            //             }}
            //             inputProps={{
            //               style: { height: "56px", boxSizing: "border-box" },
            //               maxLength: 50,
            //             }}
            //             {...field}
            //             id="casteid"
            //             name="casteid"
            //             label={
            //               <span>
            //                 {masterFields?.find(
            //                   (field) =>
            //                     field?.masterfieldlabel ===
            //                     masterFieldLabel.SELECT_CASTE
            //                 )?.ismandatory === "1" && !(parseInt(watch("ismhcandidate")) !== 1 ||
            //                   parseInt(watch("categoryid")) <= 1 ||
            //                   isNaN(parseInt(watch("categoryid"))) ||
            //                   isPaid === "1") && (
            //                     <span className="error" style={{ color: "red" }}>
            //                       {" "}
            //                       *
            //                     </span>
            //                   )}
            //                 <span>
            //                   {
            //                     masterFields?.find(
            //                       (field) =>
            //                         field?.masterfieldlabel ===
            //                         masterFieldLabel.SELECT_CASTE
            //                     )?.fieldlabel
            //                   }
            //                 </span>

            //               </span>
            //             }
            //             value={field.value ?? ""}
            //             disabled={
            //               parseInt(watch("ismhcandidate")) !== 1 ||
            //               parseInt(watch("categoryid")) <= 1 ||
            //               isNaN(parseInt(watch("categoryid"))) ||
            //               isPaid === "1"
            //             }
            //             SelectProps={{
            //               MenuProps: { disableScrollLock: true },
            //               style: { height: "56px", boxSizing: "border-box" },
            //               onChange: (e) => {
            //                 setValue("casteid", e.target.value)
            //                 clearErrors("casteid")
            //                 setValue("castecertificateno", "")
            //                 setValue("castecertificatereceiptno", "")
            //               },
            //             }}
            //             error={!!errors.casteid}
            //             helperText={errors.casteid ? errors.casteid.message : ""}
            //           >
            //             <MenuItem value="" disabled>
            //               Select One
            //             </MenuItem>
            //             {caste?.map((val) => (
            //               <MenuItem key={val?.casteid} value={val?.casteid}>
            //                 {val?.caste}
            //               </MenuItem>
            //             ))}
            //           </TextField>
            //         </Tooltip>
            //       )}
            //     />

            //   </FormControl>
            // </Grid>
            <Grid item xs={12} md={6} ref={casteid}>
              <FormControl fullWidth error={!!errors.casteid}>
                <Tooltip
                  title={
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.SELECT_CASTE
                    )?.fieldlabel
                  }
                  arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                >
                  <TextField
                    {...register("casteid", {

                      required:
                        parseInt(watch("categoryid")) > 1
                          ? "Caste is required"
                          : false,
                    })}
                    inputRef={casteid}
                    select
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: !!watch("casteid") || watch("casteid") === 0, // Shrink label when value is set or 0
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border:
                          parseInt(watch("ismhcandidate")) !== 1 ||
                            parseInt(watch("categoryid")) <= 1
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border:
                          parseInt(watch("ismhcandidate")) !== 1 ||
                            parseInt(watch("categoryid")) <= 1
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                      },
                    }}
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    id="casteid"
                    name="casteid"
                    label={
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.SELECT_CASTE
                        )?.ismandatory === "1" && !(parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) <= 1 ||
                          isNaN(parseInt(watch("categoryid"))) ||
                          isPaid === "1") && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.SELECT_CASTE
                            )?.fieldlabel
                          }
                        </span>
                      </span>
                    }
                    value={watch("casteid") ?? ""}
                    error={!!errors.casteid}
                    helperText={errors.casteid ? errors.casteid.message : ""}
                    disabled={
                      parseInt(watch("ismhcandidate")) !== 1 ||
                      parseInt(watch("categoryid")) <= 1 ||
                      isNaN(parseInt(watch("categoryid"))) ||
                      isPaid === "1"
                    }
                    SelectProps={{
                      MenuProps: { disableScrollLock: true },
                      style: { height: "56px", boxSizing: "border-box" },

                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setValue("casteid", value);
                      clearErrors("casteid"); // Clear error when value is set
                      setValue("castecertificateno", "");
                      setValue("castecertificatereceiptno", "");
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    {caste?.map((val) => (
                      <MenuItem key={val?.casteid} value={val?.casteid}>
                        {val?.caste}
                      </MenuItem>
                    ))}
                  </TextField>
                </Tooltip>
              </FormControl>
            </Grid>

          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.EWS
        ) && (
            // <Grid item xs={12} md={6} ref={appliedforews}>
            //   <FormControl fullWidth error={!!errors.appliedforews}>

            //     <Controller
            //       name="appliedforews"
            //       control={control}
            //       rules={{
            //         required:
            //           parseInt(watch("categoryid")) === 1
            //             ? "Applied for EWS is required"
            //             : false,
            //       }}
            //       render={({ field }) => (
            //         <Tooltip title={
            //           masterFields?.find(
            //             (field) =>
            //               field?.masterfieldlabel ===
            //               masterFieldLabel.EWS
            //           )?.fieldlabel
            //         } arrow   PopperProps={{

            //           <TextField
            //             inputRef={appliedforews}
            //             name="appliedforews"
            //             select
            //             variant="outlined"
            //             fullWidth
            //             InputLabelProps={{
            //               shrink: !!watch("appliedforews") || watch("appliedforews") === 0,
            //             }}
            //             MenuProps={{
            //               disableScrollLock: true,
            //             }}
            //             sx={{
            //               "& .MuiOutlinedInput-notchedOutline": {
            //                 border:
            //                   parseInt(watch("ismhcandidate")) !== 1 ||
            //                     parseInt(watch("categoryid")) !== 1
            //                     ? "2px solid grey"
            //                     : "2px solid #3F41D1",
            //               },
            //               "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            //                 border:
            //                   parseInt(watch("ismhcandidate")) !== 1 ||
            //                     parseInt(watch("categoryid")) !== 1
            //                     ? "2px solid grey"
            //                     : "2px solid #3F41D1",
            //               },
            //             }}
            //             inputProps={{
            //               style: { height: "56px", boxSizing: "border-box" },
            //               maxLength: 50,
            //             }}
            //             {...field}
            //             id="appliedforews"
            //             label={
            //               <span>
            //                 {masterFields?.find(
            //                   (field) =>
            //                     field?.masterfieldlabel === masterFieldLabel.EWS
            //                 )?.ismandatory === "1" && !(parseInt(watch("ismhcandidate")) !== 1 ||
            //                   parseInt(watch("categoryid")) !== 1 ||
            //                   isPaid === "1") && (
            //                     <span className="error" style={{ color: "red" }}>
            //                       {" "}
            //                       *
            //                     </span>
            //                   )}
            //                 <span>
            //                   {
            //                     masterFields?.find(
            //                       (field) =>
            //                         field?.masterfieldlabel === masterFieldLabel.EWS
            //                     )?.fieldlabel
            //                   }
            //                 </span>

            //               </span>
            //             }

            //             value={watch("appliedforews") ?? ""}
            //             disabled={
            //               parseInt(watch("ismhcandidate")) !== 1 ||
            //               parseInt(watch("categoryid")) !== 1 ||
            //               isPaid === "1"
            //             }
            //             SelectProps={{
            //               MenuProps: { disableScrollLock: true },
            //               style: { height: "56px", boxSizing: "border-box" },
            //               onChange: (e) => {
            //                 setValue("appliedforews", e.target.value);
            //                 setValue("ewsdistrictid", "");
            //                 setValue("ewstalukaid", "");
            //                 setValue("ewscertificatestatus", "");
            //                 setValue("ewsapplicationdate", "");
            //                 setValue("ewsapplicationno", "");
            //                 clearErrors("appliedforews")

            //                 dispatch({
            //                   type: masterActions.SET_EWS_TALUKA,
            //                   payload: [],
            //                 });
            //                 if (getValues("appliedforews") !== 1) {
            //                   clearErrors("ewscertificatestatus")
            //                   clearErrors("ewsapplicationno")
            //                   clearErrors("ewsapplicationdate")
            //                   clearErrors("ewsdistrictid")
            //                   clearErrors("ewstalukaid")

            //                 }
            //               },
            //             }}
            //             error={!!errors.appliedforews}
            //             helperText={errors.appliedforews ? errors.appliedforews.message : ""}
            //           >
            //             <MenuItem value="" disabled>
            //               Select One
            //             </MenuItem>
            //             <MenuItem value={1}>Yes</MenuItem>
            //             <MenuItem value={0}>No</MenuItem>
            //           </TextField>
            //         </Tooltip>
            //       )}
            //     />

            //   </FormControl>
            // </Grid>
            <Grid item xs={12} md={6} ref={appliedforews}>
              <FormControl fullWidth error={!!errors.appliedforews}>
                <Tooltip
                  title={
                    masterFields?.find(
                      (field) => field?.masterfieldlabel === masterFieldLabel.EWS
                    )?.fieldlabel
                  }
                  arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                >
                  <TextField
                    inputRef={appliedforews}
                    name="appliedforews"
                    select
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: !!watch("appliedforews") || watch("appliedforews") === 0,
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border:
                          parseInt(watch("ismhcandidate")) !== 1 ||
                            parseInt(watch("categoryid")) !== 1
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border:
                          parseInt(watch("ismhcandidate")) !== 1 ||
                            parseInt(watch("categoryid")) !== 1
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                      },
                    }}
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    {...register("appliedforews", {
                      required:
                        parseInt(watch("categoryid")) === 1
                          ? "Applied for EWS is required"
                          : false,
                    })}
                    id="appliedforews"
                    label={
                      <span>
                        {masterFields?.find(
                          (field) => field?.masterfieldlabel === masterFieldLabel.EWS
                        )?.ismandatory === "1" &&
                          !(parseInt(watch("ismhcandidate")) !== 1 ||
                            parseInt(watch("categoryid")) !== 1 ||
                            isPaid === "1") && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel === masterFieldLabel.EWS
                            )?.fieldlabel
                          }
                        </span>
                      </span>
                    }
                    value={watch("appliedforews") ?? ""}
                    disabled={
                      parseInt(watch("ismhcandidate")) !== 1 ||
                      parseInt(watch("categoryid")) !== 1 ||
                      isPaid === "1"
                    }
                    SelectProps={{
                      MenuProps: { disableScrollLock: true },
                      style: { height: "56px", boxSizing: "border-box" },
                      onChange: (e) => {
                        setValue("appliedforews", e.target.value);
                        setValue("ewsdistrictid", "");
                        setValue("ewstalukaid", "");
                        setValue("ewscertificatestatus", "");
                        setValue("ewsapplicationdate", "");
                        setValue("ewsapplicationno", "");
                        clearErrors("appliedforews");

                        dispatch({
                          type: masterActions.SET_EWS_TALUKA,
                          payload: [],
                        });
                        if (getValues("appliedforews") !== 1) {
                          clearErrors("ewscertificatestatus");
                          clearErrors("ewsapplicationno");
                          clearErrors("ewsapplicationdate");
                          clearErrors("ewsdistrictid");
                          clearErrors("ewstalukaid");
                        }
                      },
                    }}
                    error={!!errors.appliedforews}
                    helperText={
                      errors.appliedforews ? errors.appliedforews.message : ""
                    }
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </TextField>
                </Tooltip>
              </FormControl>
            </Grid>

          )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.EWS_CERTIFICATE_STATUS
        ) && (
            // <Grid item xs={12} md={6} ref={ewscertificatestatus}>
            //   <FormControl fullWidth error={!!errors.ewscertificatestatus}>

            //     <Controller
            //       name="ewscertificatestatus"
            //       control={control}
            //       rules={{
            //         required:
            //           watch("appliedforews") === 1 ? "EWS Certificate status is required" : false,
            //       }}
            //       render={({ field }) => (
            //         <Tooltip title={masterFields?.find(
            //           (field) =>
            //             field?.masterfieldlabel ===
            //             masterFieldLabel.EWS_CERTIFICATE_STATUS
            //         )?.fieldlabel} arrow   PopperProps={{

            //           <TextField
            //             inputRef={ewscertificatestatus}
            //             name="ewscertificatestatus"
            //             select
            //             variant="outlined"
            //             fullWidth
            //             InputLabelProps={{
            //               shrink: !!watch("ewscertificatestatus") ||
            //                 watch("ewscertificatestatus") === 0,
            //             }}
            //             MenuProps={{
            //               disableScrollLock: true,
            //             }}
            //             sx={{
            //               "& .MuiOutlinedInput-notchedOutline": {
            //                 border:
            //                   parseInt(watch("ismhcandidate")) !== 1 ||
            //                     parseInt(watch("categoryid")) !== 1 ||
            //                     parseInt(watch("appliedforews")) !== 1
            //                     ? "2px solid grey"
            //                     : "2px solid #3F41D1",
            //               },
            //               "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            //                 border:
            //                   parseInt(watch("ismhcandidate")) !== 1 ||
            //                     parseInt(watch("categoryid")) !== 1 ||
            //                     parseInt(watch("appliedforews")) !== 1
            //                     ? "2px solid grey"
            //                     : "2px solid #3F41D1",
            //               },
            //             }}
            //             inputProps={{
            //               style: { height: "56px", boxSizing: "border-box" },
            //               maxLength: 50,
            //             }}
            //             {...field}
            //             id="ewscertificatestatus"
            //             label={
            //               <span>
            //                 {masterFields?.find(
            //                   (field) =>
            //                     field?.masterfieldlabel ===
            //                     masterFieldLabel.EWS_CERTIFICATE_STATUS
            //                 )?.ismandatory === "1" && !(parseInt(watch("ismhcandidate")) !== 1 ||
            //                   parseInt(watch("categoryid")) !== 1 ||
            //                   parseInt(watch("appliedforews")) !== 1 ||
            //                   isPaid === "1") && (
            //                     <span className="error" style={{ color: "red" }}>
            //                       {" "}
            //                       *
            //                     </span>
            //                   )}
            //                 <span>
            //                   {
            //                     masterFields?.find(
            //                       (field) =>
            //                         field?.masterfieldlabel ===
            //                         masterFieldLabel.EWS_CERTIFICATE_STATUS
            //                     )?.fieldlabel
            //                   }
            //                 </span>

            //               </span>
            //             }
            //             {...field}

            //             value={watch("ewscertificatestatus") ?? ""}
            //             disabled={
            //               parseInt(watch("ismhcandidate")) !== 1 ||
            //               parseInt(watch("categoryid")) !== 1 ||
            //               parseInt(watch("appliedforews")) !== 1 ||
            //               isPaid === "1"
            //             }
            //             SelectProps={{
            //               MenuProps: { disableScrollLock: true },
            //               style: { height: "56px", boxSizing: "border-box" },
            //               onChange: (e) => {
            //                 setValue("ewscertificatestatus", e.target.value);
            //                 setValue("ewsdistrictid", "");
            //                 setValue("ewstalukaid", "");
            //                 setValue("ewsapplicationdate", "");
            //                 setValue("ewsapplicationno", "");
            //                 dispatch({
            //                   type: masterActions.SET_EWS_TALUKA,
            //                   payload: [],
            //                 });
            //                 if (getValues("ewscertificatestatus") !== 0) {
            //                   clearErrors("ewsapplicationno")
            //                   clearErrors("ewsapplicationdate")
            //                   clearErrors("ewsdistrictid")
            //                   clearErrors("ewstalukaid")
            //                 }
            //               },
            //             }}
            //             error={!!errors.ewscertificatestatus}
            //             helperText={errors.ewscertificatestatus ? errors.ewscertificatestatus.message : ""}
            //           >
            //             <MenuItem value="" disabled>
            //               Select One
            //             </MenuItem>
            //             <MenuItem value={1}>Available</MenuItem>
            //             <MenuItem value={0}> Applied But Not Received</MenuItem>
            //             <MenuItem value={2}> Not Available</MenuItem>
            //           </TextField>
            //         </Tooltip>
            //       )}
            //     />

            //   </FormControl>
            // </Grid>
            <Grid item xs={12} md={6} ref={ewscertificatestatus}>
              <FormControl fullWidth error={!!errors.ewscertificatestatus}>
                <Tooltip
                  title={
                    masterFields?.find(
                      (field) => field?.masterfieldlabel === masterFieldLabel.EWS_CERTIFICATE_STATUS
                    )?.fieldlabel
                  }
                  arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                >
                  <TextField
                    inputRef={ewscertificatestatus}
                    name="ewscertificatestatus"
                    select
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: !!watch("ewscertificatestatus") || watch("ewscertificatestatus") === 0,
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border:
                          parseInt(watch("ismhcandidate")) !== 1 ||
                            parseInt(watch("categoryid")) !== 1 ||
                            parseInt(watch("appliedforews")) !== 1
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border:
                          parseInt(watch("ismhcandidate")) !== 1 ||
                            parseInt(watch("categoryid")) !== 1 ||
                            parseInt(watch("appliedforews")) !== 1
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                      },
                    }}
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    {...register("ewscertificatestatus", {
                      required: watch("appliedforews") === 1 ? "EWS Certificate status is required" : false,
                    })}
                    id="ewscertificatestatus"
                    label={
                      <span>
                        {masterFields?.find(
                          (field) => field?.masterfieldlabel === masterFieldLabel.EWS_CERTIFICATE_STATUS
                        )?.ismandatory === "1" &&
                          !(parseInt(watch("ismhcandidate")) !== 1 ||
                            parseInt(watch("categoryid")) !== 1 ||
                            parseInt(watch("appliedforews")) !== 1 ||
                            isPaid === "1") && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        <span>
                          {
                            masterFields?.find(
                              (field) => field?.masterfieldlabel === masterFieldLabel.EWS_CERTIFICATE_STATUS
                            )?.fieldlabel
                          }
                        </span>
                      </span>
                    }
                    value={watch("ewscertificatestatus") ?? ""}
                    disabled={
                      parseInt(watch("ismhcandidate")) !== 1 ||
                      parseInt(watch("categoryid")) !== 1 ||
                      parseInt(watch("appliedforews")) !== 1 ||
                      isPaid === "1"
                    }
                    SelectProps={{
                      MenuProps: { disableScrollLock: true },
                      style: { height: "56px", boxSizing: "border-box" },
                      onChange: (e) => {
                        const value = e.target.value;
                        setValue("ewscertificatestatus", value);
                        setValue("ewsdistrictid", "");
                        setValue("ewstalukaid", "");
                        setValue("ewsapplicationdate", "");
                        setValue("ewsapplicationno", "");
                        dispatch({
                          type: masterActions.SET_EWS_TALUKA,
                          payload: [],
                        });
                        if (value !== 0) {
                          clearErrors("ewsapplicationno");
                          clearErrors("ewsapplicationdate");
                          clearErrors("ewsdistrictid");
                          clearErrors("ewstalukaid");
                        }
                      },
                    }}
                    error={!!errors.ewscertificatestatus}
                    helperText={errors.ewscertificatestatus ? errors.ewscertificatestatus.message : ""}
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    <MenuItem value={1}>Available</MenuItem>
                    <MenuItem value={0}>Applied But Not Received</MenuItem>
                    <MenuItem value={2}>Not Available</MenuItem>
                  </TextField>
                </Tooltip>
              </FormControl>
            </Grid>

          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.EWS_Certificate_Application_Number
        ) && (
            <Grid item xs={12} md={6} ref={ewsapplicationno}>
              <Tooltip title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.EWS_Certificate_Application_Number
                )?.fieldlabel
              } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                <TextField
                  inputRef={ewsapplicationno}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) !== 1 ||
                          parseInt(watch("appliedforews")) !== 1 ||
                          parseInt(watch("ewscertificatestatus")) !== 0
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) !== 1 ||
                          parseInt(watch("appliedforews")) !== 1 ||
                          parseInt(watch("ewscertificatestatus")) !== 0
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                  }}
                  fullWidth
                  variant="outlined"
                  type="text"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.EWS_Certificate_Application_Number
                      )?.ismandatory === "1" && !(parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) !== 1 ||
                        parseInt(watch("appliedforews")) !== 1 ||
                        parseInt(watch("ewscertificatestatus")) !== 0 ||
                        isPaid === "1") && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.EWS_Certificate_Application_Number
                        )?.fieldlabel
                      }

                    </span>
                  }
                  placeholder="Enter Ews Certificate Application Number"
                  {...register("ewsapplicationno", {
                    required:
                      watch("ewscertificatestatus") === 0
                        ? "\u00A0 \u00A0 EWS application number is required"
                        : false,
                    pattern: {
                      value: /^[A-Za-z0-9/-]+$/,
                      message: "Only numbers, alphabets, '-' and '/' are allowed",
                    },
                  })}

                  // onInput={AlphabetsValidation}
                  error={!!errors.ewsapplicationno}
                  helperText={errors.ewsapplicationno?.message}
                  FormHelperTextProps={{
                    style: { margin: 0 },
                  }}
                  InputLabelProps={{
                    shrink: !!watch("ewsapplicationno"),
                  }}
                  disabled={
                    parseInt(watch("ismhcandidate")) !== 1 ||
                    parseInt(watch("categoryid")) !== 1 ||
                    parseInt(watch("appliedforews")) !== 1 ||
                    parseInt(watch("ewscertificatestatus")) !== 0 ||
                    isPaid === "1"
                  }
                />
              </Tooltip>
            </Grid>
          )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.EWS_Certificate_Application_Date
        ) && (
            <Tooltip title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.EWS_Certificate_Application_Date
              )?.fieldlabel
            } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
              <Grid item xs={12} md={6} ref={ewsapplicationdate}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name="ewsapplicationdate"
                    control={control}
                    rules={{
                      required:
                        parseInt(watch("ewscertificatestatus")) === 0
                          ? "\u00A0 \u00A0 EWS application date is required"
                          : false,
                    }}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        inputRef={ewsapplicationdate}
                        name="ewsapplicationdate"
                        views={["year", "month", "day"]}
                        maxDate={dayjs()}
                        minDate={dayjs("1900-01-01")}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border:
                              parseInt(watch("ismhcandidate")) !== 1 ||
                                parseInt(watch("categoryid")) !== 1 ||
                                parseInt(watch("appliedforews")) !== 1 ||
                                parseInt(watch("ewscertificatestatus")) !== 0
                                ? "2px solid grey"
                                : "2px solid #3F41D1",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border:
                              parseInt(watch("ismhcandidate")) !== 1 ||
                                parseInt(watch("categoryid")) !== 1 ||
                                parseInt(watch("appliedforews")) !== 1 ||
                                parseInt(watch("ewscertificatestatus")) !== 0
                                ? "2px solid grey"
                                : "2px solid #3F41D1",
                          },
                        }}
                        inputProps={{
                          style: { height: "56px", boxSizing: "border-box" },
                          maxLength: 50,
                          readOnly: true,
                        }}
                        {...field}
                        disableFuture
                        label={
                          <span>
                            {masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.EWS_Certificate_Application_Date
                            )?.ismandatory === "1" && !(parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) !== 1 ||
                              parseInt(watch("appliedforews")) !== 1 ||
                              parseInt(watch("ewscertificatestatus")) !== 0 ||
                              isPaid === "1") && (
                                <span className="error" style={{ color: "red" }}>
                                  {" "}
                                  *
                                </span>
                              )}
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.EWS_Certificate_Application_Date
                              )?.fieldlabel
                            }

                          </span>
                        }
                        format="DD/MM/YYYY"
                        disabled={
                          parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) !== 1 ||
                          parseInt(watch("appliedforews")) !== 1 ||
                          parseInt(watch("ewscertificatestatus")) !== 0 ||
                          isPaid === "1"
                        }
                        value={field.value ? dayjs(field.value) : null}
                        onChange={(date) => {
                          if (date) {
                            const formattedDate = dayjs(date)
                              .hour(18)
                              .minute(30)
                              .second(0)
                              .millisecond(0)
                              .toISOString();
                            field.onChange(formattedDate);
                          } else {
                            field.onChange(null);
                          }
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!errors.ewsapplicationdate,
                            helperText: errors.ewsapplicationdate?.message,
                            FormHelperTextProps: {
                              style: { margin: 0 },
                            },
                            style: {
                              width: "100%",
                              borderRadius: "8px",
                            },
                            inputProps: {
                              readOnly: true,
                            },
                          },
                          layout: {
                            sx: {
                              borderRadius: "58px",
                            },
                          },
                        }}
                      />

                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Tooltip>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.EWS_Certificate_Issuing_District
        ) && (
            <Grid item xs={12} md={6} ref={ewsdistrictid}>
              <FormControl fullWidth error={!!errors.ewsdistrictid}>
                <Tooltip title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.EWS_Certificate_Issuing_District
                  )?.fieldlabel
                } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                  <TextField
                    inputRef={ewsdistrictid}
                    select
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: !!watch("ewsdistrictid") || watch("ewsdistrictid") === 0,
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border:
                          parseInt(watch("ismhcandidate")) !== 1 ||
                            parseInt(watch("categoryid")) !== 1 ||
                            parseInt(watch("appliedforews")) !== 1 ||
                            parseInt(watch("ewscertificatestatus")) !== 0
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border:
                          parseInt(watch("ismhcandidate")) !== 1 ||
                            parseInt(watch("categoryid")) !== 1 ||
                            parseInt(watch("appliedforews")) !== 1 ||
                            parseInt(watch("ewscertificatestatus")) !== 0
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                      },
                    }}
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                    }}
                    id="ismhcandidate"
                    label={
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.EWS_Certificate_Issuing_District
                        )?.ismandatory === "1" && !(
                          parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) !== 1 ||
                          parseInt(watch("appliedforews")) !== 1 ||
                          parseInt(watch("ewscertificatestatus")) !== 0 ||
                          isPaid === "1") && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel?.EWS_Certificate_Issuing_District
                        )?.fieldlabel}
                      </span>
                    }
                    {...register("ewsdistrictid", {
                      required:
                        parseInt(watch("ewscertificatestatus")) === 0
                          ? "EWS application issuing district is required"
                          : false,
                    })}

                    value={watch("ewsdistrictid") ?? ""}
                    disabled={
                      parseInt(watch("ismhcandidate")) !== 1 ||
                      parseInt(watch("categoryid")) !== 1 ||
                      parseInt(watch("appliedforews")) !== 1 ||
                      parseInt(watch("ewscertificatestatus")) !== 0 ||
                      isPaid === "1"
                    }
                    SelectProps={{
                      MenuProps: { disableScrollLock: true },
                      style: { height: "56px", boxSizing: "border-box" },
                      onChange: (e) => {
                        const districtid = e.target.value;
                        setValue("ewsdistrictid", e.target.value);

                        dispatch({
                          type: masterActions.GET_EWS_TALUKA,
                          payload: districtid,
                        });

                        trigger("ewsdistrictid");
                      },
                    }}
                    error={!!errors.ewsdistrictid}
                    helperText={errors.ewsdistrictid ? errors.ewsdistrictid.message : ""}
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>

                    {ewsDistrict?.map((val, i) => (
                      <MenuItem key={i} value={val?.districtid}>
                        {val?.district}
                      </MenuItem>
                    ))}
                  </TextField>
                </Tooltip>
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.EWS_Certificate_Issuing_Taluka
        ) && (
            <Grid item xs={12} md={6} ref={ewstalukaid}>
              <FormControl fullWidth error={!!errors.ewstalukaid}>
                <Tooltip title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.EWS_Certificate_Issuing_Taluka
                  )?.fieldlabel
                } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                  <TextField
                    inputRef={ewstalukaid}
                    select
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: !!watch("ewstalukaid") || watch("ewstalukaid") === 0,
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border:
                          parseInt(watch("ismhcandidate")) !== 1 ||
                            parseInt(watch("categoryid")) !== 1 ||
                            parseInt(watch("appliedforews")) !== 1 ||
                            parseInt(watch("ewscertificatestatus")) !== 0
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border:
                          parseInt(watch("ismhcandidate")) !== 1 ||
                            parseInt(watch("categoryid")) !== 1 ||
                            parseInt(watch("appliedforews")) !== 1 ||
                            parseInt(watch("ewscertificatestatus")) !== 0
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                      },
                    }}
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                    }}
                    id="ewstalukaid"
                    name="ewstalukaid"
                    label={<span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.EWS_Certificate_Issuing_Taluka
                      )?.ismandatory === "1" && !(
                        parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) !== 1 ||
                        parseInt(watch("appliedforews")) !== 1 ||
                        parseInt(watch("ewscertificatestatus")) !== 0 ||
                        isPaid === "1") && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel?.EWS_Certificate_Issuing_Taluka
                      )?.fieldlabel}</span>
                    }
                    {...register("ewstalukaid", {
                      required:
                        parseInt(watch("ewscertificatestatus")) === 0
                          ? "EWS application issuing taluka is required"
                          : false,
                    })}

                    value={watch("ewstalukaid") ?? ""}
                    disabled={
                      parseInt(watch("ismhcandidate")) !== 1 ||
                      parseInt(watch("categoryid")) !== 1 ||
                      parseInt(watch("appliedforews")) !== 1 ||
                      parseInt(watch("ewscertificatestatus")) !== 0 ||
                      isPaid === "1"
                    }
                    SelectProps={{
                      MenuProps: { disableScrollLock: true },
                      style: { height: "56px", boxSizing: "border-box" },
                      onChange: (e) => {
                        setValue("ewstalukaid", e.target.value);
                        trigger("ewstalukaid");
                      },
                    }}
                    error={!!errors.ewstalukaid}
                    helperText={errors.ewstalukaid ? errors.ewstalukaid.message : ""}
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    {ewsTaluka?.map((val, i) => (
                      <MenuItem key={i} value={val?.talukaid}>
                        {val?.taluka}
                      </MenuItem>
                    ))}
                  </TextField>
                </Tooltip>
              </FormControl>
            </Grid>
          )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.POSSESS_CASTE_CERTIFICATE
        ) && (
            // <Grid item xs={12} md={6} ref={castecertificatestatus}>
            //   <FormControl fullWidth error={!!errors.castecertificatestatus}>

            //     <Controller
            //       name="castecertificatestatus"
            //       control={control}
            //       rules={{
            //         required:
            //           parseInt(watch("categoryid")) > 1
            //             ? "Caste certificate status is required"
            //             : false,
            //       }}
            //       render={({ field }) => (
            //         <Tooltip title={
            //           masterFields?.find(
            //             (field) =>
            //               field?.masterfieldlabel ===
            //               masterFieldLabel.POSSESS_CASTE_CERTIFICATE
            //           )?.fieldlabel
            //         } arrow   PopperProps={{

            //           <TextField
            //             inputRef={castecertificatestatus}
            //             select
            //             variant="outlined"
            //             fullWidth
            //             InputLabelProps={{
            //               shrink: !!watch("castecertificatestatus") ||
            //                 watch("castecertificatestatus") === 0,
            //             }}
            //             MenuProps={{
            //               disableScrollLock: true,
            //             }}
            //             sx={{
            //               "& .MuiOutlinedInput-notchedOutline": {
            //                 border:
            //                   parseInt(watch("ismhcandidate")) !== 1 ||
            //                     parseInt(watch("categoryid")) === 1
            //                     ? "2px solid grey"
            //                     : "2px solid #3F41D1",
            //               },
            //               "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            //                 border:
            //                   parseInt(watch("ismhcandidate")) !== 1 ||
            //                     parseInt(watch("categoryid")) === 1
            //                     ? "2px solid grey"
            //                     : "2px solid #3F41D1",
            //               },
            //             }}
            //             {...field}
            //             id="castecertificatestatus"
            //             name="castecertificatestatus"
            //             label={
            //               <span>
            //                 {masterFields?.find(
            //                   (field) =>
            //                     field?.masterfieldlabel ===
            //                     masterFieldLabel.POSSESS_CASTE_CERTIFICATE
            //                 )?.ismandatory === "1" && !(parseInt(watch("ismhcandidate")) !== 1 ||
            //                   parseInt(watch("categoryid")) === 1 ||
            //                   parseInt(watch("categoryid")) === 0 ||
            //                   isNaN(parseInt(watch("categoryid"))) ||
            //                   isPaid === "1") && (
            //                     <span className="error" style={{ color: "red" }}>
            //                       {" "}
            //                       *
            //                     </span>
            //                   )}
            //                 <span>
            //                   {
            //                     masterFields?.find(
            //                       (field) =>
            //                         field?.masterfieldlabel ===
            //                         masterFieldLabel.POSSESS_CASTE_CERTIFICATE
            //                     )?.fieldlabel
            //                   }
            //                 </span>

            //               </span>
            //             }

            //             value={watch("castecertificatestatus") ?? ""}
            //             disabled={
            //               parseInt(watch("ismhcandidate")) !== 1 ||
            //               parseInt(watch("categoryid")) === 1 ||
            //               parseInt(watch("categoryid")) === 0 ||
            //               isNaN(parseInt(watch("categoryid"))) ||
            //               isPaid === "1"
            //             }
            //             SelectProps={{
            //               MenuProps: { disableScrollLock: true },
            //               style: { height: "56px", boxSizing: "border-box" },
            //               onChange: (e) => {
            //                 setValue("castecertificatestatus", e.target.value);
            //                 clearErrors("castecertificatestatus")
            //                 clearErrors("castecertificateno")
            //                 setValue("castecertificateno", "");
            //                 setValue("castevaliditystatus", "");
            //                 setValue("cvcapplicationno", "");
            //                 setValue("cvcapplicationdate", "");
            //                 setValue("cvcauthority", "");
            //                 setValue("cvcdistrictid", "");
            //                 setValue("cvcname", "");
            //                 setValue("castecertificateappdate", "");
            //                 setValue("nclstatus", "");
            //                 setValue("nclapplicationno", "");
            //                 setValue("nclapplicationdate", "");
            //                 setValue("nclauthorityid", "");
            //                 setValue("castevaliditycertificateno", "");
            //                 clearErrors("castecertificatereceiptno")
            //                 setValue("castecertificatereceiptno", "")
            //                 if (getValues("castecertificatestatus") === 3) {
            //                   clearErrors("castecertificateno")
            //                 }
            //                 if (getValues("castecertificatestatus") !== 1) {
            //                   clearErrors("castevaliditystatus")
            //                   clearErrors("castevaliditycertificateno")
            //                   clearErrors("nclstatus")
            //                   clearErrors("nclapplicationno")
            //                   clearErrors("nclapplicationdate")
            //                   clearErrors("nclauthorityid")


            //                 }
            //                 if (getValues("castecertificatestatus") !== 2) {
            //                   clearErrors("castecertificatereceiptno")
            //                   clearErrors("castecertificateappdate")
            //                 }
            //               },
            //             }}
            //             error={!!errors.castecertificatestatus}
            //             helperText={errors.castecertificatestatus ? errors.castecertificatestatus.message : ""}
            //           >
            //             <MenuItem value="" disabled>
            //               Select One
            //             </MenuItem>
            //             <MenuItem value={1}>Available</MenuItem>
            //             <MenuItem value={2}>Applied But Not Received</MenuItem>
            //             <MenuItem value={3}>Not Applied</MenuItem>
            //           </TextField>
            //         </Tooltip>
            //       )}
            //     />

            //   </FormControl>
            // </Grid>
            <Grid item xs={12} md={6} ref={castecertificatestatus}>
              <FormControl fullWidth error={!!errors.castecertificatestatus}>
                <Tooltip
                  title={
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.POSSESS_CASTE_CERTIFICATE
                    )?.fieldlabel
                  }
                  arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                >
                  <TextField
                  {...register("castecertificatestatus", {
                    required:
                              parseInt(watch("categoryid")) > 1
                                ? "Caste certificate status is required"
                                : false,
                  })}
                    inputRef={castecertificatestatus}
                    select
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink:
                        !!watch("castecertificatestatus") ||
                        watch("castecertificatestatus") === 0,
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    SelectProps={{
                      MenuProps: { disableScrollLock: true },
                      style: { height: "56px", boxSizing: "border-box" },

                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border:
                          parseInt(watch("ismhcandidate")) !== 1 ||
                            parseInt(watch("categoryid")) === 1
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border:
                          parseInt(watch("ismhcandidate")) !== 1 ||
                            parseInt(watch("categoryid")) === 1
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                      },
                    }}
                    id="castecertificatestatus"
                    name="castecertificatestatus"
                    label={
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.POSSESS_CASTE_CERTIFICATE
                        )?.ismandatory === "1" &&
                          !(
                            parseInt(watch("ismhcandidate")) !== 1 ||
                            parseInt(watch("categoryid")) === 1 ||
                            parseInt(watch("categoryid")) === 0 ||
                            isNaN(parseInt(watch("categoryid"))) ||
                            isPaid === "1"
                          ) && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.POSSESS_CASTE_CERTIFICATE
                            )?.fieldlabel
                          }
                        </span>
                      </span>
                    }
                    value={watch("castecertificatestatus") ?? ""}
                    disabled={
                      parseInt(watch("ismhcandidate")) !== 1 ||
                      parseInt(watch("categoryid")) === 1 ||
                      parseInt(watch("categoryid")) === 0 ||
                      isNaN(parseInt(watch("categoryid"))) ||
                      isPaid === "1"
                    }
                    onChange={(e) => {
                      const { value } = e.target;
                      setValue("castecertificatestatus", value);
                      clearErrors("castecertificatestatus");
                      clearErrors("castecertificateno");
                      setValue("castecertificateno", "");
                      setValue("castevaliditystatus", "");
                      setValue("cvcapplicationno", "");
                      setValue("cvcapplicationdate", "");
                      setValue("cvcauthority", "");
                      setValue("cvcdistrictid", "");
                      setValue("cvcname", "");
                      setValue("castecertificateappdate", "");
                      setValue("nclstatus", "");
                      setValue("nclapplicationno", "");
                      setValue("nclapplicationdate", "");
                      setValue("nclauthorityid", "");
                      setValue("castevaliditycertificateno", "");
                      clearErrors("castecertificatereceiptno");
                      setValue("castecertificatereceiptno", "");
                      if (value === "3") {
                        clearErrors("castecertificateno");
                      }
                      if (value !== "1") {
                        clearErrors("castevaliditystatus");
                        clearErrors("castevaliditycertificateno");
                        clearErrors("nclstatus");
                        clearErrors("nclapplicationno");
                        clearErrors("nclapplicationdate");
                        clearErrors("nclauthorityid");
                      }
                      if (value !== "2") {
                        clearErrors("castecertificatereceiptno");
                        clearErrors("castecertificateappdate");
                      }
                    }}
                    error={!!errors.castecertificatestatus}
                    helperText={
                      errors.castecertificatestatus
                        ? errors.castecertificatestatus.message
                        : ""
                    }
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    <MenuItem value={1}>Available</MenuItem>
                    <MenuItem value={2}>Applied But Not Received</MenuItem>
                    <MenuItem value={3}>Not Applied</MenuItem>
                  </TextField>
                </Tooltip>
              </FormControl>
            </Grid>

          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.CASTE_CERTIFICATE_NO
        ) && (
            <Grid item xs={12} md={6} ref={castecertificateno}>
              <Tooltip title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.CASTE_CERTIFICATE_NO
                )?.fieldlabel
              } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                <TextField
                  inputRef={castecertificateno}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("castecertificatestatus")) > 2 ||
                          isNaN(parseInt(watch("castecertificatestatus")))
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("castecertificatestatus")) > 2 ||
                          isNaN(parseInt(watch("castecertificatestatus")))
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                    maxLength: 50,
                  }}
                  fullWidth
                  variant="outlined"
                  type="text"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.CASTE_CERTIFICATE_NO
                      )?.ismandatory === "1" &&
                        !(parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("castecertificatestatus")) !== 1 ||
                          parseInt(watch("castecertificatestatus")) === 3 ||
                          isNaN(parseInt(watch("castecertificatestatus"))) ||
                          isPaid === "1") && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.CASTE_CERTIFICATE_NO
                        )?.fieldlabel
                      }
                    </span>
                  }
                  placeholder="Enter the Caste Certificate Number"
                  {...register("castecertificateno", {
                    required:
                      !(parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("castecertificatestatus")) !== 1 ||
                        parseInt(watch("castecertificatestatus")) === 3 ||
                        isNaN(parseInt(watch("castecertificatestatus"))) ||
                        isPaid === "1")
                        ? "\u00A0 \u00A0 Caste certificate Number is required"
                        : false,
                    pattern: {
                      value: /^[A-Za-z0-9/-]+$/,
                      message: "Only numbers, alphabets, '-' and '/' are allowed",
                    },
                  })}
                  disabled={
                    parseInt(watch("ismhcandidate")) !== 1 ||
                    parseInt(watch("categoryid")) === 1 ||
                    parseInt(watch("castecertificatestatus")) !== 1 ||
                    parseInt(watch("castecertificatestatus")) === 3 ||
                    isNaN(parseInt(watch("castecertificatestatus"))) ||
                    isPaid === "1"
                  }
                  value={watch("castecertificateno") ?? ""}
                  error={!!errors.castecertificateno}
                  helperText={errors.castecertificateno?.message}
                  FormHelperTextProps={{
                    style: { margin: 0 },
                  }}
                  InputLabelProps={{
                    shrink: !!watch("castecertificateno"),
                  }}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("castecertificateno", e.target.value)
                    },
                  }}
                />

              </Tooltip>
            </Grid>
          )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.CASTE_CERTIFICATE_RECEIPT_NO
        ) && (
            <Grid item xs={12} md={6} ref={castecertificatereceiptno}>
              <Tooltip title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.CASTE_CERTIFICATE_RECEIPT_NO
                )?.fieldlabel
              } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                <TextField
                  inputRef={castecertificatereceiptno}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("castecertificatestatus")) !== 2 ||
                          isNaN(parseInt(watch("castecertificatestatus")))
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("castecertificatestatus")) !== 2 ||
                          isNaN(parseInt(watch("castecertificatestatus")))
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                    maxLength: 50,
                  }}
                  fullWidth
                  onChange={handleCasteCertificateChange}
                  variant="outlined"
                  type="text"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.CASTE_CERTIFICATE_RECEIPT_NO
                      )?.ismandatory === "1" &&
                        !(parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("castecertificatestatus")) !== 2 ||
                          isNaN(parseInt(watch("castecertificatestatus"))) ||
                          isPaid === "1") && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.CASTE_CERTIFICATE_RECEIPT_NO
                        )?.fieldlabel
                      }
                    </span>
                  }
                  placeholder="Enter the Casete Certificate Receipt Number"
                  {...register("castecertificatereceiptno", {
                    required:
                      parseInt(watch("castecertificatestatus")) === 2
                        ? "\u00A0 \u00A0Caste certificate Receipt number is required"
                        : false,
                    pattern: {
                      value: /^[A-Za-z0-9/-]+$/,
                      message:
                        "Only numbers, alphabets, '-' and '/' are allowed in the receipt number",
                    },
                  })}
                  disabled={
                    parseInt(watch("ismhcandidate")) !== 1 ||
                    parseInt(watch("categoryid")) === 1 ||
                    parseInt(watch("castecertificatestatus")) !== 2 ||
                    isNaN(parseInt(watch("castecertificatestatus"))) ||
                    isPaid === "1"
                  }
                  value={watch("castecertificatereceiptno") ?? ""}
                  error={!!errors.castecertificatereceiptno}
                  helperText={errors.castecertificatereceiptno?.message}
                  FormHelperTextProps={{
                    style: { margin: 0 },
                  }}
                  InputLabelProps={{
                    shrink: !!watch("castecertificatereceiptno"),
                  }}


                />

              </Tooltip>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.CASTE_CERTIFICATE_APPLICATION_DATE
        ) && (
            <Tooltip title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.CASTE_CERTIFICATE_APPLICATION_DATE
              )?.fieldlabel
            } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
              <Grid item xs={12} md={6} ref={castecertificateappdate}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>

                  <Controller
                    name="castecertificateappdate"
                    control={control}
                    rules={{
                      required:
                        parseInt(watch("castecertificatestatus")) === 2
                          ? "\u00A0 \u00A0Caste certficate application date is required"
                          : false,
                    }}

                    render={({ field }) => (
                      <DatePicker
                        name="castecertificateappdate"
                        {...field}
                        inputRef={castecertificateappdate}
                        views={["year", "month", "day"]}
                        maxDate={dayjs()}
                        minDate={dayjs("1900-01-01")}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border:
                              parseInt(watch("ismhcandidate")) !== 1 ||
                                parseInt(watch("categoryid")) === 1 ||
                                parseInt(watch("castecertificatestatus")) !== 2
                                ? "2px solid grey"
                                : "2px solid #3F41D1",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border:
                              parseInt(watch("ismhcandidate")) !== 1 ||
                                parseInt(watch("categoryid")) === 1 ||
                                parseInt(watch("castecertificatestatus")) !== 2
                                ? "2px solid grey"
                                : "2px solid #3F41D1",
                          },
                        }}
                        inputProps={{
                          style: { height: "56px", boxSizing: "border-box" },
                          maxLength: 50,
                          readOnly: true,
                        }}
                        {...field}
                        disableFuture
                        error={!!errors.castecertificateappdate}
                        helperText={errors.castecertificateappdate ? errors.castecertificateappdate.message : ""}
                        format="DD/MM/YYYY"
                        label={
                          <span>
                            {masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.CASTE_CERTIFICATE_APPLICATION_DATE
                            )?.ismandatory === "1" && !(parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) === 1 ||
                              parseInt(watch("castecertificatestatus")) !== 2 ||
                              isPaid === "1") && (
                                <span className="error" style={{ color: "red" }}>
                                  {" "}
                                  *
                                </span>
                              )}
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.CASTE_CERTIFICATE_APPLICATION_DATE
                              )?.fieldlabel
                            }

                          </span>
                        }
                        disabled={
                          parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("castecertificatestatus")) !== 2 ||
                          isPaid === "1"
                        }
                        value={field.value ? dayjs(field.value) : null}
                        onChange={(date) => {
                          if (date) {
                            const formattedDate = dayjs(date)
                              .hour(18)
                              .minute(30)
                              .second(0)
                              .millisecond(0)
                              .toISOString();
                            field.onChange(formattedDate);
                          } else {
                            field.onChange(null);
                          }
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!errors.castecertificateappdate,
                            helperText: errors.castecertificateappdate?.message,
                            FormHelperTextProps: {
                              style: { margin: 0 },
                            },
                            style: {
                              width: "100%",
                              borderRadius: "8px",
                            },
                            inputProps: {
                              readOnly: true,
                            },
                          },
                          layout: {
                            sx: {
                              borderRadius: "58px",
                            },
                          },
                        }}

                      />

                    )}
                  />
                </LocalizationProvider>
              </Grid>

            </Tooltip>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.POSSESS_CASTE_VALIDITY
        ) && (
            <Tooltip title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.POSSESS_CASTE_VALIDITY
              )?.fieldlabel
            } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
              {/* <Grid item xs={12} md={6} ref={castevaliditystatus}>
                <FormControl fullWidth error={!!errors.castevaliditystatus}>

                  <Controller
                    name="castevaliditystatus"
                    control={control}
                    rules={{
                      required:
                        parseInt(watch("castecertificatestatus")) === 1
                          ? "Caste Validity status is required"
                          : false,
                    }}
                    render={({ field }) => (
                      <TextField
                        inputRef={castevaliditystatus}
                        select
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: !!watch("castevaliditystatus") ||
                            watch("castevaliditystatus") === 0,
                        }}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border:
                              parseInt(watch("ismhcandidate")) !== 1 ||
                                parseInt(watch("categoryid")) === 1 ||
                                parseInt(watch("castecertificatestatus")) !== 1
                                ? "2px solid grey"
                                : "2px solid #3F41D1",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border:
                              parseInt(watch("ismhcandidate")) !== 1 ||
                                parseInt(watch("categoryid")) === 1 ||
                                parseInt(watch("castecertificatestatus")) !== 1
                                ? "2px solid grey"
                                : "2px solid #3F41D1",
                          },
                        }}
                        inputProps={{
                          style: { height: "56px", boxSizing: "border-box" },
                          maxLength: 50,
                        }}
                        {...field}
                        id="castevaliditystatus"
                        label={
                          <span>
                            {masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.POSSESS_CASTE_VALIDITY
                            )?.ismandatory === "1" && !(parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) === 1 ||
                              parseInt(watch("castecertificatestatus")) !== 1 ||
                              isPaid === "1") && (
                                <span className="error" style={{ color: "red" }}>
                                  {" "}
                                  *
                                </span>
                              )}
                            <span>
                              {
                                masterFields?.find(
                                  (field) =>
                                    field?.masterfieldlabel ===
                                    masterFieldLabel.POSSESS_CASTE_VALIDITY
                                )?.fieldlabel
                              }
                            </span>

                          </span>
                        }

                        value={watch("castevaliditystatus") ?? ""}
                        disabled={
                          parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("castecertificatestatus")) !== 1 ||
                          isPaid === "1"
                        }
                        name="castevaliditystatus"
                        SelectProps={{
                          MenuProps: { disableScrollLock: true },
                          style: { height: "56px", boxSizing: "border-box" },
                          onChange: (e) => {
                            setValue("castevaliditystatus", e.target.value);
                            clearErrors("castevaliditystatus")
                            setValue("cvcapplicationno", "");
                            setValue("cvcapplicationdate", "");
                            setValue("cvcauthority", "");
                            setValue("cvcdistrictid", "");
                            setValue("cvcname", "");
                            setValue("nclstatus", "");
                            setValue("nclapplicationno", "");
                            setValue("nclapplicationdate", "");
                            setValue("nclauthorityid", "");
                            setValue("castevaliditycertificateno", "");
                            if (getValues("castevaliditystatus") !== 1) {
                              clearErrors("castevaliditycertificateno")
                            }
                            if (getValues("castevaliditystatus") !== 2) {
                              clearErrors("cvcapplicationno")
                              clearErrors("cvcapplicationdate")
                              clearErrors("cvcauthority")
                              clearErrors("cvcdistrictid")
                              clearErrors("cvcname")
                            }
                          },
                        }}
                        error={!!errors.castevaliditystatus}
                        helperText={errors.castevaliditystatus ? errors.castevaliditystatus.message : ""}
                      >
                        <MenuItem value="" disabled>
                          Select One
                        </MenuItem>
                        <MenuItem value={0}>Not Applied</MenuItem>
                        <MenuItem value={1}>Available</MenuItem>
                        <MenuItem value={2}>Applied But Not Received</MenuItem>
                      </TextField>
                    )}
                  />

                </FormControl>
              </Grid> */}
              <Grid item xs={12} md={6} ref={castevaliditystatus}>
                <FormControl fullWidth error={!!errors.castevaliditystatus}>
                
                    <TextField
                  
                    
                      inputRef={castevaliditystatus}
                      select
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink:
                          !!watch("castevaliditystatus") ||
                          watch("castevaliditystatus") === 0,
                      }}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) === 1 ||
                              parseInt(watch("castecertificatestatus")) !== 1
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) === 1 ||
                              parseInt(watch("castecertificatestatus")) !== 1
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      id="castevaliditystatus"
                      name="castevaliditystatus"
                      label={
                        <span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.POSSESS_CASTE_VALIDITY
                          )?.ismandatory === "1" &&
                            !(
                              parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) === 1 ||
                              parseInt(watch("castecertificatestatus")) !== 1 ||
                              isPaid === "1"
                            ) && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.POSSESS_CASTE_VALIDITY
                              )?.fieldlabel
                            }
                          </span>
                        </span>
                      }
                      value={watch("castevaliditystatus") ?? ""}
                      disabled={
                        parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("castecertificatestatus")) !== 1 ||
                        isPaid === "1"
                      }
                      SelectProps={{
                        MenuProps: { disableScrollLock: true },
                        style: { height: "56px", boxSizing: "border-box" },
                        onChange: (e) => {
                          const { value } = e.target;
                          setValue("castevaliditystatus", value);
                          clearErrors("castevaliditystatus");
                          setValue("cvcapplicationno", "");
                          setValue("cvcapplicationdate", "");
                          setValue("cvcauthority", "");
                          setValue("cvcdistrictid", "");
                          setValue("cvcname", "");
                          setValue("nclstatus", "");
                          setValue("nclapplicationno", "");
                          setValue("nclapplicationdate", "");
                          setValue("nclauthorityid", "");
                          setValue("castevaliditycertificateno", "");

                          if (value !== "1") {
                            clearErrors("castevaliditycertificateno");
                          }
                          if (value !== "2") {
                            clearErrors("cvcapplicationno");
                            clearErrors("cvcapplicationdate");
                            clearErrors("cvcauthority");
                            clearErrors("cvcdistrictid");
                            clearErrors("cvcname");
                          }
                        },
                      }}
                      {...register("castevaliditystatus", {
                        required:
                        parseInt(watch("castecertificatestatus")) === 1
                        ? "Caste Validity status is required"
                        : false,
                        // validate: percentageValidation,
                      })}
                      error={!!errors.castevaliditystatus}
                      helperText={
                        errors.castevaliditystatus
                          ? errors.castevaliditystatus.message
                          : ""
                      }
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      <MenuItem value={0}>Not Applied</MenuItem>
                      <MenuItem value={1}>Available</MenuItem>
                      <MenuItem value={2}>Applied But Not Received</MenuItem>
                    </TextField>
                  
                </FormControl>
              </Grid>

            </Tooltip>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.CASTE_VALIDITY_CERTIFICATE_NO
        ) && (
            <Grid item xs={12} md={6} ref={castevaliditycertificateno}>
              <Tooltip title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.CASTE_VALIDITY_CERTIFICATE_NO
                )?.fieldlabel
              } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                <TextField
                  inputRef={castevaliditycertificateno}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("castevaliditystatus")) !== 1
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("castevaliditystatus")) !== 1
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                  }}
                  fullWidth
                  variant="outlined"
                  type="text"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.CASTE_VALIDITY_CERTIFICATE_NO
                      )?.ismandatory === "1" && !(parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("castevaliditystatus")) !== 1 ||
                        isPaid === "1") && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.CASTE_VALIDITY_CERTIFICATE_NO
                        )?.fieldlabel
                      }

                    </span>
                  }
                  placeholder="Enter cvc status"
                  {...register("castevaliditycertificateno", {
                    required:
                      parseInt(watch("castevaliditystatus")) === 1
                        ? "Caste Validity Certificate number is required"
                        : false,
                    // validate: percentageValidation,

                    pattern: {
                      value: /^[A-Za-z0-9/-]+$/,
                      message:
                        "Only numbers, alphabets, '-' and '/' are allowed in the application number",
                    },
                  })}
                  // onInput={NumbersValidation}
                  disabled={
                    parseInt(watch("ismhcandidate")) !== 1 ||
                    parseInt(watch("categoryid")) === 1 ||
                    parseInt(watch("castevaliditystatus")) !== 1 ||
                    isPaid === "1"
                  }
                  error={!!errors.castevaliditycertificateno}
                  helperText={errors.castevaliditycertificateno?.message}
                  FormHelperTextProps={{
                    style: { margin: 0 },
                  }}
                  InputLabelProps={{
                    shrink: !!watch("castevaliditycertificateno"),
                  }}
                /></Tooltip>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.CASTE_VALIDITY_APPLICATION_NO
        ) && (
            <Grid item xs={12} md={6} ref={cvcapplicationno}>
              <Tooltip title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.CASTE_VALIDITY_APPLICATION_NO
                )?.fieldlabel
              } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                <TextField
                  inputRef={cvcapplicationno}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("castevaliditystatus")) !== 2
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("castevaliditystatus")) !== 2
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                  }}
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                    maxLength: 50,
                  }}
                  type="text"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.CASTE_VALIDITY_APPLICATION_NO
                      )?.ismandatory === "1" && !(parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("castevaliditystatus")) !== 2 ||
                        isPaid === "1") && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.CASTE_VALIDITY_APPLICATION_NO
                        )?.fieldlabel
                      }

                    </span>
                  }
                  placeholder="Enter the SSC Total percentage"
                  {...register("cvcapplicationno", {
                    required:
                      parseInt(watch("castevaliditystatus")) === 2
                        ? "\u00A0 \u00A0 Caste / Tribe validity certificate application is required"
                        : false,
                    // validate: percentageValidation,

                    pattern: {
                      value: /^[A-Za-z0-9/-]+$/,
                      message:
                        "Only numbers, alphabets, '-' and '/' are allowed in the application number",
                    },
                  })}
                  // onInput={NumbersValidation}
                  disabled={
                    parseInt(watch("ismhcandidate")) !== 1 ||
                    parseInt(watch("categoryid")) === 1 ||
                    parseInt(watch("castevaliditystatus")) !== 2 ||
                    isPaid === "1"
                  }
                  error={!!errors.cvcapplicationno}
                  helperText={errors.cvcapplicationno?.message}
                  FormHelperTextProps={{
                    style: { margin: 0 },
                  }}
                  InputLabelProps={{
                    shrink: !!watch("cvcapplicationno"),
                  }}
                /></Tooltip>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.CASTE_VALIDITY_APPLICATION_DATE
        ) && (
            <Tooltip title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.CASTE_VALIDITY_APPLICATION_DATE
              )?.fieldlabel
            } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
              <Grid item xs={12} md={6} ref={cvcapplicationdate}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name="cvcapplicationdate"
                    control={control}
                    rules={{
                      required:
                        !(parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("castevaliditystatus")) !== 2 ||
                          isPaid === "1")
                          ? "Caste/Tribe validity certificate application date is required"
                          : false,
                    }}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        format="DD/MM/YYYY"

                        name="cvcapplicationdate"
                        inputRef={cvcapplicationdate}
                        views={["year", "month", "day"]}
                        maxDate={dayjs()}
                        minDate={dayjs("1900-01-01")}
                        inputProps={{
                          style: { height: "56px", boxSizing: "border-box" },

                          readOnly: true,
                        }}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border:
                              parseInt(watch("ismhcandidate")) !== 1 ||
                                parseInt(watch("categoryid")) === 1 ||
                                parseInt(watch("castevaliditystatus")) !== 2
                                ? "2px solid grey"
                                : "2px solid #3F41D1",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border:
                              parseInt(watch("ismhcandidate")) !== 1 ||
                                parseInt(watch("categoryid")) === 1 ||
                                parseInt(watch("castevaliditystatus")) !== 2
                                ? "2px solid grey"
                                : "2px solid #3F41D1",
                          },
                        }}

                        {...field}
                        disableFuture
                        label={
                          <span>
                            {masterFields?.find(
                              (f) => f?.masterfieldlabel === masterFieldLabel.CASTE_VALIDITY_APPLICATION_DATE
                            )?.ismandatory === "1" && !(parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) === 1 ||
                              parseInt(watch("castevaliditystatus")) !== 2 ||
                              isPaid === "1") && (
                                <span className="error" style={{ color: "red" }}>
                                  {" "}*
                                </span>
                              )}
                            {
                              masterFields?.find(
                                (f) => f?.masterfieldlabel === masterFieldLabel.CASTE_VALIDITY_APPLICATION_DATE
                              )?.fieldlabel
                            }

                          </span>
                        }
                        disabled={
                          parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("castevaliditystatus")) !== 2 ||
                          isPaid === "1"
                        }
                        value={field.value ? dayjs(field.value) : null}
                        onChange={(date) => {
                          if (date && dayjs(date).isValid()) {
                            const formattedDate = dayjs(date)
                              .hour(18)
                              .minute(30)
                              .second(0)
                              .millisecond(0)
                              .toISOString();
                            field.onChange(formattedDate);
                          } else {
                            field.onChange(null);
                          }
                        }}
                        onKeyDown={(e) => e.preventDefault()}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!errors.cvcapplicationdate,
                            helperText: errors.cvcapplicationdate?.message,
                            FormHelperTextProps: {
                              style: { margin: 0 },
                            },
                            style: {
                              width: "100%",
                              borderRadius: "8px",
                            },

                            inputProps: {
                              readOnly: true,
                            },

                          },
                          layout: {
                            sx: {
                              borderRadius: "58px",
                            },
                          },
                        }}
                      />


                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Tooltip>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.CASTE_VALIDITY_ISSUING_AUTH_NAME
        ) && (
            <Grid item xs={12} md={6} ref={cvcauthority}>
              <Tooltip title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.CASTE_VALIDITY_ISSUING_AUTH_NAME
                )?.fieldlabel
              } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                <TextField
                  inputRef={cvcauthority}
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("cvcauthority") || watch("cvcauthority") === 0,
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("castevaliditystatus")) !== 2
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("castevaliditystatus")) !== 2
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                  }}
                  type="text"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.CASTE_VALIDITY_ISSUING_AUTH_NAME
                      )?.ismandatory === "1" && !(parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("castevaliditystatus")) !== 2 ||
                        isPaid === "1") && (
                          <span className="error" style={{ color: "red" }}>
                            {" "} *
                          </span>
                        )}
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.CASTE_VALIDITY_ISSUING_AUTH_NAME
                        )?.fieldlabel
                      }
                    </span>
                  }
                  placeholder="Enter the SSC Total percentage"
                  {...register("cvcauthority", {
                    required:
                      parseInt(watch("castevaliditystatus")) === 2
                        ? " \u00A0 \u00A0 Caste certificate validity certificate issuing authority name is required"
                        : false,
                  })}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                    style: { height: "56px", boxSizing: "border-box" },
                    onChange: (e) => {
                      setValue("cvcauthority", e.target.value)
                      clearErrors("cvcauthority")
                    },
                  }}
                  value={watch("cvcauthority") ?? ""}
                  disabled={
                    parseInt(watch("ismhcandidate")) !== 1 ||
                    parseInt(watch("categoryid")) === 1 ||
                    parseInt(watch("castevaliditystatus")) !== 2 ||
                    isPaid === "1"
                  }
                  error={!!errors.cvcauthority}
                  helperText={errors.cvcauthority?.message}
                  FormHelperTextProps={{
                    style: { margin: 0 },
                  }}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {issuingAuthority?.map((val, i) => (
                    <MenuItem key={i} value={val?.cvcauthority}>
                      {val?.cvcauthority}
                    </MenuItem>
                  ))}
                </TextField></Tooltip>
            </Grid>

          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.CASTE_VALIDITY_ISSUING_DISTRICT
        ) && (
            <Grid item xs={12} md={6} ref={cvcdistrictid}>
              <FormControl fullWidth error={!!errors.cvcdistrictid}>
                <Tooltip title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CASTE_VALIDITY_ISSUING_DISTRICT
                  )?.fieldlabel
                } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                  <TextField
                    inputRef={cvcdistrictid}
                    select
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: !!watch("cvcdistrictid") || watch("cvcdistrictid") === 0
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border:
                          parseInt(watch("ismhcandidate")) !== 1 ||
                            parseInt(watch("categoryid")) === 1 ||
                            parseInt(watch("castevaliditystatus")) !== 2
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border:
                          parseInt(watch("ismhcandidate")) !== 1 ||
                            parseInt(watch("categoryid")) === 1 ||
                            parseInt(watch("castevaliditystatus")) !== 2
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                      },
                    }}
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                    }}
                    id="cvcdistrictid"
                    name="cvcdistrictid"
                    label={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel?.CASTE_VALIDITY_ISSUING_DISTRICT
                      )?.fieldlabel
                    }
                    {...register("cvcdistrictid", {
                      required:
                        parseInt(watch("castevaliditystatus")) === 2
                          ? "Caste certificate issue District is required"
                          : false,
                    })}
                    disabled={
                      parseInt(watch("ismhcandidate")) !== 1 ||
                      parseInt(watch("categoryid")) === 1 ||
                      parseInt(watch("castevaliditystatus")) !== 2 ||
                      isPaid === "1"
                    }
                    value={watch("cvcdistrictid") ?? ""}
                    SelectProps={{
                      MenuProps: { disableScrollLock: true },
                      style: { height: "56px", boxSizing: "border-box" },
                      onChange: (e) => {
                        setValue("cvcdistrictid", e.target.value);
                        trigger("cvcdistrictid");
                      },
                    }}
                    error={!!errors.cvcdistrictid}
                    helperText={errors.cvcdistrictid ? errors.cvcdistrictid.message : ""}
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    {cvcDistrict?.map((val, i) => (
                      <MenuItem key={i} value={val?.districtid}>
                        {val?.district}
                      </MenuItem>
                    ))}
                  </TextField>
                </Tooltip>
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.CANDIDATE_NAME_AS_CASTE_CERTIFICATE
        ) && (
            <Grid item xs={12} md={6} ref={cvcname}>
              <Tooltip title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.CANDIDATE_NAME_AS_CASTE_CERTIFICATE
                )?.fieldlabel
              } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                <TextField
                  inputRef={cvcname}
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                    maxLength: 50,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("castevaliditystatus")) !== 2
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("castevaliditystatus")) !== 2
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                  }}
                  type="text"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.CANDIDATE_NAME_AS_CASTE_CERTIFICATE
                      )?.ismandatory === "1" && !(parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("castevaliditystatus")) !== 2 ||
                        isPaid === "1") && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.CANDIDATE_NAME_AS_CASTE_CERTIFICATE
                        )?.fieldlabel
                      }

                    </span>
                  }
                  placeholder="Enter candidate name as per certificate"
                  {...register("cvcname", {
                    required:
                      parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("castevaliditystatus")) !== 2
                        ? false
                        : "\u00A0 \u00A0 Caste certificate name is required",
                    // validate: percentageValidation,
                  })}
                  onInput={AlphabetsValidationUppercase}
                  disabled={
                    parseInt(watch("ismhcandidate")) !== 1 ||
                    parseInt(watch("categoryid")) === 1 ||
                    parseInt(watch("castevaliditystatus")) !== 2 ||
                    isPaid === "1"
                  }
                  error={!!errors.cvcname}
                  helperText={errors.cvcname?.message}
                  FormHelperTextProps={{
                    style: { margin: 0 },
                  }}
                  InputLabelProps={{
                    shrink: !!watch("cvcname"),
                  }}
                  name='cvcname'
                /></Tooltip>
            </Grid>
          )}

        {/* {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.DOMICILE_CERTIFICATE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.domiicilecertificatestatus}
            >
              <InputLabel
                shrink={
                  !!watch("domiicilecertificatestatus") ||
                  watch("domiicilecertificatestatus") === 0
                }
                htmlFor="domiicilecertificatestatus"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.DOMICILE_CERTIFICATE
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.DOMICILE_CERTIFICATE
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="domiicilecertificatestatus"
                control={control}
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.DOMICILE_CERTIFICATE
                    )?.ismandatory === "1"
                      ? "Select is required"
                      : false,
                }}
                render={({ field }) => (
                  <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                    {...field}
                    id="domiicilecertificatestatus"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.DOMICILE_CERTIFICATE
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.DOMICILE_CERTIFICATE
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(
                      masterFields,
                      masterFieldLabel.DOMICILE_CERTIFICATE
                    )}
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.domiicilecertificatestatus && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.domiicilecertificatestatus.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )} */}

        {/* {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.RELIGIOUS_MINORITY
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.religiousminorityid}
            >
              <InputLabel
                shrink={
                  !!watch("religiousminorityid") ||
                  watch("religiousminorityid") === 0
                }
                htmlFor="religiousminorityid"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.RELIGIOUS_MINORITY
                  )?.fieldlabel
                }
                <span className="error">*</span>
              </InputLabel>
              <Controller
                name="religiousminorityid"
                control={control}
                rules={{ required: "Select Religion is required" }}
                render={({ field }) => (
                  <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                    {...field}
                    id="religiousminorityid"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.RELIGIOUS_MINORITY
                            )?.fieldlabel
                          }
                        </span>
                        *
                      </span>
                    }
                    value={field.value ?? ""}
                  >
                    <MenuItem value="" disabled>Select One</MenuItem>
                    {religion?.map((val) => (
                      <MenuItem key={val?.religionid} value={val?.religionid}>
                        {val?.religion}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.religiousminorityid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.religiousminorityid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )} */}

        {/* <Grid item xs={12} md={6}>
          <FormControl fullWidth size="small" error={!!errors.hscboardid}>
            <InputLabel shrink={!!watch("hscboardid")} htmlFor="hscboardid">
              HSC Board<span className="error">*</span>
            </InputLabel>
            <Select  MenuProps={{
                  disableScrollLock: true,
              }}
              id="hscboardid"
              label="HSC Board"
              {...register("hscboardid", {
                required: " HSC Board is required",
              })}
              onChange={(e) => {
                setValue("hscboardid", e.target.value);
                trigger("hscboardid");
              }}
              value={watch("hscboardid") ?? ""}
            >
              <MenuItem value="">Select One</MenuItem>
              {board?.map((val, i) => (
                <MenuItem value={val?.boardid} key={i}>
                  {val?.board}
                </MenuItem>
              ))}
            </Select>
            {errors.hscboardid && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.hscboardid.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid> */}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE
        ) && (
            <Grid item xs={12} md={6} ref={nclstatus}>
              <FormControl fullWidth error={!!errors.nclstatus}>

                <Controller
                  name="nclstatus"
                  control={control}
                  rules={{
                    required:
                      parseInt(watch("categoryid")) > 3 &&
                        parseInt(watch("castecertificatestatus")) === 1
                        ? "NCL status is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Tooltip title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE
                      )?.fieldlabel
                    } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                      <TextField
                        name="nclstatus"
                        inputRef={nclstatus}
                        select
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: !!watch("nclstatus") || watch("nclstatus") === 0,
                        }}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border:
                              parseInt(watch("ismhcandidate")) !== 1 ||
                                parseInt(watch("categoryid")) === 1 ||
                                parseInt(watch("categoryid")) === 2 ||
                                parseInt(watch("categoryid")) === 3 ||
                                parseInt(watch("castecertificatestatus")) !== 1
                                ? "2px solid grey"
                                : "2px solid #3F41D1",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border:
                              parseInt(watch("ismhcandidate")) !== 1 ||
                                parseInt(watch("categoryid")) === 1 ||
                                parseInt(watch("categoryid")) === 2 ||
                                parseInt(watch("categoryid")) === 3 ||
                                parseInt(watch("castecertificatestatus")) !== 1
                                ? "2px solid grey"
                                : "2px solid #3F41D1",
                          },
                        }}
                        inputProps={{
                          style: { height: "56px", boxSizing: "border-box" },
                          maxLength: 50,
                        }}
                        {...field}
                        id="nclstatus"
                        label={
                          <span>
                            <span>
                              {masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE
                              )?.ismandatory === "1" && !(parseInt(watch("ismhcandidate")) !== 1 ||
                                parseInt(watch("categoryid")) === 1 ||
                                parseInt(watch("categoryid")) === 2 ||
                                parseInt(watch("categoryid")) === 3 ||
                                parseInt(watch("castecertificatestatus")) !== 1 ||
                                isPaid === "1") && (
                                  <span className="error" style={{ color: "red" }}>
                                    {" "}
                                    *
                                  </span>
                                )}
                              {
                                masterFields?.find(
                                  (field) =>
                                    field?.masterfieldlabel ===
                                    masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE
                                )?.fieldlabel
                              }
                            </span>

                          </span>
                        }

                        value={watch("nclstatus") ?? ""}
                        disabled={
                          parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("categoryid")) === 2 ||
                          parseInt(watch("categoryid")) === 3 ||
                          parseInt(watch("castecertificatestatus")) !== 1 ||
                          isPaid === "1"
                        }
                        SelectProps={{
                          MenuProps: { disableScrollLock: true },
                          style: { height: "56px", boxSizing: "border-box" },
                          onChange: (e) => {
                            setValue("nclstatus", e.target.value);
                            clearErrors("nclstatus")
                            setValue("nclapplicationno", "");
                            setValue("nclapplicationdate", "");
                            setValue("nclauthorityid", "");
                            if (getValues("nclstatus") !== 2) {
                              clearErrors("nclapplicationno")
                              clearErrors("nclapplicationdate")
                              clearErrors("nclauthorityid")

                            }
                          },
                        }}
                        error={!!errors.nclstatus}
                        helperText={errors.nclstatus ? errors.nclstatus.message : ""}
                      >
                        <MenuItem value="" disabled>
                          Select One
                        </MenuItem>
                        <MenuItem value={1}>Available</MenuItem>
                        <MenuItem value={2}> Applied But Not Received</MenuItem>
                        <MenuItem value={3}>Not Applied</MenuItem>
                      </TextField>
                    </Tooltip>
                  )}
                />

              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_NO
        ) && (
            <Grid item xs={12} md={6} ref={nclapplicationno}>
              <Tooltip title={
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_NO
                )?.fieldlabel
              } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("categoryid")) === 2 ||
                          parseInt(watch("categoryid")) === 3 ||
                          parseInt(watch("castecertificatestatus")) !== 1 ||
                          parseInt(watch("nclstatus")) !== 2
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("categoryid")) === 2 ||
                          parseInt(watch("categoryid")) === 3 ||
                          parseInt(watch("castecertificatestatus")) !== 1 ||
                          parseInt(watch("nclstatus")) !== 2
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                  }}
                  fullWidth
                  variant="outlined"
                  type="text"
                  label={
                    <span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_NO
                      )?.ismandatory === "1" && !(parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("categoryid")) === 2 ||
                        parseInt(watch("categoryid")) === 3 ||
                        parseInt(watch("castecertificatestatus")) !== 1 ||
                        parseInt(watch("nclstatus")) !== 2 ||
                        isPaid === "1") && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_NO
                        )?.fieldlabel
                      }

                    </span>
                  }
                  placeholder="Enter Non Creamy Layer Application Number"
                  {...register("nclapplicationno", {
                    required:
                      parseInt(watch("nclstatus")) === 2
                        ? "non creamy layer certificate application no is required"
                        : false,

                        pattern: {
                          value: /^[A-Za-z0-9/-]+$/,
                          message:
                            "Only numbers, alphabets, '-' and '/' are allowed in the application number",
                        },
                  })}
                  
                  // onInput={AlphabetsValidation}
                  error={!!errors.nclapplicationno}
                  helperText={errors.nclapplicationno?.message}
                  FormHelperTextProps={{
                    style: { margin: 0 },
                  }}
                  InputLabelProps={{
                    shrink: !!watch("nclapplicationno"),
                  }}
                  inputRef={nclapplicationno}
                  disabled={
                    parseInt(watch("ismhcandidate")) !== 1 ||
                    parseInt(watch("categoryid")) === 1 ||
                    parseInt(watch("categoryid")) === 2 ||
                    parseInt(watch("categoryid")) === 3 ||
                    parseInt(watch("castecertificatestatus")) !== 1 ||
                    parseInt(watch("nclstatus")) !== 2 ||
                    isPaid === "1"
                  }
                /></Tooltip>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_DATE
        ) && (
            <Tooltip title={
              masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_DATE
              )?.fieldlabel
            } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
              <Grid item xs={12} md={6} ref={nclapplicationdate}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name="nclapplicationdate"
                    control={control}
                    rules={{
                      required:
                        parseInt(watch("nclstatus")) === 2
                          ? "Caste valdity date is required"
                          : false,
                    }}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        name="nclapplicationdate"
                        inputRef={nclapplicationdate}
                        views={["year", "month", "day"]}
                        maxDate={dayjs()}
                        minDate={dayjs("1900-01-01")}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border:
                              parseInt(watch("ismhcandidate")) !== 1 ||
                                parseInt(watch("categoryid")) === 1 ||
                                parseInt(watch("categoryid")) === 2 ||
                                parseInt(watch("categoryid")) === 3 ||
                                parseInt(watch("castecertificatestatus")) !== 1 ||
                                parseInt(watch("nclstatus")) !== 2
                                ? "2px solid grey"
                                : "2px solid #3F41D1",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border:
                              parseInt(watch("ismhcandidate")) !== 1 ||
                                parseInt(watch("categoryid")) === 1 ||
                                parseInt(watch("categoryid")) === 2 ||
                                parseInt(watch("categoryid")) === 3 ||
                                parseInt(watch("castecertificatestatus")) !== 1 ||
                                parseInt(watch("nclstatus")) !== 2
                                ? "2px solid grey"
                                : "2px solid #3F41D1",
                          },
                        }}
                        inputProps={{
                          style: { height: "56px", boxSizing: "border-box" },
                          maxLength: 50,
                          readOnly: true,
                        }}
                        {...field}
                        disableFuture
                        format="DD/MM/YYYY"
                        label={
                          <span>
                            {masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_DATE
                            )?.ismandatory === "1" && !(parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) === 1 ||
                              parseInt(watch("categoryid")) === 2 ||
                              parseInt(watch("categoryid")) === 3 ||
                              parseInt(watch("castecertificatestatus")) !== 1 ||
                              parseInt(watch("nclstatus")) !== 2 ||
                              isPaid === "1") && (
                                <span className="error" style={{ color: "red" }}>
                                  {" "}
                                  *
                                </span>
                              )}
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_DATE
                              )?.fieldlabel
                            }

                          </span>
                        }
                        disabled={
                          parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("categoryid")) === 2 ||
                          parseInt(watch("categoryid")) === 3 ||
                          parseInt(watch("castecertificatestatus")) !== 1 ||
                          parseInt(watch("nclstatus")) !== 2 ||
                          isPaid === "1"
                        }
                        value={field.value ? dayjs(field.value) : null}
                        onChange={(date) => {
                          if (date) {
                            const formattedDate = dayjs(date)
                              .hour(18)
                              .minute(30)
                              .second(0)
                              .millisecond(0)
                              .toISOString();
                            field.onChange(formattedDate);
                          } else {
                            field.onChange(null);
                          }
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!errors.nclapplicationdate,
                            helperText: errors.nclapplicationdate?.message,
                            FormHelperTextProps: {
                              style: { margin: 0 },
                            },
                            style: {
                              width: "100%",
                              borderRadius: "8px",
                            },
                            inputProps: {
                              readOnly: true,
                            },
                          },
                          layout: {
                            sx: {
                              borderRadius: "58px",
                            },
                          },
                        }}
                      />

                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Tooltip>
          )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.NON_CREAMY_LAYER_AUTHORITY
        ) && (
            <Grid item xs={12} md={6} ref={nclauthorityid}>
              <FormControl fullWidth error={!!errors.nclauthorityid}>
                <Tooltip title={
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.NON_CREAMY_LAYER_AUTHORITY
                  )?.fieldlabel
                } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                  <TextField
                    inputRef={nclauthorityid}
                    select
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: !!watch("nclauthorityid") || watch("nclauthorityid") === 0,
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border:
                          parseInt(watch("ismhcandidate")) !== 1 ||
                            parseInt(watch("categoryid")) === 1 ||
                            parseInt(watch("categoryid")) === 2 ||
                            parseInt(watch("categoryid")) === 3 ||
                            parseInt(watch("castecertificatestatus")) !== 1 ||
                            parseInt(watch("nclstatus")) !== 2
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border:
                          parseInt(watch("ismhcandidate")) !== 1 ||
                            parseInt(watch("categoryid")) === 1 ||
                            parseInt(watch("categoryid")) === 2 ||
                            parseInt(watch("categoryid")) === 3 ||
                            parseInt(watch("castecertificatestatus")) !== 1 ||
                            parseInt(watch("nclstatus")) !== 2
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                      },
                    }}
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                    }}
                    id="nclauthorityid"
                    label={
                      <span>

                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_DATE
                        )?.ismandatory === "1" && !(parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("categoryid")) === 2 ||
                          parseInt(watch("categoryid")) === 3 ||
                          parseInt(watch("castecertificatestatus")) !== 1 ||
                          parseInt(watch("nclstatus")) !== 2 ||
                          isPaid === "1") && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel?.NON_CREAMY_LAYER_AUTHORITY
                        )?.fieldlabel}
                      </span>


                    }
                    {...register("nclauthorityid", {
                      required:
                        parseInt(watch("nclstatus")) === 2
                          ? "Non Creamy Authority Layer authority is required"
                          : false,
                    })}

                    disabled={
                      parseInt(watch("ismhcandidate")) !== 1 ||
                      parseInt(watch("categoryid")) === 1 ||
                      parseInt(watch("categoryid")) === 2 ||
                      parseInt(watch("categoryid")) === 3 ||
                      parseInt(watch("castecertificatestatus")) !== 1 ||
                      parseInt(watch("nclstatus")) !== 2 ||
                      isPaid === "1"
                    }
                    value={watch("nclauthorityid") ?? ""}
                    SelectProps={{
                      MenuProps: { disableScrollLock: true },
                      style: { height: "56px", boxSizing: "border-box" },
                      onChange: (e) => {
                        setValue("nclauthorityid", e.target.value);
                        trigger("nclauthorityid")
                      },
                    }}
                    error={!!errors.nclauthorityid}
                    helperText={errors.nclauthorityid ? errors.nclauthorityid.message : ""}
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    {nclAuthority?.map((val, i) => (
                      <MenuItem key={i} value={val?.nclauthorityid}>
                        {val?.nclauthority}
                      </MenuItem>
                    ))}
                  </TextField>
                </Tooltip>
              </FormControl>
            </Grid>
          )}

        <Grid item xs={12} md={12}>
          <Typography
            pt={1}
            sx={{
              fontSize: "24px",
              color: `${cssProperties?.color?.tertiary}`,
              fontWeight: 500,
              lineHeight: "30px",
            }}
          >
            {" "}
            MINORITY INFORMATION{" "}
          </Typography>
        </Grid>

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.MINORITY_CATEGORY
        ) && (
            <Grid item xs={12} md={6} ref={isminority}>
              <FormControl fullWidth error={!!errors.isminority}>

                <Controller
                  name="isminority"
                  control={control}
                  rules={{
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.MINORITY_CATEGORY
                      )?.ismandatory === "1"
                        ? "Minority is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Tooltip title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.MINORITY_CATEGORY
                      )?.fieldlabel
                    } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                      <TextField
                        name="isminority"
                        inputRef={isminority}
                        select
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: !!watch("isminority") || watch("isminority") === 0
                        }}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: isFieldDisabled(
                              masterFields,
                              masterFieldLabel.MINORITY_CATEGORY
                            )
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: isFieldDisabled(
                              masterFields,
                              masterFieldLabel.MINORITY_CATEGORY
                            )
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                          },
                        }}
                        inputProps={{
                          style: { height: "56px", boxSizing: "border-box" },
                          maxLength: 50,
                        }}
                        {...field}
                        id="isminority"
                        label={
                          <span>
                            {masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.MINORITY_CATEGORY
                            )?.ismandatory === "1" && (
                                <span className="error" style={{ color: "red" }}>
                                  {" "}
                                  *
                                </span>
                              )}
                            <span>
                              {
                                masterFields?.find(
                                  (field) =>
                                    field?.masterfieldlabel ===
                                    masterFieldLabel.MINORITY_CATEGORY
                                )?.fieldlabel
                              }
                            </span>

                          </span>
                        }
                        value={field.value ?? ""}
                        disabled={isFieldDisabled(
                          masterFields,
                          masterFieldLabel.MINORITY_CATEGORY,
                          null,
                          isPaid
                        ) || (getValues("ismhcandidate") !== 1)}

                        SelectProps={{
                          MenuProps: { disableScrollLock: true },
                          style: { height: "56px", boxSizing: "border-box" },
                          onChange: (e) => {
                            setValue("isminority", e.target.value);
                            trigger('isminority');
                            if (e.target.value === 0) {
                              setValue("linguisticminorityid", "");
                              setValue("religiousminorityid", "");
                            }
                          },
                        }}
                        error={!!errors.isminority}
                        helperText={errors.isminority ? errors.isminority.message : ""}
                      >
                        <MenuItem value="" disabled>
                          Select One
                        </MenuItem>
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                      </TextField>
                    </Tooltip>
                  )}
                />

              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.LINGUISTIC_MINORITY || "Linguistic minority"
        ) && (
            // <Grid item xs={12} md={6}>
            //   <FormControl fullWidth error={!!errors.linguisticminorityid}>
            //     <InputLabel
            //       shrink={
            //         !!watch("linguisticminorityid") ||
            //         watch("linguisticminorityid") === 0
            //       }
            //       htmlFor="linguisticminorityid"
            //     >
            //       {masterFields?.find(
            //         (field) =>
            //           field?.masterfieldlabel ===
            //           masterFieldLabel.LINGUISTIC_MINORITY
            //       )?.ismandatory === "1" && (
            //           <span className="error" style={{ color: "red" }}>
            //             {" "}
            //             *
            //           </span>
            //         )}
            //       {
            //         masterFields?.find(
            //           (field) =>
            //             field?.masterfieldlabel ===
            //             masterFieldLabel.LINGUISTIC_MINORITY
            //         )?.fieldlabel
            //       }

            //     </InputLabel>
            //     <Controller
            //       name="linguisticminorityid"
            //       control={control}
            //       rules={{
            //         required:
            //           watch("isminority") === 1
            //             ? "Select Linguistic Minority is required"
            //             : false,
            //       }}
            //       render={({ field }) => (
            //         <Select
            //           MenuProps={{
            //             disableScrollLock: true,
            //           }}
            //           sx={{
            //             "& .MuiOutlinedInput-notchedOutline": {
            //               border:
            //                 getValues("isminority") !== 1
            //                   ? "2px solid grey"
            //                   : "2px solid #3F41D1",
            //             },
            //             "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            //               border:
            //                 getValues("isminority") !== 1
            //                   ? "2px solid grey"
            //                   : "2px solid #3F41D1",
            //             },
            //           }}
            //           inputProps={{
            //             style: { height: "56px", boxSizing: "border-box" },
            //             maxLength: 50,
            //           }}
            //           {...field}
            //           id="linguisticminorityid"
            //           label={
            //             <span>
            //               {masterFields?.find(
            //                 (field) =>
            //                   field?.masterfieldlabel ===
            //                   masterFieldLabel.LINGUISTIC_MINORITY
            //               )?.ismandatory === "1" && (
            //                   <span className="error" style={{ color: "red" }}>
            //                     {" "}
            //                     *
            //                   </span>
            //                 )}
            //               <span>
            //                 {
            //                   masterFields?.find(
            //                     (field) =>
            //                       field?.masterfieldlabel ===
            //                       masterFieldLabel.LINGUISTIC_MINORITY
            //                   )?.fieldlabel
            //                 }
            //               </span>

            //             </span>
            //           }
            //           disabled={getValues("isminority") !== 1 || isPaid === "1"}
            //           value={field.value ?? ""}
            //         >
            //           <MenuItem value="" disabled>
            //             Select One
            //           </MenuItem>
            //           {minority?.map(
            //             (item) =>
            //               item.type === "Linguistic Minority" && (
            //                 <MenuItem
            //                   key={item?.minorityid}
            //                   value={item?.minorityid || ""}
            //                 >
            //                   {item?.minority}
            //                 </MenuItem>
            //               )
            //           )}
            //         </Select>
            //       )}
            //     />
            //     {errors.linguisticminorityid && (
            //       <FormHelperText style={{ margin: 0 }}>
            //         {errors.linguisticminorityid.message}
            //       </FormHelperText>
            //     )}
            //   </FormControl>
            // </Grid>
            <Grid item xs={12} md={6} ref={linguisticminorityid}>
              <FormControl fullWidth error={!!errors.linguisticminorityid}>

                <Controller
                  name="linguisticminorityid"
                  control={control}
                  rules={{
                    required:
                      getValues("isminority") === 1
                        ?
                        "Select Linguistic Minority is required"
                        :
                        false,
                  }}
                  render={({ field }) => (
                    <Tooltip title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.LINGUISTIC_MINORITY
                      )?.fieldlabel
                    } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                      <TextField
                        name="linguisticminorityid"
                        inputRef={linguisticminorityid}
                        select
                        fullWidth
                        variant="outlined"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        InputLabelProps={{
                          shrink: !!watch("linguisticminorityid") ||
                            watch("linguisticminorityid") === 0,
                        }}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border:
                              getValues("isminority") !== 1
                                ? "2px solid grey"
                                : "2px solid #3F41D1",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border:
                              getValues("isminority") !== 1
                                ? "2px solid grey"
                                : "2px solid #3F41D1",
                          },
                        }}
                        inputProps={{
                          style: { height: "56px", boxSizing: "border-box" },
                          maxLength: 50,
                        }}
                        {...field}
                        id="linguisticminorityid"
                        label={
                          <span>
                            {masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.LINGUISTIC_MINORITY
                            )?.ismandatory === "1" && !(getValues("isminority") !== 1 || isPaid === "1") && (
                                <span className="error" style={{ color: "red" }}>
                                  {" "}
                                  *
                                </span>
                              )}
                            <span>
                              {
                                masterFields?.find(
                                  (field) =>
                                    field?.masterfieldlabel ===
                                    masterFieldLabel.LINGUISTIC_MINORITY
                                )?.fieldlabel
                              }
                            </span>
                          </span>
                        }
                        disabled={getValues("isminority") !== 1 || isPaid === "1"}
                        value={field.value ?? ""}
                        SelectProps={{
                          MenuProps: { disableScrollLock: true },
                          style: { height: "56px", boxSizing: "border-box" },

                        }}
                        error={!!errors.linguisticminorityid}
                        helperText={errors.linguisticminorityid ? errors.linguisticminorityid.message : ""}
                      >
                        <MenuItem value="" disabled>
                          Select One
                        </MenuItem>
                        {minority?.map(
                          (item) =>
                            item.type === "Linguistic Minority" && (
                              <MenuItem
                                key={item?.minorityid}
                                value={item?.minorityid || ""}
                              >
                                {item?.minority}
                              </MenuItem>
                            )
                        )}
                      </TextField></Tooltip>
                  )}
                />

              </FormControl>
            </Grid>

          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.RELIGIOUS_MINORITY
        ) && (
            <Grid item xs={12} md={6} ref={religiousminorityid}>
              <FormControl fullWidth error={!!errors.religiousminorityid}>

                <Controller
                  name="religiousminorityid"
                  control={control}
                  rules={{
                    required:
                      getValues("isminority") === 1
                        ? "Religious Minority of Candidate is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Tooltip title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.RELIGIOUS_MINORITY
                      )?.fieldlabel
                    } arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}>
                      <TextField
                        inputRef={religiousminorityid}
                        name="religiousminorityid"
                        select
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: !!watch("religiousminorityid") ||
                            watch("religiousminorityid") === 0,
                        }}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border:
                              getValues("isminority") !== 1
                                ? "2px solid grey"
                                : "2px solid #3F41D1",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border:
                              getValues("isminority") !== 1
                                ? "2px solid grey"
                                : "2px solid #3F41D1",
                          },
                        }}
                        inputProps={{
                          style: { height: "56px", boxSizing: "border-box" },
                          maxLength: 50,
                        }}
                        {...field}
                        id="religiousminorityid"
                        label={
                          <span>
                            {masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.RELIGIOUS_MINORITY
                            )?.ismandatory === "1" && !(getValues("isminority") !== 1 || isPaid === "1") && (
                                <span className="error" style={{ color: "red" }}>
                                  {" "}
                                  *
                                </span>
                              )}
                            <span>
                              {
                                masterFields?.find(
                                  (field) =>
                                    field?.masterfieldlabel ===
                                    masterFieldLabel.RELIGIOUS_MINORITY
                                )?.fieldlabel
                              }
                            </span>

                          </span>
                        }
                        SelectProps={{
                          MenuProps: { disableScrollLock: true },
                          style: { height: "56px", boxSizing: "border-box" },

                        }}
                        disabled={getValues("isminority") !== 1 || isPaid === "1"}
                        value={field.value ?? ""}
                        error={!!errors.religiousminorityid}
                        helperText={errors.religiousminorityid ? errors.religiousminorityid.message : ""}
                      >
                        <MenuItem value="" disabled>
                          Select One
                        </MenuItem>
                        {minority?.map(
                          (item) =>
                            item.type === "Religious Minority" && (
                              <MenuItem
                                key={item?.minorityid}
                                value={item?.minorityid || ""}
                              >
                                {item.minority}
                              </MenuItem>
                            )
                        )}
                      </TextField>
                    </Tooltip>
                  )}
                />

              </FormControl>
            </Grid>
          )}

        {/* <Grid item xs={12} md={6}>
          <FormControl fullWidth size="small" error={!!errors.isscriberequired}>
            <InputLabel
              shrink={
                !!watch("isscriberequired") || watch("isscriberequired") === 0
              }
              htmlFor="isscriberequired"
            >
              Do You Require Scribe?<span className="error">*</span>
            </InputLabel>
            <Select  MenuProps={{
                  disableScrollLock: true,
              }}
              id="isscriberequired"
              label="Do You Require Scribe?"
              {...register("isscriberequired", {
                required: "Require Scribe is required",
              })}
              onChange={handleScribe}
              value={watch("isscriberequired") ?? ""}
            >
              <MenuItem value="">Select One</MenuItem>
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
            {errors.isscriberequired && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.isscriberequired.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid> */}
        {/* <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            size="small"
            error={!!errors.isextratimerequired}
          >
            <InputLabel
              shrink={!!watch("isextratimerequired")}
              htmlFor="isextratimerequired"
            >
              Do You Require Extra Time?
              {scribe !== 0 && <span className="error">*</span>}
            </InputLabel>
            <Select  MenuProps={{
                  disableScrollLock: true,
              }}
              id="isextratimerequired"
              label="Do You Require Extra Time?"
              {...register("isextratimerequired", {
                required:
                  scribe !== 0 ? "Require extra time  is required" : false,
              })}
              disabled={scribe === 0 || getValues("isscribe") === 0}
              onChange={(e) => {
                setValue("isextratimerequired", e.target.value);
                trigger("isextratimerequired");
              }}
              value={watch("isextratimerequired") ?? ""}
            >
              <MenuItem value="">Select One</MenuItem>
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
            {errors.isextratimerequired && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.isextratimerequired.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid> */}

        <Grid
          item
          md={12}
          pt={2}
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : null,
            marginBottom: 1,
          }}
        >
          <BackButton handleBack={handleBack} />
          {/* <EditButton /> */}
          <SubmitButton
            disabled={isPaid === "1"}
            name={buttonLoader ? "loader" : "save and proceed"}
            handleSubmit={handleSubmit(handleProcced, onError)}
          />
        </Grid>
      </Grid>
    </Grid >
  );
}