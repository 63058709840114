import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
// import e DecryptObjectFunction } from "../../../../../CET_Student/src/utils/cryptoFunction";
// import { hashMobile } from "../../../../../CET_Student/src/utils/validations";
import { useDispatch } from "react-redux";
import authActions from "../../redux/auth/actions";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { hashMobile } from "../../utils/validations";
import { DecryptFunction, DecryptObjectFunction } from "../../utils/cryptoFunction";
import PrimaryButton from "../../common/primaryButton";
import { cssProperties } from "../../utils/commonCssProperties";

export default function Index() {

  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [attempt, setAttempt] = useState(0);
  const [resendCountdown, setResendCountdown] = useState(120);
  const [wrongOtp, setWrongotp] = useState(0);
  const otpValue = DecryptFunction(Cookies.get('mhet_cnd_mobileno_otp'));
  const modifyOtp = otp.split("");
  useEffect(() => {
    if (Cookies.get('mhet_cnd_mobileno_verified') === true) {
      navigate(`/${Cookies.get('mhet_cnd_project')}/`);
    }
  }, []);

  useEffect(() => {
    let timer;
    if (resendCountdown > 0) {
      timer = setTimeout(() => setResendCountdown(resendCountdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [resendCountdown]);

  
  const handleVerify = () => {

    if (modifyOtp.length === 6) {
      if (modifyOtp.join("") === otpValue) {
        Cookies.remove('mhet_cnd_mobileno_otp');
        Cookies.remove('mhet_cnd_mobileno');
        dispatch({ type: authActions.VERIFY_MOBILENO_FOR_SIGNUP, payload: { data: { token: Cookies.get('mhet_cnd_temptoken') }, navigate: navigate } })
        // navigate(`/${Cookies.get('mhet_cnd_project')}/mobileno-verification`);
      } else {
        setError("Invalid OTP");
        setWrongotp(wrongOtp + 1)
      }
    } else {
      setError("Enter valid OTP");
    }
  };

  const handleResendOTP = () => {
    setOtp('')
    setResendCountdown(120);
    dispatch({
      type: authActions.SEND_OTP_FOR_MOBILE_VERIFICATION,
      payload: { data: { mobileno: Cookies.get('mhet_cnd_mobileno'), navigate: navigate } },
    });
    setAttempt(attempt + 1)
  };

  const inputStyle = {
    width: "38px",
    height: "40px",
    margin: "5px",
    borderRadius: "8px",
    border: modifyOtp.join("") !== otpValue
      ? "1px solid #D5D4DC":"1px solid red",
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "100vh", width: "100vw" }}>
      <Box

        m={3}
        sx={{
          backgroundColor: "#fff",
          borderRadius: '8px',
          minWidth: "10rem",
          width: "35rem",
          padding: '24px',
        }}

      >
        <Typography variant="h5" py={2} sx={{ color: '#212121', fontSize: "20px", fontWeight: "500" }}>
          Mobile No Verification
        </Typography>
        <Typography variant="body1" py={2} sx={{ color: '#666666' }}>
          OTP has been sent to {hashMobile(DecryptObjectFunction(Cookies.get('mhet_cnd_tempdata'))?.mobileno)}
        </Typography>
        <Box className="otp" display="flex" flexDirection="column" alignItems="start">
          <Typography variant="body1" my={1} sx={{ color: '#212121', fontSize: "16px", fontWeight: "400" }}>
            Enter OTP<span style={{ color: "red", padding: "0px 4px" }}>*</span>
          </Typography>
          <Box my={2}>
            <OTPInput
              inputStyle={inputStyle}
              value={otp}
              onChange={setOtp}
              numInputs={5}
              renderInput={(props) => <input {...props} />}
            />
            <Grid
              sx={{
                width: "18rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {!resendCountdown > 0  && (
                <Typography
                  mt={2}
                  sx={{
                    cursor: "pointer",
                    color: `${cssProperties?.color?.primary}`,
                    fontWeight: "bold",
                  }}
                  onClick={handleResendOTP}
                >
                  Resend OTP
                </Typography>
              )}

              {resendCountdown > 0 && (

                <Typography
                  sx={{
                    color: `6B6B6B`,
                    fontWeight: "bold",
                  }}
                  textAlign={"center"}
                  mt={2}

                >
                  RESEND OTP IN &nbsp;
                  {resendCountdown > 0 ? `(${resendCountdown}) SEC` : "0 SEC"}
                </Typography>

              )}
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: ".2rem" }}>
              <small>{attempt} /  5 &nbsp;</small>
            </Box>
          </Box>
        </Box>
        <Box className="verify-resend" my={4} display="flex" justifyContent="start">
          {PrimaryButton({ title: "verify", onClick: handleVerify, disabled: resendCountdown === 0 ? true : false })}
          <Button
            variant="contained"
            sx={{
              height: '40px',
              padding: '0 24px',
              backgroundColor: '#E1E8F5',
              color: '#3F41D1',
              borderRadius: '40px',
              ml: 3,
              boxShadow: "none",
              '&:hover': {
                backgroundColor: '#E1E8F5',
                boxShadow: "none"
              }
            }}
            disabled={resendCountdown > 0}
            onClick={handleResendOTP}
          >
            Resend OTP
          </Button>
        </Box>
        <Box>
          <Typography variant="body2" sx={{ color: 'red' }}>
            {error && <small className="error">{error}</small>}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" sx={{ color: 'red' }}>
            {resendCountdown > 0 ? `${resendCountdown}s` : "0s"} Time out, request to resend OTP
          </Typography>
        </Box>
      </Box>
    </Box>

  );
}



// import React, { useEffect, useState } from "react";
// import OTPInput from "react-otp-input";
// import { useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";
// import { useDispatch } from "react-redux";
// import authActions from "../../redux/auth/actions";
// import { Box, Button, Typography, Grid } from "@mui/material";
// import { hashMobile } from "../../utils/validations";
// import { DecryptFunction, DecryptObjectFunction } from "../../utils/cryptoFunction";
// import PrimaryButton from "../../common/primaryButton";
// import { cssProperties } from "../../utils/commonCssProperties";

// export default function Index() {
//   const [otp, setOtp] = useState("");
//   const [error, setError] = useState("");
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [attempt, setAttempt] = useState(0);
//   const [resendCountdown, setResendCountdown] = useState(120);
//   const [wrongOtp, setWrongOtp] = useState(0);

//   const otpValue = DecryptFunction(Cookies.get("mhet_cnd_mobileno_otp"));
//   const modifyOtp = otp.split("");

//   // Safely retrieve and decrypt the mobile number
//   const tempData = Cookies.get("mhet_cnd_tempdata");
//   let mobileNumber = null;

//   if (tempData) {
//     try {
//       const decryptedData = DecryptObjectFunction(tempData);
//       mobileNumber = decryptedData?.mobileno || null;
//     } catch (error) {
//       console.error("Error decrypting temp data:", error);
//     }
//   }

//   useEffect(() => {
//     if (Cookies.get("mhet_cnd_mobileno_verified") === "true") {
//       navigate(`/${Cookies.get("mhet_cnd_project")}/`);
//     }
//   }, [navigate]);

//   useEffect(() => {
//     let timer;
//     if (resendCountdown > 0) {
//       timer = setTimeout(() => setResendCountdown(resendCountdown - 1), 1000);
//     }
//     return () => clearTimeout(timer);
//   }, [resendCountdown]);

//   const handleVerify = () => {
//     if (modifyOtp.length === 6) {
//       if (modifyOtp.join("") === otpValue) {
//         Cookies.remove("mhet_cnd_mobileno_otp");
//         Cookies.remove("mhet_cnd_mobileno");
//         dispatch({
//           type: authActions.VERIFY_MOBILENO_FOR_SIGNUP,
//           payload: { data: { token: Cookies.get("mhet_cnd_temptoken") }, navigate },
//         });
//       } else {
//         setError("Invalid OTP");
//         setWrongOtp(wrongOtp + 1);
//       }
//     } else {
//       setError("Enter a valid OTP");
//     }
//   };

//   const handleResendOTP = () => {
//     setOtp("");
//     setResendCountdown(120);
//     dispatch({
//       type: authActions.SEND_OTP_FOR_MOBILE_VERIFICATION,
//       payload: { data: { mobileno: Cookies.get("mhet_cnd_mobileno"), navigate } },
//     });
//     setAttempt(attempt + 1);
//   };

//   const inputStyle = {
//     width: "38px",
//     height: "40px",
//     margin: "5px",
//     borderRadius: "8px",
//     border:
//       modifyOtp.join("") !== otpValue ? "1px solid #D5D4DC" : "1px solid red",
//   };

//   return (
//     <Box
//       sx={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         height: "100vh",
//         width: "100vw",
//       }}
//     >
//       <Box
//         m={3}
//         sx={{
//           backgroundColor: "#fff",
//           borderRadius: "8px",
//           minWidth: "10rem",
//           width: "35rem",
//           padding: "24px",
//         }}
//       >
//         <Typography variant="h5" py={2} sx={{ color: "#212121", fontSize: "20px", fontWeight: "500" }}>
//           Mobile No Verification
//         </Typography>
//         <Typography variant="body1" py={2} sx={{ color: "#666666" }}>
//           OTP has been sent to {mobileNumber ? hashMobile(mobileNumber) : "an unknown number"}
//         </Typography>
//         <Box className="otp" display="flex" flexDirection="column" alignItems="start">
//           <Typography variant="body1" my={1} sx={{ color: "#212121", fontSize: "16px", fontWeight: "400" }}>
//             Enter OTP<span style={{ color: "red", padding: "0px 4px" }}>*</span>
//           </Typography>
//           <Box my={2}>
//             <OTPInput
//               inputStyle={inputStyle}
//               value={otp}
//               onChange={setOtp}
//               numInputs={5}
//               renderInput={(props) => <input {...props} />}
//             />
//             <Grid
//               sx={{
//                 width: "18rem",
//                 display: "flex",
//                 justifyContent: "space-between",
//               }}
//             >
//               {!resendCountdown > 0 && (
//                 <Typography
//                   mt={2}
//                   sx={{
//                     cursor: "pointer",
//                     color: `${cssProperties?.color?.primary}`,
//                     fontWeight: "bold",
//                   }}
//                   onClick={handleResendOTP}
//                 >
//                   Resend OTP
//                 </Typography>
//               )}
//               {resendCountdown > 0 && (
//                 <Typography
//                   sx={{
//                     color: "#6B6B6B",
//                     fontWeight: "bold",
//                   }}
//                   textAlign={"center"}
//                   mt={2}
//                 >
//                   RESEND OTP IN &nbsp;({resendCountdown}) SEC
//                 </Typography>
//               )}
//             </Grid>
//             <Box sx={{ display: "flex", justifyContent: "flex-end", mt: ".2rem" }}>
//               <small>{attempt} / 5 &nbsp;</small>
//             </Box>
//           </Box>
//         </Box>
//         <Box className="verify-resend" my={4} display="flex" justifyContent="start">
//           {PrimaryButton({
//             title: "Verify",
//             onClick: handleVerify,
//             disabled: resendCountdown === 0,
//           })}
//           <Button
//             variant="contained"
//             sx={{
//               height: "40px",
//               padding: "0 24px",
//               backgroundColor: "#E1E8F5",
//               color: "#3F41D1",
//               borderRadius: "40px",
//               ml: 3,
//               boxShadow: "none",
//               "&:hover": {
//                 backgroundColor: "#E1E8F5",
//                 boxShadow: "none",
//               },
//             }}
//             disabled={resendCountdown > 0}
//             onClick={handleResendOTP}
//           >
//             Resend OTP
//           </Button>
//         </Box>
//         <Box>
//           <Typography variant="body2" sx={{ color: "red" }}>
//             {error && <small className="error">{error}</small>}
//           </Typography>
//         </Box>
//         <Box>
//           <Typography variant="body2" sx={{ color: "red" }}>
//             {resendCountdown > 0 ? `${resendCountdown}s` : "0s"} Time out, request to resend OTP
//           </Typography>
//         </Box>
//       </Box>
//     </Box>
//   );
// }
