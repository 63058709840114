export const downloadBase64 = (data) => {

try{
    // console.log('inside download',data?.base64,data?.coursename,data?.fileExtension)
    const fileName = `${data?.coursename}.${data?.fileExtension}`; // Change to the desired file name and extension
    //  const blob = new Blob([atob(result?.data)], { type: "application/octet-stream" }); // Decode Base64
    //  const url = window.URL.createObjectURL(blob);
    
     const a = document.createElement("a");
     a.href = data?.base64;
     a.download = fileName;
     a.click();
     a.remove()
    
    //  window.URL.revokeObjectURL(url);
}catch(e){
    console.log(e)
}
};
