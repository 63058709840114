import React from "react";
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  useTheme,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";

export default function Tabs(props) {
  const navigate = useNavigate();
  const {
    activeTab,
    RegistrationData,
    profileRegistrationActiveTab,
    filledForms,
    status,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const tabStatus =
    profileRegistrationActiveTab?.cu1_status === 2 &&
    profileRegistrationActiveTab?.cu2_status === 2 &&
    profileRegistrationActiveTab?.cu3_status === 2
      ? 4
      : profileRegistrationActiveTab?.other_status === 2
      ? 3
      : profileRegistrationActiveTab?.address_status
      ? 2
      : profileRegistrationActiveTab?.candidate_status === 2
      ? 1
      : 0;

  const allTabsCompleted =
    profileRegistrationActiveTab?.other_status === 2 &&
    profileRegistrationActiveTab?.address_status === 2 &&
    profileRegistrationActiveTab?.candidate_status === 2 &&
    profileRegistrationActiveTab?.cu1_status === 2 &&
    profileRegistrationActiveTab?.cu2_status === 2 &&
    profileRegistrationActiveTab?.cu3_status === 2;

  const StyledStepLabel = styled(StepLabel)(
    ({ theme, clickable, completed, index, activeTab,navigatableTab,allTabsCompleted }) => ({
      "&.MuiStepLabel-root": {
        cursor: clickable || allTabsCompleted || navigatableTab ? "pointer" : "none",
        cursor: "default",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        color: completed ? "green" : "black",
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        transition: "background-color 0.3s",
      },
      "& .MuiStepLabel-iconContainer": {
        marginRight: theme.spacing(1),
        background: completed ? "green" : index === activeTab ? "#0F4F96" : "",
        color: index === activeTab ? "white" : "inherit",
        cursor: "default",
        borderRadius: "50%",
        padding: theme.spacing(0.5),
        transition: "background-color 0.3s, color 0.3s",
      },
      "& .MuiStepLabel-label": {
        color: completed
          ? "green"
          : index === activeTab
          ? "#0F4F96"
          : "inherit",
        cursor: "default",
        fontWeight: clickable || index === activeTab || completed ? "bold" : "normal",
        textAlign:'left'
      },
    })
  );

  const handleTabClick = (i,to) => {
    if(tabStatus >= i + 1){
      navigate(to)
    }
  }

  return (
    <Grid
      sx={{
        height: "auto",
        backgroundColor: "#0F4F9633",
        margin: "10px 0px",
        padding: "15px 15px",
        borderRadius: "8px",
      }}
    >
      <Stepper
        activeStep={activeTab}
        orientation={isMobile ? "vertical" : "horizontal"}
      >
        {RegistrationData.map((item, index) => {
          const clickable = allTabsCompleted || index <= filledForms;
          const completed = status >= index + 1;
          const navigatableTab = tabStatus >= index + 1;
// console.log(tabStatus,'tabStatus')
          return (
            <Step key={index}>
              <StyledStepLabel
                onClick={() => handleTabClick(index,item?.to)}
                clickable={clickable}
                completed={completed}
                navigatableTab={navigatableTab}
                index={index}
                sx={{
                  fontWeight:
                      allTabsCompleted || index === activeTab || navigatableTab
                        ? "bold"
                        : "normal",
                  "& .MuiStepLabel-label": {
                    color:
                      allTabsCompleted || completed
                        ? "green"
                        : index === activeTab
                        ? "#001F3FB2"
                        : "inherit",
                    cursor: navigatableTab ? "pointer" : "default",
                    fontWeight:
                      allTabsCompleted || index === activeTab || navigatableTab
                        ? "bold"
                        : "normal",
                  },
                }}
                icon={
                  completed ? (
                    <FaCheckCircle size={28} sx={{ color: "green" }} />
                  ) : (
                    item.icon
                  )
                }
              >
                {item.title}
              </StyledStepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Grid>
  );
}
