import React, { useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop, convertToPixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Modal, Box, Typography, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SubmitButton from "../../common/submitButton";
import SaveButton from "../../common/saveButton";

const MIN_DIMENSION = 100;

export default function ImageCropSignature({
  showModal,
  handleClose,
  image,
  onCropDone,
  onCropCancel,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [aspectRatio] = useState(4 / 3);

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;

    const initialCrop = makeAspectCrop(
      {
        unit: "%",
        width: (MIN_DIMENSION / width) * 100, // Ensures a minimum dimension crop
      },
      aspectRatio,
      width,
      height
    );
    const centeredCrop = centerCrop(initialCrop, width, height);
    setCrop(centeredCrop);
  };

  const onCropImage = () => {
    if (imgRef.current && crop) {
      const pixelCrop = convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height);

      const canvas = document.createElement("canvas");
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        imgRef.current,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );

      const dataURL = canvas.toDataURL("image/jpeg");
      onCropDone(dataURL, pixelCrop);
    }
  };

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      aria-labelledby="crop-image-signature-modal-title"
      aria-describedby="crop-image-signature-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          boxShadow: 24,
          p: isMobile ? 2 : 1,
          width: isMobile ? "90%" : 800,
          maxWidth: isMobile ? "100%" : "none",
        }}
      >
        <Typography id="crop-image-signature-modal-title" variant="h6" component="h2" pt={1} px={2}>
          Crop Image Signature
        </Typography>
        <Box
          sx={{
            height: isMobile ? "40vh" : "50vh",
            maxHeight: "70vh",
            overflow: "hidden",
            position: "relative",
            mt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactCrop
            crop={crop}
            onChange={(newCrop) => setCrop(newCrop)}
            // aspect={aspectRatio}
            minWidth={MIN_DIMENSION}
            keepSelection
          >
            <img
              ref={imgRef}
              src={image}
              alt="Crop preview"
              onLoad={onImageLoad}
              style={{ maxHeight: "90vh" }}
            />
          </ReactCrop>
        </Box>

        <Grid container justifyContent="flex-end" spacing={2} sx={{ my: 1 }}>
          <Grid item>
            <SaveButton name="Cancel" handleDraft={onCropCancel} />
          </Grid>
          <Grid item>
            <SubmitButton name="Crop & Apply" handleSubmit={onCropImage} />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
