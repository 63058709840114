import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Breadcrumbs,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Menu, MenuItem, Avatar, List, ListItem, Divider } from "@mui/material";
import { cssProperties } from "../utils/commonCssProperties";
import notification from "./../assets/images/sidebar/notification.svg";
import { MdNavigateNext, MdPerson } from "react-icons/md";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import { RiCustomerServiceLine } from "react-icons/ri";
import "../assets/styles/common.css";
import authActions from "../redux/auth/actions";
import { RiArrowDropUpLine } from "react-icons/ri";
import ChangePassword from "../../src/components/changepasword/changepassword";
import SupportModal from "../components/modals/supportModal";
import commonActions from "../redux/common/actions";
import candidatesActions from "../redux/candidates/actions";
import keycloakService from "../modules"
export default function Header({ breadcrumbs }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const[notify,setNotify]=useState(false)
  const { candidateTokenDetails } = useSelector((state) => state.authReducer);
  const { showSidebar } = useSelector((state) => state.commonReducer);
  const { uploadDetails } = useSelector((state) => state.candidatesReducer);
  const candidateid = candidateTokenDetails?.candidateid;

  let userName = "";

  if (candidateTokenDetails && candidateTokenDetails.candidatename) {
    const shortendName = candidateTokenDetails.candidatename.trim().split(" ");
    if (shortendName.length > 0) {
      userName = shortendName[0];
    }
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  useEffect(() => {
    if (candidateid) {
      setTimeout(()=>{
        dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });
      },1000)
    }
  }, [candidateid]);
  
  useEffect(() => {
    if (candidateid) {
        dispatch({ type: commonActions.SHOW_SIDEBAR ,payload:uploadDetails && uploadDetails[0]?.candidateuploaddraftid ? false : true});
    }
  }, [candidateid,uploadDetails]);

  const handleLogout = () => {
    dispatch({ type: authActions.CANDIDATE_LOGOUT });
  };

  console.log("keycloakService.getUserInfo"
    ,keycloakService.getUserInfo("given_name")
    ,keycloakService.getUserInfo("First name")
    ,keycloakService.getUserInfo("phoneNumber")
    ,keycloakService.getUserInfo("email")
    ,keycloakService.getUserInfo("Last name")
    ,keycloakService.getUserInfo("attributes.phoneNumber")
    ,keycloakService.getUserInfo("Username")
    ,keycloakService.getUserInfo("attributes.birthdate")
,keycloakService.getUserInfo("useridentity")
,keycloakService
  )
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width:`calc(100% - ${cssProperties?.drawerWidth}px)`,
          ml:  `${cssProperties?.drawerWidth}px` ,
          border: "none",
          boxShadow: "none",
          backgroundColor: `${cssProperties?.backgroundcolor?.white}`,
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            '@media (max-width: 738px)': {
          display: 'block', 
          
        },
          }}
        >
          <CommonBreadcrumb breadcrumbs={breadcrumbs} />
          <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
            {/* <MdOutlineNotifications
              size={24}
              style={{
                height: "48px",
                width: "48px",
                borderRadius: "70px",
                border: `1px solid ${cssProperties?.bordercolor?.secondary}`,
                backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
                padding: "10px",
                color: `${cssProperties?.color?.gray}`,
              }}
            /> */}
            <RiCustomerServiceLine
              src={notification}
              alt="notification"
              style={{
                height: "48px",
                width: "auto",
                borderRadius: "70px",
                border: `1px solid ${cssProperties?.bordercolor?.black}`,
                backgroundColor: `${cssProperties?.backgroundcolor?.white}`,
                padding: "9px",
                cursor:"pointer",
                color: `${cssProperties?.color?.black}`,
              }}
              onClick={()=>{setNotify(true)}}
            />
            <Link to={`/${Cookies.get("mhet_cnd_project")}/candidate-details`}>
              <Box sx={{ position: "relative" }}>
                <MdPerson
                  size={24}
                  style={{
                    height: "48px",
                    width: "48px",
                    borderRadius: "70px",
                    border: `1px solid ${cssProperties?.bordercolor?.secondary}`,
                    backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
                    padding: "10px",
                    color: `${cssProperties?.color?.primary}`,
                  }}
                />
                {/* <MdMenu
                  size={10}
                  style={{
                    position: "absolute",
                    bottom: 0,
                    color: `${cssProperties?.color?.neutralgrey}`,
                    right: 0,
                    border: `0.5px solid ${cssProperties?.bordercolor?.secondary}`,
                    height: "16px",
                    width: "16px",
                    borderRadius: "14px",
                    padding: "3px",
                    backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
                    boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.21)",
                  }}
                /> */}
              </Box>
            </Link>
            <Box>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{
                 textTransform: "uppercase",
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: `${cssProperties?.color?.black}`,
                }}
              >
                {keycloakService.getUserInfo("given_name")} {open ? <RiArrowDropUpLine size={24} /> : <RiArrowDropDownLine size={24} />}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                anchorReference="anchorPosition"
                anchorPosition={
                  anchorEl
                    ? {
                      top: anchorEl.getBoundingClientRect().bottom + 20,
                      left: anchorEl.getBoundingClientRect().right,
                    }
                    : undefined
                }
                sx={{
                  "& .MuiPaper-root": {
                    borderRadius: "8px",
                    overflow: "hidden",
                  },
                }}
              >
                {/* Profile section */}
                <ListItem style={{ padding: "24px", height: "112px" }}>
                  <MdPerson
                    size={24}
                    style={{
                      height: "64px",
                      width: "64px",
                      borderRadius: "70px",
                      border: `1px solid ${cssProperties?.bordercolor?.secondary}`,
                      backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
                      padding: "10px",
                      color: `${cssProperties?.color?.primary}`,
                    }}
                  />
                  <Typography sx={{ ml: 2 }}
                  style={{
                    textTransform: "uppercase"
                  }}>{keycloakService.getUserInfo("given_name")}</Typography>
                  {/* <Typography sx={{ ml: 2 }}>{userName}</Typography> */}
                </ListItem>
                <Divider style={{ padding: 0, margin: 0 }} />
                {/* <MenuItem
                  sx={{ padding: "16px, 24px, 16px, 24px", height: "47px" }}
                  onClick={(e) => {
                    e.preventDefault()
                    handleClose();
                    setShowModal(true);
                  }}
                >
                  Change Password
                </MenuItem> */}

                <MenuItem
                  sx={{ padding: "16px, 24px, 16px, 24px", height: "47px" }}
                  onClick={() => handleLogout()}
                  // onClick={() => keycloakService.doLogout()}
                >
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <ChangePassword showModal={showModal} setShowModal={setShowModal} handleClose={() => setShowModal(false)} />
      <SupportModal showModal={notify} handleClose={() => setNotify(false)}/>
    </>
  );
}

function CommonBreadcrumb({ breadcrumbs }) {
  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<MdNavigateNext size={16} />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
