import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import authActions from '../../redux/auth/actions';
import { DecryptObjectFunction } from '../../utils/cryptoFunction';
import { Box, Container, Typography, Button, Grid } from '@mui/material';
// import Header from '../../layouts/header';
// import Layout from '../../layouts/layout';
// import Sidebar from '../../layouts/sideBar';

export default function EmailVerification() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (Cookies.get('mhet_cnd_email_verified') === true) {
      // navigate(`/${Cookies.get('mhet_cnd_project')}/verify-mobileno`);
    }
  }, []);

  const handleCheckEmailVerification = () => {
    if (Cookies.get('mhet_cnd_temptoken')) {
      dispatch({
        type: authActions.CHECK_EMAIL_VERIFICAITON,
        payload: { data: { token: Cookies.get('mhet_cnd_temptoken') }, navigate: navigate },
      });
    }
  };

  const handleResendEmail = () => {
    if (Cookies.get('mhet_cnd_temptoken')) {
      dispatch({
        type: authActions.RESEND_EMAIL_FOR_VERIFICATION,
        payload: { data: { token: Cookies.get('mhet_cnd_temptoken') } },
      });
    }
  };

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <Grid container sx={{minHeight:`calc(95vh - 80px)`,display:"flex",justifyContent:"center",alignItems:"center"}}>
      <Grid item sx={{ width:"50vw",maxHeight:"100vh",textWrap:"wrap"}}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            borderRadius: "8px",
            padding:'24px',
          }}
        >
          <Typography variant="h5" gutterBottom sx={{fontSize:'20px',fontWeight:500,color:'#212121'}}>
            Email Verification
          </Typography>
          <Box>
            <Typography sx={{ fontSize:'16px',fontWeight:'400',color:'#212121', wordWrap:"break-word"}}>
              An Email with instructions to verify your email address has been sent to your address{' '}
              {DecryptObjectFunction(Cookies.get('mhet_cnd_tempdata'))?.emailid}. Please visit your registered email
              address for verification.
            </Typography>
            <Typography sx={{ fontSize:'16px',fontWeight:'400',color:'#212121',  mt: 2 }}>
              Haven't received a verification link in your email?{' '}
              <Button color="primary" onClick={handleResendEmail}>
                Click here
              </Button>{' '}
              to re-send the email. Incase you do not find the email in your primary Inbox, please check your Spam
              folder, please mark the email as "Not a Spam" and proceed with the email verification.
            </Typography>
            <Typography sx={{ fontSize:'16px',fontWeight:'400',color:'#212121',  mt: 2 }}>
              Once, the email is verified, please{' '}
              <Button color="primary" onClick={handleCheckEmailVerification}>
                Click here
              </Button>{' '}
              to re-login.
            </Typography>
          </Box>
        </Box>
      </Grid>
      </Grid>
    </>
  );
}