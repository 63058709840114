import { put, call, all, takeEvery, delay, select } from "redux-saga/effects";
import commonActions from "../common/actions";
import candidatesActions from "./actions";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import masterActions from "../master/action";
import authActions from "../auth/actions";
import Cookies from "js-cookie";
import { calculateDaysDifference, formatDate } from "../../utils/validations";
import { EncryptObjectFunction } from "../../utils/cryptoFunction";
import {
  handleDecodeJSON,
  handleEncodeJson,
} from "../../utils/encodeDecodeJson";
import { downloadBase64 } from "../../utils/downloadBase64";

const candidatesSaga = function* () {
  yield all([
    yield takeEvery(
      candidatesActions.PROFILE_PERSONAL_DETAILS_CREATION,
      profilePersonalDetailsCreation
    ),
    yield takeEvery(
      candidatesActions.GET_PROFILE_PERSONAL_DETAILS,
      getProfilePersonalDetails
    ),
    yield takeEvery(
      candidatesActions.PROFILE_ADDRESS_DETAILS_CREATION,
      addressPersonalDetailsCreation
    ),
    yield takeEvery(
      candidatesActions.PROFILE_CATEGORY_DETAILS_CREATION,
      categoryPersonalDetailsCreation
    ),
    yield takeEvery(
      candidatesActions.PROFILE_EDUCATION_DETAILS_CREATION,
      educationPersonalDetailsCreation
    ),
    yield takeEvery(
      candidatesActions.PROFILE_EXAM_DETAILS_CREATION,
      examPersonalDetailsCreation
    ),

    yield takeEvery(
      candidatesActions.PROFILE_CERTIFICATES_DETAILS_CREATION,
      certificatesPersonalDetailsCreation
    ),
    yield takeEvery(
      candidatesActions.PROFILE_UPLOADS_DETAILS_CREATION,
      uploadsPersonalDetailsCreation
    ),
    yield takeEvery(
      candidatesActions.GET_ADDRESS_PERSONAL_DETAILS,
      getAddressPersonalDetails
    ),
    yield takeEvery(
      candidatesActions.GET_CATEGORY_PERSONAL_DETAILS,
      getCategoryPersonalDetails
    ),
    yield takeEvery(
      candidatesActions.GET_EDUCATION_PERSONAL_DETAILS,
      getEducationPersonalDetails
    ),
    yield takeEvery(
      candidatesActions.GET_EXAM_PERSONAL_DETAILS,
      getExamPersonalDetails
    ),
    yield takeEvery(
      candidatesActions.GET_CERTIFICATE_PERSONAL_DETAILS,
      getCertificatePersonalDetails
    ),
    yield takeEvery(
      candidatesActions.PROFILE_PERSONAL_DETAILS_DRAFT_CREATION,
      personalDetailsDraftCreation
    ),
    yield takeEvery(
      candidatesActions.PROFILE_ADDRESS_DETAILS_DRAFT_CREATION,
      personalAddressDetailsDraftCreation
    ),
    yield takeEvery(
      candidatesActions.PROFILE_CATEGORY_DETAILS_DRAFT_CREATION,
      personalCategoryDetailsDraftCreation
    ),
    yield takeEvery(
      candidatesActions.PROFILE_EDUCATION_DETAILS_DRAFT_CREATION,
      personalEducationDetailsDraftCreation
    ),
    yield takeEvery(
      candidatesActions.PROFILE_EXAM_DETAILS_DRAFT_CREATION,
      personalExamDetailsDraftCreation
    ),
    yield takeEvery(
      candidatesActions.PROFILE_CERTIFICATE_DETAILS_DRAFT_CREATION,
      personalCertificatesDetailsDraftCreation
    ),
    yield takeEvery(
      candidatesActions.GRIEVANCE_QUERY_CREATION,
      grievanceQueryCreation
    ),
    yield takeEvery(
      candidatesActions.GET_QUERY_STATUS_BY_CANDIDATEID,
      getQueryStatusByCandidate
    ),
    yield takeEvery(
      candidatesActions.PROFILE_OTHER_DETAILS_CREATION,
      otherDetailsCreation
    ),
    yield takeEvery(
      candidatesActions.PROFILE_OTHER_DETAILS_DRAFT_CREATION,
      otherDetailsDraftCreation
    ),
    yield takeEvery(
      candidatesActions.GET_OTHER_PERSONAL_DETAILS,
      getOtherDetails
    ),
    yield takeEvery(
      candidatesActions.PROFILE_UPLOADS_DETAILS_DRAFT_CREATION,
      uploadsDraftCreation
    ),
    yield takeEvery(
      candidatesActions.GET_UPLOAD_PERSONAL_DETAILS,
      getUploadDetails
    ),
    yield takeEvery(
      candidatesActions.GET_COURSE_UPLOAD_PERSONAL_DETAILS,
      getCourseUploadDetails
    ),
    yield takeEvery(candidatesActions.GET_PINCODE, getPincode),
    yield takeEvery(
      candidatesActions.GET_PINCODE_CORRESPONDENCE,
      getPincodeCorrespondence
    ),
    yield takeEvery(
      candidatesActions.GET_FULL_CANDIDATE_DETAILS,
      getFullCandidateDetails
    ),

    yield takeEvery(
      candidatesActions.COURSE_PERSONAL_DETAILS_CREATION,
      coursePersonaldetailsCreation
    ),
    yield takeEvery(
      candidatesActions.COURSE_ADDRESS_DETAILS_CREATION,
      courseAddressdetailsCreation
    ),
    yield takeEvery(
      candidatesActions.COURSE_RESERVATION_DETAILS_CREATION,
      courseReservationdetailsCreation
    ),
    yield takeEvery(
      candidatesActions.COURSE_ACADEMIC_DETAILS_CREATION,
      courseAcademicdetailsCreation
    ),
    yield takeEvery(
      candidatesActions.COURSE_EXAM_DETAILS_CREATION,
      courseExamdetailsCreation
    ),
    yield takeEvery(
      candidatesActions.OTBS_COURSE_EXAM_DETAILS_CREATION,
      otbsCourseExamdetailsCreation
    ),

    yield takeEvery(
      candidatesActions.OTBS_UPDATE_COURSE_EXAM_DETAILS_CREATION,
      otbsUpdateCourseExamdetailsCreation
    ),

    yield takeEvery(
      candidatesActions.GET_COURSE_REGISTRATION_DETAILS,
      getAllCourseRegistrationDetails
    ),
    yield takeEvery(
      candidatesActions.GET_COURSE_ADDRESS_DETAILS,
      getCourseAddressDetails
    ),
    yield takeEvery(candidatesActions.GET_ALL_COURSE_LIST, getAllCourse),
    yield takeEvery(candidatesActions.GET_COURSE_TAB, getCourseTab),
    yield takeEvery(candidatesActions.GET_MASTER_FIELDS, getMasterFields),
    yield takeEvery(
      candidatesActions.GET_COURSE_ACTIVE_TAB,
      getCourseRegistrationActiveTab
    ),
    yield takeEvery(
      candidatesActions.GET_COURSE_APPLICANTID,
      getCourseApplicantId
    ),
    yield takeEvery(candidatesActions.GET_COURSE_STATUS, getCourseStatus),
    yield takeEvery(candidatesActions.GET_IS_PAID, getIsPaid),
    yield takeEvery(
      candidatesActions.GET_PAYMENT_RECEIPT_LIST,
      getPaymentReceiptList
    ),
    yield takeEvery(candidatesActions.GET_EXAM_STATE, getExamState),
    yield takeEvery(candidatesActions.GET_EXAM_DISTRICT, getExamDistrict),
    yield takeEvery(candidatesActions.GET_EXAM_LANGUAGE, getExamLanguage),
    yield takeEvery(
      candidatesActions.GET_OTBS_VENUE_DETAILS,
      getOtbsVenueDetails
    ),
    yield takeEvery(
      candidatesActions.GET_CURRENT_VENUE_DETAILS,
      getCurrentOtbsVenueDetails
    ),
    yield takeEvery(candidatesActions.GET_VENUE_DETAILS, getVenueDetails),

    yield takeEvery(
      candidatesActions.GET_COURSE_FORM_DETAILS,
      getCourseFormDetails
    ),
    yield takeEvery(candidatesActions.GET_CURRENT_COURSE, getCurrentCourse),
    yield takeEvery(
      candidatesActions.COURSE_PREVIEW_UPDATION,
      updatePreviewandPaymentStatus
    ),
    yield takeEvery(candidatesActions.CREATE_PRINT_LOG, createPrintLog),
    yield takeEvery(candidatesActions.EDIT_COURSE_DETAILS, updateCourseDetails),
    yield takeEvery(candidatesActions.EDIT_COURSE_DETAILS, getIpAddress),
    yield takeEvery(candidatesActions.DOWLOAD_BROCHURE, downloadBrochure),
  ]);
};

const getFullCandidateDetails = function* (data) {
  const candidateid = yield select(
    (state) => state?.authReducer?.candidateTokenDetails?.candidateid
  );
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/backoffice/candidate/details/${candidateid}`)
    );
    yield put({
      type: candidatesActions.SET_FULL_CANDIDATE_DETAILS,
      payload: result?.data?.statusCode === 200 ? result?.data?.result : [],
    });
  } catch (err) {
    console.log(err);
  }
};

const getProfilePersonalDetails = function* () {
  yield put({ type: authActions.CANDIDATE_TOKEN_VERIFY });
  yield delay(1000);
  const candidateid = yield select(
    (state) => state?.authReducer?.candidateTokenDetails?.candidateid
  );
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/registration/${candidateid}`)
    );
    const decryptedData = yield call(handleDecodeJSON, {
      decodeJSON: result?.data?.result,
    });
    if (result) {
      yield put({
        type: candidatesActions.SET_PROFILE_PERSONAL_DETAILS,
        payload: decryptedData,
      });
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getCategoryPersonalDetails = function* () {
  const candidateidid = yield select(
    (state) => state?.authReducer?.candidateTokenDetails?.candidateid
  );
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/registration/category/${12}`)
    );
    if (result) {
      yield put({
        type: candidatesActions.SET_CATEGORY_PERSONAL_DETAILS,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getAddressPersonalDetails = function* () {
  const candidateidid = yield select(
    (state) => state?.authReducer?.candidateTokenDetails?.candidateid
  );
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/registration/address/${candidateidid}`)
    );

    const decryptedData = yield call(handleDecodeJSON, {
      decodeJSON: result?.data?.result,
    });
    if (result) {
      yield put({
        type: candidatesActions.SET_ADDRESS_PERSONAL_DETAILS,
        payload: decryptedData,
      });
      yield put({ type: masterActions.GET_STATE });
      if (decryptedData[0]?.pstateid) {
        yield put({
          type: masterActions.GET_DISTRICT_FOR_REGISTRATION,
          payload: {
            stateid: decryptedData[0]?.pstateid,
            type: "parmanent",
          },
        });
      }
      if (decryptedData[0]?.pdistrictid) {
        yield put({
          type: masterActions.GET_TALUKA_FOR_REGISTRATION,
          payload: {
            districtid: decryptedData[0]?.pdistrictid,
            type: "parmanent",
          },
        });
      }
      if (decryptedData[0]?.ptalukaid) {
        yield put({
          type: masterActions.GET_VILLAGE_FOR_REGISTRATION,
          payload: {
            talukaid: decryptedData[0]?.ptalukaid,
            type: "parmanent",
          },
        });
      }
      yield delay(1000);
      if (decryptedData?.cstateid) {
        yield put({
          type: masterActions.GET_DISTRICT_FOR_REGISTRATION,
          payload: {
            stateid: decryptedData[0]?.cstateid,
            type: "corespondence",
          },
        });
      }
      if (decryptedData[0]?.cdistrictid) {
        yield put({
          type: masterActions.GET_TALUKA_FOR_REGISTRATION,
          payload: {
            districtid: decryptedData[0]?.cdistrictid,
            type: "corespondence",
          },
        });
      }
      if (decryptedData[0]?.ctalukaid) {
        yield put({
          type: masterActions.GET_VILLAGE_FOR_REGISTRATION,
          payload: {
            talukaid: decryptedData[0]?.ctalukaid,
            type: "corespondence",
          },
        });
      }

      if (decryptedData[0]?.pstateid) {
        yield put({
          type: masterActions.GET_DISTRICT_FOR_COURSE,
          payload: {
            stateid: decryptedData[0]?.pstateid,
            type: "parmanent",
          },
        });
        yield delay(100);
      }
      if (decryptedData[0]?.pdistrictid) {
        yield put({
          type: masterActions.GET_TALUKA_FOR_COURSE,
          payload: {
            districtid: decryptedData[0]?.pdistrictid,
            type: "parmanent",
          },
        });
        yield delay(100);
      }
      if (decryptedData[0]?.ptalukaid) {
        yield put({
          type: masterActions.GET_VILLAGE_FOR_COURSE,
          payload: {
            talukaid: decryptedData[0]?.ptalukaid,
            type: "parmanent",
          },
        });
        yield delay(100);
      }
      yield delay(1000);
      if (decryptedData[0]?.cstateid) {
        yield put({
          type: masterActions.GET_DISTRICT_FOR_COURSE,
          payload: {
            stateid: decryptedData[0]?.cstateid,
            type: "corespondence",
          },
        });
        yield delay(100);
      }
      if (decryptedData[0]?.cdistrictid) {
        yield put({
          type: masterActions.GET_TALUKA_FOR_COURSE,
          payload: {
            districtid: decryptedData[0]?.cdistrictid,
            type: "corespondence",
          },
        });
        yield delay(100);
      }
      if (decryptedData[0]?.ctalukaid) {
        yield put({
          type: masterActions.GET_VILLAGE_FOR_COURSE,
          payload: {
            talukaid: decryptedData[0]?.ctalukaid,
            type: "corespondence",
          },
        });
        yield delay(100);
      }
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getEducationPersonalDetails = function* () {
  const candidateidid = yield select(
    (state) => state?.authReducer?.candidateTokenDetails?.candidateid
  );
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/registration/education/${candidateidid}`)
    );
    if (result) {
      yield put({
        type: candidatesActions.SET_EDUCATION_PERSONAL_DETAILS,
        payload: result?.data?.result,
      });
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getExamPersonalDetails = function* () {
  const candidateidid = yield select(
    (state) => state?.authReducer?.candidateTokenDetails?.candidateid
  );
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/candidate/registration/exampreference/${candidateidid}`
      )
    );
    if (result) {
      yield put({
        type: candidatesActions.SET_EXAM_PERSONAL_DETAILS,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getCertificatePersonalDetails = function* () {
  const candidateidid = yield select(
    (state) => state?.authReducer?.candidateTokenDetails?.candidateid
  );
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/candidate/registration/certificates/${candidateidid}`
      )
    );
    if (result) {
      yield put({
        type: candidatesActions.SET_CERTIFICATE_PERSONAL_DETAILS,
        payload: result?.data?.result,
      });
      yield put({ type: masterActions.GET_ALL_DISTRICT });
      if (result?.data?.result[0]?.ewsdistrictid) {
        yield put({
          type: masterActions.GET_TALUKA,
          payload: result?.data?.result[0]?.ewsdistrictid,
        });
      }
    }
  } catch (err) {
    console.log(err);
  }
};

const profilePersonalDetailsCreation = function* (data) {
  const candidateidid = yield select(
    (state) => state?.authReducer?.candidateTokenDetails?.candidateid
  );
  const { payload } = data;
  const { navigate } = payload;
  const encodedData = yield call(handleEncodeJson, {
    encodeJSON: payload?.data,
  });
  try {
    const result = yield call(() =>
      axios.put(
        `${API_URL}/candidate/registration/${candidateidid}`,
        encodedData
      )
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      if (result?.data?.statusCode === 200) {
        navigate("/mhcet/registration?page=address");
        yield put({
          type: authActions.GET_PROFILE_REGISTRATION_ACTIVE_TAB,
          payload: { id: candidateidid },
        });
      }
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: null,
        show: false,
        message: null,
      },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const personalDetailsDraftCreation = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_DRAFT_BUTTON_LOADER, payload: true });
  try {
    const encodedData = yield call(handleEncodeJson, {
      encodeJSON: payload?.data,
    });
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/draft/personaldetails`, encodedData)
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      yield put({ type: candidatesActions.GET_PROFILE_PERSONAL_DETAILS });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: null,
        show: false,
        message: null,
      },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_DRAFT_BUTTON_LOADER, payload: false });
};

const addressPersonalDetailsCreation = function* (data) {
  const { payload } = data;
  const { navigate } = payload;
  const candidateidid = yield select(
    (state) => state?.authReducer?.candidateTokenDetails?.candidateid
  );
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const encodedData = yield call(handleEncodeJson, {
      encodeJSON: payload?.data,
    });

    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/registration/address`, encodedData)
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      if (result?.data?.statusCode === 200) {
        navigate("/mhcet/registration?page=otherdetails");
        yield put({
          type: authActions.GET_PROFILE_REGISTRATION_ACTIVE_TAB,
          payload: { id: candidateidid },
        });
      }
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: null,
        show: false,
        message: null,
      },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const personalAddressDetailsDraftCreation = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_DRAFT_BUTTON_LOADER, payload: true });
  try {
    const encodedData = yield call(handleEncodeJson, {
      encodeJSON: payload?.data,
    });
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/draft/address`, encodedData)
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: null,
        show: false,
        message: null,
      },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_DRAFT_BUTTON_LOADER, payload: false });
};

const otherDetailsCreation = function* (data) {
  const { payload } = data;
  const { navigate } = payload;
  const candidateidid = yield select(
    (state) => state?.authReducer?.candidateTokenDetails?.candidateid
  );
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const encodedData = yield call(handleEncodeJson, {
      encodeJSON: payload?.data,
    });
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/registration/otherdetails`, encodedData)
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      if (result?.data?.statusCode === 200) {
        navigate("/mhcet/registration?page=uploads");
        yield put({
          type: authActions.GET_PROFILE_REGISTRATION_ACTIVE_TAB,
          payload: { id: candidateidid },
        });
      }
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: null,
        show: false,
        message: null,
      },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const otherDetailsDraftCreation = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_DRAFT_BUTTON_LOADER, payload: true });
  try {
    const encodedData = yield call(handleEncodeJson, {
      encodeJSON: payload?.data,
    });
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/draft/otherdetails`, encodedData)
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      yield put({ type: candidatesActions.GET_OTHER_PERSONAL_DETAILS });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: null,
        show: false,
        message: null,
      },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_DRAFT_BUTTON_LOADER, payload: false });
};

const getOtherDetails = function* () {
  yield put({ type: authActions.CANDIDATE_TOKEN_VERIFY });
  yield delay(1000);
  const candidateidid = yield select(
    (state) => state?.authReducer?.candidateTokenDetails?.candidateid
  );
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/candidate/registration/otherdetails/${candidateidid}`
      )
    );
    const decryptedData = yield call(handleDecodeJSON, {
      decodeJSON: result?.data?.result,
    });
    if (result) {
      yield put({
        type: candidatesActions.SET_OTHER_PERSONAL_DETAILS,
        payload: decryptedData,
      });
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getUploadDetails = function* () {
  yield put({ type: authActions.CANDIDATE_TOKEN_VERIFY });
  yield delay(1000);
  const candidateidid = yield select(
    (state) => state.authReducer?.candidateTokenDetails?.candidateid
  );
  try {
    if(candidateidid){
      yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });

      const result = yield call(() =>
        axios.get(`${API_URL}/candidate/registration/uploads/${candidateidid}`)
    );
    if (result) {
      yield put({
        type: candidatesActions.SET_UPLOAD_PERSONAL_DETAILS,
        payload: result?.data?.statusCode === 200 ? result?.data?.result : [],
      });
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  }
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};
const getCourseUploadDetails = function* (payload) {
  yield put({ type: authActions.CANDIDATE_TOKEN_VERIFY });
  yield delay(1000);
  const courseId = payload?.data?.courseid;
  const candidateidid = yield select(
    (state) => state?.authReducer?.candidateTokenDetails?.candidateid
  );
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/candidate/registration/courseuploads/${candidateidid}/${courseId}`
      )
    );
    if (result) {
      yield put({
        type: candidatesActions.SET_COURSE_UPLOAD_PERSONAL_DETAILS,
        payload: result?.data?.statusCode === 200 ? result?.data?.result : [],
      });
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const categoryPersonalDetailsCreation = function* (data) {
  const { payload } = data;
  const { navigate } = payload;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/registration/category`, payload.data)
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      navigate("/mhcet/registration?page=education");
    }
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const personalCategoryDetailsDraftCreation = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/draft/category`, payload.data)
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
    }
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const educationPersonalDetailsCreation = function* (data) {
  const { payload } = data;
  const { navigate } = payload;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/registration/education`, payload.data)
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      navigate("/mhcet/registration?page=exampreference");
    }
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const personalEducationDetailsDraftCreation = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/draft/education`, payload.data)
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
    }
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const examPersonalDetailsCreation = function* (data) {
  const { payload } = data;
  const { navigate } = payload;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(
        `${API_URL}/candidate/registration/exampreference`,
        payload.data
      )
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      navigate("/mhcet/registration?page=certificates");
    }
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const personalExamDetailsDraftCreation = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/draft/exampreference`, payload.data)
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
    }
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const certificatesPersonalDetailsCreation = function* (data) {
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  const { payload } = data;
  const { navigate } = payload;
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/registration/certificates`, payload.data)
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      navigate("/mhcet/registration?page=uploads");
    }
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const personalCertificatesDetailsDraftCreation = function* (data) {
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  const { payload } = data;
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/draft/certificates`, payload.data)
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
    }
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const uploadsPersonalDetailsCreation = function* (action) {
  const candidateidid = yield select(
    (state) => state?.authReducer?.candidateTokenDetails?.candidateid
  );
  const { payload } = action;
  const { navigate, editStatus, coursename, courseid } = payload;
  const fileType = payload?.data?.filetype;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const formData = new FormData();
    const photoFile = new File(
      [payload.data.photofilename],
      `${candidateidid}_photo.jpeg`,
      { type: "image/jpeg" }
    );
    const signFile = new File(
      [payload.data.signfilename],
      `${candidateidid}_signature.jpeg`,
      {
        type: "image/jpeg",
      }
    );
    const documentFile = new File(
      [payload.data.documentfilename],
      payload?.data?.documentfilename?.name,
      {
        type:
          fileType == "pdf"
            ? "application/pdf"
            : fileType == "jpg"
            ? "image/jpeg"
            : fileType == "png"
            ? "image/png"
            : fileType == "docx"
            ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            : fileType === "doc"
            ? "application/msword"
            : "",
      }
    );
    const operation = payload.data.operation;
    const candidateid = payload.data.candidateid;
    const documentid = payload.data.documentid;
    const status = payload.data.status;

    formData.append(
      "photofilename",
      payload?.data?.photofilename ? photoFile : ""
    );
    formData.append("signfilename", payload.data.signfilename ? signFile : "");
    formData.append(
      "documentfilename",
      payload.data.documentfilename ? documentFile : ""
    );

    formData.append(
      "photoUrl",
      payload?.data?.photofilename ? "" : payload?.data?.photoUrl
    );
    formData.append(
      "signUrl",
      payload?.data?.signfilename ? "" : payload?.data?.signUrl
    );
    formData.append(
      "docUrl",
      payload?.data?.documentfilename ? "" : payload?.data?.docUrl
    );

    formData.append(
      "photoname",
      payload?.data?.photoname ? payload?.data?.photoname : ""
    );
    formData.append(
      "signname",
      payload.data.signname ? payload.data.signname : ""
    );
    formData.append(
      "docname",
      payload.data.docname ? payload.data.docname : ""
    );

    formData.append("operation", operation);
    formData.append("candidateid", candidateid);
    formData.append("documentid", documentid);
    formData.append("status", status);
    formData.append(
      "frompreview",
      payload?.data?.fromPreview ? payload?.data?.fromPreview : "false"
    );
    formData.append("filetype", fileType ? fileType : "");

    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/registration/uploads`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );
    if (result?.data?.statusCode === 200) {
      navigate(`/${Cookies.get("mhet_cnd_project")}/`);
      const queryParams = {
        courseid: courseid,
        coursename: coursename,
      };
      const encryptedParams = EncryptObjectFunction(queryParams);
      editStatus === 1
        ? navigate(
            `/${Cookies.get(
              "mhet_cnd_project"
            )}/editing-facility?c_data=${encryptedParams}`
          )
        : payload?.data?.fromPreview
        ? navigate(
            `/${Cookies.get(
              "mhet_cnd_project"
            )}/course-registration?page=preview`,
            {
              state: {
                courseid: payload?.data?.courseid,
                coursename: payload?.data?.coursename,
              },
            }
          )
        : navigate(`/${Cookies.get("mhet_cnd_project")}/home`);

      // console.log('payload?.data?.fromPreview---',payload?.data?.fromPreview);

      // if (!payload?.data?.fromPreview) {
      //   window.location.reload();
      // }
      // window.location.reload();
    }
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: payload?.data?.fromPreview ? false : true,
        message: payload?.data?.fromPreview ? "" : result?.data?.message,
      },
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: null,
        show: false,
        message: null,
      },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const uploadsDraftCreation = function* (action) {
  const candidateidid = yield select(
    (state) => state?.authReducer?.candidateTokenDetails?.candidateid
  );
  const { payload } = action;
  const fileType = payload?.data?.filetype;
  yield put({ type: commonActions.SET_DRAFT_BUTTON_LOADER, payload: true });
  try {
    const formData = new FormData();
    const photoFile = payload.data.photofilename
      ? new File([payload.data.photofilename], `${candidateidid}_photo.jpeg`, {
          type: "image/jpeg",
        })
      : null;
    const signFile = payload.data.signfilename
      ? new File(
          [payload.data.signfilename],
          `${candidateidid}_signature.jpeg`,
          {
            type: "image/jpeg",
          }
        )
      : null;
    const documentFile = payload.data.documentfilename
      ? new File(
          [payload.data.documentfilename],
          payload?.data?.documentfilename?.name,
          {
            type:
              fileType == "pdf"
                ? "application/pdf"
                : fileType == "jpg"
                ? "image/jpeg"
                : fileType == "png"
                ? "image/png"
                : fileType == "docx"
                ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                : fileType === "doc"
                ? "application/msword"
                : "",
          }
        )
      : null;

    const operation = payload.data.operation;
    const candidateid = payload.data.candidateid;
    const status = payload.data.status;

    formData.append(
      "photoUrl",
      payload?.data?.photofilename ? "" : payload?.data?.photoUrl
    );
    formData.append(
      "signUrl",
      payload?.data?.signfilename ? "" : payload?.data?.signUrl
    );
    formData.append(
      "docUrl",
      payload?.data?.documentfilename ? "" : payload?.data?.docUrl
    );

    formData.append(
      "photofilename",
      payload?.data?.photofilename ? photoFile : ""
    );
    formData.append("signfilename", payload.data.signfilename ? signFile : "");
    formData.append(
      "documentfilename",
      payload.data.documentfilename ? documentFile : ""
    );

    formData.append("operation", operation);
    formData.append("candidateid", candidateid);
    formData.append(
      "documentid",
      payload?.data?.documentid ? payload?.data?.documentid : ""
    );
    formData.append("status", status);

    formData.append("filetype", fileType ? fileType : "");

    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/draft/uploads`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );

    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });

    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: null,
        show: false,
        message: null,
      },
    });

    window.location.reload();
  } catch (err) {
    console.log(err);
  }

  yield put({ type: commonActions.SET_DRAFT_BUTTON_LOADER, payload: false });
};

const grievanceQueryCreation = function* (data) {
  const { payload } = data;
  const { navigate } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });

  try {
    const formData = new FormData();

    for (let key in payload?.data) {
      formData.append(key, payload?.data[key]);
    }

    const result = yield call(() =>
      axios.post(`${API_URL}/backoffice/grievance/ticket`, formData)
    );
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      navigate(`/${Cookies.get("mhet_cnd_project")}/query-list`);
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getQueryStatusByCandidate = function* () {
  const candidateidid = yield select(
    (state) => state?.authReducer?.candidateTokenDetails?.candidateid
  );
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/backoffice/grievance/ticket/candidate/${candidateidid}`
      )
    );
    if (result?.data?.statusCode === 200) {
      let finalArr = result?.data?.result?.map((val, i) => {
        return {
          ...val,
          id: i + 1,
          noofdays: calculateDaysDifference(
            new Date(val?.createddate),
            new Date()
          ),
          ticketraisedon: formatDate(val?.createddate),
          status:
            val?.status === 1
              ? "Open"
              : val?.status === 5
              ? "Closed"
              : "Pending",
          ticketClosedOn: val?.updateddate ? formatDate(val?.updateddate) : "",
        };
      });
      yield put({
        type: candidatesActions.SET_QUERY_STATUS_BY_CANDIDATEID,
        payload: finalArr,
      });
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getPincode = function* (data) {
  try {
    const { payload } = data;
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/registration/pincode/${payload?.value}`)
    );
    if (result?.data?.statusCode === 200) {
      yield put({
        type: candidatesActions.SET_PINCODE,
        payload: result?.data?.result,
      });
      if (result?.data?.result[0]?.stateid) {
        yield put({
          type: masterActions.GET_DISTRICT_FOR_REGISTRATION,
          payload: {
            stateid: result?.data?.result[0]?.stateid,
            type: "parmanent",
          },
        });
        yield put({
          type: masterActions.GET_DISTRICT,
          payload: result?.data?.result[0]?.stateid,
        });
      }
      if (result?.data?.result[0]?.districtid) {
        yield put({
          type: masterActions.GET_TALUKA,
          payload: result?.data?.result[0]?.districtid,
        });
        yield put({
          type: masterActions.GET_TALUKA_FOR_REGISTRATION,
          payload: {
            districtid: result?.data?.result[0]?.districtid,
            type: "parmanent",
          },
        });
      }
      if (result?.data?.result[0]?.talukaid) {
        yield put({
          type: masterActions.GET_VILLAGE,
          payload: result?.data?.result[0]?.talukaid,
        });
        yield put({
          type: masterActions.GET_VILLAGE_FOR_REGISTRATION,
          payload: {
            talukaid: result?.data?.result[0]?.talukaid,
            type: "parmanent",
          },
        });
      }
    }
  } catch (err) {
    console.log(err);
  }
};

const getPincodeCorrespondence = function* (data) {
  try {
    const { payload } = data;
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/registration/pincode/${payload?.value}`)
    );
    if (result?.data?.statusCode === 200) {
      yield put({
        type: candidatesActions.SET_PINCODE_CORRESPONDENCE,
        payload: result?.data?.result,
      });
      if (result?.data?.result[0]?.stateid) {
        yield put({
          type: masterActions.GET_DISTRICT,
          payload: result?.data?.result[0]?.stateid,
        });
        yield put({
          type: masterActions.GET_DISTRICT_FOR_REGISTRATION,
          payload: {
            stateid: result?.data?.result[0]?.stateid,
            type: "corespondence",
          },
        });
      }
      if (result?.data?.result[0]?.districtid) {
        yield put({
          type: masterActions.GET_TALUKA,
          payload: result?.data?.result[0]?.districtid,
        });
        yield put({
          type: masterActions.GET_TALUKA_FOR_REGISTRATION,
          payload: {
            districtid: result?.data?.result[0]?.districtid,
            type: "corespondence",
          },
        });
      }
      if (result?.data?.result[0]?.talukaid) {
        yield put({
          type: masterActions.GET_VILLAGE,
          payload: result?.data?.result[0]?.talukaid,
        });
        yield put({
          type: masterActions.GET_VILLAGE_FOR_REGISTRATION,
          payload: {
            talukaid: result?.data?.result[0]?.talukaid,
            type: "corespondence",
          },
        });
      }
    }
  } catch (err) {
    console.log(err);
  }
};

const coursePersonaldetailsCreation = function* (data) {
  const { payload } = data;
  const { navigate, statusForRedirect } = payload;
  const encodedData = yield call(handleEncodeJson, {
    encodeJSON: payload?.data,
  });

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(
        `${API_URL}/candidate/course/registraton/personaldetails`,
        encodedData
      )
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: null,
          show: false,
          message: null,
        },
      });
    }
    if (result?.data?.statusCode === 200) {
      if (statusForRedirect === 1) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/course-registration?page=preview`,
          {
            state: {
              courseid: payload?.data?.courseid,
              coursename: payload?.data?.coursename,
            },
          }
        );
      } else {
        navigate(
          `/${Cookies.get("mhet_cnd_project")}/course-registration?page=${
            payload?.nextPageName
          }`,
          {
            state: {
              courseid: payload?.data?.courseid,
              coursename: payload?.data?.coursename,
            },
          }
        );
      }
      // window.location.reload();
    }
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const courseAddressdetailsCreation = function* (data) {
  const { payload } = data;
  const { navigate, statusForRedirect } = payload;
  const encodedData = yield call(handleEncodeJson, {
    encodeJSON: payload?.data,
  });
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(
        `${API_URL}/candidate/course/registraton/addressdetails`,
        encodedData
      )
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: null,
          show: false,
          message: null,
        },
      });
    }
    if (result?.data?.statusCode === 200) {
      if (statusForRedirect === 1) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/course-registration?page=preview`,
          {
            state: {
              courseid: payload?.data?.courseid,
              coursename: payload?.data?.coursename,
            },
          }
        );
      } else {
        navigate(
          `/${Cookies.get("mhet_cnd_project")}/course-registration?page=${
            payload.nextPageName
          }`,
          {
            state: {
              courseid: payload?.data?.courseid,
              coursename: payload?.data?.coursename,
            },
          }
        );
      }
      // window.location.reload();
    }
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const courseAcademicdetailsCreation = function* (data) {
  const { payload } = data;
  const { navigate, statusForRedirect } = payload;
  const encodedData = yield call(handleEncodeJson, {
    encodeJSON: payload?.data,
  });
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(
        `${API_URL}/candidate/course/registraton/academicdetails`,
        encodedData
      )
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: null,
          show: false,
          message: null,
        },
      });
    }
    if (result?.data?.statusCode === 200) {
      if (statusForRedirect === 1) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/course-registration?page=preview`,
          {
            state: {
              courseid: payload?.data?.courseid,
              coursename: payload?.data?.coursename,
            },
          }
        );
        window.location.reload();
      } else {
        navigate(
          `/${Cookies.get("mhet_cnd_project")}/course-registration?page=${
            payload.nextPageName
          }`,
          {
            state: {
              courseid: payload?.data?.courseid,
              coursename: payload?.data?.coursename,
            },
          }
        );
      }
      // window.location.reload();
    }
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const courseReservationdetailsCreation = function* (data) {
  const { payload } = data;
  const { navigate, statusForRedirect } = payload;
  const encodedData = yield call(handleEncodeJson, {
    encodeJSON: payload?.data,
  });
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(
        `${API_URL}/candidate/course/registraton/reservationdetails`,
        encodedData
      )
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: null,
          show: false,
          message: null,
        },
      });
    }
    if (result?.data?.statusCode === 200) {
      if (statusForRedirect === 1) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/course-registration?page=preview`,
          {
            state: {
              courseid: payload?.data?.courseid,
              coursename: payload?.data?.coursename,
            },
          }
        );
      } else {
        navigate(
          `/${Cookies.get("mhet_cnd_project")}/course-registration?page=${
            payload.nextPageName
          }`,
          {
            state: {
              courseid: payload?.data?.courseid,
              coursename: payload?.data?.coursename,
            },
          }
        );
      }
      // window.location.reload();
    }
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const courseExamdetailsCreation = function* (data) {
  const { payload } = data;
  const { navigate } = payload;
  const encodedData = yield call(handleEncodeJson, {
    encodeJSON: payload?.data,
  });
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(
        `${API_URL}/candidate/course/registraton/examdetails`,
        encodedData
      )
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: null,
          show: false,
          message: null,
        },
      });
    }
    if (result?.data?.statusCode === 200) {
      navigate(
        `/${Cookies.get("mhet_cnd_project")}/course-registration?page=${
          payload.nextPageName
        }`,
        {
          state: {
            courseid: payload?.data?.courseid,
            coursename: payload?.data?.coursename,
          },
        }
      );
      // window.location.reload();
    }
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};
const otbsCourseExamdetailsCreation = function* (data) {
  const { payload } = data;
  const { navigate } = payload;
  const encodedData = yield call(handleEncodeJson, {
    encodeJSON: payload?.data,
  });
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/backoffice/venuedata/scheduler`, encodedData)
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: null,
          show: false,
          message: null,
        },
      });
    }
    if (result?.data?.statusCode === 200) {
      yield put({
        type: candidatesActions.GET_COURSE_ACTIVE_TAB,
        payload: {
          data: {
            courseid: payload?.data?.courseid,
          },
        },
      });
      yield put({
        type: candidatesActions.GET_CURRENT_VENUE_DETAILS,
        payload: {
          courseid: payload?.data?.courseid,
          candidateid: payload?.data?.candidateid,
        },
      });
      navigate(
        `/${Cookies.get("mhet_cnd_project")}/course-registration?page=${
          payload.nextPageName
        }`,
        {
          state: {
            courseid: payload?.data?.courseid,
            coursename: payload?.data?.coursename,
          },
        }
      );
      // window.location.reload();
    }
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};
const getVenueDetails = function* (data) {
  const { payload } = data;
  console.log("data", data);
  const { setAvailable, navigate } = payload;
  const encodedData = yield call(handleEncodeJson, {
    encodeJSON: payload?.data,
  });
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/backoffice/venuedata/venue-allocation/${data?.payload?.courseid}/${data?.payload?.candidateid}/${data?.payload?.validtime}/${data?.payload?.venueid}`
      )
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: null,
          show: false,
          message: null,
        },
      });
    }
    if (result?.data?.statusCode === 200) {
      setAvailable(true);
    }
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getAllCourseRegistrationDetails = function* (data) {
  const { payload } = data;
  yield put({ type: authActions.CANDIDATE_TOKEN_VERIFY });
  yield delay(1000);
  const candidateid = yield select(
    (state) => state?.authReducer?.candidateTokenDetails?.candidateid
  );
  // yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/candidate/course/registraton/${candidateid}/${payload.courseid}`
      )
    );
    const decryptedData = yield call(handleDecodeJSON, {
      decodeJSON: result?.data?.result,
    });
    yield put({
      type: candidatesActions.SET_COURSE_REGISTRATION_DETAILS,
      payload: result?.data?.statusCode === 200 ? decryptedData : [],
    });
  } catch (err) {
    console.log(err);
  }
  // yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getCourseAddressDetails = function* (data) {
  const { payload } = data;
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/candidate/course/registraton/courseaddressdetails/${payload?.candidateid}/${payload?.courseid}`
      )
    );
    const decryptedData = yield call(handleDecodeJSON, {
      decodeJSON: result?.data?.result,
    });
    const { statusCode } = result?.data || {};
    yield put({
      type: candidatesActions.SET_COURSE_ADDRESS_DETAILS,
      payload: statusCode === 200 ? decryptedData : [],
    });

    if (statusCode === 200 && decryptedData?.length > 0) {
      const [
        { pstateid, pdistrictid, ptalukaid, cstateid, cdistrictid, ctalukaid },
      ] = decryptedData;

      yield delay(500);
      yield put({ type: masterActions.GET_STATE });
      if (pstateid) {
        yield put({
          type: masterActions.GET_DISTRICT_FOR_COURSE,
          payload: { type: "parmanent", stateid: pstateid },
        });
        yield delay(100);
      }

      if (pdistrictid) {
        yield put({
          type: masterActions.GET_TALUKA_FOR_COURSE,
          payload: { type: "parmanent", districtid: pdistrictid },
        });
        yield delay(100);
      }

      if (ptalukaid) {
        yield put({
          type: masterActions.GET_VILLAGE_FOR_COURSE,
          payload: { type: "parmanent", talukaid: ptalukaid },
        });
      }

      if (cstateid) {
        yield put({
          type: masterActions.GET_DISTRICT_FOR_COURSE,
          payload: { type: "corespondence", stateid: cstateid },
        });
        yield delay(100);
      }

      if (cdistrictid) {
        yield put({
          type: masterActions.GET_TALUKA_FOR_COURSE,
          payload: { type: "corespondence", districtid: cdistrictid },
        });
        yield delay(100);
      }

      if (ctalukaid) {
        yield put({
          type: masterActions.GET_VILLAGE_FOR_COURSE,
          payload: { type: "corespondence", talukaid: ctalukaid },
        });
      }
    }
  } catch (err) {
    console.error("Error in getCourseAddressDetails saga", err);
  }
};

const getAllCourse = function* () {
  yield put({ type: authActions.CANDIDATE_TOKEN_VERIFY });
  yield delay(1000);
  const candidateid = yield select(
    (state) => state?.authReducer?.candidateTokenDetails?.candidateid
  );
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/course/${candidateid}`)
    );
    console.log(result, "courselist in sagas");
    const decryptedData = yield call(handleDecodeJSON, {
      decodeJSON: result?.data?.result,
    });

    yield put({
      type: candidatesActions.SET_ALL_COURSE_LIST,
      payload: {
        all: result?.data?.statusCode === 200 ? decryptedData : [],
        filtered: result?.data?.statusCode === 200 ? decryptedData : [],
      },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getCourseTab = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/master/coursemanagement/form/tab/${payload?.data?.courseid}`
      )
    );
    yield put({
      type: candidatesActions.SET_COURSE_TAB,
      payload: result?.data?.statusCode === 200 ? result?.data?.result : [],
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getMasterFields = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/master/coursemanagement/form/field/${payload?.data?.courseid}`
      )
    );
    yield put({
      type: candidatesActions.SET_MASTER_FIELDS,
      payload: result?.data?.statusCode === 200 ? result?.data?.result : [],
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getCourseRegistrationActiveTab = function* (data) {
  yield put({ type: authActions.CANDIDATE_TOKEN_VERIFY });
  yield delay(1000);
  const candidateid = yield select(
    (state) => state?.authReducer?.candidateTokenDetails?.candidateid
  );
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/candidate/course/registraton/active-tab/${candidateid}/${payload?.data?.courseid}`
      )
    );
    const decryptedData = yield call(handleDecodeJSON, {
      decodeJSON: result?.data?.result,
    });
    yield put({
      type: candidatesActions.SET_COURSE_ACTIVE_TAB,
      payload: result?.data?.statusCode === 200 ? decryptedData[0]?.[0] : [],
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getCourseApplicantId = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/course/registraton/applicantid`, {
        courseid: payload?.courseid,
        candidateid: payload?.candidateid,
      })
    );
    const decryptedData = yield call(handleDecodeJSON, {
      decodeJSON: result?.data?.data,
    });
    yield put({
      type: candidatesActions.SET_COURSE_APPLICANTID,
      payload: result?.data?.statusCode === 200 ? decryptedData : {},
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getCourseStatus = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/course/course-status`, {
        courseid: payload?.courseid,
        candidateid: payload?.candidateid,
      })
    );
    const decryptedData = yield call(handleDecodeJSON, {
      decodeJSON: result?.data?.result,
    });
    yield put({
      type: candidatesActions.SET_COURSE_STATUS,
      payload: result?.data?.statusCode === 200 ? decryptedData : [],
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getIsPaid = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/course/ispaid`, {
        candidateid: payload?.candidateid,
        courseid: payload?.courseid,
      })
    );
    const decryptedData = yield call(handleDecodeJSON, {
      decodeJSON: result?.data?.result,
    });

    yield put({
      type: candidatesActions.SET_IS_PAID,
      payload: result?.data?.statusCode === 200 ? decryptedData : [],
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getPaymentReceiptList = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/paymentReceipt/${payload?.candidateid}`)
    );
    const decryptedData = yield call(handleDecodeJSON, {
      decodeJSON: result?.data?.result,
    });
    yield put({
      type: candidatesActions.SET_PAYMENT_RECEIPT_LIST,
      payload: result?.data?.statusCode === 200 ? decryptedData : [],
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getExamState = function* (data) {
  const { payload } = data;
  try {
    const result = yield call(() =>
      axios.post(
        `${API_URL}/candidate/course/registraton/exam-state`,
        payload?.data
      )
    );
    yield put({
      type: candidatesActions.SET_EXAM_STATE,
      payload: result?.data?.statusCode === 200 ? result?.data?.result : [],
    });
  } catch (err) {
    console.log(err);
  }
};

const getExamDistrict = function* (data) {
  const { payload } = data;
  try {
    const result = yield call(() =>
      axios.post(
        `${API_URL}/candidate/course/registraton/exam-district`,
        payload?.data
      )
    );
    yield put({
      type: candidatesActions.SET_EXAM_DISTRICT,
      payload:
        result?.data?.statusCode === 200
          ? { all: result?.data?.result, filtered: [] }
          : { all: [], filtered: [] },
    });
  } catch (err) {
    console.log(err);
  }
};

const getExamLanguage = function* (data) {
  const { payload } = data;
  try {
    const result = yield call(() =>
      axios.post(
        `${API_URL}/candidate/course/registraton/exam-language`,
        payload?.data
      )
    );
    yield put({
      type: candidatesActions.SET_EXAM_LANGUAGE,
      payload: result?.data?.statusCode === 200 ? result?.data?.result : [],
    });
  } catch (err) {
    console.log(err);
  }
};

const getCourseFormDetails = function* (data) {
  const { payload } = data;
  console.log('payload course details---',payload);
  
  const candidateid = yield select(
    (state) => state?.authReducer?.candidateTokenDetails?.candidateid
  );
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/candidate/course/registraton/info/${candidateid}/${payload?.courseid}`
      )
    );

    console.log('result course--',result);
    
    const decryptedData = yield call(handleDecodeJSON, {
      decodeJSON: result?.data?.result,
    });
    yield put({
      type: candidatesActions.SET_COURSE_FORM_DETAILS,
      payload: result?.data?.statusCode === 200 ? decryptedData : [],
    });
    if (decryptedData[0]?.pstateid) {
      yield put({
        type: masterActions.GET_DISTRICT,
        payload: decryptedData[0]?.pstateid,
      });
    }
    if (decryptedData[0]?.pdistrictid) {
      yield put({
        type: masterActions.GET_TALUKA,
        payload: decryptedData[0]?.pdistrictid,
      });
    }
    if (decryptedData[0]?.ptalukaid) {
      yield put({
        type: masterActions.GET_VILLAGE,
        payload: decryptedData[0]?.ptalukaid,
      });
    }
    if (decryptedData[0]?.cstateid) {
      yield put({
        type: masterActions.GET_DISTRICT,
        payload: decryptedData[0]?.cstateid,
      });
    }
    if (decryptedData[0]?.cdistrictid) {
      yield put({
        type: masterActions.GET_TALUKA,
        payload: decryptedData[0]?.cdistrictid,
      });
    }
    if (decryptedData[0]?.ctalukaid) {
      yield put({
        type: masterActions.GET_VILLAGE,
        payload: decryptedData[0]?.ctalukaid,
      });
      if (decryptedData[0]?.stateid) {
        yield put({
          type: masterActions.GET_DISTRICT,
          payload: decryptedData[0]?.stateid,
        });
      }
    }
  } catch (err) {
    
    console.log('course details error',err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getCurrentCourse = function* (data) {
  const { payload } = data;
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/course/singlecourse/${payload?.courseid}`)
    );
    const decryptedData = yield call(handleDecodeJSON, {
      decodeJSON: result?.data?.result,
    });
    yield put({
      type: candidatesActions.SET_CURRENT_COURSE,
      payload: result?.data?.statusCode === 200 ? decryptedData : [],
    });
  } catch (err) {
    console.log(err);
  }
};

const updatePreviewandPaymentStatus = function* (data) {
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  const { payload } = data;
  const { navigate, coursename } = payload;
  try {
    const encryptedData = yield call(handleEncodeJson, {
      encodeJSON: payload?.data,
    });
    const result = yield call(() =>
      axios.put(
        `${API_URL}/candidate/course/registraton/preview`,
        encryptedData
      )
    );
    if (result?.data?.statusCode === 200) {
      if (result?.data?.isTimeOver) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/course-registration?page=examinationdetails`,
          {
            state: {
              courseid: payload?.data?.courseid,
              coursename: coursename,
            },
          }
        );
      } else {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/course-registration?page=applicationfee`,
          {
            state: {
              courseid: payload?.data?.courseid,
              coursename: coursename,
            },
          }
        );
      }

      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: null,
          show: false,
          message: null,
        },
      });
    }
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const createPrintLog = function* (data) {
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  const { payload } = data;
  try {
    const encryptedData = yield call(handleEncodeJson, {
      encodeJSON: payload?.data,
    });
    yield call(() =>
      axios.post(
        `${API_URL}/candidate/course/registraton/print-log`,
        encryptedData
      )
    );
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateCourseDetails = function* (data) {
  const { payload } = data;
  const {
    navigate,
    isExtraFee,
    coursename,
    edit,
    subjectgroup,
    photofilename,
    signfilename,
  } = payload;
  try {
    const formData = new FormData();

    if (payload.data && typeof payload.data === "object") {
      Object.entries(payload.data).forEach(([key, value]) => {
        formData.append(key, value);
      });
    } else {
      console.error("Invalid payload.data structure", payload.data);
    }
    const photoFile = photofilename
      ? new File([photofilename], `${payload?.data?.candidateid}_photo.jpeg`, {
          type: "image/jpeg",
        })
      : null;
    const signFile = signfilename
      ? new File(
          [signfilename],
          `${payload?.data?.candidateid}_signature.jpeg`,
          { type: "image/jpeg" }
        )
      : null;

    formData.append("photofilename", photoFile || "");
    formData.append("signfilename", signFile || "");
    formData.append("photourl", photoFile ? "" : payload?.data?.photourl);
    formData.append("signurl", signFile ? "" : payload?.data?.signurl);

    const result = yield call(() =>
      axios.put(
        `${API_URL}/candidate/course/registraton/edit/${payload?.data?.candidateid}/${payload?.data?.courseid}`,
        formData
      )
    );
    if (result?.data?.statusCode === 200) {
      let encryptedData = {
        courseid: payload?.data?.courseid,
        isExtraFee: isExtraFee,
        coursename: coursename,
        edit: edit,
        subjectgroup: subjectgroup,
      };
      navigate(
        `/${Cookies.get(
          "mhet_cnd_project"
        )}/course-registration?page=preview&&c_data=${EncryptObjectFunction(
          encryptedData
        )}`
      );
    }
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: null,
        show: false,
        message: null,
      },
    });
  } catch (err) {
    console.log(err);
  }
};

const getIpAddress = function* () {
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/course/registraton/ip`)
    );
    const decryptedData = yield call(handleEncodeJson, {
      decodeJSON: result?.data?.result,
    });

    yield put({
      type: candidatesActions.SET_IP_ADDRESS,
      payload: result?.data?.statusCode === 200 ? decryptedData : [],
    });
  } catch (err) {
    console.log(err);
  }
};

const getOtbsVenueDetails = function* (data) {
  const { payload } = data;
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/backoffice/venuedata/otbsvenues/${payload?.courseid}/${payload?.candidateid}`
      )
    );
    yield put({
      type: candidatesActions.SET_OTBS_VENUE_DETAILS,
      payload:
        result?.data?.statusCode === 200
          ? {
              States: result?.data?.stateResult,
              Districts: result?.data?.districtResult,
              Venues: result?.data?.result,
            }
          : {
              States: [],
              Districts: [],
              Venues: [],
            },
    });
  } catch (err) {
    console.log(err);
  }
};

const getCurrentOtbsVenueDetails = function* (data) {
  const { payload } = data;
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/candidate/course/registraton/venue-allocation/${payload?.candidateid}/${payload?.courseid}`
      )
    );
    const decryptedData = yield call(handleDecodeJSON, {
      decodeJSON: result?.data?.result,
    });
    yield put({
      type: candidatesActions.SET_CURRENT_VENUE_DETAILS,
      payload: result?.data?.statusCode === 200 ? decryptedData : [],
    });
  } catch (err) {
    console.log(err);
  }
};

const otbsUpdateCourseExamdetailsCreation = function* (data) {
  const { payload } = data;
  const { navigate } = payload;
  // const encodedData = yield call(handleEncodeJson, { encodeJSON: payload?.data });
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/backoffice/venuedata/scheduler`, payload?.data)
    );
    if (result) {
      yield put({
        type: candidatesActions.GET_COURSE_ACTIVE_TAB,
        payload: {
          data: {
            courseid: payload?.data?.courseid,
          },
        },
      });
      yield put({
        type: candidatesActions.GET_CURRENT_VENUE_DETAILS,
        payload: {
          courseid: payload?.data?.courseid,
          candidateid: payload?.data?.candidateid,
        },
      });
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: null,
          show: false,
          message: null,
        },
      });
    }
    if (result?.data?.statusCode === 200) {
      navigate(
        `/${Cookies.get("mhet_cnd_project")}/course-registration?page=${
          payload.nextPageName
        }`,
        {
          state: {
            courseid: payload?.data?.courseid,
            coursename: payload?.data?.coursename,
          },
        }
      );
      // window.location.reload();
    }
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};
const downloadBrochure = function* (data) {
  const { payload } = data;
  const { courseid, coursename } = payload;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/candidate/course/brochure/${courseid}`
        //  {responseType: 'blob'}
      )
    );
    // console.log(result);
    if (result?.data?.statusCode === 200 && result?.data?.data.trim() != "") {
      yield call(downloadBase64, {
        base64: result?.data?.data,
        coursename: coursename,
        fileExtension: result?.data?.fileExtension,
      });
      yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: "success",
          show: true,
          message: "Brochure Downloaded Successfully",
        },
      });
      // downloadBase64(result?.data, coursename);
    } else if (result?.data?.data.trim() == "") {
      yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: "failed",
          show: true,
          message: "Bochure not availabe for this course",
        },
      });
    } else {
      yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: null,
        show: false,
        message: null,
      },
    });
  } catch (err) {
    console.log(err);
  }
};

export default candidatesSaga;