import { Button } from '@mui/material';

const SaveButton = ({ name, handleDraft, sx,disabled }) => (
  <Button
    variant="outlined"
    size="small"
    sx={{
      background: name === "Edit" ? "#0F4F96" : "", 
      borderRadius: "40px",
      boxShadow: "none",
      color: name === "Edit" ? "#FFFFFF" : "#001F3F",
      border: !disabled && name === "Save As Draft" || name === 'Next' ? "1px solid #001F3F" : "none",
      fontSize: "14px",
      fontWeight: "600",
      height: "40px",
      padding: '0 24px',
      '&:hover': {
        backgroundColor: name === "Edit" ? "#0F4F96" : "",
        color: name === "Edit" ? "#FFFFFF" : "#001F3F", 
      },
      '@media (max-width: 297px)': {
                    fontSize: 'xx-small', 
                    padding: '6px 12px', 
                    whiteSpace: 'normal', 
                    textAlign: 'center', 
                },
      ...sx, 
    }}
    onClick={handleDraft}
  >
    {name}
  </Button>
);

export default SaveButton;
