import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FormControl,
  Grid,
  useMediaQuery,
  MenuItem,
  ListSubheader,
  Select,
  Typography,
  useTheme,
  Box,
  Chip,
  TextField,
  Input,
  InputLabel,
  Tooltip,
  Button,
  CircularProgress,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import candidatesActions from "../redux/candidates/actions";
import { Controller, useForm } from "react-hook-form";
import SubmitButton from "./submitButton";
import Sidebar from "../layouts/sideBar";
import Layout from "../layouts/layout";
import Header from "../layouts/header";
import PrimaryButton from "./primaryButton";
import Cookies from "js-cookie";

export default function VenueAllocation() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    getValues,
    control,
    reset,
    clearErrors,
    formState: { errors },
    setFocus,
  } = useForm();

  const { courseList, otbsVenueDetails, currentOtbsVenue,courseFullRegistrationDetails } = useSelector(
    (state) => state.candidatesReducer
  );
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );

  const courseid = location?.state?.courseid;
  const coursename = location.state?.coursename;
  const handleProcced=(data)=>{
    const corcandidatepreferenceid =
    courseFullRegistrationDetails[0]?.corcandidatepreferenceid;

    dispatch({
      type: candidatesActions.OTBS_COURSE_EXAM_DETAILS_CREATION,
      payload: {
        data: {
          ...data,
          operation: corcandidatepreferenceid
            ? parseInt(corcandidatepreferenceid)
            : 0,
          candidateid: candidateid,
          status: 1,
          courseid: courseid,
          coursename: coursename,
          createdby: candidateid,
          updatedby: corcandidatepreferenceid ? parseInt(candidateid) : "",
        },
        navigate: navigate,
      },
    });
  }

  useEffect(() => {
    if (
      courseid &&
      courseList?.all?.length > 0 &&
      courseList?.all?.find((obj) => obj.courseid == courseid)?.isotbs == true
    ) {
      dispatch({
        type: candidatesActions.GET_OTBS_VENUE_DETAILS,
        payload: { courseid, candidateid },
      });
    }
  }, [courseList, courseid, candidateid]);

  useEffect(() => {
    if (
      courseid && candidateid &&
      courseList?.all?.length > 0 &&
      courseList?.all?.find((obj) => obj.courseid == courseid)?.isotbs == true
    ) {
      dispatch({
        type: candidatesActions.GET_CURRENT_VENUE_DETAILS,
        payload: { courseid: courseid, candidateid: candidateid },
      });
     
    }
  }, [courseList, courseid, candidateid]);

  {
      return (

      <>
      <Layout>
      <Header />
      <Sidebar />

        <Grid
                  sx={{
                    height: "70vh",
      alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "8px",
                  }}>
        <Grid container spacing={2} mt={1}>
        <Grid container spacing={2} px={5} mt={1}>
      <h3>
      Your venue has already been booked. You can select another available venue, or you can proceed with a refund      </h3>
    </Grid>
          <Grid item xs={12} md={5}>
            <Typography>
              Select Examintion Center State
              <span className="error" style={{ color: "red" }}>
                {" "}
                *
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <FormControl fullWidth error={!!errors.otbsexamstate}>
              <Controller
                name="otbsexamstate"
                control={control}
                rules={{ required: "Exam State is required" }}
                render={({ field }) => (
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    {...field}
                    id="otbsexamstate"
                    {...register("otbsexamstate", {
                      required: "Exam State is required",
                    })}
                    onChange={(e) => {
                      const otbsexamstate = e.target.value;
                      setValue("otbsexamstate", otbsexamstate);
                      setValue("otbsexamdistrict", "");
                    }}
                    value={watch("otbsexamstate") ?? ""}
                    inputProps={{
                      style: { height: 56, boxSizing: "border-box" },
                    }}
                  >
                    {otbsVenueDetails?.States?.map((val, i) => (
                      <MenuItem value={val?.stateid} key={i}>
                        {val?.state}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.otbsexamstate && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.otbsexamstate.message}
                </small>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={5}>
            <Typography>
              Select Examintion Center District
              <span className="error" style={{ color: "red" }}>
                {" "}
                *
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <FormControl fullWidth error={!!errors.otbsexamdistrict}>
              <Controller
                name="otbsexamdistrict"
                control={control}
                rules={{ required: "Exam District is required" }}
                render={({ field }) => (
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    {...field}
                    id="otbsexamdistrict"
                    {...register("otbsexamdistrict", {
                      required: "Exam District is required",
                    })}
                    onChange={(e) => {
                      const otbsexamdistrict = e.target.value;
                      setValue("otbsexamdistrict", otbsexamdistrict);
                      setValue("venueid", "");
                    }}
                    value={watch("otbsexamdistrict") ?? ""}
                    inputProps={{
                      style: { height: 56, boxSizing: "border-box" },
                    }}
                  >
                    {otbsVenueDetails?.Districts?.filter(
                      (obj) => obj.stateid == watch("otbsexamstate")
                    )?.map((val, i) => (
                      <MenuItem value={val?.districtid} key={i}>
                        {val?.district}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.otbsexamdistrict && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.otbsexamdistrict.message}
                </small>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={5}>
            <Typography>
              Select Examintion Center
              <span className="error" style={{ color: "red" }}>
                {" "}
                *
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <FormControl fullWidth error={!!errors.venueid}>
              <Controller
                name="venueid"
                control={control}
                rules={{ required: "Exam Center is required" }}
                render={({ field }) => (
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    {...field}
                    id="venueid"
                    {...register("venueid", {
                      required: "Exam Center is required",
                    })}
                    onChange={(e) => {
                      const venueid = e.target.value;
                      setValue("venueid", venueid);
                      clearErrors("venueid");
                      // setValue("otbsexamdistrict", "");
                    }}
                    value={watch("venueid") ?? ""}
                    inputProps={{
                      style: { height: 56, boxSizing: "border-box" },
                    }}
                  >

                    {otbsVenueDetails?.Venues?.filter(
                      (obj) =>
                        obj.districtid == watch("otbsexamdistrict") &&
                        obj.totalseats > obj.allocatedseats
                    )
                      ?.sort((a, b) => {
                        const refA = parseInt(a.referenceid.replace("V", ""));
                        const refB = parseInt(b.referenceid.replace("V", ""));
                        return refA - refB;
                      })
                      ?.map((val, i) => (
                        <MenuItem value={val?.venueid} key={i}>
                          {val?.referenceid} - {val?.venuename}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
              {errors.venueid && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.venueid.message}
                </small>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={5}>
            <Typography>
              Examintion Center Address
              <span className="error" style={{ color: "red" }}>
                {" "}
                *
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <FormControl fullWidth error={!!errors.otbsexamcenteraddress}>
              <TextField
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                fullWidth
                multiline
                type="text"
                {...register("otbsexamcenteraddress", {})}
                value={
                  `${
                    otbsVenueDetails?.Venues?.find(
                      (obj) => obj.venueid == watch("venueid")
                    )?.address1 || ""
                  }
                ${
                  otbsVenueDetails?.Venues?.find(
                    (obj) => obj.venueid == watch("venueid")
                  )?.address2
                    ? ","
                    : ""
                }
                ${
                  otbsVenueDetails?.Venues?.find(
                    (obj) => obj.venueid == watch("venueid")
                  )?.address2 || ""
                } ${
                    otbsVenueDetails?.Venues?.find(
                      (obj) => obj.venueid == watch("venueid")
                    )?.landmark
                      ? ","
                      : ""
                  }
                ${
                  otbsVenueDetails?.Venues?.find(
                    (obj) => obj.venueid == watch("venueid")
                  )?.landmark || ""
                } ${
                    otbsVenueDetails?.Venues?.find(
                      (obj) => obj.venueid == watch("venueid")
                    )?.pincode
                      ? ","
                      : ""
                  }${
                    otbsVenueDetails?.Venues?.find(
                      (obj) => obj.venueid == watch("venueid")
                    )?.pincode || ""
                  }` ?? ""
                }
                disabled={true}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {errors.otbsexamcenteraddress && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.otbsexamcenteraddress.message}
                </small>
              )}
            </FormControl>
          </Grid>
                  <Grid container spacing={2} mt={1}>
                    <Grid
                      item
                      md={12}
                      pt={2}
                      pb={2}
                      sx={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "space-between",
                        flexDirection:  "column",
                        width:"100px"
                      }}
                    >
                      <SubmitButton
                        name={ "Save and Proceed"}
                        handleSubmit={handleSubmit(handleProcced)}
                      />
                    </Grid>
                  </Grid>
        </Grid>
        </Grid>
        </Layout>
      </>
    )
    // return (
    //   <>
    //     <Layout>
    //       <Header />
    //       <Sidebar />
    //       <Grid
    //         container
    //         spacing={2}
    //         mt={1}
    //         sx={{
    //           height: "70vh",
    //           alignItems: "center",
    //           justifyContent: "center",
    //           borderRadius: "8px",
    //           display: "flex",
    //           flexDirection: "column",
    //         }}
    //       >
    //         <h3>Your venue has been allocated successfully!</h3>

    //         <div style={{ marginTop: "1rem" }}>
    //           <PrimaryButton
    //             title={"Done"}
    //             onClick={() => {
    //               navigate(`/mhcet/course-registration?page=printapplication`, {
    //                 state: {
    //                   courseid: courseid,
    //                   coursename: courseList?.all?.find(
    //                     (obj) => parseInt(obj.courseid) === parseInt(courseid)
    //                   )?.coursename,
    //                 },
    //               });
    //             }}
    //           />
    //         </div>
    //       </Grid>
    //     </Layout>
    //   </>
    // );
  }
}
