import Keycloak from 'keycloak-js';
import env from "react-dotenv";
import { KEYCLOCK_CLIENT, KEYCLOCK_REALM, KEYCLOCK_URL } from '../utils/constants';

let initOptions = {
    url: KEYCLOCK_URL,
    realm:  KEYCLOCK_REALM,
    clientId: KEYCLOCK_CLIENT,
    onLoad: 'check-sso',
    KeycloakResponseType: 'code',
}

const kc = new Keycloak(initOptions);

const initKeycloak = (onAuthenticatedCallback) => {
    kc.init({
        onLoad: initOptions.onLoad,
      }).then((auth) => {
        if (!auth) {
            kc.login();
          } else {
            onAuthenticatedCallback();
          }
      }, () => {
        console.log("Authentication Failure");
        onAuthenticatedCallback();
      });
  };

const doLogin = kc.login;

const doLogout = kc.logout;

const getToken = () => kc.token;

const getUserInfo = (userProperty) => kc.tokenParsed?.[userProperty] || null;

const keycloakTokenParsed = () => kc.tokenParsed

const getIsAuthenticated = () => kc.authenticated;

const refreshAccessToken = (sec) => kc.updateToken(sec);


const keycloakService = {
  keycloakTokenParsed,
    initKeycloak,
    doLogin,
    doLogout,
    getToken,
    getUserInfo,
    getIsAuthenticated,
    refreshAccessToken
}

export default keycloakService;
