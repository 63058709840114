import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CardContent,
  Grid,
  Link,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import authActions from "../../redux/auth/actions";
import { HiArrowLongRight } from "react-icons/hi2";
import reg from "../../../src/assets/images/sidebar/registration.svg";
import scorecard from "../../../src/assets/images/sidebar/score card.svg";
import objection from "../../../src/assets/images/sidebar/objection-tracker.svg";
import checklist from "../../../src/assets/images/sidebar/checklist.png";
import survey from "../../../src/assets/images/sidebar/survey.png";
import user from "../../../src/assets/images/sidebar/user.png";
import bell from "../../../src/assets/images/sidebar/bell-school.png";
import credit from "../../../src/assets/images/sidebar/creditcard.png";
import doubts from "../../../src/assets/images/sidebar/doubts-button.png";
import idBadge from "../../../src/assets/images/sidebar/id-badge.png";
import idCard from "../../../src/assets/images/sidebar/id-card.png";
import keycloakService from "../../modules";
import MobileVerification from "./mobileverification.js"
import {
  DecryptFunction,
  DecryptObjectFunction,
} from "../../utils/cryptoFunction";
import candidatesActions from "../../redux/candidates/actions";

export default function Index() {
  const [otp, setOtp] = useState("");
  const [wrongOTP, setWrongotp] = useState(0);
  const [attempt, setAttempt] = useState(0);
  const [red, setRed] = useState(false);
 const [error, setError] = useState("");
 const [resendCountdown, setResendCountdown] = useState(120);
 const [timer, setTimer] = useState(null);
 const [timeRemaining, setTimeRemaining] = useState(30 * 60);
 const [timeRemainingmin, setTimeRemainingmin] = useState(0);
 const [timeRemainingsec, setTimeRemainingsec] = useState(0);
const[openDialog,setOpenDialog]=useState(false);

console.log('openDialog---',openDialog);

  console.log('keycloakService---',keycloakService);
  console.log('keycloakService tokenparsed---',keycloakService?.keycloakTokenParsed());
  console.log('keycloakService sub---',keycloakService?.keycloakTokenParsed()?.sub);
  console.log('keycloakService token---',keycloakService?.getToken());
  const[mobileverified,setMobileVerified]=useState(false)
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { candidateTokenDetails, profileRegistrationActiveTab } = useSelector(
    (state) => state.authReducer
  );
  const { clickable} = useSelector((state) => state.commonReducer);

  useEffect(() => {
    dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });
  }, []);
  
  const { personalDetails, uploadDetails, addressDetails, otherDetails } =
    useSelector((state) => state.candidatesReducer);
  console.log("uploadDetails----", uploadDetails);



  
// useEffect(()=>{
//   if(uploadDetails){

//     if(allProfiledetailsDraft && Cookies.get('mhet_cnd_mobileno_verified') == "true"){
//       navigate(
//         `/${Cookies.get("mhet_cnd_project")}/registration?page=personaldetails`
//       );
//     }
//   }
//   console.log('allProfiledetailsDraft---',allProfiledetailsDraft);

// },[uploadDetails])


  let now = new Date();
  let endTime = new Date(Cookies.get("otpSignupEndTime"));
  let difference = endTime - now;
  function formatTime(number) {
    return number < 10 ? "0" + number : number;    
  }
  const allProfiledetailsDraft = uploadDetails?.[0]?.statusmain ? true : false;
  useEffect(() => {
   const now = new Date();
   const endTime = new Date(Cookies.get("otpSignupEndTime"));
 // gdgd
   if (endTime && endTime > now) {
     const difference = endTime - now;
     const remainingSeconds = Math.floor((difference / 1000) % 60);
     const remainingMinutes = Math.floor((difference / (1000 * 60)) % 60);
 
     setTimeRemaining(remainingMinutes * 60 + remainingSeconds);
     setTimeRemainingmin(
       formatTime(difference ? timeRemainingmin : remainingMinutes)
     );
     setTimeRemainingsec(
       formatTime(difference ? timeRemainingsec : remainingSeconds)
     );
 
     // startTimer(remainingMinutes * 60 + remainingSeconds);
   }
 }, []);
 useEffect(() => {
   let remainingSeconds = Math.floor((difference / 1000) % 60);
   let remainingMinutes = Math.floor((difference / (1000 * 60)) % 60);
   setTimeRemainingmin(formatTime(remainingMinutes));
   setTimeRemainingsec(formatTime(remainingSeconds));
   
 });
 
 useEffect(() => {
   if (timeRemainingmin == 0) {
     setAttempt(0);
     setWrongotp(0);
     setResendCountdown(0);
   }
 },[timeRemainingmin])

 const candidatename = keycloakService?.getUserInfo('name');
 const emailid = keycloakService?.getUserInfo('email');
 const mobileno = keycloakService?.getUserInfo('phone_number');
 const dob = keycloakService?.getUserInfo('birthdate');
const isEmailChanged =  false;
const temppassword =  '';
const sso = keycloakService?.keycloakTokenParsed()?.sub
console.log('mobileno---',mobileno);
console.log('sso---',sso);
const data = { candidatename,
  emailid,
  mobileno,
  temppassword,
  dob,
  isEmailChanged,sso}



  Cookies.set("mhet_cnd_mobileno",mobileno) 
  useEffect(() => {
    if(emailid ){
   dispatch({
        type: authActions.CANDIDATE_SINUP,
        payload: {
          navigate: navigate,
          data: data,
          setResendCountdown: setResendCountdown,
          setOpenDialog: setOpenDialog,
          dispatch:dispatch,
          navigate:navigate,
          uploadDetails:uploadDetails,
        },
      });}
      console.log('mob--',Cookies.get("mhet_cnd_mobileno",mobileno));
      Cookies.set("mhet_cnd_mobileno",mobileno) 
    const setMobileNo = ()=>{ const setMobno =  Cookies.get("mhet_cnd_mobileno") ? null : Cookies.set("mhet_cnd_mobileno",mobileno)  }
  
    setMobileNo()

  }, [emailid,
    mobileno,
    temppassword,
    dob,
    isEmailChanged]);
  

  useEffect(() => {

    // Cookies.set("mhet_cnd_email_verified", true);
    console.log(`email-verify`,Cookies.get('mhet_cnd_email_verified') == 'true');
    // navigate(`/${Cookies.get('mhet_cnd_project')}/verify-mobileno`);

    if (Cookies.get('mhet_cnd_email_verified') == 'true') {

      console.log(`email-verify`,Cookies.get('mhet_cnd_email_verified') == true);
      
        navigate(`/${Cookies.get('mhet_cnd_project')}/verify-mobileno`);
      }
  
    const setProject = keycloakService.getIsAuthenticated() ? Cookies.set('mhet_cnd_project','mhcet') : null

    if (candidateTokenDetails?.candidateid) {
      dispatch({
        type: authActions.GET_PROFILE_REGISTRATION_ACTIVE_TAB,
        payload: { id: candidateTokenDetails?.candidateid },
      });
    }
  }, []);

  useEffect(() => {
    if (!profileRegistrationActiveTab) {
      return;
    } else if (
      profileRegistrationActiveTab &&
      profileRegistrationActiveTab === "new"
    ) {
      navigate(
        `/${Cookies.get("mhet_cnd_project")}/registration?page=personaldetails`
      );
    } else {
      if (
        !profileRegistrationActiveTab?.cu1_status &&
        profileRegistrationActiveTab?.other_status === 2
      ) {
        navigate(
          `/${Cookies.get("mhet_cnd_project")}/registration?page=uploads`
        );
      } else if (
        !profileRegistrationActiveTab?.cu1_status &&
        !profileRegistrationActiveTab?.other_status &&
        profileRegistrationActiveTab?.address_status === 2
      ) {
        navigate(
          `/${Cookies.get("mhet_cnd_project")}/registration?page=otherdetails`
        );
      } else if (
        !profileRegistrationActiveTab?.cu1_status &&
        !profileRegistrationActiveTab?.other_status &&
        !profileRegistrationActiveTab?.address_status &&
        profileRegistrationActiveTab?.candidate_status === 2
      ) {
        navigate(
          `/${Cookies.get("mhet_cnd_project")}/registration?page=address`
        );
      } else if (
        !profileRegistrationActiveTab?.cu1_status &&
        !profileRegistrationActiveTab?.other_status &&
        !profileRegistrationActiveTab?.address_status &&
        !profileRegistrationActiveTab?.candidate_status
      ) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/registration?page=personaldetails`
        );
      }
    }
  }, [profileRegistrationActiveTab]);

  const items = [
    {
      icon: (
        <img
          src={user}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      name: "Profile",
      details:
        "Provide basic details, latest photo, signature, and identity document.",
      path: clickable ? `/${Cookies.get(
        "mhet_cnd_project"
      )}/registration?page=personaldetails` : null,
    },

    {
      icon: (
        <img
          src={reg}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      name: "CET Registration",
      details:
        "Register for course(s) of your choice and manage your applications.",
      path:
       (( uploadDetails.length !== 0 || allProfiledetailsDraft) && clickable)
          ? 
          `/${Cookies.get("mhet_cnd_project")}/course-selection` : null,
      disabled: uploadDetails.length === 0 || !allProfiledetailsDraft,
    },

    {
      icon: (
        <img
          src={credit}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      name: "Payment History",
      details: "View your fees payments and transaction history.",
      path: clickable ? `/${Cookies.get("mhet_cnd_project")}/payment-receipts` : null,
    },

    {
      icon: (
        <img
          src={idCard}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      name: "Hall Ticket Or Admit Card",
      disabled: true,
      details: "Download your admit card for upcoming examinations.",
      path: clickable ? `/${Cookies.get("mhet_cnd_project")}/admit-card-list` : null,
    },
    {
      icon: (
        <img
          src={scorecard}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      name: "Score Card",
      details: "Access and download your examination score cards.",
      disabled: true,
      path:clickable ? `/${Cookies.get("mhet_cnd_project")}/` :null,
    },

    {
      icon: (
        <img
          src={objection}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      disabled: true,
      name: "Objection Tracker",
      details:
        "Raise and track objections or discrepancies in your exam results.",
      path: clickable ? `/${Cookies.get("mhet_cnd_project")}/objection-tracker` : null,
    },
    {
      icon: (
        <img
          src={survey}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      name: "CAP Registration",
      details: "Register for the Centralized Admission Process (CAP) here.",
      disabled: true,
      path: clickable ? `/${Cookies.get("mhet_cnd_project")}/` : null,
    },

    {
      icon: (
        <img
          src={doubts}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      disabled: false,
      name: "Help Desk",
      details: "Contact support for assistance with any queries or issues",
      path: clickable ? `/${Cookies.get("mhet_cnd_project")}/raise-query` : null,
    },
    {
      icon: (
        <img
          src={bell}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      name: "CET Notification",
      details:
        "Stay updated with the latest notifications related to CET exams.",
      disabled: false,
      path: clickable ? `/${Cookies.get("mhet_cnd_project")}/` : null,
      link: "https://cetcell.mahacet.org/notices",
    },
    {
      icon: (
        <img
          src={bell}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      name: "CAP Notification",
      details:
        "Stay updated with the latest notifications  related to CAP activities.",
      disabled: true,
      path: clickable ? `/${Cookies.get("mhet_cnd_project")}/` : null,
    },
    {
      icon: (
        <img
          src={idBadge}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      name: "ARA",
      details: "Access ARA Portal.",
      disabled: true,
      path: clickable ?`/${Cookies.get("mhet_cnd_project")}/` : null,
    },
    {
      icon: (
        <img
          src={checklist}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      name: "Response sheet",
      details: "View your response sheet here",
      disabled: true,
      path: clickable ? `/${Cookies.get("mhet_cnd_project")}/` :  null,
    },
  ];

  const navigate = useNavigate();

  return (
    <Grid container spacing={3} justifyContent="center">
      {items.map((item, index) => (
        <Grid item sm={12} md={6} lg={4} key={index}>
          <Paper
            elevation={1}
            sx={{
              padding: 2,
              minHeight: 120,
              height: "100%",
              maxWidth: 450,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: item.disabled ? "not-allowed" : "pointer",
              opacity: item.disabled ? 0.6 : 1,
              backgroundColor: item.disabled ? "#f0f0f0" : "#fff",
              pointerEvents: item.disabled ? "none" : "auto",
            }}
            onClick={() => {
              if (!item.disabled && item.path) {
                navigate(item.path);
              }
              if (item?.link) {
                window.open("https://cetcell.mahacet.org/notices", "_blank");
              }
            }}
          >
            <Grid container alignItems="center">
              <Grid
                item
                sm={4}
                xs={12}
                md={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    color: item.disabled ? "#9e9e9e" : "#0F4F96",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 62,
                    height: 65,
                    opacity: item.disabled ? 0.5 : 1,
                  }}
                >
                  {item.icon}
                </Box>
              </Grid>
              <Grid item sm={6} xs={12} md={8}>
                <CardContent sx={{ padding: "16px " }}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      fontSize: 16,
                      fontWeight: 500,
                      fontFamily: "inter",
                      textAlign: isMobile ? "center" : "start",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#5683B5",
                      paddingTop: "7px",
                      textAlign: isMobile ? "center" : "start",
                    }}
                  >
                    {item.details}
                  </Typography>
                </CardContent>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ textAlign: isMobile ? "center" : "end" }}
              >
                <HiArrowLongRight
                  size={20}
                  style={{
                    color: "#5683B5",
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
      <MobileVerification 
       otp={otp}
       setOtp={setOtp}
       error={error}
       setError={setError}
       setResendCountdown={setResendCountdown}
       resendCountdown={resendCountdown}
       setWrongotp={setWrongotp}
       attempt={attempt}
       wrongOTP={wrongOTP}
       red={red}
       setRed={setRed}
       setAttempt={setAttempt}
       timeRemaining={timeRemaining}
       setTimeRemaining={setTimeRemaining}
       timeRemainingmin={timeRemainingmin}
       setTimeRemainingmin={setTimeRemainingmin}
       timeRemainingsec={timeRemainingsec}
       setTimeRemainingsec={setTimeRemainingsec}
      mobileno={mobileno}
      timer={timer}
      setTimer={setTimer}
      difference={difference}
      mobileverified={mobileverified}
      setMobileVerified={setMobileVerified}
      openDialog={openDialog}
      setOpenDialog={setOpenDialog}
      />
    </Grid>
  );
}