import {
  TextField,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BackButton from "../../common/backButton";
import SubmitButton from "../../common/submitButton";
import {
  AddressValidationFirstField,
  AlphabetsValidation,
  AddressValidation,
  pincodeValidation,
  pincodeNumberValidation,
  MobileNumberValidation,
  AlphabetsandNumbersValidation,
  telephoneNumberFirstNumberValidation,
  NumbersValidation,
} from "../../utils/validations";
import masterActions from "../../redux/master/action";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
import candidatesActions from "../../redux/candidates/actions";
import { masterFieldLabel, isFieldDisabled } from "../../utils/courseValues";

export default function AddressForm({ nextPageName, previousPageName }) {
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const courseid = location.state?.courseid;
  const statusForRedirect = location.state?.status;
  const coursename = location.state?.coursename;
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    state,
    districtForCor,
    talukaForCor,
    villageForCor,
  } = useSelector((state) => state.masterReducer);
  const { masterFields, courseFormDetails, courseAddressDetails } = useSelector(
    (state) => state.candidatesReducer
  );
  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const {
    pincodeCorrespondence,
    pincode,
  } = useSelector((state) => state.candidatesReducer);
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );
  const isPaid = courseFormDetails[0]?.ispaid;
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (courseAddressDetails?.length > 0) {
        setIsSameAddress(courseAddressDetails[0]?.sameascommunication == 1);
        reset({
          ...courseAddressDetails[0],
          caddressline3: courseAddressDetails[0]?.caddressline3 === 'null' ? null : courseAddressDetails[0]?.caddressline3,
        });


        if (courseAddressDetails[0]?.sameascommunication === "1") {
          setIsSameAddress(true);
          setDisabled(true)
        }
        if (courseAddressDetails[0]?.sameascommunication === "0") {
          setIsSameAddress(false);
          setDisabled(true)
        }

      }
    }, 300);
  
    return () => clearTimeout(timeout);
  }, [courseAddressDetails, reset]);


  

  const handleIsSameAddressChange = (e) => {
    const isChecked = e.target.checked;

    setIsSameAddress(isChecked);
    if (disabled) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    if (!isChecked) {
      const fields = [
        "caddressline1",
        "caddressline2",
        "caddressline3",
        "cpincode",
        "cstateid",
        "cdistrictid",
        "ctalukaid",
        "cvillageid",
        "stdcode",
      ];
      fields.forEach((field) => {
        setValue(field, "");
      });
    }
  };
  useEffect(() => {
    if (pincode.length > 0 && pincode[0]) {
      setValue("pstateid", pincode[0]?.stateid ?? 0);
      setValue("pdistrictid", pincode[0]?.districtid ?? 0);
      setValue("ptalukaid", pincode[0]?.talukaid ?? 0);
    }
  }, [pincode]);
  
  useEffect(() => {
    if (pincodeCorrespondence.length > 0 && pincodeCorrespondence[0]) {
      setValue("cstateid", pincodeCorrespondence[0]?.stateid ?? 0);
      setValue("cdistrictid", pincodeCorrespondence[0]?.districtid ?? 0);
      setValue("ctalukaid", pincodeCorrespondence[0]?.talukaid ?? 0);
      trigger(["cstateid", "cdistrictid", "ctalukaid", "cvillageid"]);
    }
  }, [pincodeCorrespondence]);
  
  useEffect(() => {
    if (isSameAddress) {
      const pdistrictid = getValues("pdistrictid");
      setValue("caddressline1", watch("paddressline1") || "");
      setValue("caddressline2", watch("paddressline2") || "");
      setValue("caddressline3", watch("paddressline3") || "");
      setValue("cpincode", watch("ppincode") || "");
      setValue("cstateid", watch("pstateid") || 0);
      setValue("cdistrictid", pdistrictid || 0);
      setValue("ctalukaid", watch("ptalukaid") || 0);
      setValue("cvillageid", watch("pvillageid") || 0);
      trigger([
        "caddressline1",
        "caddressline2",
        "caddressline3",
        "cpincode",
        "cstateid",
        "cdistrictid",
        "ctalukaid",
        "cvillageid",
      ]);
    }
  }, [
    isSameAddress,
    watch("paddressline1"),
    watch("paddressline2"),
    watch("paddressline3"),
    watch("ppincode"),
    watch("pstateid"),
    watch("pdistrictid"),
    watch("ptalukaid"),
    watch("pvillageid"),
  ]);
  
  //   if (isSameAddress) {
  //     setValue("caddressline1", watch("paddressline1") || "");
  //     setValue("caddressline2", watch("paddressline2") || "");
  //     setValue("caddressline3", watch("paddressline3") || "");
  //     setValue("cpincode", watch("ppincode") || "");
  //     setValue("cstateid", watch("pstateid") || 0);
  //     setValue("cdistrictid", watch("pdistrictid") || 0);
  //     setValue("ctalukaid", watch("ptalukaid") || 0);
  //     setValue("cvillageid", watch("pvillageid") || 0);
  //     trigger([
  //       "caddressline1",
  //       "caddressline2",
  //       "caddressline3",
  //       "cpincode",
  //       "cstateid",
  //       "cdistrictid",
  //       "ctalukaid",
  //       "cvillageid",
  //     ]);
  //   }
  // }, [
  //   isSameAddress,
  //   watch("paddressline1"),
  //   watch("paddressline2"),
  //   watch("paddressline3"),
  //   watch("ppincode"),
  //   watch("pstateid"),
  //   watch("pdistrictid"),
  //   watch("ptalukaid"),
  //   watch("pvillageid"),
  // ]);


    // useEffect(() => {
  //   if (courseAddressDetails?.length > 0) {
  //     dispatch({
  //       type: masterActions.GET_STATE,
  //     });
  //     dispatch({
  //       type: masterActions.GET_DISTRICT_FOR_COURSE,
  //       payload: {
  //         type: "parmanent",
  //         stateid: courseAddressDetails[0]?.pstateid,
  //       },
  //     });

  //     dispatch({
  //       type: masterActions.GET_TALUKA_FOR_COURSE,
  //       payload: {
  //         type: "parmanent",
  //         districtid: courseAddressDetails[0]?.pdistrictid,
  //       },
  //     });

  //     dispatch({
  //       type: masterActions.GET_VILLAGE_FOR_COURSE,
  //       payload: {
  //         type: "parmanent",
  //         talukaid: courseAddressDetails[0]?.ptalukaid,
  //       },
  //     });
  //     setTimeout(() => {
  //       dispatch({
  //         type: masterActions.GET_DISTRICT_FOR_COURSE,
  //         payload: {
  //           type: "corespondence",
  //           stateid: courseAddressDetails[0]?.cstateid,
  //         },
  //       });
  //       dispatch({
  //         type: masterActions.GET_TALUKA_FOR_COURSE,
  //         payload: {
  //           type: "corespondence",
  //           districtid: courseAddressDetails[0]?.cdistrictid,
  //         },
  //       });
  //       dispatch({
  //         type: masterActions.GET_VILLAGE_FOR_COURSE,
  //         payload: {
  //           type: "corespondence",
  //           talukaid: courseAddressDetails[0]?.ctalukaid,
  //         },
  //       });
  //     }, 1000);
  //     // reset({ ...courseAddressDetails[0] });
  //     reset({ ...courseAddressDetails[0],caddressline3:courseAddressDetails[0]?.caddressline3 == 'null' ? null :courseAddressDetails[0]?.caddressline3 });

  //     courseAddressDetails[0]?.sameascommunication === "1"
  //       ? setIsSameAddress(true)
  //       : setIsSameAddress(false);
  //     console.log("courseAddressDetails[0]---", courseAddressDetails[0]);
  //   }
  // }, [courseAddressDetails, courseFormDetails]);

  // useEffect(() => {
  //   if (isSameAddress) {
  //     setTimeout(() => {
  //       setValue("caddressline1", watch("paddressline1"));
  //       setValue("caddressline2", watch("paddressline2"));
  //       setValue("caddressline3", watch("paddressline3"));
  //       setValue("cpincode", watch("ppincode"));
  //       setValue("cstateid", watch("pstateid"));
  //       const districtValue = watch("pdistrictid") || "";
  //       setValue("cdistrictid", districtValue);
  //       setValue("ctalukaid", watch("ptalukaid"));
  //       setValue("cvillageid", watch("pvillageid"));
  //       trigger([
  //         "caddressline1",
  //         "caddressline2",
  //         "caddressline3",
  //         "cpincode",
  //         "cstateid",
  //         "cdistrictid",
  //         "ctalukaid",
  //         "cvillageid",
  //       ]);
  //     }, 1000);
  //   }
  // }, [
  //   watch("paddressline1"),
  //   watch("paddressline2"),
  //   watch("paddressline3"),
  //   watch("ppincode"),
  //   watch("pstateid"),
  //   watch("pdistrictid"),
  //   watch("ptalukaid"),
  //   watch("pvillageid"),
  //   isSameAddress,
  // ]);


  useEffect(() => {
    if (candidateid && courseid) {
      dispatch({
        type: candidatesActions.GET_COURSE_ADDRESS_DETAILS,
        payload: {
          courseid: courseid,
          candidateid: candidateid,
        },
      });
    }
  }, [candidateid, courseid]);


  const handleProcced = (data) => {
    const corcandidateaddressid =
      courseAddressDetails[0]?.corcandidateaddressid;
    dispatch({
      type: candidatesActions.COURSE_ADDRESS_DETAILS_CREATION,
      payload: {
        data: {
          ...data,
          operation: corcandidateaddressid
            ? parseInt(corcandidateaddressid)
            : 0,
          candidateid: parseInt(candidateid),
          courseid: courseid,
          coursename: coursename,
          status: 1,
          createdby: parseInt(candidateid),
          sameascommunication: isSameAddress ? 1 : 0,
        },
        navigate: navigate,
        nextPageName: nextPageName,
        statusForRedirect: statusForRedirect,
      },
    });
    dispatch({ type: candidatesActions.GET_COURSE_REGISTRATION_DETAILS });
    dispatch({
      type: candidatesActions.GET_COURSE_FORM_DETAILS,
      payload: { courseid: courseid },
    });
    dispatch({ type: candidatesActions.GET_COURSE_STATUS, payload: { candidateid, courseid } })
    dispatch({ type: candidatesActions.GET_IS_PAID, payload: { candidateid, courseid } })
  };

  const handleBack = () => {
    navigate(
      `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=${previousPageName}`,
      { state: { courseid: courseid, coursename: coursename } }
    );
  };

  const handlePincodePermanent = (e) => {
    const value = e.target.value;
    clearErrors("ppincode");
    if (value.length === 6) {
      dispatch({
        type: candidatesActions.GET_PINCODE,
        payload: { value: value },
      });
      setValue("ctalukaid", watch("ptalukaid"));
    }
  };

  const handlePincodeCorrspondance = (e) => {
    const value = e.target.value;
    if (value.length === 6) {
      dispatch({
        type: candidatesActions.GET_PINCODE_CORRESPONDENCE,
        payload: { value: value },
      });
    }
  };


  return (
    <Grid
      p={3}
      style={{
        padding: "20px 25px 0px 25px",
        minHeight: "60vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
        border: "1px solid #0F4F96",
      }}
    >
      <Grid>
        <Typography
          variant="h4"
          sx={{
            textWrap: "wrap",
            fontSize: 20,
            fontWeight: 500,
            color: "#212121",
            marginBottom: 1,
          }}
        >
          Permanent Address
        </Typography>
        <Grid pt={1} container spacing={2}>
          {masterFields?.find(
            (field) =>
              field.masterfieldlabel ===
              masterFieldLabel.PERMANENT_ADDRESS_LINE_1
          ) && (
              <Grid item xs={12} md={6}>
                <Tooltip
                  title={
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.PERMANENT_ADDRESS_LINE_1
                    )?.fieldlabel
                  }
                  arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                >
                  <TextField
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    fullWidth
                    variant="outlined"
                    type="text"
                    label={
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.PERMANENT_ADDRESS_LINE_1
                        )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.PERMANENT_ADDRESS_LINE_1
                          )?.fieldlabel
                        }
                      </span>
                    }
                    placeholder="Door no,street"
                    {...register("paddressline1", {
                      required:
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.PERMANENT_ADDRESS_LINE_1
                        )?.ismandatory === "1"
                          ? " \u00A0 \u00A0 Address is required"
                          : false,
                      validate: AddressValidationFirstField,
                    })}
                    error={!!errors.paddressline1}
                    helperText={errors.paddressline1?.message}
                    FormHelperTextProps={{
                      style: { margin: 0 },
                    }}
                    InputLabelProps={{
                      shrink: !!watch("paddressline1")?.trim(),
                    }}
                    disabled={isFieldDisabled(
                      masterFields,
                      masterFieldLabel.PERMANENT_ADDRESS_LINE_1,
                      null,
                      isPaid
                    )}
                  />
                </Tooltip>
              </Grid>
            )}

          {masterFields?.find(
            (field) =>
              field.masterfieldlabel ===
              masterFieldLabel.PERMANENT_ADDRESS_LINE_2
          ) && (
              <Grid item xs={12} md={6}>
                <Tooltip
                  title={
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.PERMANENT_ADDRESS_LINE_2
                    )?.fieldlabel
                  }
                  arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    type="text"
                    label={
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.PERMANENT_ADDRESS_LINE_2
                        )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.PERMANENT_ADDRESS_LINE_2
                          )?.fieldlabel
                        }
                      </span>
                    }
                    placeholder="Locality"
                    {...register("paddressline2", {
                      required:
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.PERMANENT_ADDRESS_LINE_2
                        )?.ismandatory === "1"
                          ? "\u00A0 \u00A0 Address is required"
                          : false,
                      validate: AddressValidationFirstField,
                    })}
                    error={!!errors.paddressline2}
                    helperText={errors.paddressline2?.message}
                    FormHelperTextProps={{
                      style: { margin: 0 },
                    }}
                    // onInput={AlphabetsandNumbersValidation}
                    InputLabelProps={{
                      shrink: !!watch("paddressline2")?.trim(),
                    }}
                    disabled={isFieldDisabled(
                      masterFields,
                      masterFieldLabel.PERMANENT_ADDRESS_LINE_2,
                      null,
                      isPaid
                    )}
                  />
                </Tooltip>
              </Grid>
            )}

          {masterFields?.find(
            (field) =>
              field.masterfieldlabel ===
              masterFieldLabel.PERMANENT_ADDRESS_LINE_3
          ) && (
              // <Grid item xs={12} md={6}>
              //   <TextField
              //     fullWidth
              //     variant="outlined"
              //     inputProps={{
              //       style: { height: "56px", boxSizing: "border-box" },
              //       maxLength: 50,
              //     }}
              //     type="text"
              //     label={
              //       <span>
              //         {
              //           masterFields?.find(
              //             (field) =>
              //               field?.masterfieldlabel ===
              //               masterFieldLabel.PERMANENT_ADDRESS_LINE_3
              //           )?.fieldlabel
              //         }
              //         {masterFields?.find(
              //           (field) =>
              //             field?.masterfieldlabel ===
              //             masterFieldLabel.PERMANENT_ADDRESS_LINE_3
              //         )?.ismandatory === "1" && (
              //           <span className="error" style={{ color: "red" }}>
              //             {" "}
              //             *
              //           </span>
              //         )}
              //       </span>
              //     }
              //     placeholder="Locality"
              //     {...register("paddressline3", {
              //       required:
              //         masterFields?.find(
              //           (field) =>
              //             field?.masterfieldlabel ===
              //             masterFieldLabel.PERMANENT_ADDRESS_LINE_3
              //         )?.ismandatory === "1"
              //           ? "Address is required"
              //           : false,
              //       validate: AddressValidationFirstField,
              //     })}
              //     error={!!errors.paddressline3}
              //     helperText={errors.paddressline3?.message}
              //     FormHelperTextProps={{
              //       style: { margin: 0 },
              //     }}
              //     // onInput={AlphabetsValidation}
              //     InputLabelProps={{
              //       shrink: !!watch("paddressline3")?.trim(),
              //     }}
              //     disabled={isFieldDisabled(
              //       masterFields,
              //       masterFieldLabel.PERMANENT_ADDRESS_LINE_3,
              //       null,
              //       isPaid
              //     )}
              //   />
              // </Grid>
              <Grid item xs={12} md={6}>
                <Tooltip
                  title={
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.PERMANENT_ADDRESS_LINE_3
                    )?.fieldlabel
                  }
                  arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    type="text"
                    label={
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.PERMANENT_ADDRESS_LINE_3
                          )?.fieldlabel
                        }
                        {/* {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.PERMANENT_ADDRESS_LINE_3
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )} */}
                      </span>
                    }
                    placeholder="Locality"
                    {...register("paddressline3", {
                      // validate: (value) => {
                      //   if (value && AddressValidationFirstField(value)) {
                      //     return true;
                      //   }
                      //   return true; // Allow empty value
                      // },
                      validate: AddressValidationFirstField
                    })}
                    error={!!errors.paddressline3}
                    helperText={errors.paddressline3?.message}
                    FormHelperTextProps={{
                      style: { margin: 0 },
                    }}
                    InputLabelProps={{
                      shrink: !!watch("paddressline3")?.trim(),
                    }}
                    disabled={isFieldDisabled(
                      masterFields,
                      masterFieldLabel.PERMANENT_ADDRESS_LINE_3,
                      null,
                      isPaid
                    )}
                  />
                </Tooltip>
              </Grid>
            )}

          {masterFields?.find(
            (field) =>
              field.masterfieldlabel === masterFieldLabel.PERMANENT_PINCODE
          ) && (
              <Grid item xs={12} md={6}>
                <Tooltip
                  title={
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.PERMANENT_PINCODE
                    )?.fieldlabel
                  }
                  arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                >
                  <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    label={
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.PERMANENT_PINCODE
                        )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.PERMANENT_PINCODE
                          )?.fieldlabel
                        }
                      </span>
                    }
                    placeholder="Enter the pincode"
                    {...register("ppincode", {
                      required:
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.PERMANENT_PINCODE
                        )?.ismandatory === "1"
                          ? "\u00A0 \u00A0 Pincode is required"
                          : false,
                      validate: pincodeValidation,
                    })}
                    onChange={handlePincodePermanent}
                    onInput={pincodeNumberValidation}
                    error={!!errors.ppincode}
                    helperText={errors.ppincode?.message}
                    FormHelperTextProps={{
                      style: { margin: 0 },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isFieldDisabled(
                      masterFields,
                      masterFieldLabel.PERMANENT_PINCODE,
                      null,
                      isPaid
                    )}
                  />
                </Tooltip>
              </Grid>
            )}

          {masterFields?.find(
            (field) =>
              field.masterfieldlabel === masterFieldLabel.PERMANENT_STATE
          ) && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errors.pstateid}>
                  <Tooltip
                    title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.PERMANENT_STATE
                      )?.fieldlabel
                    }
                    arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                  >
                    <TextField
                      select
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink: !!watch("pstateid") || watch("pstateid") === 0,
                      }}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      id="pstateid"
                      label={
                        <span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.PERMANENT_STATE
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.PERMANENT_STATE
                              )?.fieldlabel
                            }
                          </span>
                        </span>
                      }
                      // value={getValues("pstateid")}
                      {...register("pstateid", {
                        required:
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.PERMANENT_STATE
                          )?.ismandatory === "1"
                            ? "state is required"
                            : false,
                      })}
                      SelectProps={{
                        MenuProps: { disableScrollLock: true },
                        style: { height: "56px", boxSizing: "border-box" },
                        onChange: (e) => {
                          const stateid = e.target.value;
                          if (stateid === 0) {
                          }
                          setValue("pstateid", stateid);
                          setValue("pdistrictid", "");
                          setValue("ptalukaid", "");
                          setValue("pvillageid", "");
                          dispatch({
                            type: masterActions.GET_DISTRICT_FOR_COURSE,
                            payload: { stateid: stateid, type: "parmanent" },
                          });
                          dispatch({
                            type: masterActions.SET_TALUKA_FOR_COURSE,
                            payload: { ...talukaForCor, parmanent: [] },
                          });
                          dispatch({
                            type: masterActions.SET_VILLAGE_FOR_COURSE,
                            payload: { ...villageForCor, parmanent: [] },
                          });
                          trigger([
                            "pstateid",
                            "pdistrictid",
                            "ptalukaid",
                            "pvillageid",
                          ]);
                        },
                      }}
                      value={watch("pstateid") ?? ""}
                      disabled={isFieldDisabled(
                        masterFields,
                        masterFieldLabel.PERMANENT_STATE,
                        null,
                        isPaid
                      )}
                      error={!!errors.stateid}
                      helperText={errors.stateid ? errors.stateid.message : ""}
                    >
                      <MenuItem value={0}>Not Applicable</MenuItem>
                      {state?.map((val, i) => (
                        <MenuItem value={val?.stateid} key={i}>
                          {val?.state}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Tooltip>
                </FormControl>
              </Grid>
            )}

          {masterFields?.find(
            (field) =>
              field.masterfieldlabel === masterFieldLabel.PERMANENT_DISTRICT
          ) && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errors.pdistrictid}>
                  <Tooltip
                    title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.PERMANENT_DISTRICT
                      )?.fieldlabel
                    }
                    arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                  >
                    <TextField
                      select
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink:
                          !!watch("pdistrictid") || watch("pdistrictid") === 0,
                      }}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      id="pdistrictid"
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      label={
                        <span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.PERMANENT_DISTRICT
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.PERMANENT_DISTRICT
                              )?.fieldlabel
                            }
                          </span>
                        </span>
                      }
                      SelectProps={{
                        MenuProps: { disableScrollLock: true },
                        style: { height: "56px", boxSizing: "border-box" },
                        onChange: (e) => {
                          const districtid = e.target.value;
                          setValue("pdistrictid", districtid);
                          dispatch({
                            type: masterActions.GET_TALUKA_FOR_COURSE,
                            payload: {
                              districtid: districtid,
                              type: "parmanent",
                            },
                          });
                          dispatch({
                            type: masterActions.SET_VILLAGE_FOR_COURSE,
                            payload: { ...villageForCor, parmanent: [] },
                          });
                          // trigger("pdistrictid");
                          setValue("ptalukaid", "");
                          setValue("pvillageid", "");
                          // setError('ptalukaid')
                          // setError('pvillageid')
                          trigger(["pdistrictid", "ptalukaid", "pvillageid"]);
                        },
                      }}
                      // value={getValues("pdistrictid")}
                      value={watch("pdistrictid") ?? ""}
                      {...register("pdistrictid", {
                        required:
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.PERMANENT_DISTRICT
                          )?.ismandatory === "1"
                            ? "District is required"
                            : false,
                      })}
                      error={!!errors.pdistrictid}
                      helperText={
                        errors.pdistrictid ? errors.pdistrictid.message : ""
                      }
                      disabled={isFieldDisabled(
                        masterFields,
                        masterFieldLabel.PERMANENT_DISTRICT,
                        null,
                        isPaid
                      )}
                    >
                      <MenuItem value={0}>Not Applicable</MenuItem>
                      {getValues("pstateid") !== 0 &&
                        getValues("pstateid") !== -1 &&
                        districtForCor?.parmanent?.map((val, i) => (
                          <MenuItem value={val?.districtid} key={i}>
                            {val?.district}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Tooltip>
                </FormControl>
              </Grid>
            )}

          {masterFields?.find(
            (field) =>
              field.masterfieldlabel === masterFieldLabel.PERMANENT_TALUKA
          ) && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errors.ptalukaid}>
                  <Tooltip
                    title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.PERMANENT_TALUKA
                      )?.fieldlabel
                    }
                    arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                  >
                    <TextField
                      select
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink: !!watch("ptalukaid") || watch("ptalukaid") === 0,
                      }}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      id="ptalukaid"
                      label={
                        <span>
                          <span>
                            {masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.PERMANENT_TALUKA
                            )?.ismandatory === "1" && (
                                <span className="error" style={{ color: "red" }}>
                                  {" "}
                                  *
                                </span>
                              )}
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.PERMANENT_TALUKA
                              )?.fieldlabel
                            }
                          </span>
                        </span>
                      }
                      value={watch("ptalukaid") ?? ""}
                      // value={getValues("ptalukaid")}
                      aria-label="Default select example"
                      {...register("ptalukaid", {
                        required:
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.PERMANENT_TALUKA
                          )?.ismandatory === "1"
                            ? "Taluka is required"
                            : false,
                      })}
                      SelectProps={{
                        MenuProps: { disableScrollLock: true },
                        style: { height: "56px", boxSizing: "border-box" },
                        onChange: (e) => {
                          const talukaid = e.target.value;
                          setValue("ptalukaid", talukaid);
                          dispatch({
                            type: masterActions.GET_VILLAGE_FOR_COURSE,
                            payload: { talukaid: talukaid, type: "parmanent" },
                          });
                          // trigger("ptalukaid");
                          setValue("pvillageid", "");
                          // setError("pvillageid");
                          trigger(["ptalukaid", "pvillageid"]);
                        },
                      }}
                      error={!!errors.ptalukaid}
                      helperText={
                        errors.ptalukaid ? errors.ptalukaid.message : ""
                      }
                      disabled={isFieldDisabled(
                        masterFields,
                        masterFieldLabel.PERMANENT_TALUKA,
                        null,
                        isPaid
                      )}
                    >
                      <MenuItem value={0}>Not Applicable</MenuItem>
                      {talukaForCor?.parmanent?.map((val, i) => (
                        <MenuItem value={val?.talukaid} key={i}>
                          {val?.taluka}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Tooltip>
                </FormControl>
              </Grid>
            )}

          {masterFields?.find(
            (field) =>
              field.masterfieldlabel === masterFieldLabel.PERMANENT_VILLAGE
          ) && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errors.pvillageid}>
                  <Tooltip
                    title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.PERMANENT_VILLAGE
                      )?.fieldlabel
                    }
                    arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                  >
                    <TextField
                      select
                      variant="outlined"
                      fullWidth
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      id="pvillageid"
                      label={
                        <span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.PERMANENT_VILLAGE
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.PERMANENT_VILLAGE
                              )?.fieldlabel
                            }
                          </span>
                        </span>
                      }
                      value={watch("pvillageid") ?? ""}
                      // value={getValues("pvillageid")}
                      {...register("pvillageid", {
                        required:
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.PERMANENT_VILLAGE
                          )?.ismandatory === "1"
                            ? "Village is required"
                            : false,
                      })}
                      InputLabelProps={{
                        shrink:
                          !!watch("pvillageid") || watch("pvillageid") === 0,
                      }}
                      SelectProps={{
                        MenuProps: { disableScrollLock: true },
                        style: { height: "56px", boxSizing: "border-box" },
                        onChange: (e) => {
                          const villageid = e.target.value;
                          setValue("pvillageid", villageid);
                          // trigger("pvillageid");
                          trigger(["pvillageid"]);
                        },
                      }}
                      error={!!errors.pvillageid}
                      helperText={
                        errors.pvillageid ? errors.pvillageid.message : ""
                      }
                      disabled={isFieldDisabled(
                        masterFields,
                        masterFieldLabel.PERMANENT_VILLAGE,
                        null,
                        isPaid
                      )}
                    >
                      <MenuItem value={0}>Not Applicable</MenuItem>
                      {villageForCor?.parmanent?.map((val, i) => (
                        <MenuItem value={val?.villageid} key={i}>
                          {val?.village}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Tooltip>
                </FormControl>
              </Grid>
            )}

          {masterFields?.find(
            (field) => field.masterfieldlabel === masterFieldLabel.TELEPHONE_NO
          ) && (
              <Grid item xs={12} md={6}>
                <Tooltip
                  title={
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.TELEPHONE_NO
                    )?.fieldlabel
                  }
                  arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.TELEPHONE_NO
                        )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.TELEPHONE_NO
                          )?.fieldlabel
                        }
                      </span>
                    }
                    placeholder="Enter the phone number"
                    {...register("phoneno", {
                      required:
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.TELEPHONE_NO
                        )?.ismandatory === "1"
                          ? " \u00A0 \u00A0 Telephone no is required"
                          : false,
                      maxLength: {
                        value: 10,
                        message: "Enter valid phone number",
                      },
                      validate: telephoneNumberFirstNumberValidation,
                    })}
                    error={!!errors.phoneno}
                    helperText={errors.phoneno?.message}
                    FormHelperTextProps={{
                      style: { margin: 0 },
                    }}
                    onInput={MobileNumberValidation}
                    onChange={(e) => {
                      setValue("phoneno", e.target.value);
                      clearErrors("phoneno");
                      // setIsSameAddress(false)
                    }}
                    type="tel"
                    maxLength={10}
                    InputLabelProps={{
                      shrink: !!watch("phoneno")?.trim(),
                    }}
                    inputProps={{
                      pattern: "[0-9]*",
                      maxLength: 10,
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    disabled={isPaid === "1"}
                  // disabled={isFieldDisabled(masterFields, masterFieldLabel.TELEPHONE_NO) || disabled}
                  />
                </Tooltip>
              </Grid>
            )}
          {/* <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="tel"
              label={
                <span>
                  Std Code
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              placeholder="Enter the std code"
              {...register("stdcode", {
                required: "Std code is required",
                maxLength: {
                  value: 8,
                  message: "Enter valid std code",
                },
              })}
              error={!!errors.stdcode}
              helperText={errors.stdcode?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              onInput={NumbersValidation}
              maxLength={8}
              InputLabelProps={{
                shrink: !!watch("stdcode")?.trim(),
              }}
            />
            {errors.stdcode && (
                <small className="text-danger">{errors.stdcode.message}</small>
              )}
          </Grid> */}
        </Grid>

        <Typography
          mt={1}
          variant="h4"
          sx={{
            textWrap: "wrap",
            fontSize: 20,
            fontWeight: 500,
            color: "#212121",

            marginTop: 1,
          }}
        >
          Correspondence Address
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={isSameAddress}
              onChange={handleIsSameAddressChange}
              style={{
                fontSize: 18,
                color: isSameAddress ? "#06C270" : "inherit",
                marginBottom: 1,
              }}
            />
          }
          label={
            <span className="" style={{ color: "#666666" }}>
              Click here if the permanent and correspondence address are same
            </span>
          }
        />
        <Grid pt={1} container spacing={2}>
          {masterFields?.find(
            (field) =>
              field.masterfieldlabel ===
              masterFieldLabel.COMMUNICATION_ADDRESS_LINE_1
          ) && (
              <Grid item xs={12} md={6}>
                <Tooltip
                  title={
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.COMMUNICATION_ADDRESS_LINE_1
                    )?.fieldlabel
                  }
                  arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    type="text"
                    label={
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.COMMUNICATION_ADDRESS_LINE_1
                        )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.COMMUNICATION_ADDRESS_LINE_1
                          )?.fieldlabel
                        }
                      </span>
                    }
                    placeholder="Door no,street"
                    {...register("caddressline1", {
                      required:
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.COMMUNICATION_ADDRESS_LINE_1
                        )?.ismandatory === "1"
                          ? "\u00A0 \u00A0 Address is required"
                          : false,
                      validate: AddressValidationFirstField,
                    })}
                    onChange={(e) => {
                      setValue("caddressline1", e.target.value);
                      setIsSameAddress(false);
                      trigger("caddressline1")
                      clearErrors("caddressline1");
                    }}
                    error={!!errors.caddressline1}
                    helperText={errors.caddressline1?.message}
                    FormHelperTextProps={{
                      style: { margin: 0 },
                    }}
                    InputLabelProps={{
                      shrink: !!watch("caddressline1"),
                    }}
                    disabled={isSameAddress || isPaid === "1"}
                  // disabled={isFieldDisabled(masterFields, masterFieldLabel.COMMUNICATION_ADDRESS_LINE_1) || disabled}
                  />
                </Tooltip>
              </Grid>
            )}

          {masterFields?.find(
            (field) =>
              field.masterfieldlabel ===
              masterFieldLabel.COMMUNICATION_ADDRESS_LINE_2
          ) && (
              <Grid item xs={12} md={6}>
                <Tooltip
                  title={
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.COMMUNICATION_ADDRESS_LINE_2
                    )?.fieldlabel
                  }
                  arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    type="text"
                    label={
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.COMMUNICATION_ADDRESS_LINE_2
                        )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.COMMUNICATION_ADDRESS_LINE_2
                          )?.fieldlabel
                        }
                      </span>
                    }
                    placeholder="Locality"
                    {...register("caddressline2", {
                      required:
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.COMMUNICATION_ADDRESS_LINE_2
                        )?.ismandatory === "1"
                          ? "\u00A0 \u00A0 Address is required"
                          : false,
                      validate: AddressValidationFirstField,
                    })}
                    onChange={(e) => {
                      setValue("caddressline2", e.target.value);
                      trigger("caddressline2")
                      setIsSameAddress(false);
                      clearErrors("caddressline2");
                    }}
                    error={!!errors.caddressline2}
                    helperText={errors.caddressline2?.message}
                    FormHelperTextProps={{
                      style: { margin: 0 },
                    }}
                    // onInput={AlphabetsandNumbersValidation}
                    InputLabelProps={{
                      shrink: !!watch("caddressline2")?.trim(),
                    }}
                    disabled={isSameAddress || isPaid === "1"}
                  // disabled={isFieldDisabled(masterFields, masterFieldLabel.COMMUNICATION_ADDRESS_LINE_2) || disabled}
                  />
                </Tooltip>
              </Grid>
            )}

          {masterFields?.find(
            (field) =>
              field.masterfieldlabel ===
              masterFieldLabel.COMMUNICATION_ADDRESS_LINE_3
          ) && (
              <Grid item xs={12} md={6}>
                <Tooltip
                  title={
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.COMMUNICATION_ADDRESS_LINE_3
                    )?.fieldlabel
                  }
                  arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    type="text"
                    label={
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.COMMUNICATION_ADDRESS_LINE_3
                          )?.fieldlabel
                        }
                        {/* {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.COMMUNICATION_ADDRESS_LINE_3
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )} */}
                      </span>
                    }
                    placeholder="Locality"
                    // {...register("caddressline3", {
                    //   required:
                    //     masterFields?.find(
                    //       (field) =>
                    //         field?.masterfieldlabel ===
                    //         masterFieldLabel.COMMUNICATION_ADDRESS_LINE_3
                    //     )?.ismandatory === "1"
                    //       ? "Address is required"
                    //       : false,
                    //   validate: AddressValidationFirstField,
                    // })}
                    {...register("caddressline3", {
                      // validate: (value) => {
                      //   if (value && AddressValidationFirstField(value)) {
                      //     return true;
                      //   }
                      //   return true;
                      // },
                      validate: AddressValidationFirstField

                    })}
                    onChange={(e) => {
                      setValue("caddressline3", e.target.value);
                      trigger("caddressline3")
                      setIsSameAddress(false);
                    }}
                    error={!!errors.caddressline3}
                    helperText={errors.caddressline3?.message}
                    FormHelperTextProps={{
                      style: { margin: 0 },
                    }}
                    // onInput={AlphabetsValidation}
                    InputLabelProps={{
                      shrink: !!watch("caddressline3")?.trim(),
                    }}
                    disabled={isSameAddress || isPaid === "1"}
                  // disabled={isFieldDisabled(masterFields, masterFieldLabel.COMMUNICATION_ADDRESS_LINE_3) || disabled}
                  />
                </Tooltip>
              </Grid>
            )}

          {masterFields?.find(
            (field) =>
              field.masterfieldlabel === masterFieldLabel.COMMUNICATION_PINCODE
          ) && (
              <Grid item xs={12} md={6}>
                <Tooltip
                  title={
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.COMMUNICATION_PINCODE
                    )?.fieldlabel
                  }
                  arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                >
                  <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    label={
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.COMMUNICATION_PINCODE
                        )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.COMMUNICATION_PINCODE
                          )?.fieldlabel
                        }
                      </span>
                    }
                    placeholder="Enter the pincode"
                    {...register("cpincode", {
                      required:
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.COMMUNICATION_PINCODE
                        )?.ismandatory === "1"
                          ? "\u00A0 \u00A0 Pincode is required"
                          : false,
                      validate: pincodeValidation,
                    })}
                    onChange={(e) => {
                      handlePincodeCorrspondance(e);
                      setValue("cpincode", e.target.value);
                      setIsSameAddress(false);
                      clearErrors("cpincode");
                    }}
                    onInput={pincodeNumberValidation}
                    error={!!errors.cpincode}
                    helperText={errors.cpincode?.message}
                    FormHelperTextProps={{
                      style: { margin: 0 },
                    }}
                    InputLabelProps={{
                      shrink: !!watch("cpincode")?.trim(),
                    }}
                    disabled={isSameAddress || isPaid === "1"}
                  // disabled={isFieldDisabled(masterFields, masterFieldLabel.COMMUNICATION_PINCODE) || disabled}
                  />
                </Tooltip>
              </Grid>
            )}

          {masterFields?.find(
            (field) =>
              field.masterfieldlabel === masterFieldLabel.COMMUNICATION_STATE
          ) && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errors.cstateid}>
                  <Tooltip
                    title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.COMMUNICATION_STATE
                      )?.fieldlabel
                    }
                    arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                  >
                    <TextField
                      select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink: !!watch("cstateid") || watch("cstateid") === 0,
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      id="cstateid"
                      label={
                        <span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.COMMUNICATION_STATE
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.COMMUNICATION_STATE
                              )?.fieldlabel
                            }
                          </span>
                        </span>
                      }
                      // value={getValues("pstateid")}
                      {...register("cstateid", {
                        required:
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.COMMUNICATION_STATE
                          )?.ismandatory === "1"
                            ? "state is required"
                            : false,
                      })}
                      SelectProps={{
                        MenuProps: { disableScrollLock: true },
                        style: { height: "56px", boxSizing: "border-box" },
                        onChange: (e) => {
                          const stateid = e.target.value;
                          setValue("cstateid", stateid);
                          dispatch({
                            type: masterActions.GET_DISTRICT_FOR_COURSE,
                            payload: { stateid: stateid, type: "corespondence" },
                          });
                          dispatch({
                            type: masterActions.SET_TALUKA_FOR_COURSE,
                            payload: { ...talukaForCor, corespondence: [] },
                          });
                          dispatch({
                            type: masterActions.SET_VILLAGE_FOR_COURSE,
                            payload: { ...villageForCor, corespondence: [] },
                          });
                          // trigger("cstateid");
                          setValue("cdistrictid", "");
                          setValue("ctalukaid", "");
                          setValue("cvillageid", "");
                          trigger([
                            "cstateid",
                            "cdistrictid",
                            "ctalukaid",
                            "cvillageid",
                          ]);

                          setIsSameAddress(false);
                        },
                      }}
                      error={!!errors.cstateid}
                      helperText={errors.cstateid ? errors.cstateid.message : ""}
                      value={watch("cstateid") ?? ""}
                      // disabled={isFieldDisabled(masterFields, masterFieldLabel.COMMUNICATION_STATE) || disabled}
                      disabled={isSameAddress || isPaid === "1"}
                    >
                      <MenuItem value={0}>Not Applicable</MenuItem>
                      {state?.map((val, i) => (
                        <MenuItem value={val?.stateid} key={i}>
                          {val?.state}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Tooltip>
                </FormControl>
              </Grid>
            )}

          {masterFields?.find(
            (field) =>
              field.masterfieldlabel === masterFieldLabel.COMMUNICATION_DISTRICT
          ) && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errors.cdistrictid}>
                  <Tooltip
                    title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.COMMUNICATION_DISTRICT
                      )?.fieldlabel
                    }
                    arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                  >
                    <TextField
                      select
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink:
                          !!watch("cdistrictid") || watch("cdistrictid") === 0,
                      }}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      id="cdistrictid"
                      label={
                        <span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.COMMUNICATION_DISTRICT
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.COMMUNICATION_DISTRICT
                              )?.fieldlabel
                            }
                          </span>
                        </span>
                      }
                      // value={getValues("cdistrictid")}
                      {...register("cdistrictid", {
                        required:
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.COMMUNICATION_DISTRICT
                          )?.ismandatory === "1"
                            ? "District is required"
                            : false,
                      })}
                      SelectProps={{
                        MenuProps: { disableScrollLock: true },
                        style: { height: "56px", boxSizing: "border-box" },
                        onChange: (e) => {
                          const districtid = e.target.value;
                          setValue("cdistrictid", districtid);
                          dispatch({
                            type: masterActions.GET_TALUKA_FOR_COURSE,
                            payload: {
                              districtid: districtid,
                              type: "corespondence",
                            },
                          });
                          dispatch({
                            type: masterActions.SET_VILLAGE_FOR_COURSE,
                            // payload: { ...villageForCor, parmanent: [] },
                            payload: { ...villageForCor },
                          });
                          setValue("ctalukaid", "");
                          setValue("cvillageid", "");
                          trigger(["cdistrictid", "ctalukaid", "cvillageid"]);

                          setIsSameAddress(false);
                        },
                      }}
                      error={!!errors.cdistrictid}
                      helperText={
                        errors.cdistrictid ? errors.cdistrictid.message : ""
                      }
                      value={watch("cdistrictid") ?? ""}
                      // disabled={isFieldDisabled(masterFields, masterFieldLabel.COMMUNICATION_DISTRICT) || disabled}
                      disabled={isSameAddress || isPaid === "1"}
                    >
                      <MenuItem value={0}>Not Applicable</MenuItem>
                      {isSameAddress
                        ? getValues("pstateid") !== 0 &&
                        getValues("pstateid") !== -1 &&
                        districtForCor?.parmanent?.map((val, i) => (
                          <MenuItem value={val?.districtid} key={i}>
                            {val?.district}
                          </MenuItem>
                        ))
                        : getValues("cstateid") !== 0 &&
                        getValues("cstateid") !== -1 &&
                        districtForCor?.corespondence?.map((val, i) => (
                          <MenuItem value={val?.districtid} key={i}>
                            {val?.district}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Tooltip>
                </FormControl>
              </Grid>
            )}

          {masterFields?.find(
            (field) =>
              field.masterfieldlabel === masterFieldLabel.COMMUNICATION_TALUKA
          ) && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errors.ctalukaid}>
                  <Tooltip
                    title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.COMMUNICATION_TALUKA
                      )?.fieldlabel
                    }
                    arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                  >
                    <TextField
                      select
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink: !!watch("ctalukaid") || watch("ctalukaid") === 0,
                      }}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      id="ctalukaid"
                      label={
                        <span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.COMMUNICATION_TALUKA
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.COMMUNICATION_TALUKA
                              )?.fieldlabel
                            }
                          </span>
                        </span>
                      }
                      // value={getValues("ptalukaid")}
                      aria-label="Default select example"
                      {...register("ctalukaid", {
                        required:
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.COMMUNICATION_TALUKA
                          )?.ismandatory === "1"
                            ? "Taluka is required"
                            : false,
                      })}
                      SelectProps={{
                        MenuProps: { disableScrollLock: true },
                        style: { height: "56px", boxSizing: "border-box" },
                        onChange: (e) => {
                          const talukaid = e.target.value;
                          setValue("ctalukaid", talukaid);
                          dispatch({
                            type: masterActions.GET_VILLAGE_FOR_COURSE,
                            payload: {
                              talukaid: talukaid,
                              type: "corespondence",
                            },
                          });
                          // trigger("ctalukaid");
                          setValue("cvillageid", "");
                          // setError("cvillageid");
                          trigger(["ctalukaid", "cvillageid"]);

                          setIsSameAddress(false);
                        },
                      }}
                      error={!!errors.ctalukaid}
                      helperText={
                        errors.ctalukaid ? errors.ctalukaid.message : ""
                      }
                      value={watch("ctalukaid") ?? ""}
                      disabled={isSameAddress || isPaid === "1"}
                    // disabled={isFieldDisabled(masterFields, masterFieldLabel.COMMUNICATION_TALUKA) || disabled}
                    >
                      <MenuItem value={0}>Not Applicable</MenuItem>
                      {isSameAddress
                        ? talukaForCor?.parmanent?.map((val, i) => (
                          <MenuItem value={val?.talukaid} key={i}>
                            {val?.taluka}
                          </MenuItem>
                        ))
                        : talukaForCor?.corespondence?.map((val, i) => (
                          <MenuItem value={val?.talukaid} key={i}>
                            {val?.taluka}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Tooltip>
                </FormControl>
              </Grid>
            )}

          {masterFields?.find(
            (field) =>
              field.masterfieldlabel === masterFieldLabel.COMMUNICATION_VILLAGE
          ) && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errors.cvillageid}>
                  <Tooltip
                    title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.COMMUNICATION_VILLAGE
                      )?.fieldlabel
                    }
                    arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                  >
                    <TextField
                      select
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink:
                          !!watch("cvillageid") || watch("cvillageid") === 0,
                      }}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      id="cvillageid"
                      label={
                        <span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.COMMUNICATION_VILLAGE
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.COMMUNICATION_VILLAGE
                              )?.fieldlabel
                            }
                          </span>
                        </span>
                      }
                      // value={getValues("pvillageid")}
                      {...register("cvillageid", {
                        required:
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.COMMUNICATION_VILLAGE
                          )?.ismandatory === "1"
                            ? "Village is required"
                            : false,
                      })}
                      SelectProps={{
                        MenuProps: { disableScrollLock: true },
                        style: { height: "56px", boxSizing: "border-box" },
                        onChange: (e) => {
                          const villageid = e.target.value;
                          setValue("cvillageid", villageid);
                          trigger(["cvillageid"]);
                          // trigger("cvillageid");
                          setIsSameAddress(false);
                        },
                      }}
                      error={!!errors.cvillageid}
                      helperText={
                        errors.cvillageid ? errors.cvillageid.message : ""
                      }
                      value={watch("cvillageid") ?? ""}
                      disabled={isSameAddress || isPaid === "1"}
                    // disabled={isFieldDisabled(masterFields, masterFieldLabel.COMMUNICATION_VILLAGE) || disabled}
                    >
                      <MenuItem value={0}>Not Applicable</MenuItem>
                      {isSameAddress
                        ? villageForCor?.parmanent?.map((val, i) => (
                          <MenuItem value={val?.villageid} key={i}>
                            {val?.village}
                          </MenuItem>
                        ))
                        : villageForCor?.corespondence?.map((val, i) => (
                          <MenuItem value={val?.villageid} key={i}>
                            {val?.village}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Tooltip>
                </FormControl>
              </Grid>
            )}
          {masterFields?.find(
            (field) => field.masterfieldlabel === masterFieldLabel.STD_CODE
          ) && (
              <Grid item xs={12} md={6}>
                <Tooltip
                  title={
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.STD_CODE
                    )?.fieldlabel
                  }
                  arrow   PopperProps={{
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 0], 
        },
      },
    ],
  }}
    placement="right" 
  disableInteractive  
  enterDelay={500} 
  leaveDelay={200}
                >
                  <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    inputProps={{
                      style: { height: "56px", boxSizing: "border-box" },
                      maxLength: 50,
                    }}
                    label={
                      <span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.STD_CODE
                        )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.STD_CODE
                          )?.fieldlabel
                        }
                      </span>
                    }
                    placeholder="Enter the STD Code"
                    {...register("stdcode", {
                      required:
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.STD_CODE
                        )?.ismandatory === "1"
                          ? " \u00A0 \u00A0 STD Code is required"
                          : false,
                      // validate: NumbersValidation,
                    })}
                    onChange={(e) => {
                      setValue("stdcode", e.target.value);
                      trigger("stdcode");
                      clearErrors("stdcode");
                    }}
                    onInput={NumbersValidation}
                    error={!!errors.stdcode}
                    helperText={errors.stdcode?.message}
                    FormHelperTextProps={{
                      style: { margin: 0 },
                    }}
                    InputLabelProps={{
                      shrink: !!watch("stdcode")?.trim(),
                    }}
                    disabled={isPaid === "1"}
                  // disabled={isFieldDisabled(masterFields, masterFieldLabel.COMMUNICATION_PINCODE) || disabled}
                  />
                </Tooltip>
              </Grid>
            )}
        </Grid>
        <Grid
          item
          md={12}
          pt={2}
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : null,
            marginBottom: 1,
          }}
        >
          <BackButton handleBack={handleBack} />
          {/* <EditButton /> */}
          <SubmitButton
            disabled={isPaid === "1"}
            name={buttonLoader ? "loader" : "Save and Proceed"}
            handleSubmit={handleSubmit(handleProcced)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
