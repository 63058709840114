const commonActions = {
    SHOW_SIDEBAR: 'SHOW_SIDEBAR',
    SET_ALERT: 'SET_ALERT',
    SET_BUTTON_LOADER: 'SET_BUTTON_LOADER',
    SET_DRAFT_BUTTON_LOADER : 'SET_DRAFT_BUTTON_LOADER',
    SET_PAGE_LOADER: 'SET_PAGE_LOADER',
    SET_SHOW_MODAL: 'SET_SHOW_MODAL',
    SET_CLICKABLE: 'SET_CLICKABLE',
    
};

export default commonActions;