// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { Provider } from "react-redux";
// import { store } from "./redux/store";
// import './assets/styles/fonts.css';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   // <React.StrictMode>
//   <Provider store={store}>
//     <App />
//   </Provider>
// // </React.StrictMode>
// );

// reportWebVitals();
// Import necessary libraries and components
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AppProvider } from './contexts/AppContext';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import keycloakService from './modules';

// Initialize Keycloak before rendering the application
const renderApp = () => {
    ReactDOM.createRoot(document.getElementById('root')).render(
        // <React.StrictMode>
            <AppProvider>
                <Router>
                    <Provider store={store}>
                        <App />
                    </Provider>
                </Router>
            </AppProvider>
        // </React.StrictMode>
    );
};

// Initialize Keycloak and render the app
keycloakService.initKeycloak(renderApp);

// Measure app performance
reportWebVitals();
