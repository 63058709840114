// import React, { useEffect, useState } from "react";
// import { Route, Routes } from "react-router-dom";
// import PublicRoute from "../protectedRoute/publicRoute";
// import RouteVerification from "../common/routeVerification";
// import Cookies from 'js-cookie';
// import CandidateRoutes from "./candidateRoutes";
// import NotFound from "../common/notfound";
// import setAuthToken from "../utils/setAuthToken";
// import { store } from '../redux/store';
// import authActions from "../redux/auth/actions";
// import VerifyEmailRedirection from "../common/verification/verifyEmailRedirection";
// import VerifiedEmailPage from "../common/verification/verifiedEmailPage";
// import { PiArrowCounterClockwiseDuotone } from "react-icons/pi";
// import { useDispatch, useSelector } from "react-redux";
// import candidatesActions from "../redux/candidates/actions";
// import ResetPasswordFaillure from "../common/verification/resetPasswordFaillure";


// if (Cookies.get('mhet_cnd_token')) {
//   setAuthToken(Cookies.get('mhet_cnd_token'));
//   store.dispatch({ type: authActions.CANDIDATE_TOKEN_VERIFY });
// };

// const AppRoutes = () => {
// const [count,setCount]  = useState(0);
// const dispatch = useDispatch();
// const { candidateTokenDetails } = useSelector(
//   (state) => state.authReducer
// );

//   useEffect(() => {
//     if (Cookies.get('mhet_cnd_project') && window.location.pathname === '/') {
//       if (Cookies.get('mhet_cnd_token')) {
//         window.location.href = `${`/${Cookies.get('mhet_cnd_project')}/home`}`;
//       } else {
//         window.location.href = `${`/${Cookies.get('mhet_cnd_project')}/`}`;
//       };
//     }else if((window.location.pathname === '/mhcet/home' || window.location.pathname === '/mhcet/login') && !Cookies.get('mhet_cnd_project')){
// console.log("inelse")
//  window.location.reload();
//             Cookies.set('mhet_cnd_project', "mhcet", { expires: 7 });
//     }
    
//   }, []);


 
// useEffect(()=>{
  
  
//   const int = setInterval(() => {
//     dispatch({ type: authActions.CHECK_KEYCLOAK_SID });
//   }, 9000);

//   // Clear the interval if candidateTokenDetails is null or undefined
//   if (!candidateTokenDetails?.candidateid) {
//     clearInterval(int);
//   }

//   // Cleanup interval on component unmount or dependency change
//   return () => clearInterval(int);

// },[candidateTokenDetails?.candidateid])

//     // candidateTokenDetails?.candidateid ? c
    
//     // startInterval(() => {
//     //   // console.log("testtt");
//     //   console.log("testtt");
//     //       dispatch({type:authActions.CHECK_KEYCLOAK_SID})
//     //     },9000)


// return (
//     <>
//       <Routes>
//         <Route path="/" element={<PublicRoute><RouteVerification /></PublicRoute>} />
//         <Route path="/mhcet" element={<PublicRoute><RouteVerification /></PublicRoute>} />
//         {/* <Route path="/mhcet" element={<PublicRoute><RouteVerification /></PublicRoute>} /> */}
//         <Route path="/candidate" element={<PublicRoute><RouteVerification /></PublicRoute>} />
//         <Route path="/candidate/email-verification" element={<PublicRoute><VerifyEmailRedirection /></PublicRoute>} />
//         <Route path="/candidate/email-verification-status" element={<PublicRoute><VerifiedEmailPage /></PublicRoute>} />
//         <Route path="/candidate/reset-password-failure" element={<PublicRoute><ResetPasswordFaillure/></PublicRoute>} />
//         <Route path="/*" element={<PublicRoute><NotFound /></PublicRoute>} />
//         <Route path={Cookies.get('mhet_cnd_project') ? `${Cookies.get('mhet_cnd_project')}/*` : '/404'} element={<CandidateRoutes />} />
//       </Routes>
//     </>
//   );
// };

// export default AppRoutes;

import keycloakService from "../modules"; 
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import PublicRoute from "../protectedRoute/publicRoute";
import RouteVerification from "../common/routeVerification";
import Cookies from 'js-cookie';
import CandidateRoutes from "./candidateRoutes";
import NotFound from "../common/notfound";
import setAuthToken from "../utils/setAuthToken";
import { store } from '../redux/store';
import authActions from "../redux/auth/actions";
import VerifyEmailRedirection from "../common/verification/verifyEmailRedirection";
import VerifiedEmailPage from "../common/verification/verifiedEmailPage";
import { PiArrowCounterClockwiseDuotone } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import candidatesActions from "../redux/candidates/actions";
import ResetPasswordFaillure from "../common/verification/resetPasswordFaillure";
import LandingPage from '../pages/landingPage';
import Header from "../layouts/header";


const AppRouter = () => {
  
  useEffect(()=>{
  //   const Component = keycloakService.getIsAuthenticated() ? LandingPage : VerifiedEmailPage;
  //   keycloakService.getIsAuthenticated() &&   setAuthToken(keycloakService?.getToken())  
  // //  const setProject = 
  //  keycloakService.getIsAuthenticated() ?   
  //  Cookies.set("mhet_cnd_token",keycloakService?.getToken())
  //   : Cookies.remove("mhet_cnd_token")
  
  // console.log("app routes",keycloakService.getIsAuthenticated(),keycloakService?.getToken())
    
      if(keycloakService.getIsAuthenticated()){
        store.dispatch({ type: authActions.CANDIDATE_TOKEN_VERIFY });
        Cookies.set('mhet_cnd_project','mhcet') 
        Cookies.set("mhet_cnd_token",keycloakService?.getToken())
        setAuthToken(keycloakService?.getToken()) 
        store.dispatch({type:authActions.CANDIDATE_TOKEN_VERIFY})
      }else{
        // Cookies.remove('mhet_cnd_project') 
        Cookies.remove("mhet_cnd_token")
      }

  // console.log(Cookies.get('mhet_cnd_project'),'app routes')
  },[]);
  
  return (
    <Routes>
      <Route path="/" element={<LandingPage/>} />
     
      <Route path={
        Cookies.get('mhet_cnd_project') ? `${Cookies.get('mhet_cnd_project')}/*` : 
      'mhcet/*'} element={<CandidateRoutes />} />
 {/* <Route path="*" element={<LandingPage/>} /> */}
    </Routes>
  );
};

export default AppRouter;