import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import Tabs from "./tabs";
import PersonalDetailsForm from "./personalDetailsForm";
import AddressForm from "./addressForm";
import ExamCenterForm from "./examCenterForm";
import PreviewForm from "./previewForm";
import PayApplicationFeeForm from "./payApplicationFeeForm";
import PrintApplicationForm from "./printApplicationForm";
import AcademicDetailsForm from "./academicDetailsForm";
import ReservationDetailsForm from "./reservationDetailsForm";
import { useDispatch, useSelector } from "react-redux";
import candidatesActions from "../../redux/candidates/actions";
import authActions from "../../redux/auth/actions";
import { RiFileUserLine } from "react-icons/ri";
import { IoIosHome } from "react-icons/io";
import { PiGraduationCap } from "react-icons/pi";
import { IoDocumentTextOutline } from "react-icons/io5";
import { cssProperties } from "../../utils/commonCssProperties";
import { DecryptObjectFunction } from "../../utils/cryptoFunction";
import { Paper, Grid } from "@mui/material";
export default function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const coursename = location.state?.coursename;
  const { courseTabs, courseFormDetails } = useSelector(
    (state) => state.candidatesReducer
  );
  const initialTab = courseTabs[0]?.tablabel?.toLowerCase().replaceAll(" ", "");
  const [activeTab, setActiveTab] = useState(0);
  const [index, setIndex] = useState(0);
  const [nextPageName, setNextPageName] = useState("");
  const [previousPageName, setPreviousPageName] = useState("");
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const [courseTab, setCourseTab] = useState(`${initialTab}`);
  const encryptedData = searchParams.get("c_data");
  const decrypted = DecryptObjectFunction(encryptedData);
  const courseid = location?.state?.courseid || decrypted?.courseid;
  const edit = decrypted?.edit;

  const getDynamicSpanStyle = () => ({
    borderRadius: "50%",
    width: "30px",
    height: "30px",
  });
  const iconstyle = {
    width: "23px",
    height: "23px",
    color: "#658F9D",
    marginTop: "2px",
  };

  const candidateid = useSelector(
    (state) => state.authReducer?.candidateTokenDetails?.candidateid
  );
  const { courseActiveTab } = useSelector((state) => state.candidatesReducer);
  console.log(courseActiveTab, "courseActiveTab");
  const { currentOtbsVenue } = useSelector((state) => state.candidatesReducer);

  console.log("ehehehg", currentOtbsVenue);
  useEffect(() => {
    if (candidateid && courseid) {
      console.log(candidateid , courseid,"courseId")
      dispatch({
        type: candidatesActions.GET_COURSE_FORM_DETAILS,
        payload: { courseid: courseid },
      });
    }
  }, [candidateid]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page]);

  const iconMapping = {
    personalinformation: (
      <span style={getDynamicSpanStyle(null)}>
        {" "}
        <RiFileUserLine style={iconstyle} />
      </span>
    ),
    address: (
      <span style={getDynamicSpanStyle(null)}>
        <IoIosHome style={iconstyle} />
      </span>
    ),
    qualificationdetails: (
      <span style={getDynamicSpanStyle(null)}>
        <PiGraduationCap style={iconstyle} />
      </span>
    ),
    domicileandcategorydetails: (
      <span style={getDynamicSpanStyle(null)}>
        <IoDocumentTextOutline style={iconstyle} />
      </span>
    ),
    preview: (
      <span style={getDynamicSpanStyle(null)}>
        <IoDocumentTextOutline style={iconstyle} />
      </span>
    ),
    applicationfee: (
      <span style={getDynamicSpanStyle(null)}>
        <IoDocumentTextOutline style={iconstyle} />
      </span>
    ),
    printapplication: (
      <span style={getDynamicSpanStyle(null)}>
        <IoDocumentTextOutline style={iconstyle} />
      </span>
    ),
    examinationdetails: (
      <span style={getDynamicSpanStyle(null)}>
        <PiGraduationCap style={iconstyle} />
      </span>
    ),
    academicdetails: (
      <span style={getDynamicSpanStyle(null)}>
        <PiGraduationCap style={iconstyle} />
      </span>
    ),
    reservationdetails: (
      <span style={getDynamicSpanStyle(null)}>
        <PiGraduationCap style={iconstyle} />
      </span>
    ),
  };

  useEffect(() => {
    if (candidateid && courseid) {
      console.log(candidateid , courseid,"courseId")

      dispatch({
        type: candidatesActions.GET_COURSE_TAB,
        payload: {
          data: {
            courseid: courseid,
          },
        },
      });
    }
  }, [candidateid]);
  useEffect(() => {
    if(courseid && candidateid);
    console.log(candidateid , courseid,"courseId")
    {
      dispatch({
        type: candidatesActions.GET_CURRENT_VENUE_DETAILS,
        payload: { courseid: courseid, candidateid: candidateid },
      });
    }
  }, [courseid, candidateid]);

  useEffect(() => {
    if (courseid) {
      console.log(candidateid , courseid,"courseId")
      dispatch({
        type: candidatesActions.GET_COURSE_ACTIVE_TAB,
        payload: {
          data: {
            courseid: courseid,
          },
        },
      });
    }
  }, [courseid]);

  useEffect(() => {
    const tabMapping = courseTabs.reduce((acc, tab, index) => {
      const pageName = tab.tablabel.toLowerCase().replace(/\s+/g, "");
      acc[pageName] = index;
      return acc;
    }, {});
    tabMapping["preview"] = courseTabs.length;
    tabMapping["applicationfee"] = courseTabs.length + 1;
    tabMapping["printapplication"] = courseTabs.length + 2;
    const currentActiveTab = tabMapping[page] ?? null;
    if (currentActiveTab !== null) {
      setActiveTab(currentActiveTab);
    }
    setCourseTab(page);
    const reverseMapping = Object.fromEntries(
      Object.entries(tabMapping).map(([key, value]) => [value, key])
    );
    const nextPageIndex = (currentActiveTab + 1) % (courseTabs.length + 3);
    const nextPageName = reverseMapping[nextPageIndex];
    setNextPageName(nextPageName);

    const previousPageIndex =
      (currentActiveTab - 1 + (courseTabs.length + 3)) %
      (courseTabs.length + 3);
    const previousPageName = reverseMapping[previousPageIndex];
    setPreviousPageName(previousPageName);
  }, [page, courseTabs, courseActiveTab]);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [days, setDays] = useState(0);

  const updateTimeDifference = () => {
    const currentTime = new Date();
    const validTimeObj = new Date(currentOtbsVenue[0]?.validtime);
    const timeDifference = validTimeObj - currentTime;
    console.log("timeDifference", timeDifference);
    if (timeDifference > 0) {
      const totalSeconds = Math.floor(timeDifference / 1000);
      const totalMinutes = Math.floor(totalSeconds / 60);
      const totalHours = Math.floor(totalMinutes / 60);
      const totalDays = Math.floor(totalHours / 24);

      setSeconds(String(totalSeconds % 60).padStart(2, "0"));
      setMinutes(String(totalMinutes % 60).padStart(2, "0"));
      setHours(String(totalHours % 24).padStart(2, "0"));
      setDays(totalDays);
    } else {
      setSeconds("00");
      setMinutes("00");
      setHours("00");
      setDays(0);
    }
  };

  useEffect(() => {
    updateTimeDifference();

    const intervalId = setInterval(() => {
      const currentTime = new Date();
      const validTimeObj = new Date(currentOtbsVenue[0]?.validtime);
      // validTimeObj.setMinutes(validTimeObj.getMinutes() - 1);

      const timeDifference = validTimeObj - currentTime;

      if (timeDifference > 0) {
        updateTimeDifference();
      } else {
        setSeconds(0);
        setMinutes(0);
        setHours(0);
        setDays(0);
        clearInterval(intervalId);
        currentOtbsVenue?.length > 0 &&
          dispatch({
            type: candidatesActions.GET_COURSE_REGISTRATION_DETAILS,
            payload: {
              courseid: currentOtbsVenue[0]?.courseid,
              candidateid: currentOtbsVenue[0]?.candidateid,
            },
          });
      }
    }, 1000);

    return () => {
      currentOtbsVenue?.length > 0 &&
        dispatch({
          type: candidatesActions.GET_COURSE_REGISTRATION_DETAILS,
          payload: {
            courseid: currentOtbsVenue[0]?.courseid,
            candidateid: currentOtbsVenue[0]?.candidateid,
          },
        });
      clearInterval(intervalId);
    };
  }, [currentOtbsVenue[0]?.validtime]);

  useEffect(() => {
    if (!edit) {
      if (
        courseActiveTab === undefined ||
        courseActiveTab === "" ||
        courseActiveTab === null
      ) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/course-registration?page=personalinformation`,
          { state: { courseid: courseid, coursename: coursename } }
        );
      } else if (parseInt(courseActiveTab?.ispaid) === 1) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/course-registration?page=printapplication`,
          { state: { courseid: courseid, coursename: coursename } }
        );
      }

      // else if (
      //   courseFormDetails[0]?.isotbs &&
      //   courseActiveTab?.exampreference_status === 1
      // ) {
      //   navigate(
      //     `/${Cookies.get(
      //       "mhet_cnd_project"
      //     )}/course-registration?page=examinationdetails`,
      //     { state: { courseid: courseid, coursename: coursename } }
      //   );
      // }
      else if (courseActiveTab?.exampreference_status === 1) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/course-registration?page=preview`,
          { state: { courseid: courseid, coursename: coursename } }
        );
      } else if (courseActiveTab?.academic_status === 1) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/course-registration?page=examinationdetails`,
          { state: { courseid: courseid, coursename: coursename } }
        );
      } else if (courseActiveTab?.reservation_status === 1) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/course-registration?page=qualificationdetails`,
          { state: { courseid: courseid, coursename: coursename } }
        );
      } else if (courseActiveTab?.address_status === 1) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/course-registration?page=domicileandcategorydetails`,
          { state: { courseid: courseid, coursename: coursename } }
        );
      } else if (courseActiveTab?.personalinformation_status === 1) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/course-registration?page=address`,
          { state: { courseid: courseid, coursename: coursename } }
        );
      }
    }
  }, [courseActiveTab, edit]);

  const courseRegistrationData = courseTabs.map((tab, index) => {
    const pageName = tab.tablabel.toLowerCase().replace(/\s+/g, "");
    const isActive = activeTab === index;
    return {
      title: tab.tablabel,
      icon: (
        <span style={getDynamicSpanStyle(isActive)}>
          {iconMapping[pageName] || <IoDocumentTextOutline style={iconstyle} />}
        </span>
      ),
      to: `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=${pageName}`,
    };
  });

  courseRegistrationData.push(
    {
      title: "Preview & Validate Information",
      icon: (
        <span style={getDynamicSpanStyle(null)}>
          <IoDocumentTextOutline style={iconstyle} />
        </span>
      ),
      to: `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=preview`,
    },
    {
      title: "Pay Application Fee",
      icon: (
        <span style={getDynamicSpanStyle(null)}>
          <IoDocumentTextOutline style={iconstyle} />
        </span>
      ),
      to: `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=applicationfee`,
    },
    {
      title: "Print Application Form",
      icon: (
        <span style={getDynamicSpanStyle(null)}>
          <IoDocumentTextOutline style={iconstyle} />
        </span>
      ),
      to: `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=printapplication`,
    }
  );

  if (courseTabs.length > 0) {
    return (
      <>
        <Tabs
          setIndex={setIndex}
          activeTab={activeTab}
          courseRegistrationData={courseRegistrationData}
        />

        {courseActiveTab?.exampreference_status === 1 &&
          currentOtbsVenue?.length > 0 &&
          days == 0 &&
          hours == 0 &&
          minutes <= 30 &&
          courseTab !== "printapplication" && (
            <Grid item xs={12} md={4}>
              <Paper
                sx={{
                  backgroundColor: "rgb(15, 79, 150)",
                  padding: "5px",
                  borderRadius: "8px",
                  boxShadow: 2,
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  color: "white",
                  marginBottom: "5px",
                }}
              >
                {minutes <= 0 && seconds <= 0 ? (
                  <p>
                    {" "}
                    Allocated center has been cancelled, Please reselect the
                    center{" "}
                  </p>
                ) : (
                  <p>
                    Selected center is temporarily booked for {minutes}:
                    {seconds} mins
                  </p>
                )}
              </Paper>
            </Grid>
          )}
        {courseTabs?.find((obj) => obj.tablabel === "Personal Information") && (
          <>
            {courseTab === "personalinformation" && (
              <PersonalDetailsForm
                nextPageName={nextPageName}
                previousPageName={previousPageName}
              />
            )}
          </>
        )}

        {courseTabs?.find((obj) => obj.tablabel === "Address") && (
          <>
            {courseTab === "address" && (
              <AddressForm
                nextPageName={nextPageName}
                previousPageName={previousPageName}
              />
            )}
          </>
        )}

        {courseTabs?.find(
          (obj) => obj.tablabel === "Qualification Details"
        ) && (
          <>
            {courseTab === "qualificationdetails" && (
              <AcademicDetailsForm
                nextPageName={nextPageName}
                previousPageName={previousPageName}
              />
            )}
          </>
        )}

        {courseTabs?.find(
          (obj) => obj.tablabel === "Domicile and Category Details"
        ) && (
          <>
            {courseTab === "domicileandcategorydetails" && (
              <ReservationDetailsForm
                nextPageName={nextPageName}
                previousPageName={previousPageName}
              />
            )}
          </>
        )}

        {courseTabs?.find((obj) => obj.tablabel === "Examination Details") && (
          <>
            {courseTab === "examinationdetails" && (
              <ExamCenterForm
                nextPageName={nextPageName}
                previousPageName={previousPageName}
              />
            )}
          </>
        )}

        {courseTab === "preview" && <PreviewForm />}
        {courseTab === "applicationfee" && <PayApplicationFeeForm />}
        {courseTab === "printapplication" && <PrintApplicationForm />}
      </>
    );
  }
}

// import React, { useState, useEffect } from "react";
// import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
// import Cookies from "js-cookie";
// import Tabs from "./tabs";
// import PersonalDetailsForm from "./personalDetailsForm";
// import AddressForm from "./addressForm";
// import ExamCenterForm from "./examCenterForm";
// import PreviewForm from "./previewForm";
// import PayApplicationFeeForm from "./payApplicationFeeForm";
// import PrintApplicationForm from "./printApplicationForm";
// import AcademicDetailsForm from "./academicDetailsForm";
// import ReservationDetailsForm from "./reservationDetailsForm";
// import { useDispatch, useSelector } from "react-redux";
// import candidatesActions from "../../redux/candidates/actions";
// import { RiFileUserLine } from "react-icons/ri";
// import { IoIosHome } from "react-icons/io";
// import { PiGraduationCap } from "react-icons/pi";
// import { IoDocumentTextOutline } from "react-icons/io5";
// import { cssProperties } from "../../utils/commonCssProperties";

// export default function Index() {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const location = useLocation();
//   const courseid = location.state?.courseid;
//   const { courseTabs } = useSelector(
//     (state) => state.candidatesReducer
//   );
//   const { courseActiveTab } = useSelector(
//     (state) => state.candidatesReducer
//   );
//   // const  courseTabs=["personalinformation","address","qualificationdetails","domicileandcategorydetails",
//   //   "examinationdetails"
//   // ]
//  console.log(courseTabs,"courseTabs");
//   console.log(courseActiveTab,'courseActiveTab');
//   const candidateid = useSelector(
//     (state) => state.authReducer?.candidateTokenDetails?.candidateid
//   );
//   const initialTab = courseTabs[0]?.tablabel?.toLowerCase().replaceAll(" ", "");

// const [courseTab, setCourseTab] = useState(`${initialTab}`);

//   const [activeTab, setActiveTab] = useState(0);
//   const [nextPageName, setNextPageName] = useState("");
//   const [previousPageName, setPreviousPageName] = useState("");
//   const [searchParams] = useSearchParams();
//   const page = searchParams.get("page");

//   useEffect(() => {
//     if (candidateid) {
//       dispatch({
//         type: candidatesActions.GET_COURSE_TAB,
//         payload: { data: { courseid } },
//       });
//       dispatch({
//         type: candidatesActions.GET_COURSE_ACTIVE_TAB,
//         payload: { data: { courseid } },
//       });
//     }
//   }, [candidateid, courseid, dispatch]);

//   useEffect(() => {
//     if (courseTabs.length === 0) return;

//     const tabMapping = courseTabs.reduce((acc, tab, index) => {
//       const pageName = tab.tablabel.toLowerCase().replace(/\s+/g, "");
//       acc[pageName] = index;
//       return acc;
//     }, {});

//     const additionalTabs = {
//       preview: courseTabs.length,
//       applicationfee: courseTabs.length + 1,
//       printapplication: courseTabs.length + 2,
//     };

//     const currentActiveTab = additionalTabs[page] ?? tabMapping[page] ?? null;
//     if (currentActiveTab !== null) {
//       setActiveTab(currentActiveTab);
//     }
//     setCourseTab(page);
//   }, [page, courseTabs]);

//     useEffect(() => {
//     const tabMapping = courseTabs.reduce((acc, tab, index) => {
//       const pageName = tab.tablabel.toLowerCase().replace(/\s+/g, "");
//       acc[pageName] = index;
//       return acc;
//     }, {});
//     tabMapping["preview"] = courseTabs.length;
//     tabMapping["applicationfee"] = courseTabs.length + 1;
//     tabMapping["printapplication"] = courseTabs.length + 2;
//     const currentActiveTab = tabMapping[page] ?? null;
//     if (currentActiveTab !== null) {
//       setActiveTab(currentActiveTab);
//     }
//     setCourseTab(page);
//     const reverseMapping = Object.fromEntries(
//       Object.entries(tabMapping).map(([key, value]) => [value, key])
//     );
//     const nextPageIndex = (currentActiveTab + 1) % (courseTabs.length + 3);
//     const nextPageName = reverseMapping[nextPageIndex];
//     setNextPageName(nextPageName);

//     const previousPageIndex =
//       (currentActiveTab - 1 + (courseTabs.length + 3)) %
//       (courseTabs.length + 3);
//     const previousPageName = reverseMapping[previousPageIndex];
//     setPreviousPageName(previousPageName);

//     console.log("previous page name:", previousPageName);
//   }, [page, courseTabs,courseActiveTab]);
//   useEffect(() => {
//     if (!courseActiveTab) return;

//     const projectPath = Cookies.get("mhet_cnd_project");
//     const navigateTo = (pageName) => {
//       navigate(`/${projectPath}/course-registration?page=${pageName}`, {
//         state: { courseid },
//       });
//     };

//     if (parseInt(courseActiveTab?.ispaid) === 1) {
//       navigateTo("printapplication");
//     } else if (courseActiveTab?.exampreference_status === 1) {
//       navigateTo("preview");
//     } else if (courseActiveTab?.academic_status === 1) {
//       navigateTo("examinationdetails");
//     } else if (courseActiveTab?.reservation_status === 1) {
//       navigateTo("academicdetails");
//     } else if (courseActiveTab?.address_status === 1) {
//       navigateTo("domicileandcategorydetails");
//     } else if (courseActiveTab?.personalinformation_status === 1) {
//       navigateTo("address");
//     }
//   }, [courseActiveTab, navigate, courseid]);

//   const iconMapping = {
//     personalinformation:<span style={getDynamicSpanStyle(null)}> <RiFileUserLine style={iconstyle} /></span>,
//     address: <span style={getDynamicSpanStyle(null)}><IoIosHome style={iconstyle} /></span>,
//     qualificationdetails: <span style={getDynamicSpanStyle(null)}><PiGraduationCap style={iconstyle} /></span>,
//     domicileandcategorydetails: <span style={getDynamicSpanStyle(null)}><IoDocumentTextOutline style={iconstyle} /></span>,
//     preview: <span style={getDynamicSpanStyle(null)}><IoDocumentTextOutline style={iconstyle} /></span>,
//     applicationfee: <span style={getDynamicSpanStyle(null)}><IoDocumentTextOutline style={iconstyle} /></span>,
//     printapplication: <span style={getDynamicSpanStyle(null)}><IoDocumentTextOutline style={iconstyle} /></span>,
//     examinationdetails: <span style={getDynamicSpanStyle(null)}><PiGraduationCap style={iconstyle} /></span>,
//     academicdetails: <span style={getDynamicSpanStyle(null)}><PiGraduationCap style={iconstyle} /></span>,
//     reservationdetails: <span style={getDynamicSpanStyle(null)}><PiGraduationCap style={iconstyle} /></span>,
//   };

//   const courseRegistrationData = courseTabs.map((tab) => {
//     const pageName = tab.tablabel.toLowerCase().replace(/\s+/g, "");
//     return {
//       title: tab.tablabel,
//       icon: iconMapping[pageName] || <IoDocumentTextOutline style={iconstyle} />,
//       to: `/${Cookies.get("mhet_cnd_project")}/course-registration?page=${pageName}`,
//     };
//   });

//   courseRegistrationData.push (
//     {
//       title: "Preview & Validate Information",
//       icon: <span style={getDynamicSpanStyle(null)}><IoDocumentTextOutline style={iconstyle} /></span>,
//       to: `/${Cookies.get("mhet_cnd_project")}/course-registration?page=preview`,
//     },
//     {
//       title: "Pay Application Fee",
//       icon: <span style={getDynamicSpanStyle(null)}><IoDocumentTextOutline style={iconstyle} /></span>,
//       to: `/${Cookies.get("mhet_cnd_project")}/course-registration?page=applicationfee`,
//     },
//     {
//       title: "Print Application Form",
//       icon: <span style={getDynamicSpanStyle(null)}><IoDocumentTextOutline style={iconstyle} /></span>,
//       to: `/${Cookies.get("mhet_cnd_project")}/course-registration?page=printapplication`,
//     },
//   )

//   return (
//     <>
//       <Tabs
//         activeTab={activeTab}
//         courseRegistrationData={courseRegistrationData}
//       />
// {courseTabs?.find((obj) => obj.tablabel === "Personal Information") && (
//   <>
//     {courseTab === "personalinformation" && (
//       <PersonalDetailsForm
//         nextPageName={nextPageName}
//         previousPageName={previousPageName}
//       />
//     )}
//   </>
// )}

// {courseTabs?.find((obj) => obj.tablabel === "Address") && (
//   <>
//     {courseTab === "address" && (
//       <AddressForm
//         nextPageName={nextPageName}
//         previousPageName={previousPageName}
//       />
//     )}
//   </>
// )}

// {courseTabs?.find((obj) => obj.tablabel === "Qualification Details") && (
//   <>
//     {courseTab === "qualificationdetails" && (
//       <AcademicDetailsForm
//         nextPageName={nextPageName}
//         previousPageName={previousPageName}
//       />
//     )}
//   </>
// )}

// {courseTabs?.find((obj) => obj.tablabel === "Domicile and Category Details") && (
//   <>
//     {courseTab === "domicileandcategorydetails" && (
//       <ReservationDetailsForm
//         nextPageName={nextPageName}
//         previousPageName={previousPageName}
//       />
//     )}
//   </>
// )}

// {courseTabs?.find((obj) => obj.tablabel === "Examination Details") && (
//   <>
//     {courseTab === "examinationdetails" && (
//       <ExamCenterForm
//         nextPageName={nextPageName}
//         previousPageName={previousPageName}
//       />
//     )}
//   </>
// )}

// {courseTab === "preview" && <PreviewForm />}
// {courseTab === "applicationfee" && <PayApplicationFeeForm />}
// {courseTab === "printapplication" && <PrintApplicationForm />}
//    </>
//   );
// }
