import authActions from "./actions";
import commonActions from "../common/actions";
import { takeEvery, call, all, put, delay, select } from "redux-saga/effects";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import Cookies from "js-cookie";
import candidatesActions from "../../redux/candidates/actions";

import {
  DecryptFunction,
  EncryptFunction,
  EncryptObjectFunction,
} from "../../utils/cryptoFunction";
import {
  handleDecodeJSON,
  handleEncodeJson,
} from "../../utils/encodeDecodeJson";
import keycloakService from "../../modules";

const authSagas = function* () {
  yield all([
    yield takeEvery(authActions.CANDIDATE_SINUP, candidateSignup),
    // yield takeEvery(authActions.CHANGE_PASSWORD, candidateChangePassword),
    // yield takeEvery(authActions.CANDIDATE_LOGIN, candidateLogin),
    // yield takeEvery(
    //   authActions.CANDIDATE_FORGETPASSWORD,
    //   candidateForgetPassword
    // ),
    yield takeEvery(authActions.CANDIDATE_TOKEN_VERIFY, candidateTokenVerify),
    // yield takeEvery(
    //   authActions.VERIFY_EMAILID_FOR_SIGNUP,
    //   verifyEmailidForSignup
    // ),
    yield takeEvery(authActions.CANDIDATE_LOGOUT, candidateLogout),
    // yield takeEvery(
    //   authActions.CHECK_EMAIL_VERIFICAITON,
    //   checkEmailVerification
    // ),
    // yield takeEvery(
    //   authActions.RESEND_EMAIL_FOR_VERIFICATION,
    //   resendEmailForVerification
    // ),
    yield takeEvery(
      authActions.VERIFY_MOBILENO_FOR_SIGNUP,
      verifyMobilenoForSignup
    ),
    yield takeEvery(
      authActions.GET_PROFILE_REGISTRATION_ACTIVE_TAB,
      getProfileRegistrationActiveTab
    ),
    yield takeEvery(
      authActions.CHECK_CANDIDATE_MOBILENO,
      checkCandidateMobileno
    ),
    yield takeEvery(
      authActions.SEND_OTP_FOR_MOBILE_VERIFICATION,
      sendOtpForMobileVerification
    ),
    yield takeEvery(
      authActions.FORGOTPASSWORD_MOBILEVERIFICATION_SENDMAILID,
      forgotPasswordMobileVerification
    ),
    yield takeEvery(
      authActions.CHECK_DUPLICATE_EMAIL_OR_MOBILENO,
      checkDuplicateEmailOrMobileno
    ),
    // yield takeEvery(
    //   authActions.CANDIDATE_RESET_PASSWORD,
    //   candidateResetPassword
    // ),
    // yield takeEvery(
    //   authActions.CANDIDATE_FORGET_EMAILID,
    //   forgetEmailId
    // ),
    // yield takeEvery(
    //   authActions.FORGOTMAIL_OTP_VERIFY,
    //   forgetEmailIdOTPverify
    // ),
    // yield takeEvery(
    //   authActions.CHECK_KEYCLOAK_SID, checkKeycloakSessionId),
    yield takeEvery(
      authActions.GET_TEMP_CANDIDATE_DETAILS,
      getTempCanidateDetails
    ),
  ]);
};

// const candidateSignup = function* (data) {

//   const { payload } = data;
//   const { setResendCountdown } = payload;
//   const verifiedEmail = true;
//   const currentEmail = payload?.data?.emailid;
//   const isEmailChanged =false;
//   payload.data.isEmailChanged = isEmailChanged;

//   yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
//   // try {
//   //   const encryptedData = yield call(handleEncodeJson, { encodeJSON:payload?.data});
//   //   const result = yield call(() =>
//   //     axios.post(`${API_URL}/candidate/signup`, encryptedData)
//   //   );
//   //   // yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
//   //   // yield put({
//   //   //   type: commonActions.SET_ALERT,
//   //   //   payload: {
//   //   //     status: result?.data?.statusCode === 200 ? "success" : "failed",
//   //   //     show: (Cookies.get("mhet_cnd_email_verified") === "true") ? false : true,
//   //   //     message: result?.data?.message,
//   //   //   },
//   //   // });
//   //   yield
//   //   console.log('result----',result);
//   //   if (result?.data?.statusCode === 200) {
//   //         console.log("successss--");

//   //     // navigate(`/${Cookies.get('mhet_cnd_project')}/login`);
//   //     Cookies.set("mhet_cnd_email", result?.data?.data?.emailid);
//   //     if (Cookies.get("mhet_cnd_email_verified") === "true") {
//   //       setResendCountdown(120)
//   //       yield put({
//   //         type: commonActions.SET_SHOW_MODAL,
//   //         payload: {
//   //           show: true,
//   //           type: "MV",
//   //           method: "emailAndMobileVerification",
//   //         },
//   //       });
//   //       yield put({
//   //         type: authActions.SEND_OTP_FOR_MOBILE_VERIFICATION,
//   //         payload: { data: { mobileno: payload?.data?.mobileno } },
//   //       });
//   //       // console.log(OTPResult,"result");
//   //       Cookies.set(
//   //         "mhet_cnd_tempdata",
//   //         EncryptObjectFunction(result?.data?.data),
//   //       );
//   //       Cookies.set("mhet_cnd_mobileno", payload?.data?.mobileno);
//   //     }
//   //     else {
//   //       setResendCountdown(120)
//   //       yield put({
//   //         type: commonActions.SET_SHOW_MODAL,
//   //         payload: {
//   //           show: true,
//   //           type: "EV",
//   //           method: "emailAndMobileVerification",
//   //         },
//   //       });
//   //       Cookies.set("mhet_cnd_temptoken", result?.data?.token, { expires: 7 });
//   //       Cookies.set(
//   //         "mhet_cnd_tempdata",
//   //         EncryptObjectFunction(result?.data?.data),
//   //         { expires: 7 }
//   //       );
//   //       // Cookies.set("mhet_cnd_email_verified", false);
//   //       Cookies.set("mhet_cnd_mobileno_verified", false);
//   //       Cookies.set("mhet_cnd_mobileno", payload?.data?.mobileno);
//   //     }
//   //     //   navigate(`/${Cookies.get("mhet_cnd_project")}/verify-email`);
//   //     // setAuthToken(result?.data?.token);
//   //     // yield put({ type: authActions.CANDIDATE_TOKEN_VERIFY });
//   //   }
//   //   else if (result?.data?.statusCode === 600) {
//   //     Cookies.set("mhet_cnd_email", result?.data?.data?.emailid);
//   //     setResendCountdown(6)
//   //     Cookies.set("mhet_cnd_mobileno_verified", false);
//   //     Cookies.set("mhet_cnd_mobileno", payload?.data?.mobileno);
//   //   }
//   //   else {
//   //   }
//   //   yield delay(2000);
//   //   yield put({
//   //     type: commonActions.SET_ALERT,
//   //     payload: { status: null, show: false, message: null },
//   //   });
//   // } catch (error) {
//   //   console.log(error);
//   //   // Cookies.remove('mhet_cnd_token');
//   //   window.location.href = `/${Cookies.get("mhet_cnd_project")}/login`;
//   // }
//   try {
//     const encryptedData = yield call(handleEncodeJson, { encodeJSON: payload?.data });

//     // Send the POST request
//     const result = yield call(() =>
//       axios.post(`${API_URL}/candidate/signup`, encryptedData)
//     );

//     // Log the result for debugging
//     console.log('result----', result);

//     // Check the result status and handle accordingly
//     if (result?.data?.statusCode === 200) {
//       console.log("successss--");
//       Cookies.set("mhet_cnd_email", result?.data?.data?.emailid);
//       if (Cookies.get("mhet_cnd_email_verified") === "true") {
//         // Success handling
//       } else {
//         // Alternative handling
//       }
//     } else if (result?.data?.statusCode === 600) {
//       console.log("statusCode 600 case:", result);
//     } else {
//       console.log("Unexpected response:", result);
//     }
//   } catch (error) {
//     console.error("Error during API call:", error);
//   }

//   yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
// };

// const candidateLogin = function* (data) {
//   const { payload } = data;
//   const { navigate } = payload;

//   yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
//   try {
//     const encryptedData = yield call(handleEncodeJson, {encodeJSON:payload?.data});
//     const result = yield call(() =>
//       axios.post(`${API_URL}/candidate/login`, encryptedData)
//     );
//     // debugger
//     yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
//     yield put({
//       type: commonActions.SET_ALERT,
//       payload: {
//         status: result?.data?.statusCode === 200 ? "success" : "failed",
//         show: true,
//         message: result?.data?.message,
//       },
//     });
//     if (result?.data?.statusCode === 200) {
//       navigate(`/${Cookies.get("mhet_cnd_project")}/home`);
//       const [decodedEmailid, decodedSessionid] = yield all([
//         call(handleDecodeJSON, { decodeJSON: result?.data?.emailid }),
//         call(handleDecodeJSON, { decodeJSON: result?.data?.sid }),
//       ]);
//       Cookies.set("mhet_cnd_token", result?.data?.token, { expires: 7 });
//       Cookies.set("mhet_cnd_sid", decodedSessionid);
//       setAuthToken(result?.data?.token);
//       yield put({ type: authActions.CANDIDATE_TOKEN_VERIFY });
//     }
//     yield delay(2000);
//     yield put({
//       type: commonActions.SET_ALERT,
//       payload: { status: null, show: false, message: null },
//     });
//   } catch (error) {
//     console.log(error);
//     Cookies.remove("mhet_cnd_token");
//     window.location.href = `/${Cookies.get("mhet_cnd_project")}/login`;
//   }
// };
// const candidateSignup = function* (data) {
//   const { payload } = data;
//   const { setMobileVerified, dispatch, setOpenDialog,navigate } = payload;
//   try {
//     console.log("Before encryption");
//     const encryptedData = yield call(handleEncodeJson, {
//       encodeJSON: payload?.data,
//     });
//     console.log("Encrypted data:", encryptedData);

//     // Use async/await for the API call
//     const result = yield call(async () => {
//       console.log("Before API call");
//       const response = await axios.post(
//         `${API_URL}/candidate/signup`,
//         encryptedData
//       );
//       console.log("API call completed", response);
//       return response; // Return the response after API call
//     });

//     console.log("After API call:", result);

//     if (result?.data?.statusCode === 200) {
//       Cookies.set("mhet_cnd_email", result?.data?.data?.emailid);
//       Cookies.set("mhet_cnd_mobileno",result?.data?.data?.mobileno)
//       // navigate(`/${Cookies.get("mhet_cnd_project")}/registration?page=personaldetails`)
//       console.log("Signup successful:", result.data);

//       yield put({ type: "SIGNUP_SUCCESS", payload: result.data });
//     } else {
//       setOpenDialog(true);
      
//       console.log("Signup failed:", result.data);
//       yield put({ type: "SIGNUP_FAILED", payload: result.data });
//       dispatch({
//         type: authActions.SEND_OTP_FOR_MOBILE_VERIFICATION,
//         payload: {
//           data: {
//             mobileno: Cookies.get("mhet_cnd_mobileno"),
//           },
//         },
//       });
//     }
//   } catch (error) {
//     console.error("Error in candidateSignup:", error);

//     if (error.response) {
//       console.error("Error response data:", error.response.data);
//     } else if (error.request) {
//       console.error("No response received:", error.request);
//     } else {
//       console.error("Error message:", error.message);
//     }

//     yield put({ type: "SIGNUP_ERROR", payload: error.message });
//   }
// };





const candidateSignup = function* (data) {
  const { payload } = data;
  console.log("payload33",payload)
  const { setMobileVerified, dispatch, setOpenDialog, navigate,setResendCountdown } = payload;
  yield put({ type: commonActions.SET_CLICKABLE, payload: false });

  yield delay(4000);
  
  yield put({ type: commonActions.SET_CLICKABLE, payload: true });
  const uploadDetails = yield select(
    (state) => state.candidatesReducer.uploadDetails
  );
  

  const allProfiledetailsDraft = uploadDetails?.[0]?.statusmain ? true : false;
console.log('allProfiledetailsDraft---',allProfiledetailsDraft);

  try {
    console.log("Before encryption");
    const encryptedData = yield call(handleEncodeJson, {
      encodeJSON: payload?.data,
    });
    console.log("Encrypted data:", encryptedData);

    // Use async/await for the API call
    const result = yield call(async () => {
      console.log("Before API call");
      const response = await axios.post(
        `${API_URL}/candidate/signup`,
        encryptedData
      );
      console.log("API call completed", response);
      return response; // Return the response after API call
    });

    console.log("After API call:", result);

    if (result?.data?.statusCode === 200) {
      Cookies.set("mhet_cnd_email", result?.data?.data?.emailid);
      Cookies.set("mhet_cnd_mobileno", result?.data?.data?.mobileno);
      if(!allProfiledetailsDraft){
        navigate(`/${Cookies.get("mhet_cnd_project")}/registration?page=personaldetails`)
      }
      else{
        navigate(`/${Cookies.get("mhet_cnd_project")}/home`);
      }
      // Introduce a 2-second delay before navigating or getting upload details
      // yield delay(2000);
      
      console.log("Signup successful:", result.data);

      yield put({ type:  "SIGNUP_SUCCESS", payload: result.data });
    } else {
      setOpenDialog(true);
      console.log("Signup failed:", result.data);

      console.log("hhh");
      
      
      yield put({ type: "SIGNUP_FAILED", payload: result.data });
      dispatch({
        type: authActions.SEND_OTP_FOR_MOBILE_VERIFICATION,
        payload: {
          data: {
            mobileno: Cookies.get("mhet_cnd_mobileno"),
            candidatename:payload?.data?.candidatename
          },
        },
      });
      setResendCountdown(120)
    }
    yield put({ type: commonActions.SET_CLICKABLE, payload: true });

  } catch (error) {
    console.error("Error in candidateSignup:", error);

    if (error.response) {
      console.error("Error response data:", error.response.data);
    } else if (error.request) {
      console.error("No response received:", error.request);
    } else {
      console.error("Error message:", error.message);
    }

    yield put({ type: "SIGNUP_ERROR", payload: error.message });
  }
};

// const candidateLogout = function* (data) {
//   // const { payload } = data;
//   // const { navigate } = payload;
//   Cookies.remove("mhet_cnd_email");
//   Cookies.remove("mhet_cnd_email_verified");
//   Cookies.remove("mhet_cnd_mobileno");
//   Cookies.remove("mhet_cnd_mobileno_otp");
//   Cookies.remove("mhet_cnd_mobileno_verified");
//   Cookies.remove("mhet_cnd_project");
//   Cookies.remove("mhet_cnd_token");
  
//   keycloakService.doLogout()
  
// };
const candidateLogout = function* (data) {
  // const { payload } = data;
  // const { navigate } = payload;

  // yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });

  try {
    let token = Cookies.get("mhet_cnd_token");
    if (token) {
      const result = yield call(() =>
        axios.post(`${API_URL}/candidate/logout`, { token: token })
      );

        Cookies.remove("mhet_cnd_email");
  Cookies.remove("mhet_cnd_email_verified");
  Cookies.remove("mhet_cnd_mobileno");
  Cookies.remove("mhet_cnd_mobileno_otp");
  Cookies.remove("mhet_cnd_mobileno_verified");
  Cookies.remove("mhet_cnd_project");
  Cookies.remove("mhet_cnd_token");
  
      keycloakService.doLogout()
      // yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      if (result?.data?.statusCode === 200) {
        // Cookies.remove("mhet_cnd_token");
        // Cookies.remove("mhet_cnd_sid");
        // window.location.href = `/${Cookies.get("mhet_cnd_project")}/`;
        // window.location.reload();



      }
      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: { status: null, show: false, message: null },
      });
    }
  } catch (error) {
    // stopAllIntervals()

    console.log(error);
    Cookies.remove("mhet_cnd_token");
    window.location.href = `/${Cookies.get("mhet_cnd_project")}/login`;
  }
};

// const candidateForgetPassword = function* (data) {
//   const { payload } = data;
//   const { navigate, setReset, setVerified, setEmailid } = payload;
//   yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
//   try {
//     const encodedData = yield call(handleEncodeJson, {encodeJSON:payload?.data})
//     const result = yield call(() =>
//       axios.post(`${API_URL}/candidate/forgot-password`, encodedData)
//     );
//     // debugger
//     yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
//     yield put({
//       type: commonActions.SET_ALERT,
//       payload: {
//         status: result?.data?.statusCode === 200 ? "success" : "failed",
//         show: true,
//         message: result?.data?.message,
//       },
//     });
//     if (result?.data?.statusCode === 200) {
//       // navigate(`/${Cookies.get('mhet_cnd_project')}/forgot-password-status`);
//       // Cookies.set('mhet_cnd_token', result?.data?.token, { expires: 7 });
//       // setAuthToken(result?.data?.token);
//       setReset(false);
//       setVerified(true);
//       setEmailid(payload?.data?.email);
//       // yield put({ type: authActions.CANDIDATE_TOKEN_VERIFY });
//     }
//     yield delay(2000);
//     yield put({
//       type: commonActions.SET_ALERT,
//       payload: { status: null, show: false, message: null },
//     });
//   } catch (error) {
//     console.log(error);
//     Cookies.remove("mhet_cnd_token");
//     // window.location.href = `/${Cookies.get('mhet_cnd_project')}/login`;
//   }
// };

// const candidateResetPassword = function* (data) {
//   const { payload } = data;
//   const { navigate } = payload;
//   yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
//   try {
//     const encryptedData = yield call(handleEncodeJson,{encodeJSON:payload?.data})
//     const result = yield call(() =>
//       axios.post(`${API_URL}/candidate/reset-password`, encryptedData)
//     );
//     yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
//     yield put({
//       type: commonActions.SET_ALERT,
//       payload: {
//         status: result?.data?.statusCode === 200 ? "success" : "failed",
//         show: true,
//         message: result?.data?.message,
//       },
//     });
//     if (result?.data?.statusCode === 200) {
//       navigate(
//         `/${Cookies.get("mhet_cnd_project")}/reset-password?page=success`
//       );
//     }
//     else {
//       navigate(`/candidate/reset-password-failure`);
//     }
//     yield delay(2000);
//     yield put({
//       type: commonActions.SET_ALERT,
//       payload: { status: null, show: false, message: null },
//     });
//   } catch (error) {
//     console.log(error);
//   }
// };

// const candidateChangePassword = function* (data) {
//   const { payload } = data;
//   const { navigate } = payload;
//   yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
//   try {
//     const result = yield call(() =>
//       axios.put(`${API_URL}/candidate/change-password`, payload?.data)
//     );
//     yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
//     yield put({
//       type: commonActions.CHANGE_PASSWORD,
//       payload: {
//         status: result?.data?.statusCode === 200 ? "success" : "failed",
//         show: true,
//         message: result?.data?.message,
//       },
//     });
//     if (result?.data?.statusCode === 200) {
//     }
//     yield delay(2000);
//     yield put({
//       type: commonActions.SET_ALERT,
//       payload: { status: null, show: false, message: null },
//     });
//   } catch (error) {
//     console.log(error);
//   }
// };

// const candidateChangePassword = function* (data) {
//   const { payload } = data;
//   const { setSuccess } = payload

//   yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
//   try {
//     const encryptedData = yield call(handleEncodeJson, {encodeJSON:payload?.data});
//     const result = yield call(() =>
//       axios.put(`${API_URL}/candidate/change-password`, encryptedData)
//     );

//     yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });

//     yield put({
//       type: commonActions.SET_ALERT,
//       payload: {
//         status: (result?.data?.statusCode === 200) ? 'success' : 'failed',
//         show: true,
//         message: result?.data?.message
//       }
//     });
//     if (result?.data?.statusCode === 200) {
//       setSuccess(false)

//     };
//     yield delay(2000);
//     yield put({
//       type: commonActions.SET_ALERT,
//       payload: { status: null, show: false, message: null }
//     });

//   } catch (error) {
//     console.log(error);
//   };
// };
const candidateTokenVerify = function* () {
  try {
    const { candidateTokenDetails } = yield select(
      (state) => state.authReducer
    );
    const Token=Cookies.get("mhet_cnd_token")
    console.log("token",Token)
    if (!candidateTokenDetails) {
      const result = yield call(() =>
        axios.get(`${API_URL}/candidate/verify-token`, {
          headers: {
            authorization: `Bearer ${Token}`,
          },
        })
      );
      if (result?.data?.statusCode === 200) {
        yield put({
          type: authActions.SET_CANDIDATE_TOKEN_DETAILS,
          payload: result?.data?.result,
        });
      } else {
        // window.location.href = `/${Cookies.get("mhet_cnd_project")}/login`;
      }
    }
    // else{
    //     Cookies.remove('mhet_cnd_token');
    //     window.location.href = `/${Cookies.get('mhet_cnd_project')}/`;
    // }
  } catch (err) {
    console.log(err);
    Cookies.remove("mhet_cnd_token");
    // window.location.href = `/${Cookies.get("mhet_cnd_project")}/login`;
  }
};

// const verifyEmailidForSignup = function* (data) {
//   const { payload } = data;
//   const { navigate } = payload;
//   try {
//     const encryptedData = yield call( handleEncodeJson, {encodeJSON:payload?.data});
//     const result = yield call(() =>
//       axios.post(`${API_URL}/candidate/verify-emailid`, encryptedData)
//     );
//     if (result?.data?.statusCode === 200) {
//       navigate(`/candidate/email-verification-status?status=success`);
//     }
//     else {
//       navigate(`/candidate/email-verification-status?status=failed`);
//     }
//     yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
//   } catch (err) {
//     console.log(err);
//   }
//   yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
// };

// const checkEmailVerification = function* (data) {
//   const { payload } = data;
//   try {
//     const encryptedData = yield call( handleEncodeJson, {encodeJSON:payload?.data});
//     const result = yield call(() =>
//       axios.post(`${API_URL}/candidate/check-emailid-verified`, encryptedData)
//     );
//     yield put({
//       type: commonActions.SET_ALERT,
//       payload: {
//         status: result?.data?.statusCode === 200 ? "success" : "failed",
//         show: true,
//         message: result?.data?.message,
//       },
//     });
//     if (result?.data?.statusCode === 200) {
//       yield put({
//         type: commonActions.SET_SHOW_MODAL,
//         payload: {
//           show: false,
//           type: "EV",
//           method: "emailAndMobileVerification",
//         },
//       });
//       yield put({
//         type: commonActions.SET_SHOW_MODAL,
//         payload: {
//           show: true,
//           type: "EVS",
//           method: "emailAndMobileVerification",
//         },
//       });
//       Cookies.set("mhet_cnd_email_verified", true);
//     }
//     yield delay(2000);
//     yield put({
//       type: commonActions.SET_ALERT,
//       payload: { status: null, show: false, message: null },
//     });
//   } catch (err) {
//     console.log(err);
//   }
// };

// const resendEmailForVerification = function* (data) {
//   const { payload } = data;

//   try {
//     const result = yield call(() =>
//       axios.post(`${API_URL}/candidate/resend-verify-email`, payload?.data)
//     );
//     yield put({
//       type: commonActions.SET_ALERT,
//       payload: {
//         status: result?.data?.statusCode === 200 ? "success" : "failed",
//         show: true,
//         message: result?.data?.message,
//       },
//     });
//     yield delay(2000);
//     yield put({
//       type: commonActions.SET_ALERT,
//       payload: { status: null, show: false, message: null },
//     });
//   } catch (err) {
//     console.log(err);
//   }
// };

const sendOtpForMobileVerification = function* (data) {
  const { payload } = data;
  console.log("payload", payload);

  const { navigate, mobileno, setResendCountdown,candidatename } = payload.data;
  try {
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });

    const result = yield call(
      async () =>
        await axios.get(`${API_URL}/candidate/check-mobileno/${mobileno}/${candidatename}`)
    );
    // console.log(result)
    console.log("resulto----", result?.data);

    if (result?.data?.statusCode === 200) {
      console.log("result----", result?.data?.statusCode);

      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: "success",
          show: true,
          message: result?.data?.message,
        },
      });
      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: { status: null, show: false, message: null },
      });
      yield console.log("otp---", result);

      // yield
      Cookies.set("mhet_cnd_mobileno_otp", result?.data?.testotp);
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: {
          show: false,
          type: "EVS",
          method: "emailAndMobileVerification",
        },
      });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: {
          show: true,
          type: "MV",
          method: "emailAndMobileVerification",
        },
      });
      setResendCountdown(120);
      //   navigate(`/${Cookies.get("mhet_cnd_project")}/verify-mobileno`);
    }
  } catch (err) {
    console.log(err);
  }
};
const getTempCanidateDetails = function* (data) {
  const { payload } = data;
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/get/mobileno/${payload?.emailid}`)
    );
    if (result) {
      yield put({
        type: authActions.SET_TEMP_CANDIDATE_DETAILS,
        payload: result?.data?.statusCode === 200 ? result?.data?.result : [],
      });
    }
  } catch (err) {
    console.log(err);
  }
};
// const verifyMobilenoForSignup = function* (data) {
//   console.log(data, "sagas trigeered");
//   const { payload } = data;
//   const { setMobileVerified } = payload;
//   console.log(payload, "payload---");

//   const { navigate, setRed, setAttempt, setWrongotp, attempt, wrongOTP } =
//     payload;
//   const verifiedmob = Cookies.get("mhet_cnd_mobileno");
//   const currentMob = payload?.data?.mobileno;
//   const isMobChanged = false;
//   payload.data.isMobChanged = isMobChanged;
//   const email = Cookies.get("mhet_cnd_email");
//   payload.data.email = email;

//   try {
//     const encryptedData = yield call(handleEncodeJson, {
//       encodeJSON: payload?.data,
//     });
//     const result = yield call(() =>
//       axios.post(`${API_URL}/candidate/verify-mobileno`, encryptedData)
//     );
//     if (result?.data?.statusCode === 200) {
//       // navigate(`/${Cookies.get("mhet_cnd_project")}/mobileno-verification`);
//       setMobileVerified(true);

//       yield put({
//         type: commonActions.SET_SHOW_MODAL,
//         payload: {
//           show: false,
//           type: "Mv",
//           method: "emailAndMobileVerification",
//         },
//       });
//       // yield put({
//       //   type: commonActions.SET_SHOW_MODAL,
//       //   payload: {
//       //     show: true,
//       //     type: "MVS",
//       //     method: "emailAndMobileVerification",
//       //   },
//       // });
//       yield put({
//         type: commonActions.SET_ALERT,
//         payload: { status: null, show: false, message: null },
//       });
//       yield delay(2000);
//       yield put({
//         type: commonActions.SET_SHOW_MODAL,
//         payload: {
//           show: false,
//           type: "MVS",
//           method: "emailAndMobileVerification",
//         },
//       });
//       // navigate(`/${Cookies.get("mhet_cnd_project")}/login`);
//       Cookies.set("mhet_cnd_mobileno_verified", true);
//       Cookies.remove("mhet_cnd_mobileno");
//       Cookies.set("mhet_cnd_email_verified", false);
//       yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });

//       setRed(false);
//     } else {
//       setWrongotp(wrongOTP + 1);
//       setRed(true);
//     }
//     yield put({
//       type: commonActions.SET_ALERT,
//       payload: {
//         status: result?.data?.statusCode === 200 ? "success" : "failed",
//         show: true,
//         message: result?.data?.message,
//       },
//     });
//     yield delay(2000);
//     yield put({
//       type: commonActions.SET_ALERT,
//       payload: {
//         status: null,
//         show: false,
//         message: null,
//       },
//     });
//     // setRed(false)
//   } catch (err) {
//     console.log(err);
//   }
// };
const verifyMobilenoForSignup = function* (data) {
  console.log(data, "sagas trigeered verify mobile for sig");
  const { payload } = data;
  const { setMobileVerified } = payload;
  console.log(payload, "payload---");

  const { navigate, setRed, setAttempt, setWrongotp, attempt, wrongOTP } =
    payload;
  const verifiedmob = Cookies.get("mhet_cnd_mobileno");
  const currentMob = payload?.data?.mobileno;
  const isMobChanged = false;
  payload.data.isMobChanged = isMobChanged;
  const email = Cookies.get("mhet_cnd_email");
  payload.data.email = email;

  try {
    const encryptedData = yield call(handleEncodeJson, {
      encodeJSON: payload?.data,
    });
    
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/verify-mobileno`, encryptedData)
    );
    console.log('result-mob',result);
    
    if (result?.data?.statusCode === 200) {
      // navigate(`/${Cookies.get("mhet_cnd_project")}/mobileno-verification`);
      setMobileVerified(true);
      navigate(`/${Cookies.get("mhet_cnd_project")}/registration?page=personaldetails`)
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: {
          show: false,
          type: "Mv",
          method: "emailAndMobileVerification",
        },
      });
      // yield put({
      //   type: commonActions.SET_SHOW_MODAL,
      //   payload: {
      //     show: true,
      //     type: "MVS",
      //     method: "emailAndMobileVerification",
      //   },
      // });
      yield put({
        type: commonActions.SET_ALERT,
        payload: { status: null, show: false, message: null },
      });
      yield delay(2000);
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: {
          show: false,
          type: "MVS",
          method: "emailAndMobileVerification",
        },
      });
      // navigate(`/${Cookies.get("mhet_cnd_project")}/login`);
      Cookies.set("mhet_cnd_mobileno_verified", true);
      Cookies.remove("mhet_cnd_mobileno");
      Cookies.set("mhet_cnd_email_verified", false);
      yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });

      setRed(false);
    } else {
      setWrongotp(wrongOTP + 1);
      setRed(true);
    }
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: null,
        show: false,
        message: null,
      },
    });
    // setRed(false)
  } catch (err) {
    console.log(err);
  }
};
const getProfileRegistrationActiveTab = function* (data) {
  const { payload } = data;

  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/profile-activetab/${payload?.id}`)
    );
    // const decryptedData = yield call(handleDecodeJSON, {decodeJSON:result?.data?.result});
    yield put({
      type: authActions.SET_PROFILE_REGISTRATION_ACTIVE_TAB,
      payload: result?.data?.statusCode === 200 ? result?.data?.result : [],
    });
  } catch (err) {
    console.log(err);
  }
};

const checkCandidateMobileno = function* (data) {
  const { payload } = data;
  const { handleShowOtpInput, setForget, setResendCountdown, setVerified } =
    payload;

  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/check-mobileno/${payload?.data}`)
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    if (result?.data?.statusCode === 200) {
      Cookies.set(
        "mhet_cnd_forgetemail_otp",
        EncryptFunction(result?.data?.otp)
      );
      setForget(false);
      setResendCountdown(120);
      handleShowOtpInput();
      setVerified(false);
      yield delay(2000);
    }

    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const forgotPasswordMobileVerification = function* (data) {
  const { payload } = data;
  const { navigate } = payload;

  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/send-mail-by-mobileno/${payload.data}`)
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    if (result?.data?.statusCode === 200) {
      navigate(`/${Cookies.get("mhet_cnd_project")}/login`);
    }
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const checkDuplicateEmailOrMobileno = function* (data) {
  const { payload } = data;
  const { setAndClearError } = payload;

  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/check-duplicate`, payload?.data)
    );
    if (result?.data) {
      setAndClearError({ ...result?.data, type: payload?.data?.type });
    }
    // if (result?.data?.statusCode === 200) {
    //     navigate(`/${Cookies.get('mhet_cnd_project')}/login`);
    // };
    // yield put({
    //     type: commonActions.SET_ALERT,
    //     payload: {
    //         status: (result?.data?.statusCode === 200) ? 'success' : 'failed',
    //         show: true,
    //         message: result?.data?.message
    //     }
    // });
    // yield delay(2000);
    // yield put({
    //     type: commonActions.SET_ALERT,
    //     payload: { status: null, show: false, message: null }
    // });
  } catch (err) {
    console.log(err);
  }
};

const forgetEmailId = function* (data) {
  const { payload } = data;
  const { setForget, setResendCountdown, setVerified } = payload;

  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/forgot-emailid/${payload?.data}`)
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    if (result?.data?.statusCode === 200) {
      Cookies.set(
        "mhet_cnd_forgetemail_otp",
        EncryptFunction(result?.data?.otp)
      );
      const decryptedData = yield call(handleDecodeJSON, {
        decodeJSON: result?.data?.email,
      });
      Cookies.set("mhet_email", decryptedData);
      setForget(false);
      setResendCountdown(120);
      setVerified(true);
      Cookies.set("mobile_no_forget_emailid", payload?.data);
      yield delay(2000);
    }

    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};
const forgetEmailIdOTPverify = function* (data) {
  const { payload } = data;
  const { setVerified, setForget, setRed, setWrongotp, wrongOTP } = payload;

  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const encryptedData = yield call(handleEncodeJson, { encodeJSON: payload });
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/verify-OTP`, encryptedData)
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    if (result?.data?.statusCode === 200) {
      setRed(false);
      setForget(false);
      setVerified(false);
    } else {
      setWrongotp(wrongOTP + 1);
      setRed(true);
    }

    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
    setRed(false);
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

// const checkKeycloakSessionId = function* () {

//   yield put({ type: authActions.CANDIDATE_TOKEN_VERIFY });
//   yield delay(1000)

//   try {
//   const candidateTokenDetails = yield select(
//     (state) => state?.authReducer?.candidateTokenDetails
//   );
//   const data = { candidateid: candidateTokenDetails?.candidateid }
//   const encodedData = yield call(handleEncodeJson,{encodeJSON:data});
//   // yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
//     const result = yield call(() =>
//       axios.post(`${API_URL}/candidate/check-keycloak-sid`, encodedData)
//     );
//     // debugger
//     // yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
//     // yield put({
//     //   type: commonActions.SET_ALERT,
//     //   payload: {
//     //     status: result?.data?.statusCode === 200 ? "success" : "failed",
//     //     show: true,
//     //     message: result?.data?.message,
//     //   },
//     // });
//     if (result?.data?.statusCode === 200) {

//       const localSid = Cookies.get("mhet_cnd_sid");
//       const sid = result?.data?.sid

//       if (localSid != sid) {

//       Cookies.remove("mhet_cnd_token");
//       Cookies.remove("mhet_cnd_sid")
//       window.location.href = `/${Cookies.get("mhet_cnd_project")}/`;
//       window.location.reload()
//         // yield put({ type: authActions.CANDIDATE_LOGOUT });

//       }
//       // yield put({ type: authActions.CANDIDATE_TOKEN_VERIFY });
//     }else if(result?.data?.statusCode == 400){

//       Cookies.remove("mhet_cnd_token");
//       Cookies.remove("mhet_cnd_sid")
//       window.location.href = `/${Cookies.get("mhet_cnd_project")}/`;
//       window.location.reload()

//         // yield put({ type: authActions.CANDIDATE_LOGOUT });

//     }
//     // yield delay(2000);
//     // yield put({
//     //   type: commonActions.SET_ALERT,
//     //   payload: { status: null, show: false, message: null },
//     // });
//   } catch (error) {
//     console.log(error);
//     Cookies.remove("mhet_cnd_token");
//     window.location.href = `/${Cookies.get("mhet_cnd_project")}/login`;
//   }
// };

export default authSagas;









