import React, { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  useMediaQuery,
  MenuItem,
  ListSubheader,
  Select,
  Typography,
  useTheme,
  Box,
  Chip,
  TextField,
  Input,
  InputLabel,
  Tooltip,
  Button,
  CircularProgress,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import masterActions from "../../redux/master/action";
import SubmitButton from "../../common/submitButton";
import { useLocation, useNavigate } from "react-router-dom";
import candidatesActions from "../../redux/candidates/actions";
import { cssProperties } from "../../utils/commonCssProperties";

import { masterFieldLabel, isFieldDisabled } from "../../utils/courseValues";
import BackButton from "../../common/backButton";
import Cookies from "js-cookie";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";

import commonActions from "../../redux/common/actions";
export default function ExamCenterForm({ nextPageName, previousPageName }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const courseid = location.state?.courseid;
  const coursename = location.state?.coursename;
  const [filteredDistricts, setFilteredDistricts] = useState([]);

  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const { masterFields, courseList } = useSelector(
    (state) => state.candidatesReducer
  );
  console.log("courseList----", courseList);

  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );
  const {
    courseFullRegistrationDetails,
    courseFormDetails,
    otbsVenueDetails,
    currentOtbsVenue,
  } = useSelector((state) => state.candidatesReducer);
  const { examState, examDistrict, examLanguage } = useSelector(
    (state) => state.candidatesReducer
  );

  const isPaid = courseFormDetails[0]?.ispaid;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    getValues,
    control,
    reset,
    clearErrors,
    formState: { errors },
    setFocus,
  } = useForm();

  const onError = (errors) => {
    const fError = Object.keys(errors)[0];
    if (fError) {
      setFocus(fError);
      document.getElementsByName(fError)[0]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };
  useEffect(() => {
    if (courseid) {
      dispatch({
        type: candidatesActions.GET_COURSE_REGISTRATION_DETAILS,
        payload: {
          courseid: courseid,
        },
      });
      dispatch({ type: candidatesActions.GET_ALL_COURSE_LIST });
    }
  }, [courseid]);
  // useEffect(() => {
  //   if (
  //     candidateid &&
  //      courseFormDetails.length > 0 &&
  //     courseList?.all?.length > 0 &&
  //     courseList?.all?.find((obj) => obj.courseid == courseid)?.isotbs == true
  //   ) {
  //     console.log(candidateid,'candidateidid')
  //     dispatch({
  //       type: candidatesActions.GET_CURRENT_VENUE_DETAILS,
  //       payload: { courseid: courseid, candidateid: candidateid },
  //     });
  //   }
  // }, [courseList, courseid, courseFormDetails, candidateid]);

  useEffect(() => {
    if (
      courseFullRegistrationDetails?.length > 0 &&
      courseList?.all?.length > 0
    ) {
      reset({ ...courseFullRegistrationDetails[0] });
      setValue(
        "preference1districtid",
        courseFullRegistrationDetails[0]?.preference1districtid
      );
      setValue(
        "preference2districtid",
        courseFullRegistrationDetails[0]?.preference2districtid
      );
      // setValue(
      //   "preference3districtid",
      //   courseFullRegistrationDetails[0]?.preference3districtid
      // );
      setValue(
        "preference3districtid",
        courseFullRegistrationDetails[0]?.preference3districtid || ""
      );

      setValue(
        "preference4districtid",
        courseFullRegistrationDetails[0]?.preference4districtid
      );
      setValue(
        "otbsexamstate",
        otbsVenueDetails?.Venues?.length > 0 &&
          otbsVenueDetails?.Venues?.find(
            (obj) => obj?.venueid == courseFullRegistrationDetails[0]?.venueid
          )?.stateid
          ? otbsVenueDetails?.Venues?.find(
              (obj) => obj?.venueid == courseFullRegistrationDetails[0]?.venueid
            )?.stateid
          : ""
      );
      setValue(
        "otbsexamdistrict",
        otbsVenueDetails?.Venues?.length > 0 &&
          otbsVenueDetails?.Venues?.find(
            (obj) => obj?.venueid == courseFullRegistrationDetails[0]?.venueid
          )?.districtid
          ? otbsVenueDetails?.Venues?.find(
              (obj) => obj?.venueid == courseFullRegistrationDetails[0]?.venueid
            )?.districtid
          : ""
      );
      setValue(
        "venueid",
        otbsVenueDetails?.Venues?.length > 0 &&
          otbsVenueDetails?.Venues?.find(
            (obj) => obj?.venueid == courseFullRegistrationDetails[0]?.venueid
          )?.venueid
          ? courseFullRegistrationDetails[0]?.venueid
          : ""
      );
    }
  }, [courseFullRegistrationDetails, otbsVenueDetails, courseList]);

  const handleProcced = (data) => {
    const corcandidatepreferenceid =
      courseFullRegistrationDetails[0]?.corcandidatepreferenceid;

    if (
      courseid &&
      courseList?.all?.length > 0 &&
      courseList?.all?.find((obj) => obj.courseid == courseid)?.isotbs == true
      //   &&
      // courseFullRegistrationDetails[0]?.venueid != data?.venueid
    ) {
      dispatch({
        type: candidatesActions.OTBS_COURSE_EXAM_DETAILS_CREATION,
        payload: {
          data: {
            ...data,
            operation: corcandidatepreferenceid
              ? parseInt(corcandidatepreferenceid)
              : 0,
            candidateid: candidateid,
            status: 1,
            courseid: courseid,
            coursename: coursename,
            createdby: candidateid,
            updatedby: corcandidatepreferenceid ? parseInt(candidateid) : "",
          },
          navigate: navigate,
          nextPageName: nextPageName,
        },
      });
      dispatch({
        type: candidatesActions.GET_COURSE_FORM_DETAILS,
        payload: { courseid: courseid },
      });
      dispatch({
        type: candidatesActions.GET_COURSE_STATUS,
        payload: { candidateid, courseid },
      });
      dispatch({
        type: candidatesActions.GET_IS_PAID,
        payload: { candidateid, courseid },
      });
    } else {
      dispatch({
        type: candidatesActions.COURSE_EXAM_DETAILS_CREATION,
        payload: {
          data: {
            ...data,
            operation: corcandidatepreferenceid
              ? parseInt(corcandidatepreferenceid)
              : 0,
            candidateid: candidateid,
            status: 1,
            courseid: courseid,
            coursename: coursename,
            createdby: candidateid,
            updatedby: corcandidatepreferenceid ? parseInt(candidateid) : "",
          },
          navigate: navigate,
          nextPageName: nextPageName,
        },
      });
      dispatch({
        type: candidatesActions.GET_COURSE_FORM_DETAILS,
        payload: { courseid: courseid },
      });
      dispatch({
        type: candidatesActions.GET_COURSE_STATUS,
        payload: { candidateid, courseid },
      });
      dispatch({
        type: candidatesActions.GET_IS_PAID,
        payload: { candidateid, courseid },
      });
    }
  };

  const handleBack = () => {
    navigate(
      `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=${previousPageName}`,
      { state: { courseid: courseid, coursename: coursename } }
    );
  };

  useEffect(() => {
    if (courseid) {
      dispatch({
        type: candidatesActions.GET_COURSE_FORM_DETAILS,
        payload: { courseid: courseid },
      });
      dispatch({
        type: candidatesActions.GET_MASTER_FIELDS,
        payload: {
          data: { courseid: courseid },
        },
      });
      dispatch({
        type: candidatesActions.GET_EXAM_STATE,
        payload: {
          data: { courseid: courseid },
        },
      });
      dispatch({
        type: candidatesActions.GET_EXAM_DISTRICT,
        payload: {
          data: { courseid: courseid },
        },
      });
      dispatch({
        type: candidatesActions.GET_EXAM_LANGUAGE,
        payload: {
          data: { courseid: courseid },
        },
      });
    }
  }, [courseid]);

  const groupedData = (data) => {
    return data.reduce((acc, curr) => {
      const { stateid, statename, district, districtid } = curr;

      if (!acc[stateid]) {
        acc[stateid] = { statename, districts: [] };
      }
      acc[stateid].districts.push({ district, districtid });
      return acc;
    }, {});
  };

  useEffect(() => {
    if (
      courseid &&
      courseList?.all?.length > 0 &&
      courseList?.all?.find((obj) => obj.courseid == courseid)?.isotbs == true
    ) {
      dispatch({
        type: candidatesActions.GET_OTBS_VENUE_DETAILS,
        payload: { courseid, candidateid },
      });
    }
  }, [courseList, courseid, candidateid]);
  // if (courseList?.all?.length > 0) {
  const course = courseList?.all?.find((obj) => obj.courseid === courseid);
  if (
    // courseList?.all?.find((obj) => obj.courseid == courseid)?.ispcmb == true ||
    // courseList?.all?.find((obj) => obj.courseid == courseid)?.isotbs != true ||
    // courseList?.all?.find((obj) => obj.courseid == courseid)?.isotbs == true
    // course !== undefined
    courseList?.all?.length > 0
  ) {
    return (
      <Grid
        style={{
          padding: "20px 25px 0px 25px",
          minHeight: "60vh",
          backgroundColor: "#fff",
          borderRadius: "8px",
          border: "1px solid #0F4F96",
        }}
      >
        <Typography
          pb={2}
          variant="h4"
          sx={{ textWrap: "wrap", fontSize: 30, fontWeight: 500 }}
        >
          Exam Center Selection
        </Typography>

        {courseList?.all?.find((obj) => obj.courseid == courseid)?.ispcmb ==
          true &&
          masterFields?.find(
            (field) =>
              field.masterfieldlabel === masterFieldLabel.SELECT_SUBJECT_GROUP
          ) && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SELECT_SUBJECT_GROUP
                    )?.fieldlabel
                  }

                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SELECT_SUBJECT_GROUP
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <FormControl fullWidth error={!!errors.subjectgroup}>
                  <Controller
                    name="subjectgroup"
                    control={control}
                    rules={{
                      required:
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.SELECT_SUBJECT_GROUP
                        )?.ismandatory === "1"
                          ? "Select Subject Group is required"
                          : false,
                    }}
                    render={({ field }) => (
                      <Select
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        {...field}
                        id="subjectgroup"
                        value={watch("subjectgroup") || ""}
                        disabled={isFieldDisabled(
                          masterFields,
                          masterFieldLabel.SELECT_SUBJECT_GROUP,
                          null,
                          isPaid
                        )}
                        inputProps={{
                          style: { height: 56, boxSizing: "border-box" },
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select one
                        </MenuItem>
                        <MenuItem value="PCM">PCM</MenuItem>
                        <MenuItem value="PCB">PCB</MenuItem>
                        <MenuItem value="PCM and PCB">PCMB</MenuItem>
                      </Select>
                    )}
                  />

                  {errors.subjectgroup && (
                    <small
                      className="error"
                      style={{ margin: 0, marginTop: ".2rem", color: "red" }}
                    >
                      {errors.subjectgroup.message}
                    </small>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SELECT_LANGUAGE
        ) && (
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={5}>
              <Typography>
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SELECT_LANGUAGE
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.SELECT_LANGUAGE
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <FormControl fullWidth error={!!errors.languageid}>
                <Controller
                  name="languageid"
                  control={control}
                  rules={{
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SELECT_LANGUAGE
                      )?.ismandatory === "1"
                        ? "Select Language is required"
                        : false,
                  }}
                  render={({ field }) => (
                    // <Select
                    //   MenuProps={{
                    //     disableScrollLock: true,
                    //   }}
                    //   {...field}

                    //   fullWidth
                    //   value={field.value || []}
                    //   inputProps={{
                    //     style: { height: 40, boxSizing: "border-box" },
                    //   }}
                    //   onChange={(event) => {
                    //     const selectedValues = event.target.value;
                    //     field.onChange(selectedValues);
                    //     console.log(selectedValues);
                    //   }}
                    //   renderValue={(selected) => (
                    //     <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    //       {selected?.map((value) => {
                    //         const selectedLanguage = examLanguage?.find(
                    //           (lang) => lang?.languagename === value
                    //         );
                    //         console.log(
                    //           selectedLanguage?.languagename,
                    //           "languagename"
                    //         );

                    //         return (
                    //           <Chip
                    //             key={value}
                    //             label={selectedLanguage?.languagename}
                    //           />
                    //         );
                    //       })}
                    //     </Box>
                    //   )}
                    //   disabled={isFieldDisabled(
                    //     masterFields,
                    //     masterFieldLabel.SELECT_LANGUAGE,
                    //     null,
                    //     isPaid
                    //   )}
                    // >
                    //   {examLanguage?.map((val, i) => (
                    //     <MenuItem key={i} value={val?.languagename}>
                    //       {val?.languagename}
                    //     </MenuItem>
                    //   ))}
                    // </Select>
                    <Tooltip
                      title={
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.SELECT_LANGUAGE
                        )?.fieldlabel
                      }
                      arrow
                      PopperProps={{
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [10, 0],
                            },
                          },
                        ],
                      }}
                      placement="right"
                      disableInteractive
                      enterDelay={500}
                      leaveDelay={200}
                    >
                      <Select
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        {...field}
                        fullWidth
                        value={field.value || ""}
                        inputProps={{
                          style: { height: 40, boxSizing: "border-box" },
                        }}
                        onChange={(event) => {
                          const selectedValue = event.target.value;
                          field.onChange(selectedValue);
                          setValue("languageid", event.target.value);
                        }}
                        renderValue={(selected) => {
                          const selectedLanguage = examLanguage?.find(
                            (lang) => lang?.examlanguageid === selected
                          );
                          return (
                            selectedLanguage?.languagename ||
                            "Select a language"
                          );
                        }}
                        disabled={isFieldDisabled(
                          masterFields,
                          masterFieldLabel.SELECT_LANGUAGE,
                          null,
                          isPaid
                        )}
                      >
                        {examLanguage?.map((val, i) => (
                          <MenuItem key={i} value={val?.examlanguageid}>
                            {val?.languagename}
                          </MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                  )}
                />

                {errors.languageid && (
                  <small
                    className="error"
                    style={{ margin: 0, marginTop: ".2rem" }}
                  >
                    {errors.languageid.message}
                  </small>
                )}
              </FormControl>
            </Grid>
          </Grid>
        )}

        {courseList?.all?.find((obj) => obj.courseid == courseid)?.isotbs !=
        true ? (
          <>
            {masterFields?.find(
              (field) =>
                field.masterfieldlabel ===
                masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_1
            ) && (
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={5}>
                  <Tooltip
                    title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_1
                      )?.fieldlabel
                    }
                    arrow

                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [10, 0],
                          },
                        },
                      ],
                    }}
                    placement="right"
                    disableInteractive
                    
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <TextField
                      select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      variant="outlined"
                      fullWidth
                      id="preferencestate1id"
                      // multiple
                      label={
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_1
                            )?.fieldlabel
                          }{" "}
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_1
                          )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        </span>
                      }
                      // value={getValues("ppreferencestate1id")}
                      {...register("preferencestate1id", {
                        required:
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_1
                          )?.ismandatory === "1"
                            ? "Preference State 1 is required"
                            : false,
                      })}
                      SelectProps={{
                        MenuProps: { disableScrollLock: true },
                        style: { height: "56px", boxSizing: "border-box" },
                        onChange: (e) => {
                          setValue("preferencestate1id", e.target.value);
                          trigger("preferencestate1id");

                          setValue("preference1districtid", "");
                        },
                      }}
                      value={watch("preferencestate1id") ?? ""}
                      disabled={isFieldDisabled(
                        masterFields,
                        masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_1,
                        null,
                        isPaid
                      )}
                      error={!!errors.preferencestate1id}
                      helperText={
                        errors.preferencestate1id
                          ? errors.preferencestate1id.message
                          : ""
                      }
                    >
                      {examState
                        ?.filter((state) => {
                          const availableDistricts = examDistrict?.all?.filter(
                            (district) =>
                              district.stateid === state.stateid &&
                              district.districtid !==
                                watch("preference2districtid") &&
                              district.districtid !==
                                watch("preference3districtid") &&
                              district.districtid !==
                                watch("preference4districtid")
                          );
                          return availableDistricts?.length > 0;
                        })
                        .map((val, i) => (
                          <MenuItem value={val?.stateid} key={i}>
                            {val?.state}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Tooltip>
                </Grid>

                <Grid item xs={12} md={5}>
                  <Tooltip
                    title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_1
                      )?.fieldlabel
                    }
                    arrow
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [10, 0],
                          },
                        },
                      ],
                    }}
                    placement="right"
                    disableInteractive
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <TextField
                      select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      variant="outlined"
                      fullWidth
                      id="preference1districtid"
                      label={
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_1
                            )?.fieldlabel
                          }{" "}
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_1
                          )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        </span>
                      }
                      {...register("preference1districtid", {
                        required:
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_1
                          )?.ismandatory === "1"
                            ? "Preference District 1 is required"
                            : false,
                      })}
                      SelectProps={{
                        MenuProps: { disableScrollLock: true },
                        style: { height: "56px", boxSizing: "border-box" },
                        onChange: (e) => {
                          const selectedValue = e.target.value;
                          setValue("preference1districtid", selectedValue);
                          trigger("preference1districtid");
                          watch("preference2districtid") == selectedValue &&
                            setValue("preference2districtid", "");
                          watch("preference3districtid") == selectedValue &&
                            setValue("preference3districtid", "");
                          watch("preference4districtid") == selectedValue &&
                            setValue("preference4districtid", "");
                        },
                      }}
                      value={watch("preference1districtid") ?? ""}
                      disabled={
                        isFieldDisabled(
                          masterFields,
                          masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_1,
                          null,
                          isPaid
                        ) || getValues("preferencestate1id") === ""
                      }
                      error={!!errors.preference1districtid}
                      helperText={
                        errors.preference1districtid
                          ? errors.preference1districtid.message
                          : ""
                      }
                    >
                      {watch("preferencestate1id") &&
                        examDistrict?.all
                          ?.filter(
                            (obj) =>
                              obj.stateid == watch("preferencestate1id") &&
                              obj.districtid !=
                                watch("preference2districtid") &&
                              obj.districtid !=
                                watch("preference3districtid") &&
                              obj.districtid != watch("preference4districtid")
                          )
                          ?.map((val, i) => (
                            <MenuItem value={val?.districtid} key={i}>
                              {val?.district}
                            </MenuItem>
                          ))}
                    </TextField>
                  </Tooltip>
                </Grid>

                <Grid
                  sx={{
                    mt: 3,
                    marginLeft: "24px",
                  }}
                >
                  <SubmitButton
                    name={"Reset"}
                    handleSubmit={() => {
                      setValue("preferencestate1id", "");
                      setValue("preference1districtid", "");
                    }}
                  />
                </Grid>
              </Grid>
            )}

            {masterFields?.find(
              (field) =>
                field.masterfieldlabel ===
                masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_2
            ) && (
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={5}>
                  <Tooltip
                    title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_2
                      )?.fieldlabel
                    }
                    arrow
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [10, 0],
                          },
                        },
                      ],
                    }}
                    placement="right"
                    disableInteractive
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <TextField
                      select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      variant="outlined"
                      fullWidth
                      id="preferencestate2id"
                      label={
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_2
                            )?.fieldlabel
                          }{" "}
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_2
                          )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        </span>
                      }
                      {...register("preferencestate2id", {
                        required:
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_2
                          )?.ismandatory === "1"
                            ? "Preference State 2 is required"
                            : false,
                      })}
                      SelectProps={{
                        MenuProps: { disableScrollLock: true },
                        style: { height: "56px", boxSizing: "border-box" },
                        onChange: (e) => {
                          // setValue("preference1districtid", "");
                          setValue("preference2districtid", "");
                          // setValue("preference3districtid", "");
                          // setValue("preference4districtid", "");
                          const preferencestate2id = e.target.value;
                          setValue("preferencestate2id", preferencestate2id);
                          // dispatch({
                          //   type: masterActions.GET_DISTRICT,
                          //   payload: preferencestate2id,
                          // });
                          trigger("preferencestate2id");
                          // const selectedValues = e.target.value;
                          // const validValues = selectedValues.filter(
                          //   (val) => val !== null && val !== undefined
                          // );
                          // const uniqueValues = Array.from(
                          //   new Set(validValues.map((val) => val?.toString()))
                          // );

                          // field.onChange(uniqueValues);
                          // setValue("preference1districtid", "");
                        },
                      }}
                      value={watch("preferencestate2id") ?? ""}
                      disabled={isFieldDisabled(
                        masterFields,
                        masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_2,
                        null,
                        isPaid
                      )}
                      error={!!errors.preferencestate2id}
                      helperText={
                        errors.preferencestate2id
                          ? errors.preferencestate2id.message
                          : ""
                      }
                    >
                      {examState
                        ?.filter((state) => {
                          const availableDistricts = examDistrict?.all?.filter(
                            (district) =>
                              district.stateid === state.stateid &&
                              district.districtid !==
                                watch("preference1districtid") &&
                              district.districtid !==
                                watch("preference3districtid") &&
                              district.districtid !==
                                watch("preference4districtid")
                          );
                          return availableDistricts?.length > 0;
                        })
                        .map((val, i) => (
                          <MenuItem value={val?.stateid} key={i}>
                            {val?.state}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Tooltip>
                </Grid>

                <Grid item xs={12} md={5}>
                  <Tooltip
                    title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_2
                      )?.fieldlabel
                    }
                    arrow
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [10, 0],
                          },
                        },
                      ],
                    }}
                    placement="right"
                    disableInteractive
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <TextField
                      select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      variant="outlined"
                      fullWidth
                      id="preference2districtid"
                      label={
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_2
                            )?.fieldlabel
                          }{" "}
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_2
                          )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        </span>
                      }
                      {...register("preference2districtid", {
                        required:
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_2
                          )?.ismandatory === "1"
                            ? "Preference District 2 is required"
                            : false,
                      })}
                      SelectProps={{
                        MenuProps: { disableScrollLock: true },
                        style: { height: "56px", boxSizing: "border-box" },
                        onChange: (e) => {
                          const selectedValue = e.target.value;
                          setValue("preference2districtid", selectedValue);
                          trigger("preference2districtid");
                          watch("preference1districtid") == selectedValue &&
                            setValue("preference1districtid", "");
                          watch("preference3districtid") == selectedValue &&
                            setValue("preference3districtid", "");
                          watch("preference4districtid") == selectedValue &&
                            setValue("preference4districtid", "");
                        },
                      }}
                      value={watch("preference2districtid") ?? ""}
                      disabled={
                        isFieldDisabled(
                          masterFields,
                          masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_2,
                          null,
                          isPaid
                        ) || watch("preferencestate2id") === ""
                      }
                      error={!!errors.preference2districtid}
                      helperText={
                        errors.preference2districtid
                          ? errors.preference2districtid.message
                          : ""
                      }
                    >
                      {watch("preferencestate2id") &&
                        examDistrict?.all
                          ?.filter(
                            (obj) =>
                              obj.stateid == watch("preferencestate2id") &&
                              obj.districtid !=
                                watch("preference1districtid") &&
                              obj.districtid !=
                                watch("preference3districtid") &&
                              obj.districtid != watch("preference4districtid")
                          )
                          ?.map((val, i) => (
                            <MenuItem value={val?.districtid} key={i}>
                              {val?.district}
                            </MenuItem>
                          ))}
                    </TextField>
                  </Tooltip>
                </Grid>

                <Grid
                  sx={{
                    mt: 3,
                    marginLeft: "24px",
                  }}
                >
                  <SubmitButton
                    name={"Reset"}
                    handleSubmit={() => {
                      setValue("preferencestate2id", "");
                      setValue("preference2districtid", "");
                    }}
                  />
                </Grid>
              </Grid>
            )}

            {masterFields?.find(
              (field) =>
                field.masterfieldlabel ===
                masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_3
            ) && (
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={5}>
                  <Tooltip
                    title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_3
                      )?.fieldlabel
                    }
                    arrow
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [10, 0],
                          },
                        },
                      ],
                    }}
                    placement="right"
                    disableInteractive
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <TextField
                      select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      variant="outlined"
                      fullWidth
                      id="preferencestate3id"
                      label={
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_3
                            )?.fieldlabel
                          }{" "}
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_3
                          )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        </span>
                      }
                      {...register("preferencestate3id", {
                        required:
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_3
                          )?.ismandatory === "1"
                            ? "Preference State 3 is required"
                            : false,
                      })}
                      SelectProps={{
                        MenuProps: { disableScrollLock: true },
                        style: { height: "56px", boxSizing: "border-box" },
                        onChange: (e) => {
                          const preferencestate3id = e.target.value;
                          setValue("preferencestate3id", preferencestate3id);
                          clearErrors("preferencestate3id");

                          // Clear other district preferences when state changes
                          setValue("preference3districtid", "");
                        },
                      }}
                      value={watch("preferencestate3id") ?? ""}
                      disabled={
                        isFieldDisabled(
                          masterFields,
                          masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_3,
                          null,
                          isPaid
                        ) || watch("preferencestate2id") === ""
                      }
                      error={!!errors.preferencestate3id}
                      helperText={
                        errors.preferencestate3id
                          ? errors.preferencestate3id.message
                          : ""
                      }
                    >
                      {examState
                        ?.filter((state) => {
                          const availableDistricts = examDistrict?.all?.filter(
                            (district) =>
                              district.stateid === state.stateid &&
                              district.districtid !==
                                watch("preference1districtid") &&
                              district.districtid !==
                                watch("preference2districtid") &&
                              district.districtid !==
                                watch("preference4districtid")
                          );
                          return availableDistricts?.length > 0;
                        })
                        .map((val, i) => (
                          <MenuItem value={val?.stateid} key={i}>
                            {val?.state}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Tooltip>
                </Grid>

                <Grid item xs={12} md={5}>
                  <Tooltip
                    title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_3
                      )?.fieldlabel
                    }
                    arrow
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [10, 0],
                          },
                        },
                      ],
                    }}
                    placement="right"
                    disableInteractive
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <TextField
                      select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      variant="outlined"
                      fullWidth
                      id="preference3districtid"
                      label={
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_3
                            )?.fieldlabel
                          }{" "}
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_3
                          )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        </span>
                      }
                      {...register("preference3districtid", {
                        required:
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_3
                          )?.ismandatory === "1"
                            ? "Preference District 3 is required"
                            : false,
                      })}
                      SelectProps={{
                        MenuProps: { disableScrollLock: true },
                        style: { height: "56px", boxSizing: "border-box" },
                        onChange: (e) => {
                          const selectedValue = e.target.value;
                          setValue("preference3districtid", selectedValue);
                          trigger("preference3districtid");

                          // Clear other district preferences when district is selected
                          watch("preference1districtid") == selectedValue &&
                            setValue("preference1districtid", "");
                          watch("preference2districtid") == selectedValue &&
                            setValue("preference2districtid", "");
                          watch("preference4districtid") == selectedValue &&
                            setValue("preference4districtid", "");
                        },
                      }}
                      value={watch("preference3districtid") ?? ""}
                      disabled={
                        isFieldDisabled(
                          masterFields,
                          masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_3,
                          null,
                          isPaid
                        ) || watch("preferencestate3id") === ""
                      }
                      error={!!errors.preference3districtid}
                      helperText={
                        errors.preference3districtid
                          ? errors.preference3districtid.message
                          : ""
                      }
                    >
                      {watch("preferencestate3id") &&
                        examDistrict?.all
                          ?.filter(
                            (obj) =>
                              obj.stateid === watch("preferencestate3id") &&
                              obj.districtid !==
                                watch("preference1districtid") &&
                              obj.districtid !==
                                watch("preference2districtid") &&
                              obj.districtid !== watch("preference4districtid")
                          )
                          ?.map((val, i) => (
                            <MenuItem value={val?.districtid} key={i}>
                              {val?.district}
                            </MenuItem>
                          ))}
                    </TextField>
                  </Tooltip>
                </Grid>

                <Grid
                  sx={{
                    mt: 3,
                    marginLeft: "24px",
                  }}
                >
                  <SubmitButton
                    name={"Reset"}
                    handleSubmit={() => {
                      setValue("preferencestate3id", "");
                      setValue("preference3districtid", "");
                    }}
                  />
                </Grid>
              </Grid>
            )}

            {masterFields?.find(
              (field) =>
                field.masterfieldlabel ===
                masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_4
            ) && (
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={5}>
                  <Tooltip
                    title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_4
                      )?.fieldlabel
                    }
                    arrow
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [10, 0],
                          },
                        },
                      ],
                    }}
                    placement="right"
                    disableInteractive
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <TextField
                      select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      variant="outlined"
                      fullWidth
                      id="preferencestate4id"
                      label={
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_4
                            )?.fieldlabel
                          }{" "}
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_4
                          )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        </span>
                      }
                      {...register("preferencestate4id", {
                        required:
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_4
                          )?.ismandatory === "1"
                            ? "Preference State 4 is required"
                            : false,
                      })}
                      SelectProps={{
                        MenuProps: { disableScrollLock: true },
                        style: { height: "56px", boxSizing: "border-box" },
                        onChange: (e) => {
                          const selectedState = e.target.value;
                          setValue("preferencestate4id", selectedState);
                          clearErrors("preferencestate4id");

                          // Clear related district preferences when state is selected
                          setValue("preference4districtid", "");
                        },
                      }}
                      value={watch("preferencestate4id") ?? ""}
                      disabled={isFieldDisabled(
                        masterFields,
                        masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_4,
                        null,
                        isPaid
                      )}
                      error={!!errors.preferencestate4id}
                      helperText={
                        errors.preferencestate4id
                          ? errors.preferencestate4id.message
                          : ""
                      }
                    >
                      {examState
                        ?.filter((state) => {
                          const availableDistricts = examDistrict?.all?.filter(
                            (district) =>
                              district.stateid === state.stateid &&
                              district.districtid !==
                                watch("preference1districtid") &&
                              district.districtid !==
                                watch("preference3districtid") &&
                              district.districtid !==
                                watch("preference2districtid")
                          );
                          return availableDistricts?.length > 0;
                        })
                        .map((state, i) => (
                          <MenuItem value={state?.stateid} key={i}>
                            {state?.state}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Tooltip>
                </Grid>

                <Grid item xs={12} md={5}>
                  <Tooltip
                    title={
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_4
                      )?.fieldlabel
                    }
                    arrow
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [10, 0],
                          },
                        },
                      ],
                    }}
                    placement="right"
                    disableInteractive
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <TextField
                      select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      variant="outlined"
                      fullWidth
                      id="preference4districtid"
                      label={
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_4
                            )?.fieldlabel
                          }{" "}
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_4
                          )?.ismandatory === "1" && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        </span>
                      }
                      {...register("preference4districtid", {
                        required:
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_4
                          )?.ismandatory === "1"
                            ? "Preference District 4 is required"
                            : false,
                      })}
                      SelectProps={{
                        MenuProps: { disableScrollLock: true },
                        style: { height: "56px", boxSizing: "border-box" },
                        onChange: (e) => {
                          const selectedValue = e.target.value;
                          setValue("preference4districtid", selectedValue);
                          trigger("preference4districtid");
                          // Clearing related preferences when one is selected
                          if (
                            watch("preference1districtid") === selectedValue
                          ) {
                            setValue("preference1districtid", "");
                          }
                          if (
                            watch("preference2districtid") === selectedValue
                          ) {
                            setValue("preference2districtid", "");
                          }
                          if (
                            watch("preference3districtid") === selectedValue
                          ) {
                            setValue("preference3districtid", "");
                          }
                        },
                      }}
                      value={watch("preference4districtid") ?? ""}
                      disabled={
                        isFieldDisabled(
                          masterFields,
                          masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_4,
                          null,
                          isPaid
                        ) || watch("preferencestate4id") === ""
                      }
                      error={!!errors.preference4districtid}
                      helperText={
                        errors.preference4districtid
                          ? errors.preference4districtid.message
                          : ""
                      }
                    >
                      {watch("preferencestate4id") &&
                        examDistrict?.all
                          ?.filter(
                            (obj) =>
                              obj.stateid === watch("preferencestate4id") &&
                              obj.districtid !==
                                watch("preference1districtid") &&
                              obj.districtid !==
                                watch("preference2districtid") &&
                              obj.districtid !== watch("preference3districtid")
                          )
                          ?.map((val, i) => (
                            <MenuItem value={val?.districtid} key={i}>
                              {val?.district}
                            </MenuItem>
                          ))}
                    </TextField>
                  </Tooltip>
                </Grid>

                <Grid
                  sx={{
                    mt: 3,
                    marginLeft: "24px",
                  }}
                >
                  <SubmitButton
                    name={"Reset"}
                    handleSubmit={() => {
                      setValue("preferencestate4id", "");
                      setValue("preference4districtid", "");
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} md={5}>
                <Typography>
                  Select Examintion Center State
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <FormControl fullWidth error={!!errors.otbsexamstate}>
                  <Controller
                    name="otbsexamstate"
                    control={control}
                    rules={{ required: "Exam State is required" }}
                    render={({ field }) => (
                      <Select
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        {...field}
                        id="otbsexamstate"
                        {...register("otbsexamstate", {
                          required: "Exam State is required",
                        })}
                        onChange={(e) => {
                          const otbsexamstate = e.target.value;
                          setValue("otbsexamstate", otbsexamstate);
                          setValue("otbsexamdistrict", "");
                        }}
                        value={watch("otbsexamstate") ?? ""}
                        // disabled={isFieldDisabled(
                        //   masterFields,
                        //   masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_4,
                        //   null,
                        //   isPaid
                        // )}
                        inputProps={{
                          style: { height: 56, boxSizing: "border-box" },
                        }}
                      >
                        {otbsVenueDetails?.States?.map((val, i) => (
                          <MenuItem value={val?.stateid} key={i}>
                            {val?.state}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.otbsexamstate && (
                    <small
                      className="error"
                      style={{ margin: 0, marginTop: ".2rem" }}
                    >
                      {errors.otbsexamstate.message}
                    </small>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} md={5}>
                <Typography>
                  Select Examintion Center District
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <FormControl fullWidth error={!!errors.otbsexamdistrict}>
                  <Controller
                    name="otbsexamdistrict"
                    control={control}
                    rules={{ required: "Exam District is required" }}
                    render={({ field }) => (
                      <Select
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        {...field}
                        id="otbsexamdistrict"
                        {...register("otbsexamdistrict", {
                          required: "Exam District is required",
                        })}
                        onChange={(e) => {
                          const otbsexamdistrict = e.target.value;
                          setValue("otbsexamdistrict", otbsexamdistrict);
                          setValue("venueid", "");
                        }}
                        value={watch("otbsexamdistrict") ?? ""}
                        // disabled={isFieldDisabled(
                        //   masterFields,
                        //   masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_4,
                        //   null,
                        //   isPaid
                        // )}
                        inputProps={{
                          style: { height: 56, boxSizing: "border-box" },
                        }}
                      >
                        {otbsVenueDetails?.Districts?.filter(
                          (obj) => obj.stateid == watch("otbsexamstate")
                        )?.map((val, i) => (
                          <MenuItem value={val?.districtid} key={i}>
                            {val?.district}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.otbsexamdistrict && (
                    <small
                      className="error"
                      style={{ margin: 0, marginTop: ".2rem" }}
                    >
                      {errors.otbsexamdistrict.message}
                    </small>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} md={5}>
                <Typography>
                  Select Examintion Center
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <FormControl fullWidth error={!!errors.venueid}>
                  <Controller
                    name="venueid"
                    control={control}
                    rules={{ required: "Exam Center is required" }}
                    render={({ field }) => (
                      <Select
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        {...field}
                        id="venueid"
                        {...register("venueid", {
                          required: "Exam Center is required",
                        })}
                        onChange={(e) => {
                          const venueid = e.target.value;
                          setValue("venueid", venueid);
                          clearErrors("venueid");
                          // setValue("otbsexamdistrict", "");
                        }}
                        value={watch("venueid") ?? ""}
                        // disabled={isFieldDisabled(
                        //   masterFields,
                        //   masterFieldLabel.EXAM_CENTER_STATE_PREFERENCE_4,
                        //   null,
                        //   isPaid
                        // )}
                        inputProps={{
                          style: { height: 56, boxSizing: "border-box" },
                        }}
                      >
                        {otbsVenueDetails?.Venues?.filter(
                          (obj) => obj.districtid == watch("otbsexamdistrict")
                        )
                          ?.sort((a, b) => {
                            const refA = parseInt(
                              a.referenceid.replace("V", "")
                            );
                            const refB = parseInt(
                              b.referenceid.replace("V", "")
                            );
                            return refA - refB;
                          })
                          ?.map((val, i) => (
                            <MenuItem value={val?.venueid} key={i}>
                              {val?.referenceid} - {val?.venuename}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                  {errors.venueid && (
                    <small
                      className="error"
                      style={{ margin: 0, marginTop: ".2rem" }}
                    >
                      {errors.venueid.message}
                    </small>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} md={5}>
                <Typography>
                  Examintion Center Address
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <FormControl fullWidth error={!!errors.otbsexamcenteraddress}>
                  <TextField
                    sx={{ textAlign: "start" }}
                    inputProps={{
                      style: {
                        height: "auto",
                        boxSizing: "border-box",
                        textAlign: "start",
                        whiteSpace: "normal",
                        padding: "10px",
                      },
                    }}
                    fullWidth
                    multiline
                    // rows={2}
                    // maxRows={4}
                    type="text"
                    //   label={
                    //     <Typography>
                    //   Select Examintion Center Address
                    //   <span className="error" style={{ color: "red" }}>
                    //     {" "}
                    //     *
                    //   </span>
                    // </Typography>
                    //   }
                    // placeholder="Enter otbsexamcenteraddress"
                    {...register("otbsexamcenteraddress", {})}
                    value={
                      `${
                        otbsVenueDetails?.Venues?.find(
                          (obj) => obj.venueid == watch("venueid")
                        )?.address1 || ""
                      }
                      ${
                        otbsVenueDetails?.Venues?.find(
                          (obj) => obj.venueid == watch("venueid")
                        )?.address2
                          ? ","
                          : ""
                      }
                      ${
                        otbsVenueDetails?.Venues?.find(
                          (obj) => obj.venueid == watch("venueid")
                        )?.address2 || ""
                      } ${
                        otbsVenueDetails?.Venues?.find(
                          (obj) => obj.venueid == watch("venueid")
                        )?.landmark
                          ? ","
                          : ""
                      }
                      ${
                        otbsVenueDetails?.Venues?.find(
                          (obj) => obj.venueid == watch("venueid")
                        )?.landmark || ""
                      } ${
                        otbsVenueDetails?.Venues?.find(
                          (obj) => obj.venueid == watch("venueid")
                        )?.pincode
                          ? ","
                          : ""
                      }${
                        otbsVenueDetails?.Venues?.find(
                          (obj) => obj.venueid == watch("venueid")
                        )?.pincode || ""
                      }` ?? ""
                    }
                    disabled={true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      textAlign: "start",
                    }}
                  />
                  {errors.otbsexamcenteraddress && (
                    <small
                      className="error"
                      style={{ margin: 0, marginTop: ".2rem" }}
                    >
                      {errors.otbsexamcenteraddress.message}
                    </small>
                  )}
                </FormControl>
                {/* <Input aria-label="" readOnly multiline  value={otbsVenueDetails?.Venues?.find(obj=>obj.venueid == watch("venueid"))?.address1 ?? ""}  />; */}
              </Grid>
            </Grid>
          </>
        )}
        <Grid container spacing={2} mt={1}>
          <Grid
            item
            md={12}
            pt={2}
            pb={2}
            sx={{
              display: "flex",
              gap: "8px",
              justifyContent: "space-between",
              flexDirection: isMobile ? "column" : null,
            }}
          >
            <BackButton handleBack={handleBack} />
            <SubmitButton
              disabled={isPaid === "1"}
              name={buttonLoader ? "loader" : "Save and Proceed"}
              handleSubmit={handleSubmit(handleProcced, onError)}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          position: "fixed",
          top: 0,
          left: 0,
          // backgroundColor: '#21212173',
          // backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
          zIndex: 9999,
        }}
      >
        <CircularProgress
          sx={{ color: `${cssProperties?.color?.primary}` }}
          size={70}
          thickness={4.5}
        />
      </Box>
    );
  }
}
