// import { Grid, Typography } from "@mui/material";
// import React, { useState } from "react";
// import { CiSearch } from "react-icons/ci";
// import Searchbar from "../../common/searchbar";
// import CourseList from "./courseList";
// import { useDispatch, useSelector } from "react-redux";
// import candidatesActions from "../../redux/candidates/actions";

// export default function Index() {
//   const dispatch = useDispatch();
//   const [searchText, setSearchText] = useState("");
//   const { courseList } = useSelector((state) => state.candidatesReducer);
//   const handleSearch = (e) => {
//     let filtered = [];
//     setSearchText(e.target.value);
//     if(e.target.value){
//       filtered = courseList?.all?.filter(val => val?.coursename.toLowerCase().includes(e.target.value?.toLowerCase())) 
         
//     }else {
//       filtered = [...courseList?.all]
//     }
//     dispatch({ type: candidatesActions.SET_ALL_COURSE_LIST, payload: { ...courseList, filtered: filtered } });
//   }


//   return (
//     <Grid container >
//       <Grid item lg={12} md={12} sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
//         <Typography sx={{fontWeight:"bold",fontSize:"20px",color:" #504E50"}} pl={2}>CET Registration</Typography>
//         <Searchbar handleSearch={handleSearch} value={searchText}  />
//       </Grid>
//       <Grid item lg={12} md={12} sx={{width:'100%'}}>
//         <CourseList />
//       </Grid>
//     </Grid>
//   );
// }


import { Grid, Typography, Box } from "@mui/material";
import React, { useState } from "react";
import Searchbar from "../../common/searchbar";
import CourseList from "./courseList";
import { useDispatch, useSelector } from "react-redux";
import candidatesActions from "../../redux/candidates/actions";

export default function Index() {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const { courseList } = useSelector((state) => state.candidatesReducer);

  const handleSearch = (e) => {
    let filtered = [];
    setSearchText(e.target.value);
    if (e.target.value) {
      filtered = courseList?.all?.filter(val =>
        val?.coursename.toLowerCase().includes(e.target.value?.toLowerCase())
      );
    } else {
      filtered = [...courseList?.all];
    }
    dispatch({
      type: candidatesActions.SET_ALL_COURSE_LIST,
      payload: { ...courseList, filtered: filtered },
    });
  };

  const isResultsEmpty = courseList?.filtered?.length === 0 && searchText;

  return (
    <Grid container>
      <Grid
        item
        lg={6}
        sm={6}
        xs={12}
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: "20px", color: " #504E50" }} pl={2}>
          CET Registration
        </Typography>
      </Grid>
      <Grid
        item
        lg={6}
        sm={6}
        xs={12}
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <Searchbar   handleSearch={handleSearch} value={searchText} />

      </Grid>
      <Grid item lg={12} md={12} sx={{ width: "100%" }}>
        {isResultsEmpty ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px", 
            }}
          >
            <Typography sx={{ color: "red", fontWeight: "bold", textAlign: "center" }}>
              No Results Found !
            </Typography>
          </Box>
        ) : (
          <CourseList />
        )}
      </Grid>
    </Grid>
  );
}
