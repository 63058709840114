// export const API_URL = 'http://18.143.159.223:5000'; //old url
// export const API_URL = 'http://54.169.144.238:3001'; //TEST url

// export const API_URL = "http://34.93.35.227:3001"; //UAT url
// export const FRONTEND_URL = 'http://34.93.35.227:8080';
// export const BILLDESK_URL = 'http://34.93.35.227:3001';

// Local
// export const API_URL = 'http://localhost:3001';
// export const FRONTEND_URL  = 'http://localhost:3000';
// export const BILLDESK_URL = 'http://34.93.35.227:8086';
// export const KEYCLOCK_REALM = 'myrealm';
// export const KEYCLOCK_CLIENT = 'myclient';
// export const KEYCLOCK_URL = 'http://localhost:8080';
// export const KEYCLOCK_USERNAME = 'kuralnithi1999@gmail.com';

// new UAT
// export const API_URL = "http://35.207.221.237/api";
// export const FRONTEND_URL = "http://35.207.221.237";
// export const BILLDESK_URL = "http://35.207.221.237/api";

// Bill desk test
// export const API_URL = 'http://34.93.35.227:8087';
// export const FRONTEND_URL  = 'http://localhost:3000';
// export const BILLDESK_URL = 'http://34.93.35.227:8086';

// // V3 
// export const API_URL = 'https://mhcet-reg-uat.hubblehox.ai/api';
// export const FRONTEND_URL  = 'https://mhcet-reg-uat.hubblehox.ai';
// export const BILLDESK_URL = 'https://mhcet-reg-uat.hubblehox.ai/api';


// // // New Dev Server

export const API_URL = 'https://mhcet-reg-dev.hubblehox.ai/api';
export const FRONTEND_URL  = 'https://mhcet-reg-dev.hubblehox.ai';
export const BILLDESK_URL = 'https://mhcet-reg-dev.hubblehox.ai/api';

export const KEYCLOCK_REALM = 'CET_DEV_NEW';
export const KEYCLOCK_CLIENT = 'CET_DEV_NEW';
export const KEYCLOCK_USERNAME = 'admin';
export const KEYCLOCK_URL = 'https://auth.mhcet-reg-dev.hubblehox.ai';
// export const KEYCLOCK_URL = 'http://35.207.241.119:8080';

// // Test SErver

// export const API_URL = 'https://mhcet-test.praathee.in/api';
// export const FRONTEND_URL  = 'https://mhcet-test.praathee.in';
// export const BILLDESK_URL = 'https://mhcet-test.praathee.in/api';

