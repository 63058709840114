import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import masterActions from "../../redux/master/action";
import {
  percentageValidation,
  percentageNumberValidation,
  markValidation,
  cgpaValidation,
} from "../../utils/validations";
import candidatesActions from "../../redux/candidates/actions";
import { useSelector, useDispatch } from "react-redux";
import SubmitButton from "../../common/submitButton";
import SaveButton from "../../common/saveButton";
import { useNavigate } from "react-router-dom";
import BackButton from "../../common/backButton";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Cookies from "js-cookie";
import commonActions from "../../redux/common/actions";

export default function OtherDetailsForm({ preprocessFormData }) {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    control,
    trigger,
    watch,
    clearErrors,
    setError,
    formState: { errors },
    setFocus,
  } = useForm({
    mode: "onChange",
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const date = new Date();
  const currentYear = date.getFullYear();
  const [years, setYears] = useState([]);
  const [hscYears, setHscYears] = useState([]);
  const [hscStartYr, setHscStartYr] = useState(1994);
  const [disability, setDisability] = useState("");
  const [minorityDetails, setMinorityDetails] = useState("");
  const [scribe, setScribe] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [marksObtained, setMarksObtained] = useState("");
  const [marksOutOf, setMarksOutOf] = useState("");

  const watchMarksObtained = watch("hscmarksobtained");
  const watchMarksOutOf = watch("hscmarksoutof");
  const ismhcandidate = watch("ismhcandidate");

  const onError = (errors) => {
    const fError = Object.keys(errors)[0];
    if (fError) {
      setFocus(fError);
      document.getElementsByName(fError)[0]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };
  const handleDisability = (e) => {

    setValue("isdisability", parseInt(e.target.value));
    setDisability(e.target.value);
    dispatch({
      type: masterActions.SET_PWDSUB_TYPE,
      payload: [],
    });
    trigger("isdisability");

    setValue("disabilitytypeid", "");
    setValue("subdisabilitytypeid", "");
    setValue("isscriberequired", "");
    setValue("isextratimerequired", "");
  };

  const handleMinority = (e) => {
    setMinorityDetails(e.target.value);
    // trigger("isminority");
    if (e.target.value === 0) {
      setValue("linguisticminorityid", "");
      setValue("religiousminorityid", "");
    }
  };

  // const handleScribe = (e) => {
  //   setValue("isscriberequired", parseInt(e.target.value));
  //   setScribe(e.target.value);
  //   trigger("isscriberequired");
  //   if (e.target.value === 0) {
  //     setValue("isextratimerequired", "");
  //   }
  // };

  const handleBack = () => {
    Cookies.remove("draftclicked");
    navigate(`/${Cookies.get("mhet_cnd_project")}/registration?page=address`);
  };

  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const { draftButtonLoader } = useSelector((state) => state.commonReducer);
  const { minority, pwdType, pwdSubType, board, state } = useSelector(
    (state) => state.masterReducer
  );

  const [filteredStates, setFilteredStates] = useState(state);

  const watchedBoardId = useWatch({ control, name: "sscboardid" });
  useEffect(() => {
    console.log("isscriberequired----", watch("isscriberequired"));

    const selectedBoard = board.find((b) => b.boardid === watchedBoardId);
    if (selectedBoard) {
      const boardStateId = selectedBoard.boardstateid;
      const filteredStates = state.filter(
        (s) => s.boardstateid === boardStateId
      );
      setFilteredStates(filteredStates);
    } else {
      setFilteredStates(state);
      setValue("sscschoolstateid", "");
    }
  }, [watchedBoardId, board, state, setValue]);

  // useEffect(() => {
  //   // console.log('hscp gyear----',hscStartYr);
  //   // Check if markstype is not 2
  //   if (watch("marktype") === 2) {
  //     if (watchMarksObtained && watchMarksOutOf) {
  //       const obtained = parseFloat(watchMarksObtained);
  //       const outOf = parseFloat(watchMarksOutOf);
  //       if (!isNaN(obtained) && !isNaN(outOf) && outOf > 0) {
  //         const percentage = (obtained / outOf) * 100;
  //         setValue("hscpercentage", percentage.toFixed(2));
  //       } else {
  //         setValue("hscpercentage", "");
  //       }
  //     }
  //   } else {
  //     // Clear the percentage value if markstype is 2
  //     setValue("hscpercentage", "");
  //   }
  // }, [watchMarksObtained, watchMarksOutOf, setValue, watch]);

  // useEffect(() => {
  //   // Check if markstype is not 2
  //   if (watch("marktype") === 2) {
  //     if (watchMarksObtained && watchMarksOutOf) {
  //       const obtained = parseFloat(watchMarksObtained);
  //       const outOf = parseFloat(watchMarksOutOf);

  //       if (!isNaN(obtained) && !isNaN(outOf) && outOf > 0) {
  //         let percentage = (obtained / outOf) * 100;

  //         // Ensure percentage is not greater than 100
  //         if (percentage > 100) {
  //           percentage = 0;
  //         }

  //         setValue("hscpercentage", percentage.toFixed(2));
  //       } else {
  //         setValue("hscpercentage", "");
  //       }
  //     }
  //   } else {
  //     // Clear the percentage value if markstype is not 2
  //     setValue("hscpercentage", "");
  //   }
  // }, [watchMarksObtained, watchMarksOutOf, setValue, watch]);

  const { candidateTokenDetails } = useSelector((state) => state.authReducer);
  const candidateid = candidateTokenDetails?.candidateid;
  const { uploadDetails } = useSelector((state) => state.candidatesReducer);
  const { otherDetails } = useSelector((state) => state.candidatesReducer);

  const candidateotherdetailsid = otherDetails[0]?.candidateotherdetailsid;
  const candidateotherdetailsdraftid =
    otherDetails[0]?.candidateotherdetailsdraftid;

  useEffect(() => {
    if (otherDetails.length > 0) {
      if (otherDetails[0].status === 2 || otherDetails[0].status === 1) {
        const draftclicked = Cookies.get("draftclicked");

        draftclicked ? setDisabled(false) : setDisabled(true);

        // setDisabled(true);
      } else {
        setDisabled(false);
      }
      reset({ ...otherDetails[0] });

      setValue("linguisticminorityid", otherDetails[0]?.linguisticminorityid);
      setValue("isminority", otherDetails[0]?.isminority);
      setValue("isscriberequired", parseInt(otherDetails[0]?.isscriberequired));
      setValue(
        "isextratimerequired",
        parseInt(otherDetails[0]?.isextratimerequired)
      );
      setTimeout(() => {
        setValue(
          "disabilitytypeid",
          getValues("isdisability") == 0
            ? ""
            : otherDetails[0]?.disabilitytypeid
        );
        setValue(
          "subdisabilitytypeid",
          getValues("isdisability") == 0
            ? ""
            : otherDetails[0]?.subdisabilitytypeid
        );

        if (
          getValues("disabilitytypeid") != null ||
          getValues("disabilitytypeid") != ""
        ) {
          dispatch({
            type: masterActions.GET_PWDSUB_TYPE,
            payload: { pwdTypeID: parseInt(getValues("disabilitytypeid")) },
          });
        }
      }, 1000);
    }
  }, [otherDetails]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: masterActions.GET_CATEGORY });
    dispatch({ type: masterActions.GET_CASTE });
    dispatch({ type: masterActions.GET_PWD_TYPE });
    // dispatch({ type: masterActions.GET_PWDSUB_TYPE });

    dispatch({ type: masterActions.GET_BOARD });
    dispatch({ type: masterActions.GET_STATE });
    dispatch({ type: masterActions.GET_MINORITY });
    if (candidateid) {
      dispatch({ type: candidatesActions.GET_OTHER_PERSONAL_DETAILS });
    }
  }, [candidateid]);

  useEffect(() => {
    const generateYears = () => {
      const currentYear = new Date().getFullYear();
      const yearsArray = [];
      for (let i = 1960; i <= currentYear; i++) {
        yearsArray.push(i);
      }
      return yearsArray;
    };
    setYears(generateYears());

    const generateHscYears = () => {

      const startYear = parseInt(getValues("sscpassingyear")) + 2 ?? hscStartYr;
      // const startYear = watch('sscpassingyear')
      const currentYear = new Date().getFullYear();
      const sscYearsArray = [];
      for (let i = startYear ? startYear : 1994; i <= currentYear; i++) {
        sscYearsArray.push(i);
      }
      return sscYearsArray;
    };

    setHscYears(generateHscYears());
  }, [watch("sscpassingyear")]);

  useEffect(() => {
    if (candidateid) {
      setTimeout(() => {
        dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });
      }, 1000);
    }
  }, [candidateid]);

  useEffect(() => {
    if (candidateid) {
      dispatch({
        type: commonActions.SHOW_SIDEBAR,
        payload:
          uploadDetails?.length == 0
            ? false
            : uploadDetails[0]?.candidateuploaddraftid
            ? false
            : true,
      });
    }
  }, [candidateid, uploadDetails]);

  const handleDraft = (getValues) => {
    Cookies.set("draftclicked", true);

    const values = preprocessFormData(getValues());
    let operation = candidateotherdetailsdraftid ? 1 : 0;
    // let operation = candidateotherdetailsid ? 1 : 0;
    dispatch({
      type: candidatesActions.PROFILE_OTHER_DETAILS_DRAFT_CREATION,
      payload: {
        data: {
          ...values,
          status: candidateotherdetailsid ? 2 : 1,
          operation: operation,
          candidateid: candidateid,
          ismhcandidate: ismhcandidate,
        },
      },
    });

    setEditMode(false);
  };

  const handleSave = (data) => {
    Cookies.remove("draftclicked");

    trigger("isscriberequired");
    trigger("sscpassingyear");
    // console.log( `(watch('isdisability') == 1 && watch('isscriberequired') == 2)---`,getValues('isdisability') == 1 && (getValues('isscriberequired') != 0 || getValues('isscriberequired') != 1));
    // if(getValues('isdisability') == 1 && (getValues('isscriberequired') != 0 || getValues('isscriberequired') != 1) ) return
    let operation = candidateotherdetailsid ? 1 : 0;
    dispatch({
      type: candidatesActions.PROFILE_OTHER_DETAILS_CREATION,
      payload: {
        data: {
          ...data,
          status: 2,
          candidateid: candidateid,
          operation: operation,
          hscmarksoutof: watchMarksOutOf,
          // ,
          // isextratimerequired:watch('isextratimerequired') == 1 ? 1 : watch('isextratimerequired') == 0 ? 0  : watch('isextratimerequired') == 2 ? "" :"",
          // isscriberequired:watch('isscriberequired') == 1 ? 1 : watch('isscriberequired') == 0 ? 0 : watch('isscriberequired') == 2 ? "" : ""
        },
        navigate: navigate,
      },
    });
    dispatch({ type: candidatesActions.GET_OTHER_PERSONAL_DETAILS });
  };

  const handleCancel = () => {
    navigate(`/${Cookies.get("mhet_cnd_project")}/home`);
  };


  // const subdisabilitytypeid = watch("subdisabilitytypeid");
  // const isextratimerequired = watch(" ");

  // const getScribeRequirement = () => {
  //   const selectedSubType = pwdSubType?.find(
  //     (obj) => parseInt(obj.pwdsubtypeid) === parseInt(subdisabilitytypeid)
  //   );

  //   if (selectedSubType?.scriberequired !== 2) {
  //     return selectedSubType?.scriberequired;
  //   }

  //   return isextratimerequired ?? 0;
  // };

  const outOfMarkValidation = (input) => {
    let error = null;

    if (!input || input === "") {
      return error; // No error for empty input (use required validation separately)
    }

    const inputStr = input.toString();

    if (inputStr.includes(".")) {
      return "Invalid input. Marks cannot have decimal places.";
    }

    const value = parseInt(inputStr, 10);

    if (isNaN(value)) {
      return "Invalid input. Marks can only contain numbers and a decimal point.";
    }

    const obt = parseInt(watch("hscmarksobtained"), 10);

    if (isNaN(obt)) {
      return "Please enter the obtained marks first.";
    }

    if (value < 0) {
      error = "Invalid input. Marks cannot be negative.";
    } else if (value < obt) {
      error =
        'Invalid input. "Out Of" marks cannot be less than "Obtained" marks.';
      // setValue('hscpercentage',0)
      // trigger('hscpercentage')
    } else if (inputStr.length > 4) {
      error = "Invalid input. Marks cannot have more than four digits.";
    }

    return error;
  };

  return (
    <Grid
      style={{
        padding: "20px 25px 0px 25px",
        minHeight: "60vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
        border: "1px solid #0F4F96",
      }}
    >
      <Grid>
        <Typography
          pb={2}
          variant="h4"
          sx={{
            textWrap: "wrap",
            fontSize: 20,
            fontWeight: 500,
            color: "#212121",
          }}
        >
          Other Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.ismhcandidate}>
              <TextField
                MenuProps={{
                  disableScrollLock: true,
                }}
                InputLabelProps={{
                  shrink:
                    !!watch("ismhcandidate") || watch("ismhcandidate") === 0,
                }}
                select
                variant="outlined"
                fullWidth
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                  style: { height: "56px", boxSizing: "border-box" },
                  onChange: (e) => {
                    setValue("ismhcandidate", e.target.value);
                    if (getValues("ismhcandidate") === 0) {
                      clearErrors("isminority");
                      setValue("isminority", 0);
                      setValue("linguisticminorityid", "");
                      setValue("religiousminorityid", "");
                    } else {
                      setValue("isminority", "");
                    }
                    trigger("ismhcandidate");
                    // trigger("isminority");
                    clearErrors("ismhcandidate");
                  },
                }}
                label={
                  <span>
                    Are you Domiciled in the state of Maharashtra?
                    <span className="error" style={{ color: "#666666" }}>
                      {" "}
                      *
                    </span>
                  </span>
                }
                disabled={disabled}
                id="ismhcandidate"
                {...register("ismhcandidate", {
                  required: "MH Candidate is required",
                })}
                value={watch("ismhcandidate") ?? ""}
                error={!!errors.ismhcandidate}
                helperText={
                  errors.ismhcandidate ? errors.ismhcandidate.message : ""
                }
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.isminority}>
              <TextField
                select
                label={
                  <span>
                    Minority Details
                    {!(getValues("ismhcandidate") &&
                    getValues("ismhcandidate") === 1 &&
                    !disabled
                      ? false
                      : true) && <span style={{ color: "#666666" }}> *</span>}
                  </span>
                }
                variant="outlined"
                fullWidth
                MenuProps={{
                  disableScrollLock: true,
                }}
                InputLabelProps={{
                  shrink: !!watch("isminority") || watch("isminority") === 0,
                }}
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                  style: { height: "56px", boxSizing: "border-box" },
                  onChange: (e) => {
                    setValue("isminority", e.target.value);
                    handleMinority(e);
                    // setValue("isminority", e.target.value);
                    // trigger("isminority");
                    clearErrors("isminority");
                    clearErrors("linguisticminorityid");
                    clearErrors("religiousminorityid");
                    if (e.target.value === 0) {
                      clearErrors("isminority");
                    }
                  },
                }}
                id="isminority"
                disabled={disabled || parseInt(watch("ismhcandidate")) !== 1}
                // {...register("isminority", {
                //   required:
                //     watch("ismhcandidate") == 1
                //       ? "Minority details is required"
                //       : false,
                // })}
                // {...register("isminority", {
                //   validate: (value) => {
                //     if (parseInt(watch("ismhcandidate")) === 1 && !value) {
                //       return "Minority details is required";
                //     }
                //     return true;
                //   },
                // })}
                {...register(
                  "isminority",

                  {
                    required:
                      watch("ismhcandidate") == 1
                        ? "Minority details is required"
                        : false,
                  }
                )}
                value={watch("isminority") ?? ""}
                error={!!errors.isminority}
                helperText={errors.isminority ? errors.isminority.message : ""}
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.linguisticminorityid}>
              <TextField
                select
                label={
                  <span>
                    Linguistic Minority
                    {!(getValues("isminority") &&
                    getValues("isminority") === 1 &&
                    !disabled
                      ? false
                      : true) && <span style={{ color: "#666666" }}> *</span>}
                  </span>
                }
                InputLabelProps={{
                  shrink: !!watch("linguisticminorityid"),
                }}
                variant="outlined"
                fullWidth
                MenuProps={{
                  disableScrollLock: true,
                }}
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                  style: { height: "56px", boxSizing: "border-box" },
                  onChange: (e) => {
                    setValue("linguisticminorityid", e.target.value);
                    trigger("linguisticminorityid");
                    clearErrors("linguisticminorityid");
                  },
                }}
                id="linguisticminorityid"
                {...register(
                  "linguisticminorityid",

                  {
                    required:
                      getValues("isminority") == 1
                        ? "Linguistic minority is required"
                        : false,
                  }
                )}
                disabled={
                  // disabled||
                  // parseInt(watch("isminority") === 0)
                  // ||   parseInt(watch("ismhcandidate")) !== 1

                  parseInt(watch("isminority")) &&
                  parseInt(watch("isminority")) === 1 &&
                  !disabled
                    ? false
                    : true
                  // minorityDetails === 0 || getValues("isminority") === "0"
                }
                value={watch("linguisticminorityid") ?? ""}
                error={!!errors.linguisticminorityid}
                helperText={
                  errors.linguisticminorityid
                    ? errors.linguisticminorityid.message
                    : ""
                }
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                {minorityDetails !== "0" &&
                  minority &&
                  minority.length > 0 &&
                  minority.map(
                    (item) =>
                      item.type === "Linguistic Minority" && (
                        <MenuItem
                          key={item?.minorityid}
                          value={item?.minorityid || ""}
                        >
                          {item?.minority}
                        </MenuItem>
                      )
                  )}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.religiousminorityid}>
              <TextField
                select
                label={
                  <span>
                    Religious Minority
                    {!(getValues("isminority") &&
                    getValues("isminority") === 1 &&
                    !disabled
                      ? false
                      : true) && <span style={{ color: "#666666" }}> *</span>}
                  </span>
                }
                InputLabelProps={{
                  shrink: !!watch("religiousminorityid"),
                }}
                variant="outlined"
                fullWidth
                MenuProps={{
                  disableScrollLock: true,
                }}
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                  style: { height: "56px", boxSizing: "border-box" },
                  onChange: (e) => {
                    setValue("religiousminorityid", e.target.value);
                    trigger("religiousminorityid");
                    clearErrors("religiousminorityid");
                  },
                }}
                id="religiousminorityid"
                {...register(
                  "religiousminorityid",
                  // {
                  //   required:
                  //     minorityDetails !== 0
                  //       ? "Religious minority is required"
                  //       : false,
                  // }
                  {
                    required:
                      getValues("isminority") == 1
                        ? "Religious minority is required"
                        : false,
                  }
                )}
                disabled={
                  // disabled||
                  // parseInt(watch("isminority") === 0)||
                  // parseInt(watch("ismhcandidate")) !== 1
                  parseInt(watch("isminority")) &&
                  parseInt(watch("isminority")) === 1 &&
                  !disabled
                    ? false
                    : true
                }
                value={watch("religiousminorityid") ?? ""}
                error={!!errors.religiousminorityid}
                helperText={
                  errors.religiousminorityid
                    ? errors.religiousminorityid.message
                    : ""
                }
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                {minorityDetails !== "0" &&
                  minority?.map(
                    (item) =>
                      item.type === "Religious Minority" && (
                        <MenuItem
                          key={item?.minorityid}
                          value={item?.minorityid || ""}
                        >
                          {item.minority}
                        </MenuItem>
                      )
                  )}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.isdisability}>
              <TextField
                select
                label={
                  <span>
                    Are you person with Disability?
                    <span style={{ color: "#666666" }}> *</span>
                  </span>
                }
                variant="outlined"
                fullWidth
                MenuProps={{
                  disableScrollLock: true,
                }}
                InputLabelProps={{
                  shrink:
                    !!watch("isdisability") || watch("isdisability") === 0,
                }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                id="isdisability"
                disabled={disabled}
                {...register("isdisability", {
                  required: "Disability status is required",
                })}
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                  style: { height: "56px", boxSizing: "border-box" },
                  onChange: (e) => {
                    handleDisability(e);
                    clearErrors("isdisability");
                    clearErrors("disabilitytypeid");
                    clearErrors("subdisabilitytypeid");
                    clearErrors("isscriberequired");
                    clearErrors("isextratimerequired");
                  },
                }}
                value={watch("isdisability") ?? ""}
                error={!!errors.isdisability}
                helperText={
                  errors.isdisability ? errors.isdisability.message : ""
                }
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </TextField>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.disabilitytypeid}>
              <TextField
                select
                label={
                  <span>
                    Type of Disability?
                    {!(disabled || parseInt(watch("isdisability")) !== 1) && (
                      <span style={{ color: "#666666" }}> *</span>
                    )}
                  </span>
                }
                variant="outlined"
                fullWidth
                MenuProps={{
                  disableScrollLock: true,
                }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                id="disabilitytypeid"
                {...register("disabilitytypeid", {
                  required:
                    getValues("isdisability") == 1
                      ? "Type of Disability type is required"
                      : false,
                })}
                disabled={
                  disabled || parseInt(watch("isdisability")) !== 1
                  // disability === 0 || getValues("isdisability") === "0"
                }
                InputLabelProps={{
                  shrink: !!watch("disabilitytypeid"),
                }}
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                  style: { minHeight: "56px", boxSizing: "border-box" },
                  onChange: (e) => {
                    setValue("disabilitytypeid", e.target.value);
                    trigger("disabilitytypeid");
                    setValue("subdisabilitytypeid", "");
                    setValue("isscriberequired", "");
                    setValue("isextratimerequired", "");
                    const disabilitytypeid = e.target.value;
                    if (disabilitytypeid) {
                      dispatch({
                        type: masterActions.GET_PWDSUB_TYPE,
                        payload: { pwdTypeID: disabilitytypeid },
                      });
                    }
                    clearErrors("disabilitytypeid");
                  },
                }}
                value={watch("disabilitytypeid") ?? ""}
                error={!!errors.disabilitytypeid}
                helperText={
                  errors.disabilitytypeid ? errors.disabilitytypeid.message : ""
                }
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                {disability !== "0" &&
                  pwdType?.map((val, i) => (
                    <MenuItem value={val?.pwdtypeid} key={i}>
                         <span style={{ textWrap: "wrap" }}>
                      {val?.pwdtype}
                      </span>
                    </MenuItem>
                  ))}
              </TextField>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.subdisabilitytypeid}>
              <TextField
                InputLabelProps={{
                  shrink: !!watch("subdisabilitytypeid"),
                }}
                select
                label={
                  <span>
                    SubType of Disability?
                    {!(
                      disabled ||
                      watch("isdisability") == 0 ||
                      getValues("disabilitytypeid") === ""
                    ) && <span style={{ color: "#666666" }}> *</span>}{" "}
                  </span>
                }
                variant="outlined"
                fullWidth
                MenuProps={{
                  disableScrollLock: true,
                }}
                inputProps={{
                  style: { minHeight: "56px", boxSizing: "border-box" },
                }}
                id="subdisabilitytypeid"
                {...register("subdisabilitytypeid", {
                  required:
                    getValues("isdisability") == 1
                      ? "Disability type is required"
                      : false,
                })}
                disabled={
                  disabled ||
                  watch("isdisability") == 0 ||
                  getValues("disabilitytypeid") === ""
                }
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                  style: { height: "56px", boxSizing: "border-box" },
                  onChange: (e) => {
                    setValue("subdisabilitytypeid", e.target.value);
                    setValue("isscriberequired", "");
                    setValue("isextratimerequired", "");
                    trigger("subdisabilitytypeid");
                    let extratimerequiredvalue = pwdSubType?.find(
                      (obj) =>
                        parseInt(obj.pwdsubtypeid) === parseInt(e.target.value)
                    )?.extratimerequired;
                    extratimerequiredvalue !== 2 &&
                      setValue("isextratimerequired", extratimerequiredvalue);
                      clearErrors("isextratimerequired")
                    let isscriberequiredvalue = pwdSubType?.find(
                      (obj) =>
                        parseInt(obj.pwdsubtypeid) === parseInt(e.target.value)
                    )?.scriberequired;
                    isscriberequiredvalue !== 2 &&
                      setValue("isscriberequired", isscriberequiredvalue);
                      clearErrors("isscriberequired")
                  
                    clearErrors("subdisabilitytypeid");
                  },
                }}
                value={watch("subdisabilitytypeid") ?? ""}
                error={!!errors.subdisabilitytypeid}
                helperText={
                  errors.subdisabilitytypeid
                    ? errors.subdisabilitytypeid.message
                    : ""
                }
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>

                {pwdSubType?.map((val, i) => (
                  <MenuItem value={val?.pwdsubtypeid} key={i}>
                     <span style={{ textWrap: "wrap" }}>
                    {val?.pwdsubtype}
                    </span>
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.isscriberequired}>
              <TextField
                select
                label={
                  <span>
                    Do You Require Scribe?
                    {!(
                      disabled ||
                      (watch("subdisabilitytypeid")
                        ? pwdSubType.find(
                            (obj) =>
                              parseInt(obj.pwdsubtypeid) ===
                              parseInt(watch("subdisabilitytypeid"))
                          )?.scriberequired !== 2
                        : true)
                    ) && <span style={{ color: "#666666" }}> *</span>}
                  </span>
                }
                InputLabelProps={{
                  shrink:
                    watch("isscriberequired") ||
                    parseInt(watch("isscriberequired")) === 0,
                }}
                variant="outlined"
                fullWidth
                MenuProps={{
                  disableScrollLock: true,
                }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                id="isscriberequired"
                disabled={
                  disabled ||
                  (watch("subdisabilitytypeid")
                    ? pwdSubType.find(
                        (obj) =>
                          parseInt(obj.pwdsubtypeid) ===
                          parseInt(watch("subdisabilitytypeid"))
                      )?.scriberequired !== 2
                    : true)
                }
                {...register("isscriberequired", {
                  required:
                    watch("isdisability") == 1
                      ? "Scribe field is required"
                      : false,
                })}
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                  style: { height: "56px", boxSizing: "border-box" },
                  onChange: (e) => {
                    setValue("isscriberequired", e.target.value);
                    trigger("isscriberequired");
                    clearErrors("isscriberequired");
                  },
                }}
                value={
                  watch("isscriberequired") ?? ""
                  //   //   ? watch("isscriberequired")
                  //   //   :
                }
                error={!!errors.isscriberequired}
                helperText={
                  errors.isscriberequired ? errors.isscriberequired.message : ""
                }
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.isextratimerequired}>
              <TextField
                select
                label={
                  <span>
                    Do You Require Extra Time?
                    {!(
                      disabled ||
                      (watch("subdisabilitytypeid")
                        ? pwdSubType.find(
                            (obj) =>
                              parseInt(obj.pwdsubtypeid) ===
                              parseInt(watch("subdisabilitytypeid"))
                          )?.extratimerequired !== 2
                        : true)
                    ) && <span style={{ color: "#666666" }}> *</span>}
                  </span>
                }
                InputLabelProps={{
                  shrink:
                    !!watch("isextratimerequired") ||
                    watch("isextratimerequired") === 0,
                }}
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                  style: { height: "56px", boxSizing: "border-box" },
                  onChange: (e) => {
                    setValue("isextratimerequired", e.target.value);
                    trigger("isextratimerequired");
                    clearErrors("isextratimerequired");
                  },
                }}
                variant="outlined"
                fullWidth
                MenuProps={{
                  disableScrollLock: true,
                }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                id="isextratimerequired"
                {...register("isextratimerequired", {
                  required:
                    watch("isdisability") == 1
                      ? "Extra time is required"
                      : false,
                })}
                disabled={
                  disabled ||
                  (watch("subdisabilitytypeid")
                    ? pwdSubType.find(
                        (obj) =>
                          parseInt(obj.pwdsubtypeid) ===
                          parseInt(watch("subdisabilitytypeid"))
                      )?.extratimerequired !== 2
                    : true)
                }
                value={watch("isextratimerequired") ?? ""}
                error={!!errors.isextratimerequired}
                helperText={
                  errors.isextratimerequired
                    ? errors.isextratimerequired.message
                    : ""
                }
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
        </Grid>

        <Typography
          pb={2}
          pt={2}
          variant="h4"
          sx={{
            textWrap: "wrap",
            fontSize: 20,
            fontWeight: 500,
            color: "#212121",
          }}
        >
          Education Details
        </Typography>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.sscpassingyear}>
              <InputLabel
                shrink={!!watch("sscpassingyear")}
                htmlFor="sscpassingyear"
              >
                SSC Passing Year{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select
                MenuProps={{
                  disableScrollLock: true,
                }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                id="sscpassingyear"
                label="SSC Passing Year"
                disabled={disabled}
                {...register("sscpassingyear", {
                  required: "SSC Passing Year is required",
                })}
                onChange={(e) => {
                  setValue("sscpassingyear", e.target.value);
                  setValue("hscpassingyear", "");
                  setHscStartYr(parseInt(e.target.value));
                  trigger("sscpassingyear");
                  console.log("hscStartYr-----", hscStartYr);
                }}
                value={watch("sscpassingyear") ?? ""}
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                {years?.map((year, index) => (
                  <MenuItem key={index} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
              {errors.sscpassingyear && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.sscpassingyear.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid> */}
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              error={!!errors.sscpassingyear}
              variant="outlined"
            >
              <TextField
                select
                label={
                  <span>
                    SSC Passing Year
                    <span style={{ color: "#666666" }}> *</span>
                  </span>
                }
                variant="outlined"
                fullWidth
                value={watch("sscpassingyear") ?? ""}
                {...register("sscpassingyear", {
                  required: "SSC Passing Year is required",
                })}
                InputLabelProps={{
                  shrink: !!watch("sscpassingyear"),
                }}
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                  style: { height: "56px", boxSizing: "border-box" },
                  onChange: (e) => {
                    setValue("sscpassingyear", e.target.value);
                    setValue("hscpassingyear", "");
                    setHscStartYr(parseInt(e.target.value));
                    trigger("sscpassingyear");
                    clearErrors("sscpassingyear");
                  },
                }}
                disabled={disabled}
                error={!!errors.sscpassingyear}
                helperText={
                  errors.sscpassingyear ? errors.sscpassingyear.message : ""
                }
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                {/* {years?.map((year, index) => (
                  <MenuItem key={index} value={year}>
                    {year}
                  </MenuItem>
                ))} */}
                {Array.from(
                  { length: new Date().getFullYear() - 1945 },
                  (_, i) => 1945 + i
                ).map((year, index) => (
                  <MenuItem key={index} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
              }}
              fullWidth
              disabled={disabled}
              variant="outlined"
              type="text"
              label={
                <span>
                  SSC Total percentage
                  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              placeholder="Enter the SSC Total percentage"
              {...register("ssctotalpercentage", {
                required: "\u00A0 \u00A0 SSC Total percentage % is required",
                validate: percentageValidation,
              })}
              onInput={percentageNumberValidation}
              error={!!errors.ssctotalpercentage}
              helperText={errors.ssctotalpercentage?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("ssctotalpercentage"),
              }}
            />
            <Box>
              <small style={{ color: "#666666" }}>
                Up to 2 decimal places are allowed
              </small>
            </Box>
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined" error={!!errors.sscboardid}>
              <InputLabel
                shrink={!!watch("sscboardid") || watch("sscboardid") === 0}
                htmlFor="sscboardid"
              >
                SSC Board{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select
                MenuProps={{
                  disableScrollLock: true,
                }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                disabled={disabled}
                id="sscboardid"
                label="SSC Board"
                {...register("sscboardid", {
                  required: "SSC Board is required",
                })}
                onChange={(e) => {
                  const id = e.target.value;
                  const filterId = board?.find(
                    (row) => row.boardid === parseInt(id)
                  );
                  const finalId = filterId.boardstateid;
                  setValue("sscschoolstateid", finalId);
                  setValue("sscboardid", id);
                  clearErrors("sscboardid");
                }}
                value={watch("sscboardid") >= 0 ? watch("sscboardid") : ""}
              >
                {board?.map((val, i) => (
                  <MenuItem value={val?.boardid} key={i}>
                    {val?.board}
                  </MenuItem>
                ))}
              </Select>
              {errors.sscboardid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.sscboardid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid> */}
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              error={!!errors.sscboardid}
              variant="outlined"
            >
              <TextField
                disabled={disabled}
                select
                label={
                  <span>
                    SSC Board
                    <span style={{ color: "#666666" }}> *</span>
                  </span>
                }
                variant="outlined"
                fullWidth
                value={watch("sscboardid") >= 0 ? watch("sscboardid") : ""}
                {...register("sscboardid", {
                  required: "SSC Board is required",
                })}
                InputLabelProps={{
                  shrink: !!watch("sscboardid") || watch("sscboardid") === 0,
                }}
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                  style: { minHeight: "56px", boxSizing: "border-box" },
                  onChange: (e) => {
                    const id = e.target.value;
                    const filterId = board?.find(
                      (row) => row.boardid === parseInt(id)
                    );

                    const finalId = filterId?.boardstateid;

                    setValue(
                      "sscschoolstateid",
                      finalId == -2 ? null : finalId
                    );
                    setValue("sscboardid", id);
                    clearErrors("sscboardid");
                    clearErrors("sscschoolstateid");
                  },
                }}
                error={!!errors.sscboardid}
                helperText={errors.sscboardid ? errors.sscboardid.message : ""}
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                {board?.map((val, i) => (
                  <MenuItem style={{ textWrap: "wrap" }} value={val?.boardid} key={i}>
                     <span style={{ textWrap: "wrap" }}>
                    {val?.board}
                    </span>
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.sscschoolstateid}>
              <InputLabel
                shrink={
                  !!watch("sscschoolstateid") || watch("sscschoolstateid") === 0
                }
                htmlFor="sscschoolstateid"
              >
                SSC School State{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select
                MenuProps={{
                  disableScrollLock: true,
                }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                disabled={disabled}
                id="sscschoolstateid"
                label="SSC School State"
                {...register("sscschoolstateid", {
                  required: "SSC School State is required",
                })}
                onChange={(e) => {
                  setValue("sscschoolstateid", e.target.value);
                  trigger("sscschoolstateid");
                }}
                value={watch("sscschoolstateid") ?? ""}
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                {state?.map((val, i) => (
                  <MenuItem value={val?.stateid} key={i}>
                    {val?.state}
                  </MenuItem>
                ))}
              </Select>
              {errors.sscschoolstateid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.sscschoolstateid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid> */}
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              error={!!errors.sscschoolstateid}
              variant="outlined"
            >
              <TextField
                disabled={disabled}
                select
                label={
                  <span>
                    SSC School State
                    <span style={{ color: "#666666" }}> *</span>
                  </span>
                }
                variant="outlined"
                fullWidth
                value={watch("sscschoolstateid") ?? null}
                {...register("sscschoolstateid", {
                  required: "SSC School State is required",
                })}
                InputLabelProps={{
                  shrink:
                    !!watch("sscschoolstateid") ||
                    watch("sscschoolstateid") === 0,
                }}
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                  style: { height: "56px", boxSizing: "border-box" },
                  onChange: (e) => {
                    setValue("sscschoolstateid", e.target.value);
                    trigger("sscschoolstateid");
                    clearErrors("sscschoolstateid");
                 
                  },
                }}
                error={!!errors.sscschoolstateid}
                helperText={
                  errors.sscschoolstateid ? errors.sscschoolstateid.message : ""
                }
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                {state?.map((val, i) => (
                  <MenuItem value={val?.stateid} key={i}>
                    {val?.state}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.appearingfor}>
              <InputLabel
                shrink={!!watch("appearingfor") || parseInt(watch("appearingfor")) === 0}
                htmlFor="appearingfor"
              >
                Are you Appearing/Appeared for HSC / Diploma exam in 2025?{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select
                MenuProps={{
                  disableScrollLock: true,
                }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                disabled={disabled}
                id="appearingfor"
                label={
                  <>
                    <span>
                      Are you appearing/applied for HSC / Diploma exam in{" "}
                      {currentYear}?
                    </span>
                    <span className="error" style={{ color: "#666666" }}>
                      {" "}
                      *
                    </span>
                  </>
                }
                {...register("appearingfor", {
                  required: "appearing/applied for  HSC / Diploma is required",
                })}
                onChange={(e) => {
                  setValue("appearingfor", e.target.value);
                  trigger("appearingfor");
                  if (e.target.value === 1) {
                    setValue("hscboardid", "");
                    setValue("hscpassingyear", "");
                    setValue("marktype", "");
                    setValue("hscmarksobtained", "");
                    setValue("hscmarksoutof", "");
                    setValue("hscpercentage", "");
                  }
                }}
                value={watch("appearingfor") ?? ""}
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
              {errors.appearingfor && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.appearingfor.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid> */}
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              error={!!errors.appearingfor}
              variant="outlined"
            >
              <TextField
                disabled={disabled}
                select
                label={
                  <span>
                    Are you Appearing/Appeared for HSC / Diploma exam in{" "}
                    {currentYear + 1}?
                    <span style={{ color: "#666666" }}> *</span>
                  </span>
                }
                variant="outlined"
                fullWidth
                value={watch("appearingfor") ?? ""}
                {...register("appearingfor", {
                  required: "Appearing/Appeared for HSC / Diploma is required",
                })}
                InputLabelProps={{
                  shrink:
                    !!watch("appearingfor") ||
                    parseInt(watch("appearingfor")) === 0,
                }}
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                  style: { height: "56px", boxSizing: "border-box" },
                  onChange: (e) => {
                    setValue("appearingfor", e.target.value);
                    trigger("appearingfor");
                    if (e.target.value === 1) {
                      setValue("hscboardid", "");
                      setValue("hscpassingyear", "");
                      setValue("marktype", "");
                      setValue("hscmarksobtained", "");
                      setValue("hscmarksoutof", "");
                      setValue("hscpercentage", "");
                      clearErrors("marktype");
                      clearErrors("hscmarksobtained");
                      clearErrors("hscmarksoutof");
                      clearErrors("hscpercentage");
                      clearErrors("hscpassingyear");
                    }
                  },
                }}
                error={!!errors.appearingfor}
                helperText={
                  errors.appearingfor ? errors.appearingfor.message : ""
                }
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </TextField>
            </FormControl>
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.hscpassingyear}>
              <InputLabel
                shrink={!!watch("hscpassingyear")}
                htmlFor="hscpassingyear"
              >
                HSC / Diploma Passing Year
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select
                MenuProps={{
                  disableScrollLock: true,
                }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                id="hscpassingyear"
                label="HSC / Diploma Passing Year"
                disabled={disabled || parseInt(watch("appearingfor")) === 1}
                {...register("hscpassingyear", {
                  required:
                    parseInt(watch("appearingfor")) === 0
                      ? "HSC Passing Year is required"
                      : false,
                })}
                onChange={(e) => {
                  setValue("hscpassingyear", e.target.value);
                  trigger("hscpassingyear");
                }}
                value={watch("hscpassingyear") ?? ""}
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                {hscYears.map((year, index) => (
                  <MenuItem key={index} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
              {errors.hscpassingyear && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.hscpassingyear.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid> */}
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              error={!!errors.hscpassingyear}
              variant="outlined"
            >
              <TextField
                select
                label={
                  <span>
                    HSC / Diploma Passing Year
                    {!(parseInt(watch("appearingfor")) === 1) && (
                      <span style={{ color: "#666666" }}> *</span>
                    )}
                  </span>
                }
                variant="outlined"
                fullWidth
                value={watch("hscpassingyear") ?? ""}
                {...register("hscpassingyear", {
                  required:
                    parseInt(watch("appearingfor")) === 0
                      ? "HSC /Diploma Passing Year is required"
                      : false,
                })}
                InputLabelProps={{
                  shrink: !!watch("hscpassingyear"),
                }}
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                  style: { height: "56px", boxSizing: "border-box" },
                  onChange: (e) => {
                    setValue("hscpassingyear", e.target.value);
                    trigger("hscpassingyear");
                    clearErrors("hscpassingyear");
                  },
                }}
                disabled={disabled || parseInt(watch("appearingfor")) === 1}
                error={!!errors.hscpassingyear}
                helperText={
                  errors.hscpassingyear ? errors.hscpassingyear.message : ""
                }
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                {/* {hscYears.map((year, index) => (
                  <MenuItem key={index} value={year}>
                    {year}
                  </MenuItem>
                ))} */}
                {watch("sscpassingyear") &&
                  Array.from(
                    {
                      length:
                        new Date().getFullYear() -
                        (parseInt(watch("sscpassingyear")) + 1),
                    },
                    (_, i) => parseInt(watch("sscpassingyear")) + 2 + i
                  )?.map((year, index) => (
                    <MenuItem key={index} value={year}>
                      {year}
                    </MenuItem>
                  ))}
              </TextField>
            </FormControl>
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.hscboardid}>
              <InputLabel
                shrink={!!watch("hscboardid") || watch("hscboardid") === 0}
                htmlFor="hscboardid"
              >
                HSC / Diploma Board{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select
                MenuProps={{
                  disableScrollLock: true,
                }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                id="hscboardid"
                label="HSC / Diploma Board"
                {...register("hscboardid", {
                  required:
                    // parseInt(watch("appearingfor")) === 0
                    " HSC / Diploma  Board is required",
                  // : false,
                })}
                onChange={(e) => {
                  setValue("hscboardid", e.target.value);
                  trigger("hscboardid");
                }}
                value={watch("hscboardid") ?? ""}
                disabled={disabled}
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                {board?.map((val, i) => (
                  <MenuItem value={val?.boardid} key={i}>
                    {val?.board}
                  </MenuItem>
                ))}
              </Select>
              {errors.hscboardid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.hscboardid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid> */}
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              error={!!errors.hscboardid}
              variant="outlined"
            >
              <TextField
                select
                label={
                  <span>
                    HSC / Diploma Board
                    <span style={{ color: "#666666" }}> *</span>
                  </span>
                }
                variant="outlined"
                fullWidth
                value={watch("hscboardid") ?? ""}
                {...register("hscboardid", {
                  required: "HSC / Diploma Board is required",
                })}
                InputLabelProps={{
                  shrink: !!watch("hscboardid") || watch("hscboardid") === 0, // Manages label floating behavior
                }}
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                  style: { minHeight: "56px", boxSizing: "border-box" },
                  onChange: (e) => {
                    setValue("hscboardid", e.target.value);
                    trigger("hscboardid");
                    clearErrors("hscboardid");
                  },
                }}
                disabled={disabled}
                error={!!errors.hscboardid}
                helperText={errors.hscboardid ? errors.hscboardid.message : ""}
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                {board?.map((val, i) => (
                  <MenuItem value={val?.boardid} key={i}>
                     <span style={{ textWrap: "wrap" }}>
                    {val?.board}
                    </span>
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.marktype}>
              <InputLabel shrink={!!watch("marktype")} htmlFor="marktype">
                Mark Type{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select
                MenuProps={{
                  disableScrollLock: true,
                }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                id="marktype"
                label="Mark Type"
                {...register("marktype", {
                  required:
                    parseInt(watch("appearingfor")) === 0
                      ? "Mark Type is required"
                      : false,
                })}
                onChange={(e) => {
                  setValue("marktype", e.target.value);
                  setValue("hscmarksobtained", "");
                  setValue("hscmarksoutof", "");
                  setValue("hscpercentage", "");
                }}
                value={watch("marktype") ?? ""}
                disabled={disabled || parseInt(watch("appearingfor")) != 0}
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                <MenuItem value={1}>CGPA</MenuItem>
                <MenuItem value={2}>Percentage</MenuItem>
              </Select>
              {errors.marktype && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.marktype.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid> */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.marktype} variant="outlined">
              <TextField
                select
                label={
                  <span>
                    Mark Type
                    {!(parseInt(watch("appearingfor")) !== 0) && (
                      <span style={{ color: "#666666" }}> *</span>
                    )}
                  </span>
                }
                variant="outlined"
                fullWidth
                value={watch("marktype") ?? ""}
                {...register("marktype", {
                  required:
                    parseInt(watch("appearingfor")) === 0
                      ? "Mark Type is required"
                      : false,
                })}
                InputLabelProps={{
                  shrink: !!watch("marktype"),
                }}
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                  style: { height: "56px", boxSizing: "border-box" },
                  onChange: (e) => {
                    setValue("marktype", e.target.value);
                    setValue("hscmarksobtained", "");
                    setValue("hscmarksoutof", "");
                    setValue("hscpercentage", "");
                    clearErrors("marktype");
                    clearErrors("hscmarksobtained");
                    clearErrors("hscmarksoutof");
                  },
                }}
                disabled={disabled || parseInt(watch("appearingfor")) !== 0}
                error={!!errors.marktype}
                helperText={errors.marktype ? errors.marktype.message : ""}
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                <MenuItem value={1}>CGPA</MenuItem>
                <MenuItem value={2}>Percentage</MenuItem>
              </TextField>
            </FormControl>
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              disabled={
                disabled ||
                parseInt(watch("appearingfor")) === 1 ||
                watch("marktype") === ""
              }
              variant="outlined"
              type="text"
              label={
                <span>
                  HSC / Diploma Marks / CGPA Obtained
                  {!(disabled ||
                    parseInt(watch("appearingfor")) === 1 ||
                    watch("marktype") === "") && <span className="error" style={{ color: "#666666" }}>
                      {" "}
                      *
                    </span>}
                </span>
              }
              placeholder="Enter the HSC / Diploma Marks / CGPA Obtained"
              {...register("hscmarksobtained", {
                required:
                  parseInt(watch("appearingfor")) === 0
                    ? "HSC / Diploma Marks / CGPA Obtained is required"
                    : false,
                validate:
                  watch("marktype") === 1
                    ? cgpaValidation
                    : markValidation,

              })}
              onInput={
                watch("marktype") === 1
                  ? cgpaValidation
                  : markValidation
              }
              onChange={(e) => {
                clearErrors("hscmarksobtained")
                setValue("hscmarksobtained", e.target.value);
                if (watch("marktype") === 2) {
                  percentageValidation();
                } else {
                  cgpaValidation();
                }
                const value = parseInt(e.target.value);
                const maxValue = parseInt(getValues("hscmarksoutof"));
                const maxAllowed = maxValue <= 900 ? maxValue : 900;
                if (value > maxAllowed) {
                  e.target.value = maxAllowed;
                }
                let percentage = (value / maxValue) * 100;
                watch("marktype") == 2 &&
                  setValue(
                    "hscpercentage",
                    !isNaN(percentage) ? parseFloat(percentage).toFixed(2) : ""
                  );
                let cgpa = (value / maxValue) * 100
                watch("marktype") == 1 &&
                  setValue(
                    "hscpercentage",
                    !isNaN(cgpa) ? parseFloat(cgpa).toFixed(2) : "");
              }}
              InputProps={{ inputMode: "decimal" }}

              error={!!errors.hscmarksobtained}
              helperText={errors.hscmarksobtained?.message}
              FormHelperTextProps={{ style: { margin: 0 } }}
              InputLabelProps={{ shrink: !!watch("hscmarksobtained") }}
            />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              disabled={
                disabled ||
                parseInt(watch("appearingfor")) === 1 ||
                // watch("marktype") === ""
                !watch("marktype")
              }
              variant="outlined"
              type="text"
              label={
                <span>
                  HSC / Diploma Marks / CGPA Obtained
                  {!(
                    disabled ||
                    parseInt(watch("appearingfor")) === 1 ||
                    watch("marktype") === ""
                  ) && (
                    <span className="error" style={{ color: "#666666" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Enter the HSC / Diploma Marks / CGPA Obtained"
              {...register("hscmarksobtained", {
                required:
                  parseInt(watch("appearingfor")) === 0
                    ? "           HSC / Diploma Marks / CGPA Obtained is required"
                    : false,
                validate: (value) => {
                  return watch("marktype") === 1
                    ? cgpaValidation(value)
                    : percentageNumberValidation(value);
                },
              })}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                const maxValue = parseFloat(getValues("hscmarksoutof"));
                const maxAllowed = maxValue <= 900 ? maxValue : 900;
                clearErrors("hscmarksobtained");
                setValue("hscmarksobtained", e.target.value);
                trigger("hscmarksobtained");
                trigger("hscmarksoutof");

                if (watch("marktype") === 2) {
                  percentageValidation();
                } else {
                  const cgpaError = cgpaValidation(value);
                  if (cgpaError) {
                    setError("hscmarksobtained", {
                      type: "manual",
                      message: cgpaError,
                    });
                  }
                }

                // if (value > maxAllowed) {
                //   e.target.value = maxAllowed;
                // }

                let percentage = (value / maxValue) * 100;
                if (watch("marktype") === 2) {
                  setValue(
                    "hscpercentage",
                    !isNaN(percentage) ? percentage.toFixed(2) : ""
                  );
                }

                if (watch("marktype") === 1) {
                  let cgpa = (value / maxValue) * 100; // Example conversion for CGPA
                  setValue(
                    "hscpercentage",
                    !isNaN(cgpa) ? cgpa.toFixed(2) : ""
                  );
                }
              }}
              InputProps={{ inputMode: "decimal" }}
              error={!!errors.hscmarksobtained}
              helperText={errors.hscmarksobtained?.message}
              FormHelperTextProps={{ style: { margin: 0 } }}
              InputLabelProps={{ shrink: !!watch("hscmarksobtained") }}
            />
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <TextField
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
                inputMode: "numeric", // Helps mobile keyboards show numeric keypad
                pattern: "[0-9]*",
              }}
              fullWidth
              disabled={
                disabled ||
                parseInt(watch("appearingfor")) === 1 ||

                watch("marktype") === ""
              }
              variant="outlined"
              type="text"
              label={
                <span>
                  HSC / Diploma Marks / CGPA Out Of
                  {!(parseInt(watch("appearingfor")) === 1 ||

                    watch("marktype") === "") && <span className="error" style={{ color: "#666666" }}>
                      {" "}
                      *
                    </span>}
                </span>
              }
              placeholder="Enter the HSC / Diploma Marks / CGPA Out Of"
              {...register("hscmarksoutof", {
                required:
                  parseInt(watch("appearingfor")) === 0
                    ? "HSC / Diploma Marks / CGPA Out Of is required"
                    : false,

                validate:
                  watch("marktype") === 1
                    ? cgpaValidation
                    : outOfMarkValidation,

              })}
              onChange={(e) => {
                if (watch("marktype") === 2) {
                  percentageValidation();
                } else {
                  cgpaValidation();
                }
                clearErrors("hscmarksoutof")
                const maxValue = parseInt(e.target.value);
                const value = parseInt(getValues("hscmarksobtained"));
                const maxAllowed = maxValue <= 900 ? maxValue : 900;
                if (value > maxAllowed) {
                  e.target.value = maxAllowed;
                }
                setValue("hscmarksoutof", e.target.value);
                trigger("hscmarksobtained");

                let percentage = (value / maxValue) * 100;
                watch("marktype") == 2 &&
                  setValue(
                    "hscpercentage",
                    !isNaN(percentage) ? parseFloat(percentage).toFixed(2) : ""
                  );
                let cgpa = (value / maxValue) * 100
                watch("marktype") == 1 &&
                  setValue(
                    "hscpercentage",
                    !isNaN(cgpa) ? parseFloat(cgpa).toFixed(2) : "");
              }}
              InputProps={{ inputMode: "decimal" }}
              onInput={
                watch("marktype") === 1
                  ? cgpaValidation
                  : percentageNumberValidation
              }
              error={!!errors.hscmarksoutof}
              helperText={errors.hscmarksoutof?.message}
              FormHelperTextProps={{ style: { margin: 0 } }}
              InputLabelProps={{ shrink: !!watch("hscmarksoutof") }}
            />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <TextField
              InputProps={{ inputMode: "decimal" }}
              fullWidth
              disabled={
                disabled ||
                parseInt(watch("appearingfor")) === 1 ||
                watch("marktype") === "" ||
                getValues("hscmarksobtained") === ""
              }
              variant="outlined"
              type="text"
              label={
                <span>
                  HSC / Diploma Marks / CGPA Out Of
                  {!(
                    parseInt(watch("appearingfor")) === 1 ||
                    watch("marktype") === ""
                  ) && (
                    <span className="error" style={{ color: "#666666" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Enter the HSC / Diploma Marks / CGPA Out Of"
              {...register("hscmarksoutof", {
                required:
                  parseInt(watch("appearingfor")) === 0
                    ? "HSC / Diploma Marks / CGPA Out Of is required"
                    : false,
                validate: (value) => {
                  const obtainedMarks = parseFloat(
                    getValues("hscmarksobtained"),
                    10
                  );

                  if (value && obtainedMarks > parseFloat(value, 10)) {
                    return "Marks out of must be greater than or equal to marks obtained";
                  }
                  return watch("marktype") === 1
                    ? cgpaValidation(value)
                    : outOfMarkValidation(value);
                },
              })}
              onChange={(e) => {
                const maxValue = parseFloat(e.target.value);
                const value = parseFloat(getValues("hscmarksobtained"));
                const maxAllowed = maxValue <= 900 ? maxValue : 900;
                const obtainedMarks = parseFloat(
                  getValues("hscmarksobtained"),
                  10
                );
                trigger("hscmarksobtained");
                trigger("hscmarksoutof");
                clearErrors("D");
                setValue("hscmarksoutof", e.target.value);

                // if (value > maxAllowed) {
                //   e.target.value = maxAllowed;
                // }
                if (maxValue < obtainedMarks) {
                  setError("hscmarksoutof", {
                    type: "manual",
                    message:
                      "Marks out of must be greater than or equal to marks obtained",
                  });
                } else {
                  clearErrors("hscmarksoutof");
                  clearErrors("hscpercentage");
                  setValue("hscmarksoutof", e.target.value);
                  if (watch("hscmarktype") === 1) {
                    const cgpaError = cgpaValidation(maxValue);
                    if (cgpaError) {
                      setError("hscmarksoutof", {
                        type: "manual",
                        message: cgpaError,
                      });
                    }
                  } else if (watch("marktype") === 2) {
                    percentageValidation();
                  }
                }
                let percentage = (value / maxValue) * 100;
                if (watch("marktype") === 2) {
                  setValue(
                    "hscpercentage",
                    !isNaN(percentage) ? percentage.toFixed(2) : ""
                  );
                }

                if (watch("marktype") === 1) {
                  let cgpa = (value / maxValue) * 100;
                  setValue(
                    "hscpercentage",
                    !isNaN(cgpa) ? cgpa.toFixed(2) : ""
                  );
                }

                trigger("hscmarksobtained");
                trigger("hscmarksoutof");
              }}
              error={!!errors.hscmarksoutof}
              helperText={errors.hscmarksoutof?.message}
              FormHelperTextProps={{ style: { margin: 0 } }}
              InputLabelProps={{ shrink: !!watch("hscmarksoutof") }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
                inputMode: "numeric",
              }}
              fullWidth
              disabled={
                disabled ||
                parseInt(watch("marktype")) !== 1 ||
                parseInt(watch("marktype")) !== 2
              }
              variant="outlined"
              type="number"
              min="0"
              label={
                <span>
                  {"HSC / Diploma Equivalent Percentage"}{" "}
                  {!(parseInt(watch("marktype")) !== 1) && (
                    <span className="error" style={{ color: "#666666" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder={"HSC / Diploma Equivalent Percentage"}
              {...register("hscpercentage", {
                required:
                  parseInt(watch("appearingfor")) === 0
                    ? "HSC / Diploma Equivalent Percentage % is required"
                    : false,
                validate: percentageValidation,
              })}
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
              error={!!errors.hscpercentage}
              helperText={errors.hscpercentage?.message}
              FormHelperTextProps={{ style: { margin: 0 } }}
              InputLabelProps={{ shrink: !!watch("hscpercentage") }}
              InputProps={{ inputMode: "decimal" }}
            />
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          pb={2}
          pt={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px" }}>
            <BackButton handleBack={handleBack} />
          </Box>

          <Box sx={{ display: "flex", gap: "8px" }}>
            {/* <SaveButton
              name={"Edit"}
              handleDraft={() => {
                setDisabled(false);
                setEditMode(true);
              }}
            />
            <BackButton name={"clear"} handleBack={handleCancel} />
            {disabled ? (
              <SaveButton
                name={"Save As Draft"}
                handleDraft={() => setDisabled(false)}
              />
            ) : (
              <SaveButton
                name={draftButtonLoader ? "loader" : "Save As Draft"}
                handleDraft={() => handleDraft(getValues)}
              />
            )}
            <SubmitButton
              name={buttonLoader ? "loader" : "Next"}
              handleSubmit={handleSubmit(handleSave)}
            /> */}

            {((otherDetails[0]?.status !== 1 &&
              otherDetails[0]?.status !== 2) ||
              editMode ||
              Cookies.get("draftclicked")) && (
              <>
                {/* <BackButton name={"clear"} handleBack={handleCancel} /> */}
                <SaveButton
                  name={"Save As Draft"}
                  handleDraft={() => handleDraft(getValues)}
                />
                <SubmitButton
                  name={"Next"}
                  handleSubmit={handleSubmit(handleSave, onError)}
                />
              </>
            )}

            {/* {(editMode || Cookies.get('draftclicked')) && ( */}
            {/* <> */}
            {/* <BackButton name={"clear"} handleBack={handleCancel} /> */}
            {/* <SaveButton
                  name={"Save As Draft"}
                  handleDraft={() => handleDraft(getValues)}
                />
                <SubmitButton
                  name={buttonLoader ? "Save As Draft" : "Next"}
                  handleSubmit={handleSubmit(handleSave, onError)}
                /> */}
            {/* </> */}
            {/* )} */}

            {!editMode &&
              !Cookies.get("draftclicked") &&
              (otherDetails[0]?.status === 1 ||
                otherDetails[0]?.status === 2) && (
                <>
                  <SaveButton
                    name={"Edit"}
                    handleDraft={() => {
                      setDisabled(false);
                      setEditMode(true);
                    }}
                  />
                  <SaveButton
                    name={"Next"}
                    handleDraft={() =>
                      navigate(
                        `/${Cookies.get(
                          "mhet_cnd_project"
                        )}/registration?page=uploads`
                      )
                    }
                  />
                </>
              )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
