import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../redux/auth/actions";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import verifyImage from "../../assets/images/verify.png";
import {
  DecryptFunction,
  DecryptObjectFunction,
} from "../../utils/cryptoFunction";
import PrimaryButton from "../../common/button/primaryButton";
import { hashMobile } from "../../utils/validations";
import OTPInput from "react-otp-input";
import { cssProperties } from "../../utils/commonCssProperties";
import { PiPencilSimpleLineBold } from "react-icons/pi";
import commonActions from "../../redux/common/actions";
import keycloakService from "../../modules";

export default function MobileVerification(props) {
  const {
    otp,
    setOtp,
    error,
    setError,
    setResendCountdown,
    resendCountdown,
    setWrongotp,
    attempt,
    wrongOTP,
    red,
    setRed,
    setAttempt,
    timeRemaining,
    setTimeRemaining,
    timeRemainingmin,
    setTimeRemainingmin,
    timeRemainingsec,
    setTimeRemainingsec,
    mobileno,
    timer,
    setTimer,
    difference,
    mobileverified,
    setMobileVerified,
    setOpenDialog,
    openDialog
} = props;

 const [focusedInput, setFocusedInput] = useState(null);
 const modifyOtp = otp?.split("");
 const otpValue = Cookies.get("mhet_cnd_mobileno_otp");
 const dispatch = useDispatch();
 const navigate = useNavigate();



 const candidatename = keycloakService?.getUserInfo('name');
 const emailid = keycloakService?.getUserInfo('email');
 const mobno = keycloakService?.getUserInfo('phone_number');
 const dob = keycloakService?.getUserInfo('birthdate');
const isEmailChanged =  false;
const temppassword =  '';
console.log('mobileno---',mobileno);
const data = { candidatename,
  emailid,
  mobileno,
  temppassword,
  dob,
  isEmailChanged}

 

  const inputStyle = (isFocused) => ({
    width: "38px",
    height: "40px",
    margin: "5px",
    borderRadius: "8px",
    outline: "none",
    border:
      // modifyOtp.join("") === otpValue
        // ? 
        // "1px solid #504E50"
        // : "red"
        // ? "2px solid red"
        // : isFocused
        // ?
         "5px solid #BDD8FE",
        // : "",
    // border: modifyOtp.join("") === otpValue ? "1px solid #504E50" :modifyOtp.join("") !== otpValue ? "2px solid red": isFocused ? "5px solid #BDD8FE" :"",
    // border: modifyOtp.join("") === otpValue ? "1px solid #504E50" :modifyOtp.join("") !== otpValue ? "2px solid red": isFocused ? "5px solid #BDD8FE" :"",
    textAlign: "center",
  });

  function formatTime(number) {
    return number < 10 ? "0" + number : number;    
  }

  useEffect(() => {
    console.log("Attempt",attempt,"Wrongotp",wrongOTP)
    setRed(false)
    if ( attempt === 5 && wrongOTP===5) {

      const initialTime = difference
        ? timeRemainingmin * timeRemainingsec
        : 30 * 60;
      startTimer(initialTime);
    }
  }, [wrongOTP, attempt]);

  useEffect(() => {
    let timer;
    if (resendCountdown > 0) {
      timer = setTimeout(() => setResendCountdown(resendCountdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [resendCountdown]);

  const handleVerify = () => {
    setRed(false);
    const otpValue = DecryptFunction(Cookies.get("mhet_cnd_mobileno_otp"));
    const modifyOtp = otp.split("");
    dispatch({
      type: authActions.VERIFY_MOBILENO_FOR_SIGNUP,
      payload: {
        data: {
          token: Cookies.get("mhet_cnd_temptoken"),
          mobileno: Cookies.get("mhet_cnd_mobileno") ?Cookies.get("mhet_cnd_mobileno") : mobno,
          otp: otp,
          emailid:emailid,
          dob:dob,
          candidatename:candidatename

        },
        navigate: navigate,
        setRed: setRed,
        setWrongotp: setWrongotp,
        wrongOTP: wrongOTP,
        setAttempt:setAttempt,
        attempt: attempt,
        setMobileVerified:setMobileVerified
      },
    });
    if (modifyOtp.length === 6) {
      if (modifyOtp.join("") === otpValue) {
        // Cookies.remove("mhet_cnd_mobileno_otp");
        setRed(false);
      } else {
        // setError("Wrong OTP Entered");
        // setWrongotp(wrongOTP + 1)
        // setRed(true)
      }
    } else {
      setError("Enter valid OTP");
    }
  };
  const startTimer = (initialTime) => {
    setResendCountdown(0);
    setTimeRemaining(initialTime);

    if (timer) {
      clearInterval(timer);
    }

    let now = new Date();
    let endTime = new Date(now);
    endTime.setMinutes(endTime.getMinutes() + 30);

    let hours = formatTime(now.getHours());
    let minutes = formatTime(now.getMinutes());
    let seconds = formatTime(now.getSeconds());
    Cookies.set("otpSignupStartTime", new Date());

    let endHours = formatTime(endTime.getHours());
    let endMinutes = formatTime(endTime.getMinutes());
    let endSeconds = formatTime(endTime.getSeconds());

    Cookies.set("otpSignupEndTime", endTime);


    const newTimer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(newTimer);
          Cookies.remove("otpSignupEndTime");
          Cookies.remove("otpSignupEndTime");
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    setTimer(newTimer);
  };

  const handleResendOTP = () => {
    setOtp("");
    setResendCountdown(120);
    dispatch({
      type: authActions.SEND_OTP_FOR_MOBILE_VERIFICATION,
      payload: {
        data: {
          mobileno: Cookies.get("mhet_cnd_mobileno"),
          candidatename:candidatename
          // navigate: navigate,
        },
      },
    });
    setAttempt(attempt + 1);
    if(attempt <=5){
        setWrongotp(0)
    }
  };
  const handleLog = () => {};

  return (
    <Dialog
      open={
        openDialog
      }
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          minHeight: "80vh",
          borderRadius: "21px 21px 10px 10px",
          background: "#FEFEFEFE",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: "20px",
          fontWeight: 500,
          color: "#212121",
          textAlign: "center",
        }}
      ></DialogTitle>

       {!mobileverified? <DialogContent>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              minHeight: "70vh",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "24.2px",
                textAlign: "center",
              }}
            >
              Mobile verification
            </Typography>
            <Typography
              mt={2}
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: "center",
              }}
            >
              {" "}
              We have sent an OTP to your registered phone number. Enter the OTP
              below
            </Typography>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: "center",
              }}
            >
              {" "}
              to verify your phone number.
            </Typography>
            <Typography
              mt={2}
              sx={{
                width: "145px",
                height: "20px",
                gap: "16px",
                fontWeight: "bold",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "16px",
                }}
              >
                <Box sx={{ display: "flex", gap: "4px" }}>
                  <Box>+91 </Box>
                  {/* {hashMobile(
                    DecryptObjectFunction(Cookies.get("mhet_cnd_tempdata"))
                      ?.mobileno
                  )} */}
                  {keycloakService?.getUserInfo('phone_number')}
                </Box>
                {/* <PiPencilSimpleLineBold
                  onClick={() => handleEdit()}
                  style={{
                    color: `${cssProperties?.color?.primary}`,
                    cursor: "pointer",
                  }}
                  size={20}
                /> */}
              </Box>
            </Typography>
            <Typography
              mt={2}
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "bold",
                lineHeight: "16.94px",
                textAlign: "center",
              }}
            >
              {/* {error && <small className="error">{error}</small>} */}
            </Typography>
            {wrongOTP >= 1 && (
              <Typography
                mt={2}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "bold",
                  lineHeight: "16.94px",
                  textAlign: "center",
                  color: "red",
                }}
              >
                {" "}
                Wrong OTP entered
                 ( {wrongOTP} / 5){" "}
              </Typography>
            )}
            <Box my={2}>
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderInput={(props, index) => (
                  <input
                    {...props}
                    style={inputStyle(focusedInput === index)}
                    onFocus={() => setFocusedInput(index)}
                    onBlur={() => setFocusedInput(null)}
                  />
                )}
              />
            </Box>

            {timeRemainingmin > 0 && timeRemainingsec > 0 && (
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "bold",
                  lineHeight: "16.94px",
                  textAlign: "center",
                  color: "red",
                }}
              >
                <div>
                  User have to wait for {timeRemainingmin} :{timeRemainingsec}{" "}
                  to enable Resend OTP{" "}
                </div>
              </Typography>
            )}
            <Box pt={2} 
            sx={{ width:  "24rem" }}>

                            <PrimaryButton
                              text={"Verify"}
                              handleClick={handleVerify}
                              disabled={
                                otp?.length !== 6 ||
                                wrongOTP == 5 && attempt ===5 || wrongOTP == 5 ||
                                (timeRemainingmin > 0 && timeRemainingsec > 0)
                              }
                            />
            </Box>

            <Grid
              sx={{
                width: "24rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {!resendCountdown > 0 && (
                <Typography
                  mt={2}
                  sx={{
                    cursor:
                      attempt === 5 && wrongOTP===5
                        ? "not-allowed"
                        : "pointer",
                    color:
                      attempt === 5 && wrongOTP===5
                        ? "grey"
                        : `${cssProperties?.color?.primary}`,
                    fontWeight: "bold",
                  }}
                  onClick={
                    attempt === 5 && wrongOTP===5
                      ? handleLog
                      : handleResendOTP
                  }
                >
                  Resend OTP
                </Typography>
              )}
              {resendCountdown > 0 && (
                <Typography
                  sx={{
                    color: `${cssProperties?.color?.primary}`,
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                  textAlign={"left"}
                  mt={2}
                >
                  RESEND OTP IN{" "}
                  {resendCountdown > 0 ? `(${resendCountdown}) SEC` : "0 SEC"}
                </Typography>
              )}
              <Typography
                mt={2}
                sx={{
                  color: `${cssProperties?.color?.primary}`,
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                {attempt} / 5 &nbsp;
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>:
        <DialogContent>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              minHeight: "70vh",
            }}
          >
            <Box
              component="img"
              sx={{ height: "90px", width: "auto" }}
              src={verifyImage}
            />
            <Typography
              mt={2}
              sx={{
                fontFamily: "Inter",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "24.2px",
                textAlign: "center",
              }}
            >
              Mobile number Verified successfully
            </Typography>
            <Box pt={2} sx={{ width:"30rem" }}>
              <PrimaryButton
                handleClick={() =>
                  setOpenDialog(false)                }
                text={"Continue"}
              />
            </Box>
          </Grid>
        </DialogContent>}
 
    </Dialog>
  );
}
